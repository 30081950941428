import api from "../../Utils/interceptors";

export const getTodaysTimesheetOfAllEMployees = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_TODAY'S_TIMESHEETS_REQUEST",
    });
    const link = `/timesheet/users/today`;
    const { data } = await api.get(link);
    dispatch({
      type: "GET_TODAY'S_TIMESHEETS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_TODAY'S_TIMESHEETS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const getMonthlyTimesheetOfSelectedEmployee =
  ({ id, month, year }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_MONTHLY_EMPLOYEE_TIMESHEET_REQUEST",
      });
      const link = `/timesheet/user/${id}?year=${year}&month=${month}`;
      const { data } = await api.get(link);
      dispatch({
        type: "GET_MONTHLY_EMPLOYEE_TIMESHEET_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_MONTHLY_EMPLOYEE_TIMESHEET_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
