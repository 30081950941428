import { Button, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Modal from "../../../../../Components/Modal/Modal";
import AutoCompleteSearch from "../../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartments } from "../../../../../Redux/Actions/HR";
import {
  clearErrors,
  updateDepartmentByHR,
} from "../../../../../Redux/Actions/Departments";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { Spinner } from "react-bootstrap";

const UpdateDepartmentModal = ({
  classes,
  updateModalOpen,
  setUpdateModalOpen,
  page,
  rowsPerPage,
  updateDepartmentDetails,
  setUpdateDepartmentDetails,
  HODId,
  setHODId,
  loading,
}) => {
  const dispatch = useDispatch();
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees,
  );
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.updateDepartment);
  useEffect(() => {
    if (isUpdated?.success) {
      successNotify(isUpdated?.message);
      dispatch({ type: "UPDATE_DEPARTMENT_RESET" });
      setUpdateModalOpen(false);
      dispatch(getAllDepartments(page, rowsPerPage));
      setUpdateDepartmentDetails({
        id: "",
        department_name: "",
      });
      setHODId("");
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch(clearErrors());
    }
  }, [isUpdated, updateError]);

  const handleUpdateDepartment = () => {
    dispatch(updateDepartmentByHR(updateDepartmentDetails, HODId));
  };
  const handleCancel = () => {
    setUpdateModalOpen(false);
    setUpdateDepartmentDetails({
      id: "",
      department_name: "",
    });
    setHODId("");
  };
  return (
    <Modal
      open={updateModalOpen}
      setOpen={setUpdateModalOpen}
      title="Update Department"
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="large" animation="border" />
        </Box>
      ) : (
        <>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Department Name
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Enter Department Name"
              fullWidth
              className={classes.inputContainer}
              value={updateDepartmentDetails?.department_name}
              onChange={(e) =>
                setUpdateDepartmentDetails({
                  ...updateDepartmentDetails,
                  department_name: e.target.value,
                })
              }
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label} sx={{ mb: 1 }}>
              Head Of Department
            </InputLabel>
            <AutoCompleteSearch
              data={employees?.employees}
              departmemntData={HODId}
              setDepartmentData={setHODId}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                className={classes.cancelBtn}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                className={classes.createBtn}
                onClick={() => handleUpdateDepartment()}
                disabled={updateLoading ? true : false}
              >
                {updateLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default UpdateDepartmentModal;
