import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import OtpInput from "react18-input-otp";
import classes from "./RebnderModal.module.css";
const RebnderModal = ({ setShow, show, handlePinCode, loader }) => {
  const [otp, setOtp] = useState("");
  return (
    <Modal
      title="Change Pin Code"
      size={"md"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <div className={classes.main__pin}>
        <span onClick={() => setShow(false)}>
          <IoIosClose />
        </span>
        <div className={classes.opt__box}>
          <label>Enter Pin Code</label>
          <OtpInput
            containerStyle={classes.optCont}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={true}
            isInputNum={true}
            isInputSecure={true}
            onSubmit={() => handlePinCode(otp)}
          />
        </div>
        <div className={`password_submit_container ${classes.submitBtnMain}`}>
          <button disabled={loader} onClick={() => handlePinCode(otp)}>
            {loader ? <Spinner animation="border" size="sm" /> : "Submit"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RebnderModal;
