import { Box, Paper } from "@mui/material";
import React from "react";
import Header from "../../../../Components/Header/Header";
import MetaData from "../../../../Components/MetaData";
import AllAnnouncements from "../../../HR/Pages/Announcement/AllAnnouncements";
import store from "../../../../Redux/store";
import Loader from "../../../../Utils/Loader";

const Announcement = () => {
  const loading = store.getState().getAnnouncement?.loading;
  return (
    <div className="page_responsive">
      <Header title="ANNOUNCEMENTS" />
      <MetaData title={`Announcements -- HRM`} />
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            margin: "20px 30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper
            sx={{
              width: "75%",
              borderRadius: "30px",
              border: "none",
              boxShadow:
                "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
              p: 3,
              pb: 7,
            }}
          >
            <AllAnnouncements height={600} />
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default Announcement;
