import api from "../../Utils/interceptors";

export const createDesignationByHR = (designationName) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_DESIGNATION_REQUEST",
    });

    const { data } = await api.post("designation/create", {
      designation_name: designationName,
    });
    dispatch({
      type: "CREATE_DESIGNATION_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_DESIGNATION_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const getDesignationDetails = (desId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_DESIGNATION_DETAILS_REQUEST",
    });
    const { data } = await api.get(`designation/${desId}`);
    dispatch({
      type: "GET_DESIGNATION_DETAILS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_DESIGNATION_DETAILS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const updateDesignationByHR = (designationData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_DESIGNATION_REQUEST",
    });

    const { data } = await api.put(`designation/${designationData._id}`, {
      designation_name: designationData.designation_name,
    });
    dispatch({
      type: "UPDATE_DESIGNATION_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "UPDATE_DESIGNATION_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const deleteDesignationByHR = (desId) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_DESIGNATION_REQUEST",
    });

    const { data } = await api.delete(`designation/${desId}`);
    dispatch({
      type: "DELETE_DESIGNATION_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "DELETE_DESIGNATION_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
