import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import { FiSearch } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { getAllFilteredEmployees } from "../../../../Redux/Actions/HR";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../Components/Header/Header";
import MetaData from "../../../../Components/MetaData";
import AddRequestModal from "./AddRequestModal";
import {
  getAllRequests,
  indivisualRequestDeleted,
} from "../../../../Redux/Actions/Requests";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import Loader from "../../../../Utils/Loader";
import { Avatar, Typography } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { BiSearch, BiReset } from "react-icons/bi";
import { useStyle } from "../Attendance/attendance-styles";

const Requests = () => {
  const [id, setId] = useState();
  const [addModal, setAddModal] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState("");
  const [pageSearch, setPageSearch] = useState(false);
  const [isSearched, setIsSearch] = useState(false);
  const { employees: filtered } = useSelector(
    (state) => state.filteredEmployees
  );
  const { loading, requests, error, requestCount } = useSelector(
    (state) => state.getRequests
  );

  const { success } = useSelector((state) => state.createRequest);
  const {
    deleted,
    loading: deletedLoading,
    error: deleteError,
  } = useSelector((state) => state.deleteRequest);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove particular data from localStorage
      window.sessionStorage.removeItem("saveUser");

      setSearchName("");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const getUser = JSON.parse(window.sessionStorage.getItem("saveUser"));
    if (getUser) {
      setSearchName(getUser.full_name);
      setIsSearch(true);
      dispatch(getAllFilteredEmployees());
      dispatch(getAllRequests(page, size, getUser?._id));
    } else {
      setIsSearch(false);
      dispatch(getAllFilteredEmployees());
      dispatch(getAllRequests(page, size));
    }
  }, []);

  useEffect(() => {
    dispatch(getAllFilteredEmployees());
    dispatch(getAllRequests(page, size));
  }, [page, size, pageSearch]);

  useEffect(() => {
    if (success) {
      setAddModal(false);
      dispatch(getAllRequests(page, size));
    }
    if (deleted?.success) {
      successNotify(deleted?.message);
      dispatch({ type: "INDIVISUAL_REQUEST_DELETE_RESET" });
      dispatch(getAllRequests(page, size));
    }
    if (deleteError) {
      errorNotify(deleteError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [success, deleted?.success, deleteError]);

  const handleRequestDelete = (e, id) => {
    e.preventDefault();
    dispatch(indivisualRequestDeleted(id));
  };
  const ticketsColumns = [
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "request_by",
      label: "Request By",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography
                onClick={() => navigate(`/HR/employee/${value?.userId}`)}
                style={{
                  cursor: "pointer",
                }}
                sx={{ pl: 1 }}
                className="fs-12 link__color"
              >
                {value ? value.full_name : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "subject",
      label: "subject",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1, margin: "0 auto" }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "request_date",
      label: "Request Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "line_manager_status",
      label: "HOD Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                  ? classes.absent
                  : value === "pending"
                  ? classes.halfDay
                  : classes.late
              }`}
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              // className="fs-12"
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "hr_status",
      label: "HR Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                  ? classes.absent
                  : value === "pending"
                  ? classes.halfDay
                  : classes.late
              }`}
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              // className="fs-12"
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },

    // onClick={() => navigate(`/HR/leave-management/${tableMeta.rowData[1]}`)}
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(`/HR/request/${tableMeta.rowData[0]}`, {
                    state: {
                      update: true,
                    },
                  })
                }
              >
                <FaEye />
              </button>
              {/* onClick={() => handleLeaveID(tableMeta.rowData[1])} */}
              <button
                className="delete_btn"
                onClick={(e) => handleRequestDelete(e, tableMeta.rowData[0])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];

  const handleSearchRequests = async (e) => {
    const filterSaerch = filtered?.employees?.filter((e) => e._id == id)[0];
    if (id) {
      window.sessionStorage.setItem("saveUser", JSON.stringify(filterSaerch));
      const saveName = await JSON.parse(
        window.sessionStorage.getItem("saveUser")
      );
      setSearchName(saveName.full_name);
    }
    e.preventDefault();
    dispatch(getAllRequests(page, size, id));
  };

  const clearSearch = () => {
    setPageSearch(!pageSearch);
    window.sessionStorage.removeItem("saveUser");
    setSearchName("");
  };

  return (
    <>
      <div className="page_responsive">
        <Header title="Requests" />
        <MetaData title={`Request Module -- HRM`} />
        <Container fluid>
          <Row className="m-0">
            <Col md={12}>
              <div className="employees_main_selection">
                <Form>
                  <Row className="align-items-start justify-content-between">
                    {/* <Col md={2}>
                      <Input>
                        <Form.Label>Employee Id</Form.Label>
                        <Form.Control
                          type="text"
                        //   {...register("employee_code")}
                          placeholder="Enter Employee Id"
                        />
                      </Input>
                    </Col> */}
                    <Col md={4}>
                      <Row>
                        <Input>
                          <Form.Label>Employee Name</Form.Label>
                          <AutoCompleteSearch
                            data={filtered?.employees}
                            departmentData={id}
                            setDepartmentData={setId}
                            title={"Search Employee"}
                          />
                        </Input>
                      </Row>
                      <Row>
                        <div className="d-flex align-items-end mt-2">
                          {searchName && (
                            <div
                              className="mx-2 rounded-2 p-2"
                              style={{
                                fontSize: "12px",
                                fontStyle: "italic",
                                background: "#efefef",
                                width: "fit-contain",
                              }}
                            >
                              <BiSearch />{" "}
                              <span className="mx-2">{searchName}</span>
                            </div>
                          )}
                        </div>
                      </Row>
                    </Col>

                    <Col md={4} className="gap-3">
                      <div className="add_employees_btn_container mb-3">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setAddModal(true);
                          }}
                        >
                          {" "}
                          <AiOutlinePlus /> Add Request
                        </button>
                      </div>
                      <div className="search_btn_container">
                        <button onClick={handleSearchRequests}>
                          <FiSearch />
                          Search
                        </button>
                      </div>
                      <div className="search_btn_container mt-3">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            clearSearch();
                          }}
                        >
                          <BiReset />
                          Clear Search
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col md={12}>
              {loading ? (
                <Loader />
              ) : (
                <div className="tickets_management_table">
                  <MuiDataTable
                    title={"ALL TICKETS"}
                    columns={ticketsColumns}
                    data={requests}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={size}
                    setRowsPerPage={setSize}
                    count={requestCount}
                    tableId="hr_requests_table"
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <AddRequestModal modalOpen={addModal} setModalOpen={setAddModal} />
    </>
  );
};

export default Requests;
