import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import "./Notification.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { dummyNotifications } from "./dummyData";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllNotifications,
  readNotifications,
} from "../../Redux/Actions/Employee";
import { errorNotify } from "../../Utils/toast";
import ProfilePic from "../../Asssets/Images/profile.png";
const Notification = () => {
  const dispatch = useDispatch();
  const { loading, notifications, error } = useSelector(
    (state) => state.employeeNotifications,
  );
  const {
    loading: readLoading,
    success,
    error: readError,
  } = useSelector((state) => state.markAsRead);
  const { user } = useSelector((state) => state.currentUser);

  const [readNotify, setReadNotify] = useState();
  useEffect(() => {
    if (success) {
      dispatch(getAllNotifications());
      if (user?.user?.role === "HR") {
        if (readNotify?.notification_type === "Leave Application") {
          navigate(`/HR/leave-management/${readNotify.event}`, {
            state: {
              update: true,
            },
          });
        }
        if (readNotify?.notification_type === "Ticket") {
          navigate(`/HR/tickets/${readNotify.event}`, {
            state: {
              update: true,
            },
          });
        }
        if (readNotify?.notification_type === "Request") {
          navigate(`/HR/request/${readNotify.event}`, {
            state: {
              update: true,
            },
          });
        }
      } else if (user?.user?.role === "employee") {
        if (readNotify?.notification_type === "Leave Application") {
          navigate(`/employee/leaves/${readNotify.event}`, {
            state: {
              update:
                user?.user?._id === user?.user?.department?.head_of_department
                  ? true
                  : false,
            },
          });
        }
        if (readNotify?.notification_type === "Ticket") {
          navigate(`/employee/tickets/${readNotify.event}`, {
            state: {
              update: true,
            },
          });
        }
      } else if (user?.user?.role === "manager") {
        if (readNotify?.notification_type === "Leave Application") {
          navigate(`/manager/leaves/${readNotify.event}`, {
            state: {
              update:
                user?.user?._id === user?.user?.department?.head_of_department
                  ? true
                  : false,
            },
          });
        }
        if (readNotify?.notification_type === "Ticket") {
          navigate(`/manager/tickets/${readNotify.event}`, {
            state: {
              update: true,
            },
          });
        }
      }
      dispatch({ type: "READ_NOTIFICATION_RESET" });
    }
    if (readError) {
      errorNotify(readError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [success, readError]);
  const navigate = useNavigate();
  const handleNotification = (e, data) => {
    e.preventDefault();
    setReadNotify(data);
    dispatch(readNotifications(data._id));
  };
  return (
    <div className="noti_container">
      <h6>Notifications</h6>

      <Container>
        <React.Fragment>
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : notifications.length > 0 ? (
            notifications?.map((data, index) => {
              return (
                <Row
                  className="justify-content-center notifications_row"
                  style={{ cursor: readLoading ? "wait" : "pointer" }}
                  key={index}
                  onClick={(e) => handleNotification(e, data)}
                >
                  <Col md={3}>
                    <div className="avatar_img_container">
                      <img
                        src={
                          data?.from?.profile_picture[0]?.fileName
                            ? data?.from?.profile_picture[0]?.fileName
                            : ProfilePic
                        }
                        alt="avatar"
                      />
                    </div>
                  </Col>

                  <Col md={9} className="p-0">
                    <div className="notification_content">
                      <h5>{data?.from?.full_name}</h5>
                      <p>{data?.message}</p>
                      <span>
                        <AccessTimeIcon />{" "}
                        {new Date(data.createdAt).toLocaleString()}
                      </span>
                    </div>
                  </Col>
                </Row>
              );
            })
          ) : (
            <p className="pt-3 text-center">No New Notifications!</p>
          )}
        </React.Fragment>
      </Container>
    </div>
  );
};

export default Notification;
