import { Box, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import Header from "../../../../Components/Header/Header";
import MetaData from "../../../../Components/MetaData";
import Loader from "../../../../Utils/Loader";
import BankDetailsCard from "../../../HR/Pages/StaffManagement/EmployeeDetails/BankDetailsCard";
import DocumentDetailsCard from "../../../HR/Pages/StaffManagement/EmployeeDetails/DocumentDetailsCard";
import JobDetailsCard from "../../../HR/Pages/StaffManagement/EmployeeDetails/JobDetailsCard";
import PersonalDetailsCard from "../../../HR/Pages/StaffManagement/EmployeeDetails/PersonalDetailsCard";
import { useStyle } from "../../../HR/Pages/StaffManagement/EmployeeDetails/EmployeeDetails-style";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getCurrentUser,
  changePassword,
  changePinCode,
} from "../../../../Redux/Actions/Auth";
import Avatar from "../../../../Asssets/Images/avatar.png";
import { AiOutlineCamera } from "react-icons/ai";
import { Form, Spinner } from "react-bootstrap";
import "./Profile.css";
import { AiOutlineUpload } from "react-icons/ai";
import { MdOutlinePassword } from "react-icons/md";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { updateProfilePictureById } from "../../../../Redux/Actions/Employee";
import Modal from "../../../../Components/SiteModal/SiteModal";
import { useForm } from "react-hook-form";
import { changePassValidation } from "../../../../Utils/Validation";
import RebnderModal from "../../../../Components/RebnderModal";
import ChangePinCode from "../../../../Components/ChangePinCode";
import { toast } from "react-toastify";

const Profile = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm();
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [preview, setPreview] = React.useState(null);
  const { loading, user } = useSelector((state) => state.currentUser);
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.updateProfilePic);
  const {
    loading: changePassLoading,
    isChangedPass,
    error: changePasswordError,
  } = useSelector((state) => state.passChanged);
  const {
    loading: changePinLoading,
    isChangedPin,
    error: changePinError,
  } = useSelector((state) => state.pinChanged);
  // test------------
  const [modalOpen, setModalOpen] = useState(false);
  const [isCheck, setIsCheck] = useState("");

  // test------------
  useEffect(() => {
    // dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    if (isChangedPass?.success) {
      successNotify(isChangedPass?.message);
      reset();
      setShow(false);
      dispatch({ type: "CHANGE_PASSWORD_RESET" });
    } else if (changePasswordError) {
      errorNotify(changePasswordError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [isChangedPass?.success, changePasswordError]);
  useEffect(() => {
    if (isChangedPin?.success) {
      successNotify(isChangedPin?.data);
      reset2();
      setModalOpen(false);
      dispatch({ type: "CHANGE_PIN_RESET" });
    } else if (changePinError) {
      errorNotify(changePinError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [isChangedPin?.success, changePinError]);

  useEffect(() => {
    if (isUpdated) {
      successNotify(isUpdated?.message);
      dispatch({ type: "UPDATE_PROFILE_PICTURE_RESET" });
      dispatch(getCurrentUser());
    } else if (updateError) {
      errorNotify(updateError?.message);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [isUpdated, updateError]);

  const AvatarChangeHandler = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setSelectedFile((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onSubmitHandler = handleSubmit(async (data) => {
    if (
      data.newPassword.trim() ||
      data.oldPassword.trim() ||
      data.confirmPassword.trim()
    ) {
      dispatch(changePassword(user?.user.userId, data));
    }
  });
  const onSubmitHandler2 = handleSubmit2(async (data) => {
    if (data.newPinCode !== data.confirmPinCode) {
      return toast.error("New pin code and confirm pin code must match");
    }
    if (
      data.newPinCode.trim() ||
      data.currentPinCode.trim() ||
      data.confirmPinCode.trim()
    ) {
      dispatch(changePinCode(user?.user.userId, data));
    }
  });

  const OnProfileSubmit = async (e) => {
    e.preventDefault();
    if (selectedFile.length > 0) {
      await dispatch(updateProfilePictureById(user?.user.userId, selectedFile));
    } else {
      errorNotify("Please upload an image first");
    }
  };

  const modal = (
    <Modal
      title="Change Password"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <Form onSubmit={onSubmitHandler}>
          <div className="change_password_form_container">
            <div className="form_inner_container">
              <label>Old Password</label>
              <input
                type="password"
                placeholder="Enter an old password"
                {...register("oldPassword", changePassValidation.oldPassword)}
              />
              {errors.oldPassword ? (
                <small className="text-danger">
                  {errors.oldPassword && errors.oldPassword.message}
                </small>
              ) : null}
            </div>

            <div className="form_inner_container">
              <label>New Password</label>
              <input
                type="password"
                placeholder="Enter a new password"
                {...register("newPassword", changePassValidation.newPassword)}
              />
              {errors.newPassword ? (
                <small className="text-danger">
                  {errors.newPassword && errors.newPassword.message}
                </small>
              ) : null}
            </div>

            <div className="form_inner_container">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Enter a confirm password"
                {...register(
                  "confirmPassword",
                  changePassValidation.confirmPassword,
                )}
              />
              {errors.confirmPassword ? (
                <small className="text-danger">
                  {errors.confirmPassword && errors.confirmPassword.message}
                </small>
              ) : null}
            </div>
            <div className="password_submit_container">
              <button type="submit">
                {changePassLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </Form>
      </React.Fragment>
    </Modal>
  );

  return (
    <div className="page_responsive">
      <Header title={"PROFILE"} />
      <MetaData
        title={`${!loading ? user?.user?.full_name : "Employee"} -- HRM`}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ margin: "20px 20px" }} className="edit_profile">
            <Paper
              className={classes.personal_details_card}
              style={{ paddingBottom: "60px" }}
            >
              <Grid container sx={{ alignItems: "center" }}>
                <Grid
                  className={"imgSection"}
                  item
                  md={6}
                  sx={{
                    padding: "10px 40px",
                    display: "flex",
                  }}
                >
                  <div>
                    <div className={"avatar"}>
                      <img
                        src={
                          preview
                            ? preview
                            : user?.user.profile_picture[0]?.fileName
                              ? user?.user.profile_picture[0]?.fileName
                              : Avatar
                        }
                        alt={"avatar"}
                      />
                    </div>

                    <Form onSubmit={OnProfileSubmit}>
                      <div className={"input_file"}>
                        <input
                          type="file"
                          id="file-input"
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            AvatarChangeHandler(e);
                            setPreview(URL.createObjectURL(e.target.files[0]));
                          }}
                          className="file_input"
                        />
                        <label className="file_label" htmlFor="file-input">
                          <AiOutlineCamera />
                          <span>Change Avatar</span>
                        </label>
                      </div>
                      <div className={"change_profile_btn_container"}>
                        <button type={"submit"}>
                          <React.Fragment>
                            {updateLoading ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              <React.Fragment>
                                <AiOutlineUpload /> Upload{" "}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        </button>

                        <button
                          type={"button"}
                          onClick={() => {
                            setShow(true);
                            setIsCheck("pass");
                          }}
                        >
                          <MdOutlinePassword /> Change Password
                        </button>
                        <button
                          onClick={() => {
                            setModalOpen(true);
                            setIsCheck("pin");
                          }}
                          type={"button"}
                        >
                          <MdOutlinePassword /> Change Pin Code
                        </button>
                      </div>
                    </Form>
                  </div>
                  <Box sx={{ paddingLeft: "20px" }}>
                    <Typography
                      style={{ paddingTop: "20px" }}
                      variant="h6"
                      className={classes.personName}
                    >
                      {user?.user?.full_name}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      {user?.user?.designation?.designation_name}
                    </Typography>
                    <div className={classes.department_box}>
                      <Typography sx={{ fontWeight: "600" }} className="fs-14">
                        {user?.user?.department?.department_name}
                      </Typography>
                    </div>
                  </Box>
                </Grid>
                <Grid item md={6} className={classes.personal_detail_box}>
                  <Box>
                    <Typography variant="h6">Phone:</Typography>
                    <Typography>{user?.user?.phone}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Email:</Typography>
                    <Typography>{user?.user?.personal_email}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Gender:</Typography>
                    <Typography>{user?.user?.gender}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Birthday:</Typography>
                    <Typography>
                      {moment(user?.user.DOB).format("DD-MM-YYYY")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Address:</Typography>
                    <Typography sx={{ fontSize: "14px", color: "#A5A9B5" }}>
                      {user?.user?.address}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {/* <IconButton
                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <FiEdit style={{ color: "#A5A9B5" }} />
              </IconButton> */}
            </Paper>
          </Box>
          <Box sx={{ margin: "30px 20px" }}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <PersonalDetailsCard classes={classes} employee={user?.user} />
              </Grid>
              <Grid item md={6}>
                <JobDetailsCard classes={classes} employee={user?.user} />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ margin: "60px 20px" }}>
            <BankDetailsCard classes={classes} employee={user?.user} />
          </Box>
          <Box sx={{ margin: "60px 20px" }}>
            <DocumentDetailsCard classes={classes} employee={user?.user} />
          </Box>
        </>
      )}
      {modal}
      {modalOpen && (
        <ChangePinCode
          isLoading={changePinLoading}
          errors={errors2}
          setShow={setModalOpen}
          show={modalOpen}
          register={register2}
          onSubmitHandler={onSubmitHandler2}
        />
      )}
    </div>
  );
};

export default Profile;
