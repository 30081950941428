import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import { FaEdit } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { RiAddLine } from "react-icons/ri";
import { useStyle } from "./department-styles";
import {
  getAllDepartments,
  getAllEmployees,
} from "../../../../../Redux/Actions/HR";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../Utils/Loader";
import DepartmentModal from "./CreateDepartmentModal";
import {
  clearErrors,
  deleteDepartmentByHR,
  getDepartmentDetails,
} from "../../../../../Redux/Actions/Departments";
import UpdateDepartmentModal from "./UpdateDepartmentModal";
import { errorNotify, successNotify } from "../../../../../Utils/toast";

const Departments = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [updateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [createDepartmentName, setCreateDepartmentName] = useState("");
  const [createHODID, setCreateHODID] = useState("");
  const [updateHODID, setUpdateHODID] = useState("");
  const [updateDepartmentDetails, setUpdateDepartmentDetails] = useState({
    id: "",
    department_name: "",
  });
  const {
    loading: departmentLoading,
    department,
    error: departmentError,
  } = useSelector((state) => state.departmentDetails);
  const {
    loading: delDepLoading,
    isDeleted,
    error: delDepError,
  } = useSelector((state) => state.deleteDepartment);
  const {
    loading: departmentsLoading,
    departments,
    departmentCount,
  } = useSelector((state) => state.departments);
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees
  );
  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAllDepartments(page, rowsPerPage));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (isDeleted?.success) {
      successNotify(isDeleted?.message);
      dispatch(getAllDepartments(page, rowsPerPage));
      dispatch({ type: "DELETE_DEPARTMENT_RESET" });
    }
    if (delDepError) {
      errorNotify(delDepError);
      dispatch(clearErrors());
    }
  }, [isDeleted?.success, delDepError]);
  useEffect(() => {
    if (department?.success) {
      setUpdateDepartmentDetails({
        _id: department?.department._id,
        department_name: department?.department?.department_name,
      });
      setUpdateHODID(department?.department?.head_of_department?._id);
    }
  }, [department]);

  const updateDepartment = (depId) => {
    dispatch(getDepartmentDetails(depId));
    setUpdateModalOpen(true);
  };
  const deleteDepartment = (id) => {
    dispatch(deleteDepartmentByHR(id));
  };

  const departmentCols = [
    { name: "sno", label: "S:NO" },
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    { name: "department_name", label: "DEPARTMENT NAME" },
    {
      name: "head_of_department",
      label: "HEAD OF DEPARTMENT",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value?.full_name;
        },
      },
    },
    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                type="button"
                onClick={() => updateDepartment(tableMeta.rowData[1])}
              >
                <FaEdit />
              </button>
              <button
                className="delete_btn"
                type="button"
                onClick={() => deleteDepartment(tableMeta.rowData[1])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];

  let newData = [];
  departments?.map((item, index) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });
  return (
    <>
      {departmentsLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="departmentsTable">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                className="addDepartment_btn"
                onClick={() => setModalOpen(true)}
              >
                <span
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                >
                  <RiAddLine />
                </span>
                Add Department{" "}
              </Button>
            </Box>
            <MuiDataTable
              title="Departments"
              columns={departmentCols}
              data={newData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={departmentCount}
              tableId="departments_table"
            />
          </Box>
        </>
      )}
      <DepartmentModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        page={page}
        rowsPerPage={rowsPerPage}
        departmentData={createDepartmentName}
        setDepartmentData={setCreateDepartmentName}
        HODId={createHODID}
        setHODId={setCreateHODID}
        employees={employees}
      />
      <UpdateDepartmentModal
        classes={classes}
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        page={page}
        rowsPerPage={rowsPerPage}
        updateDepartmentDetails={updateDepartmentDetails}
        setUpdateDepartmentDetails={setUpdateDepartmentDetails}
        HODId={updateHODID}
        setHODId={setUpdateHODID}
        employees={employees}
        loading={departmentLoading}
      />
    </>
  );
};

export default Departments;
