const regex = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const authValidation = {
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
  password: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },
};

// Create User Form Validation

export const createUserForm = {
  full_name: {
    required: {
      value: true,
      message: "Name cannot be empty",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },

  personal_email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },

  password: {
    required: {
      value: true,
      message: "Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },

  role: {
    required: {
      value: true,
      message: "Role cannot be empty",
    },
  },

  department: {
    required: {
      value: true,
      message: "Department cannot be empty",
    },
  },
  manager: {
    // required: {
    //     value: true,
    //     message: "Manager cannot be empty",
    // }
  },

  designation: {
    required: {
      value: true,
      message: "Designation cannot be empty",
    },
  },

  father_husband_name: {
    required: {
      value: true,
      message: "Father/husband name required",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Gender cannot be empty",
    },
  },

  address: {
    required: {
      value: true,
      message: "Address cannot be empty",
    },
  },

  ECE_name: {
    required: {
      value: true,
      message: "ECE name cannot be empty",
    },
  },
  marital_status: {
    required: {
      value: true,
      message: "Martial Status cannot be empty",
    },
  },
  permanent_date: {
    required: {
      value: false,
      message: "Permanent Date cannot be empty",
    },
  },
  joining_date: {
    required: {
      value: true,
      message: "Joining Date cannot be empty",
    },
  },
  line_manager: {
    required: {
      value: true,
      message: "Line Manager cannot be empty",
    },
  },
  shift: {
    required: {
      value: true,
      message: "Shift cannot be empty",
    },
  },
  medical_status: {
    required: {
      value: true,
      message: "Medical status cannot be empty",
    },
  },
  leaves_status: {
    required: {
      value: true,
      message: "leaves status cannot be empty",
    },
  },

  skype_link: {
    required: {
      value: true,
      message: "Link cannot be empty",
    },
  },

  bank_name: {
    required: {
      value: true,
      message: "Bank name cannot be empty",
    },
  },

  account_title: {
    required: {
      value: true,
      message: "Account title cannot be empty",
    },
  },

  branch: {
    required: {
      value: true,
      message: "Branch cannot be empty",
    },
  },

  bank_branch_address: {
    required: {
      value: true,
      message: "bank branch address cannot be empty",
    },
  },

  bank_branch_code: {
    required: {
      value: true,
      message: "bank branch code cannot be empty",
    },
  },

  account_number: {
    required: {
      value: true,
      message: "Account No cannot be empty",
    },
  },

  job_title: {
    required: {
      value: true,
      message: "Job Title cannot be empty",
    },
  },

  CNIC: {
    required: {
      value: true,
      message: "CNIC cannot be empty",
    },
  },

  DOB: {
    required: {
      value: true,
      message: "DOB cannot be empty",
    },
  },

  ECE_number: {
    required: {
      value: true,
      message: "ECE Number cannot be empty",
    },
  },

  employment_status: {
    required: {
      value: true,
      message: "Employment status cannot be empty",
    },
  },

  salary: {
    required: {
      value: true,
      message: "Salary cannot be empty",
    },
    minLength: {
      value: 1,
      message: "Salary must be in positive",
    },
  },

  fuel_allowance: {
    required: {
      value: true,
      message: "Fuel allowance cannot be empty",
    },
  },

  phone: {
    required: {
      value: true,
      message: "Phone No cannot be empty",
    },
  },
};

export const changePassValidation = {
  oldPassword: {
    required: {
      value: true,
      message: "Old Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },

  newPassword: {
    required: {
      value: true,
      message: "New Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Password length must be greater than 7",
    },
  },

  confirmPassword: {
    required: {
      value: true,
      message: "Confirm Password cannot be empty",
    },
    minLength: {
      value: 8,
      message: "Confirm Password length must be greater than 7",
    },
  },
};
export const changePinValidation = {
  oldPinCode: {
    required: {
      value: true,
      message: "Old Pin Code cannot be empty",
    },
    minLength: {
      value: 4,
      message: "Please enter 4 digit pin code",
    },
    maxLength: {
      value: 4,
      message: "Please enter 4 digit pin code",
    },
  },

  newPinCode: {
    required: {
      value: true,
      message: "New Pin Code cannot be empty",
    },
    minLength: {
      value: 4,
      message: "Please enter 4 digit pin code",
    },
    maxLength: {
      value: 4,
      message: "Please enter 4 digit pin code",
    },
  },

  confirmPinCode: {
    required: {
      value: true,
      message: "Confirm Pin Code cannot be empty",
    },
    minLength: {
      value: 4,
      message: "Please enter 4 digit pin code",
    },
    maxLength: {
      value: 4,
      message: "Please enter 4 digit pin code",
    },
  },
};

export const ICreateLeavesValidation = {
  leave_subject: {
    required: {
      value: true,
      message: "Leave Subject cannot be empty",
    },
  },

  leave_application: {
    required: {
      value: true,
      message: "Leave Application cannot be empty",
    },
  },
};

export const ITimeSheetValidation = {
  start_date: {
    required: {
      value: true,
      message: "Start date cannot be empty",
    },
  },

  end_date: {
    required: {
      value: true,
      message: "End date cannot be empty",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email cannot be empty",
    },
    pattern: {
      value: regex.email,
      message: "Email is not valid",
    },
  },
};

export const createLeaveApplicationValidation = {
  start_date: {
    required: {
      value: true,
      message: "Start date cannot be empty",
    },
  },

  end_date: {
    required: {
      value: true,
      message: "End date cannot be empty",
    },
  },
  leave_type: {
    required: {
      value: true,
      message: "Leave type cannot be empty",
    },
  },
  leave_application: {
    required: {
      value: true,
      message: "Leave application cannot be empty",
    },
  },
  leave_subject: {
    required: {
      value: true,
      message: "Leave subject cannot be empty",
    },
  },
};

// Tickets Validation

export const ticketsValidation = {
  priority: {
    required: {
      value: true,
      message: "Priority level cannot be empty",
    },
  },
  status: {
    required: {
      value: true,
      message: "Status cannot be empty",
    },
  },
  subject: {
    required: {
      value: true,
      message: "Ticket subject cannot be empty",
    },
  },
  description: {
    required: {
      value: true,
      message: "Ticket description cannot be empty",
    },
  },
};
