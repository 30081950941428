import { Close } from "@mui/icons-material";
import { Button, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useStyle } from "./modal-styles";

const ReactModal = ({ open, setOpen, title, children, size = 600 }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: parseInt(size),
    height: "90%",
    backgroundColor: "white",
    border: "none",
    boxShadow: 24,
    borderRadius: "20px",
    px: 6,
    pt: 3,
    pb: 5,
    overflow: "auto",
  };
  const classes = useStyle();
  const handleClose = () => setOpen(false);
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "700 !important", color: "#0097D9 !important" }}
            >
              {title}
            </Typography>
            <IconButton
              size="small"
              className={classes.btnStyle}
              onClick={() => handleClose()}
            >
              <Close style={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
          <Box sx={{ mt: 4 }}>{children}</Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ReactModal;
