import api from "../../Utils/interceptors";

export const getLastPayrollsOfEmployees = (page, size) => async (dispatch) => {
  try {
    dispatch({
      type: "LAST_EMPLOYEES_PAYROLLS_REQUEST",
    });

    const { data } = await api.get(
      `payrolls/employees/last?page=${page}&size=${size}`
    );
    dispatch({
      type: "LAST_EMPLOYEES_PAYROLLS_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "LAST_EMPLOYEES_PAYROLLS_FAILED",
      payload: e.response?.data?.message,
    });
  }
};

export const getPayrollsById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_PAYROLL_REQUEST",
    });

    const { data } = await api.get(`payroll/${id}`);
    dispatch({
      type: "GET_PAYROLL_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "GET_PAYROLL_FAILED",
      payload: e.response?.data?.message,
    });
  }
};

export const getPayrolls =
  (page, size, user = "", department = "", month = "", year = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_PAYROLLS_REQUEST",
      });
      let link = `payrolls?page=${page}&size=${size}&user=${user}&department=${department}`;
      if (month === "" || year === "") {
        link = `payrolls?page=${page}&size=${size}&user=${user}&department=${department}`;
      } else {
        link = `payrolls?page=${page}&size=${size}&user=${user}&department=${department}&date=${`${month},${year}`}`;
      }

      const { data } = await api.get(link);
      dispatch({
        type: "GET_PAYROLLS_SUCCESS",
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: "GET_PAYROLLS_FAILED",
        payload: e.response?.data?.message,
      });
    }
  };

export const createPayroll = (payrollData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_PAYROLL_REQUEST",
    });

    const { data } = await api.post("payroll", payrollData);
    dispatch({
      type: "CREATE_PAYROLL_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_PAYROLL_FAILED",
      payload: e.response?.data?.message,
    });
  }
};
export const PayrollByDepartment = (payrollData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_PAYROLL_BY_DEPARTMENT_REQUEST",
    });

    const { data } = await api.post("payroll/department", payrollData);
    dispatch({
      type: "CREATE_PAYROLL_BY_DEPARTMENT_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_PAYROLL_BY_DEPARTMENT_FAILED",
      payload: e.response?.data?.message,
    });
  }
};
export const PayrollByDate = (payrollData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_PAYROLL_BY_DATE_REQUEST",
    });

    const { data } = await api.post("payroll/by-date", payrollData);
    dispatch({
      type: "CREATE_PAYROLL_BY_DATE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_PAYROLL_BY_DATE_FAILED",
      payload: e.response?.data?.message,
    });
  }
};
export const updatePayroll = (id, updateData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_PAYROLL_REQUEST",
    });

    const { data } = await api.put(`/payroll/${id}`, updateData);

    dispatch({
      type: "UPDATE_PAYROLL_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "UPDATE_PAYROLL_FAILED",
      payload: e.response?.data?.message,
    });
  }
};
export const deletePayrollById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_PAYROLL_REQUEST",
    });

    const { data } = await api.delete(`/payroll/${id}`);

    dispatch({
      type: "DELETE_PAYROLL_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "DELETE_PAYROLL_FAILED",
      payload: e.response?.data?.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
