import { Box, Button, InputLabel, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "../../../../../Components/Modal/Modal";
import {
  clearErrors,
  createHolidayByHR,
} from "../../../../../Redux/Actions/Holidays";
import {
  getAllDepartments,
  getAllHolidays,
} from "../../../../../Redux/Actions/HR";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import Loader from "../../../../../Utils/Loader";
import Select from "react-select";

const CreateHolidayModal = ({
  classes,
  modalOpen,
  setModalOpen,
  holidayData,
  setHolidayData,
  page,
  rowsPerPage,
}) => {
  const dispatch = useDispatch();

  const {
    loading: createLoading,
    holiday,
    error: createError,
    success,
  } = useSelector((state) => state.createHoliday);

  const { loading: departmentsLoading, departments } = useSelector(
    (state) => state.departments
  );

  useEffect(() => {
    if (success) {
      successNotify(holiday?.message);
      dispatch({ type: "CREATE_HOLIDAY_RESET" });
      setModalOpen(false);
      dispatch(getAllHolidays(page, rowsPerPage));
    }
    if (createError) {
      errorNotify(createError);
      dispatch(clearErrors());
    }
  }, [success, createError, holiday]);
  const handleHolidayCreate = () => {
    dispatch(
      createHolidayByHR({
        ...holidayData,
        departments: !holidayData?.departments
          ? []
          : holidayData?.departments?.map((ele) => ele?.value),
      })
    );
  };

  useEffect(() => {
    dispatch(getAllDepartments());
  }, []);

  return (
    <ReactModal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create New Holiday"
    >
      {departmentsLoading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Holiday Title
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Enter holiday title"
              fullWidth
              className={classes.inputContainer}
              value={holidayData.holiday_title}
              onChange={(e) =>
                setHolidayData({
                  ...holidayData,
                  holiday_title: e.target.value,
                })
              }
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Holiday Start Date
            </InputLabel>
            <Box className={classes.datePicker}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={holidayData.holiday_start_date}
                  maxDate={holidayData.holiday_end_date}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setHolidayData({
                      ...holidayData,
                      holiday_start_date: newValue,
                    });
                  }}
                  className={classes.inputContainer}
                  renderInput={(params) => (
                    <TextField
                      className={classes.inputContainer}
                      {...params}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Holiday End Date
            </InputLabel>
            <Box className={classes.datePicker}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={holidayData.holiday_start_date}
                  value={holidayData.holiday_end_date}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setHolidayData({
                      ...holidayData,
                      holiday_end_date: newValue,
                    });
                  }}
                  className={classes.inputContainer}
                  renderInput={(params) => (
                    <TextField
                      className={classes.inputContainer}
                      {...params}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Departments
            </InputLabel>
            <Select
              // defaultValue={selectedLeaves}
              isMulti
              // isClearable
              name="Departments"
              placeholder="Select Departments"
              options={departments?.map((ele) => ({
                label: ele?.department_name,
                value: ele?._id,
              }))}
              value={holidayData?.departments}
              onChange={(newValue) => {
                setHolidayData({
                  ...holidayData,
                  departments: newValue,
                });
              }}
              classNamePrefix="select"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                className={classes.cancelBtn}
                onClick={() => setModalOpen(false)}
                disabled={createLoading ? true : false}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.createBtn}
                onClick={() => handleHolidayCreate()}
              >
                {createLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Create"
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </ReactModal>
  );
};

export default CreateHolidayModal;
