export const CreateShiftReducer = (state = { shift: {} }, action) => {
  switch (action.type) {
    case "CREATE_SHIFT_REQUEST":
      return {
        loading: true,
        shift: null,
        error: null,
      };
    case "CREATE_SHIFT_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        shift: action.payload,
      };
    case "CREATE_SHIFT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_SHIFT_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const ShiftDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_SHIFT_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        shift: {},
      };
    case "GET_SHIFT_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        shift: action.payload,
      };
    case "GET_SHIFT_DETAILS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const UpdateShiftReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_SHIFT_REQUEST":
      return {
        loading: true,
      };
    case "UPDATE_SHIFT_SUCCESS":
      return {
        loading: false,
        isUpdated: action.payload,
      };
    case "UPDATE_SHIFT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_SHIFT_RESET":
      return {
        ...state,
        isUpdated: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const DeleteShiftReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_SHIFT_REQUEST":
      return {
        loading: true,
      };
    case "DELETE_SHIFT_SUCCESS":
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case "DELETE_SHIFT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DELETE_SHIFT_RESET":
      return {
        ...state,
        isDeleted: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
