import { Avatar, Button, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
// import { useStyle } from "../Attendance/attendance-styles";
import ProfilePic from "../../../../Asssets/Images/profile.png";
import {
  indivisualLeavesDetail,
  sendLeaveComment,
} from "../../../../Redux/Actions/LeaveApplications";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../Utils/Loader";
import { indivisualLeavesUpdate } from "../../../../Redux/Actions/LeaveApplications";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useStyle } from "./leaveStyles";
import { MdOutlineWatchLater } from "react-icons/md";
import { SiRocketdotchat } from "react-icons/si";
import { getCurrentUser } from "../../../../Redux/Actions/Auth";
import moment from "moment";
import Header from "../../../../Components/Header/Header";

const LeaveDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyle();
  const [comment, setComment] = useState("");
  const { loading, indivisualLeaveDetails } = useSelector(
    (state) => state.leaveApplicationDetails
  );
  const {
    loading: updateLoading,
    leaveUpdated,
    error,
  } = useSelector((state) => state.UpdateLeaveApplication);
  const {
    loading: commentLoading,
    sendComment,
    error: commentError,
  } = useSelector((state) => state.leaveComment);
  const { user } = useSelector((state) => state.currentUser);

  // const classes = useStyle();
  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    dispatch(indivisualLeavesDetail(id));
    dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    if (leaveUpdated?.success) {
      successNotify(leaveUpdated?.message);
      dispatch({ type: "INDIVISUAL_LEAVE_UPDATE_RESET" });
      navigate(-1);
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    if (sendComment?.success) {
      successNotify(sendComment?.message);
      dispatch({ type: "SEND_LEAVE_COMMENT_RESET" });
      setComment("");
      dispatch(indivisualLeavesDetail(id));
    }
    if (commentError) {
      errorNotify(commentError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [leaveUpdated?.success, error, sendComment?.success, commentError]);

  const updateHandler = (leaveStatus) => {
    if (leaveStatus) {
      const updateStatus = {
        status: leaveStatus,
      };
      dispatch(indivisualLeavesUpdate(id, updateStatus));
    }
  };

  // Splitting the name in detail page
  var space =
    indivisualLeaveDetails?.leaveApplication?.user?.full_name.indexOf(" ");
  const firstname =
    indivisualLeaveDetails?.leaveApplication?.user?.full_name.substring(
      0,
      space
    );

  const handleSendComment = () => {
    dispatch(sendLeaveComment(id, comment));
  };
  return (
    <div className="page_responsive">
      <Header title="Leave Details" />
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Row>
            <Col md={7}>
              <Box className="my-2 ms-4">
                <Paper className={classes.sectionsContainer}>
                  <Box className={classes.sectionHeader}>{firstname} Leave</Box>
                  <Box className="mx-3">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="my-3"
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <Avatar
                          sx={{ width: "50px", height: "50px" }}
                          src={
                            indivisualLeaveDetails?.leaveApplication?.user
                              ?.profile_picture
                              ? indivisualLeaveDetails?.leaveApplication?.user
                                  ?.profile_picture[0]?.fileName
                              : ProfilePic
                          }
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "18px",
                            fontWeight: "700",
                            color: "#253766",
                          }}
                        >
                          {
                            indivisualLeaveDetails?.leaveApplication?.user
                              ?.full_name
                          }
                        </Typography>
                      </div>

                      <div className="created_at_container">
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <MdOutlineWatchLater
                            style={{
                              fontSize: "23px",
                              marginRight: "3px",
                              color: "#0097d9",
                            }}
                          />{" "}
                          <div>
                            <h6
                              className="mb-1"
                              style={{ color: "#0097d9", fontWeight: "500" }}
                            >
                              Created At{" "}
                            </h6>
                            <h6 style={{ fontSize: "14px" }}>
                              {moment(
                                indivisualLeaveDetails?.leaveApplication
                                  ?.createdAt
                              ).format("DD-MM-YYYY")}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                  <Box className={`mx-3 ${classes.leaveDetails}`}>
                    <Row className="mt-4 mb-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Leave Type
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Typography>
                          {indivisualLeaveDetails?.leaveApplication.leave_type}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Leave Subject
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Typography>
                          {
                            indivisualLeaveDetails?.leaveApplication
                              .leave_subject
                          }
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Leave Category
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Typography>
                          {
                            indivisualLeaveDetails?.leaveApplication
                              .leave_category
                          }
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          From
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Typography>
                          {moment(
                            indivisualLeaveDetails?.leaveApplication.start_date
                          ).format("DD-MM-YYYY")}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          To
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Typography>
                          {moment(
                            indivisualLeaveDetails?.leaveApplication.end_date
                          ).format("DD-MM-YYYY")}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Leave Description
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Typography>
                          {
                            indivisualLeaveDetails?.leaveApplication
                              .leave_application
                          }
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Number Of Days
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Typography>
                          {indivisualLeaveDetails?.leaveApplication.numOfDays}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={4}>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          HR Status
                        </Typography>
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col md={4}>
                            <Box
                              className={
                                indivisualLeaveDetails?.leaveApplication
                                  ?.hr_status === "pending"
                                  ? classes.pending
                                  : indivisualLeaveDetails?.leaveApplication
                                      ?.hr_status === "approved"
                                  ? classes.approved
                                  : classes.rejected
                              }
                            >
                              {
                                indivisualLeaveDetails?.leaveApplication
                                  .hr_status
                              }
                            </Box>
                          </Col>
                          <Col md={8}>
                            <Row>
                              <Col md={6}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: "500", fontSize: "16px" }}
                                >
                                  HOD Status
                                </Typography>
                              </Col>
                              <Col md={6}>
                                <Box
                                  className={
                                    indivisualLeaveDetails?.leaveApplication
                                      ?.line_manager_status === "pending"
                                      ? classes.pending
                                      : indivisualLeaveDetails?.leaveApplication
                                          ?.line_manager_status === "approved"
                                      ? classes.approved
                                      : classes.rejected
                                  }
                                >
                                  {
                                    indivisualLeaveDetails?.leaveApplication
                                      .line_manager_status
                                  }
                                </Box>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      {state?.update && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "20px",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            {(user?.user?.role === "HR" &&
                              indivisualLeaveDetails?.leaveApplication
                                .hr_status === "pending") ||
                            (user?.user?.role !== "HR" &&
                              indivisualLeaveDetails?.leaveApplication
                                .line_manager_status === "pending" &&
                              (user?.user?.isLineManager ||
                                user?.user?.isHOD)) ? (
                              <React.Fragment>
                                <Button
                                  onClick={() => updateHandler("approved")}
                                  variant="contained"
                                  className={classes.approvedBtn}
                                  // onClick={() => setModalOpen(false)}
                                >
                                  {updateLoading ? (
                                    <Spinner animation="border" size="sm" />
                                  ) : (
                                    "Approved"
                                  )}
                                </Button>

                                <Button
                                  onClick={() => updateHandler("rejected")}
                                  variant="contained"
                                  className={classes.rejectedBtn}
                                >
                                  {updateLoading ? (
                                    <Spinner animation="border" size="sm" />
                                  ) : (
                                    "Reject"
                                  )}
                                </Button>
                              </React.Fragment>
                            ) : null}
                          </Box>
                        </Box>
                      )}
                    </Row>
                  </Box>
                </Paper>
              </Box>
            </Col>
            <Col md={5}>
              <Box className="my-2 me-4">
                <Paper className={classes.sectionsContainer}>
                  <Box className={classes.sectionHeader}>Documents</Box>
                  <Row>
                    {indivisualLeaveDetails?.leaveApplication?.leave_docs &&
                    indivisualLeaveDetails?.leaveApplication?.leave_docs
                      .length !== 0 ? (
                      indivisualLeaveDetails?.leaveApplication?.leave_docs.map(
                        (d) => (
                          <Col md={6} className="d-flex justify-content-center">
                            <Box className={classes.documents_container}>
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                  position: "relative",
                                }}
                              >
                                <a
                                  href={d?.url}
                                  target="_blank"
                                  style={{
                                    width: "100%",
                                    height: "200px",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                  }}
                                />

                                {d?.url.includes(".pdf") && (
                                  <iframe
                                    src={d?.url}
                                    width="100%"
                                    height="100px"
                                  />
                                )}
                                {/* {(d?.url.includes(".doc") || d?.url.includes("docx")) && <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                  }}
                                  src="https://res.cloudinary.com/dfzrlsjiq/image/upload/v1712142938/word-file-default.png"
                                />} */}
                                {(d?.url.includes(".png") ||
                                  d?.url.includes(".jpg") ||
                                  d?.url.includes(".jpeg")) && (
                                  <img
                                    src={d?.url}
                                    style={{
                                      width: "100%",
                                      objectFit: "cover",
                                      height: "100px",
                                    }}
                                  />
                                )}
                                {/* </a> */}
                              </Box>
                            </Box>
                          </Col>
                        )
                      )
                    ) : (
                      <Typography variant="h6" className="text-center mt-3">
                        {" "}
                        No Documents submitted!
                      </Typography>
                    )}
                  </Row>
                </Paper>
              </Box>
            </Col>
            <Col md={12}>
              <Box className="my-2 mx-4">
                <Paper className={classes.sectionsContainer}>
                  <Box className={classes.sectionHeader}>
                    <SiRocketdotchat /> Chat
                  </Box>
                  <Box sx={{ p: 2 }}>
                    {indivisualLeaveDetails?.leaveApplication?.conversation &&
                    indivisualLeaveDetails?.leaveApplication?.conversation
                      .length !== 0 ? (
                      indivisualLeaveDetails?.leaveApplication?.conversation.map(
                        (d) => (
                          <Box sx={{ pb: 2, display: "flex", gap: "10px" }}>
                            <Avatar
                              sx={{
                                width: "55px",
                                height: "55px",
                                mr: 1,
                                objectFit: "contain",
                              }}
                              src={d?.user?.profile_picture[0]?.fileName}
                            />
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  mb: 1,
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "#253766",
                                  }}
                                >
                                  {d?.user?.full_name}
                                </Typography>

                                <Typography
                                  sx={{ fontWeight: "500", fontSize: "14px" }}
                                >
                                  {new Date(d?.created).toLocaleString()}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  backgroundColor: "#ebf4f7",
                                  padding: "10px 10px",
                                }}
                              >
                                {d?.comment}
                              </Typography>
                            </Box>
                          </Box>
                        )
                      )
                    ) : (
                      <Typography variant="h6" className="mt-3">
                        {" "}
                        No Previous comment found!
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Box sx={{ position: "relative" }}>
                      <input
                        placeholder="Type a message"
                        style={{
                          width: "100%",
                          padding: "20px 110px 40px 20px",
                          border: "1px solid #d6eff7",
                        }}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "26%",
                        }}
                        className={classes.sendBtn}
                        onClick={handleSendComment}
                        disabled={commentLoading ? true : false}
                      >
                        {commentLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Send"
                        )}
                      </button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default LeaveDetails;
