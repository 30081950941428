import { Button, Grid, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import DashBoardCard from "../../../../Components/DashboardCard/DashBoardCard";
import CustomDatePicker from "../../../../Components/DatePicker/DatePicker";
import Input from "../../../../Components/Input/Input";
import Modal from "../../../../Components/Modal/Modal";
import { useStyle } from "../Attendance/attendance-styles";
import { useForm } from "react-hook-form";
import { createApplicationLeave } from "../../../../Redux/Actions/LeaveApplications";
import { useSelector, useDispatch } from "react-redux";
import { createLeaveApplicationValidation } from "../../../../Utils/Validation";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { getAllLeaveApplications } from "../../../../Redux/Actions/LeaveApplications";
import { getCurrentUser } from "../../../../Redux/Actions/Auth";
import { newLeaveSubmitted } from "../../../../realTimeCommunication/SocketConnection";
import { BsFillFileImageFill } from "react-icons/bs";

const ApplyForLeaveModal = ({
  modalOpen,
  setModalOpen,
  start_date,
  end_date,
  page,
  size,
  hRStatus,
  leaveType,
  user,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const {
    error,
    loading,
    createLeave,
    success: createLeaveSuccess,
  } = useSelector((state) => state.createLeaveApplication);
  const {
    err: userError,
    user: employee,
    loading: userLoading,
  } = useSelector((state) => state.currentUser);
  const classes = useStyle();
  const [leave_start_date, setStartDate] = useState("");
  const [leave_end_date, setEndDate] = useState("");
  const [leaveFor, setLeaveFor] = useState("full-day");
  const [leaveCategory, setLeaveCategory] = useState("paid");
  const [docs, setDocs] = useState([]);
  const [previewDocs, setPreviewDocs] = useState([]);
  const [extDocs, setExtDocs] = useState([]);
  const fromRef = useRef(null);
  const toRef = useRef(null);

  // let userAuth = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
  let totalLeaves = 0;
  employee?.user &&
    employee?.user?.leaves.map((leave, index) => {
      totalLeaves = totalLeaves + leave.leave_quota;
    });

  const handleStartDate = (dateValue) => {
    setStartDate(dateValue);
  };
  const handleEndDate = (dateValue) => {
    setEndDate(dateValue);
  };

  useEffect(() => {
    if (createLeaveSuccess) {
      successNotify(createLeave?.message);
      reset();
      setModalOpen(false);
      setLeaveCategory("");
      setLeaveFor("");
      setPreviewDocs([]);
      setExtDocs([]);
      setStartDate("");
      setEndDate("");
      fromRef.current = null;
      toRef.current = null;
      setDocs([]);
      dispatch({ type: "CREATE_LEAVE_RESET" });
      dispatch(
        getAllLeaveApplications(
          page,
          size,
          user,
          leaveType,
          hRStatus,
          start_date,
          end_date
        )
      );
      dispatch(getCurrentUser());
      newLeaveSubmitted(createLeave?.leave);
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [createLeaveSuccess, error]);
  // useEffect(() => {
  //   dispatch(getCurrentUser());
  // }, []);
  const uploadDocsChange = (e) => {
    const files = Array.from(e.target.files);
    Promise.all(files.map((file) => URL.createObjectURL(file))).then((urls) => {
      setPreviewDocs(urls);
    });
    Promise.all(files.map((file) => file.name)).then((ext) => {
      setExtDocs(ext);
    });
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setDocs((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const onSubmitHandler = handleSubmit(async (data) => {
    if (leaveFor === "full-day") {
      if (!leave_start_date || !leave_end_date) {
        errorNotify("Please enter valid date");
        return;
      }

      const leaveData = {
        ...data,
        start_date: leave_start_date,
        end_date: leave_end_date,
        leave_for: leaveFor,
        leave_category: leaveCategory,
        leave_docs: docs,
      };

      dispatch(createApplicationLeave(leaveData));
    } else if (leaveFor === "half-day") {
      const leaveData = {
        ...data,
        start_date: leave_start_date,
        end_date: leave_start_date,
        leave_for: leaveFor,
        leave_category: leaveCategory,
        leave_docs: docs,
      };

      dispatch(createApplicationLeave(leaveData));
    }
  });

  const leaveForHandler = (e) => {
    setLeaveFor(e.target.value);
  };
  const leaveCategoryHandler = (e) => {
    setLeaveCategory(e.target.value);
  };

  const closeModal = () => {
    setModalOpen(false);
    setLeaveCategory("");
    setLeaveFor("");
    setPreviewDocs([]);
    setExtDocs([]);
    setStartDate("");
    setEndDate("");
    fromRef.current = null;
    toRef.current = null;
    reset();
    setDocs([]);
  };

  return (
    <Modal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create New Leave"
      size="1200"
    >
      <Box sx={{ margin: "20px 0px" }}>
        <Grid container spacing={3}>
          <DashBoardCard
            style={{ backgroundColor: "#8b65ef" }}
            value={totalLeaves}
            heading="Leaves"
            sub="TOTAL"
          />
          {employee?.user?.leaves.map((item, index) => (
            <DashBoardCard
              key={index}
              style={{
                backgroundColor:
                  index === 0
                    ? "#59C680"
                    : index === 1
                    ? "#14D4E2"
                    : index === 2
                    ? "#FE022C"
                    : "#59C680",
              }}
              value={item.leave_quota}
              heading={item.leave_type}
              sub="TOTAL"
            />
          ))}
        </Grid>
      </Box>
      <React.Fragment>
        <Form onSubmit={onSubmitHandler}>
          <Box>
            <Grid container columnSpacing={7}>
              <Grid item md={12}>
                <Box>
                  <Input>
                    <Form.Label>Leave Category</Form.Label>
                    <Form.Select
                      name="leave_category"
                      onChange={leaveCategoryHandler}
                    >
                      <option defaultValue={-1} key={-1} value="">
                        Select Leave Category
                      </option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </Form.Select>
                  </Input>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ mt: 2 }}>
                  <Input>
                    <Form.Label>Leave Duration</Form.Label>
                    <Form.Select name="leave_for" onChange={leaveForHandler}>
                      <option defaultValue={-1} key={-1} value="">
                        Select Leave Duration
                      </option>
                      <option value="half-day">Half Day</option>
                      <option value="full-day">Full Day</option>
                    </Form.Select>
                  </Input>
                </Box>
              </Grid>

              <Grid item md={6}>
                <Box sx={{ mt: 2 }}>
                  <Input>
                    <Form.Label>Leave Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      {...register(
                        "leave_type",
                        createLeaveApplicationValidation.leave_type
                      )}
                    >
                      <option defaultValue={-1} key={-1} value="">
                        Select Leave Type
                      </option>
                      {employee?.user?.leaves &&
                        employee?.user?.leaves.map((leave, i) => (
                          <option value={leave.leave_type} key={i}>
                            {leave.leave_type}
                          </option>
                        ))}
                    </Form.Select>
                  </Input>
                  {errors.leave_type ? (
                    <small className="text-danger">
                      {errors.leave_type && errors.leave_type.message}
                    </small>
                  ) : null}
                </Box>
              </Grid>

              <Grid item md={12}>
                <Box sx={{ mt: 2 }}>
                  <InputLabel className={classes.department_label}>
                    Leave Subject
                  </InputLabel>
                  <TextField
                    sx={{ mt: 1 }}
                    placeholder="Enter Leave Subject"
                    fullWidth
                    className={classes.inputContainer}
                    {...register(
                      "leave_subject",
                      createLeaveApplicationValidation.leave_subject
                    )}
                  />

                  {errors.leave_subject ? (
                    <small className="text-danger">
                      {errors.leave_subject && errors.leave_subject.message}
                    </small>
                  ) : null}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ my: 1 }}>
                  <CustomDatePicker
                    ref={fromRef}
                    maxDate={leave_end_date}
                    label={"From"}
                    value={leave_start_date}
                    onChangeHandler={handleStartDate}
                  />
                </Box>
              </Grid>
              {leaveFor === "full-day" && (
                <Grid item md={6}>
                  <Box sx={{ my: 1 }}>
                    <CustomDatePicker
                      ref={toRef}
                      minDate={leave_start_date}
                      label={"To"}
                      value={leave_end_date}
                      onChangeHandler={handleEndDate}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item md={12}>
                <Box sx={{ my: 1 }}>
                  <InputLabel className={classes.department_label}>
                    Leave Application
                  </InputLabel>
                  <TextField
                    sx={{ mt: 1 }}
                    placeholder="Enter Leave Description"
                    fullWidth
                    className={classes.inputContainer}
                    rows={2}
                    multiline
                    {...register(
                      "leave_application",
                      createLeaveApplicationValidation.leave_application
                    )}
                  />
                  {errors.leave_application ? (
                    <small className="text-danger">
                      {errors.leave_application &&
                        errors.leave_application.message}
                    </small>
                  ) : null}
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                sx={{ display: "flex", gap: "25px", mt: 2, flexWrap: "wrap" }}
              >
                {previewDocs.length !== 0 &&
                  previewDocs.map((d, i) => {
                    return (
                      <div
                        style={{
                          width: "90px",
                          height: "120px",
                          border: "1px solid lightgrey",
                          padding: "5px",
                        }}
                      >
                        {extDocs[i]?.includes(".pdf") && (
                          <iframe src={d} width="100%" height="100px" />
                        )}
                        {/* {(extDocs[i]?.includes(".doc") || extDocs[i]?.includes(".docx")) &&  <img
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src="https://res.cloudinary.com/dfzrlsjiq/image/upload/v1712142938/word-file-default.png"
                      />} */}
                        {(extDocs[i]?.includes(".png") ||
                          extDocs[i]?.includes(".jpg") ||
                          extDocs[i]?.includes(".jpeg")) && (
                          <img
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "100px",
                            }}
                            src={d}
                          />
                        )}
                      </div>
                    );
                  })}
              </Grid>
              <Grid item md={12} sx={{ mt: 3 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Button variant="contained" component="label">
                    Upload Documents
                    <BsFillFileImageFill
                      style={{ fontSize: "26px", marginLeft: "10px" }}
                    />
                    <input
                      hidden
                      accept="image/*, .pdf"
                      multiple
                      type="file"
                      onChange={uploadDocsChange}
                    />
                  </Button>

                  <p style={{ marginBottom: "0px", color: "red" }}>
                    *Supported files: Images and pdf only.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                type="button"
                className={classes.cancelBtn}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className={classes.createLeaveBtn}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Create"}
              </Button>
            </Box>
          </Box>
        </Form>
      </React.Fragment>
    </Modal>
  );
};

export default ApplyForLeaveModal;
