import { Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const PersonalDetailsCard = ({ classes, employee }) => {
  return (
    <Paper className={classes.details_card}>
      <div className="details_head">
        <h5>Personal Details</h5>
      </div>
      <Grid container className={classes.personal_details_form}>
        <Grid item md={6}>
          <Typography variant="h6">Full Name:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.full_name}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Father/Husband Name:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.father_husband_name}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Date Of Birth:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{moment(employee?.DOB).format("DD-MM-YYYY")}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Gender:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.gender}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Email:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.personal_email}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Marital Status:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.marital_status}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">CNIC:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.CNIC}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Phone:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.phone}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Address:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.address}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Emergency Contact Name:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.ECE_name}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Emergency Contact Number:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.ECE_number}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Relation With Emergency Contact</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.ECE_relation}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PersonalDetailsCard;
