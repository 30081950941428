import { Box, Button, InputLabel, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "../../../../../Components/Modal/Modal";
import {
  clearErrors,
  createDesignationByHR,
} from "../../../../../Redux/Actions/Designations";
import { getAllDesignations } from "../../../../../Redux/Actions/HR";
import { errorNotify, successNotify } from "../../../../../Utils/toast";

const CreateDesignationModal = ({
  classes,
  modalOpen,
  setModalOpen,
  page,
  rowsPerPage,
  designationName,
  setDesignationName,
}) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    designation,
    error: createError,
    success,
  } = useSelector((state) => state.createDesignation);
  useEffect(() => {
    if (success) {
      successNotify(designation?.message);
      dispatch({ type: "CREATE_DESIGNATION_RESET" });
      setModalOpen(false);
      dispatch(getAllDesignations(page, rowsPerPage));
    }
    if (createError) {
      errorNotify(createError);
      dispatch(clearErrors());
    }
  }, [success, createError, designation]);
  const handleCreateDesignation = () => {
    dispatch(createDesignationByHR(designationName));
  };
  return (
    <ReactModal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create New Designation"
    >
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>
          Designation Name
        </InputLabel>
        <TextField
          sx={{ mt: 1 }}
          placeholder="Enter Designation Name"
          fullWidth
          className={classes.inputContainer}
          value={designationName}
          onChange={(e) => setDesignationName(e.target.value)}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            disabled={createLoading ? true : false}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.createBtn}
            onClick={() => handleCreateDesignation()}
          >
            {createLoading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Box>
    </ReactModal>
  );
};

export default CreateDesignationModal;
