import { Avatar, Typography } from "@mui/material";
import React from "react";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../Components/SiteModal/SiteModal";
import { Spinner } from "react-bootstrap";
import { dummySearchData } from "./SearchedData";

const SearchedPayrolls = ({
  payrollsPage,
  setPayrollsPage,
  payrollsRowsPerPage,
  setPayrollsRowsPerPage,
  payrolls,
  payrollsCount,
}) => {
  const [payrollId, setPayrollId] = useState("");
  const [show, setShow] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, loading } = useSelector((state) => state.currentUser);

  const payrollColumns = [
    {
      name: "user",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
            </div>
          );
        },
        download: false,
        print: false,
        display: false,
      },
    },

    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "user",
      label: "Employee",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={tableMeta.rowData[0].profile_picture[0]?.fileName} />
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value?.department_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "date",
      label: "Month/Year",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value.month}/{value.year}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "actualSalary",
      label: "Salary",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "calculatedSalary",
      label: "Net Salary",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {Math.round(value)}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(
                    `/${user?.user?.role}/payroll/${tableMeta.rowData[1]}`,
                    {},
                  )
                }
              >
                <FaEye />
              </button>
            </div>
          );
        },
      },
    },
  ];
  return (
    <div className="departmentsTable my-5 mx-3">
      <MuiDataTable
        title={"ALL LEAVES"}
        columns={payrollColumns}
        data={payrolls}
        page={payrollsPage}
        setPage={setPayrollsPage}
        rowsPerPage={payrollsRowsPerPage}
        setRowsPerPage={setPayrollsRowsPerPage}
        count={payrollsCount}
        tableId="searched_payroll_table"
      />
    </div>
  );
};

export default SearchedPayrolls;
