import { Avatar, TextField, Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  createAnnouncement,
  getAllAnnouncements,
  getAnnouncementData,
} from "../../../../Redux/Actions/Announcement";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import Modal from "../../../../Components/SiteModal/SiteModal";
import { useDispatch, useSelector } from "react-redux";
import "./Announcement.css";
import Group from "../../../../Asssets/Images/group.jpg";
import Loader from "../../../../Utils/Loader";
import { FaShare, FaImages } from "react-icons/fa";
import GetAnnouncement from "./GetAnnouncement/GetAnnouncement";
import "./Announcement.css";
import dummyProfile from "../../../../Asssets/Images/profile.png";
import { useStyle } from "./announcement-styles";
import ReactQuill from "react-quill";
import { getUser } from "../../../../Utils/helper";

const AllAnnouncements = ({ height }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  let userAuth = getUser() ? getUser() || "" : null;
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees,
  );
  const {
    loading: annouceLoading,
    announce,
    error,
  } = useSelector((state) => state.createAnnouncement);
  const {
    loading: announceLoading,
    announceData,
    announceCount,
    success: announceSuccess,
  } = useSelector((state) => state.getAnnouncement);
  const [showModal, setShowModal] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [getImage, setGetImage] = useState({});
  const [getText, setGetText] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [announcementData, setAnnouncementData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [previewFile, setPreviewFile] = useState(null);
  useEffect(() => {
    if (announce?.success) {
      successNotify(announce.message);
      dispatch({ type: "ANNOUNCE_RESET" });
      dispatch(
        getAnnouncementData(
          announcementData.length !== 0 && !announcementData.length % 5 === 0
            ? page
            : page + 1,
        ),
      );
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "CLEAR_ERRORS" });
    }
    if (announceSuccess) {
      setAnnouncementData(announceData);
      setTotalPages(announceCount);
    }
  }, [announce?.success, error, announceSuccess, announceData]);
  useEffect(() => {
    dispatch(getAnnouncementData(page));
  }, []);

  const fetchData = () => {
    if (page >= totalPages) {
      setHasMore(false);
      return;
    } else {
      setIsLoading(true);
      getAllAnnouncements(page + 1)
        .then((res) => {
          setAnnouncementData([...res.data.announcements]);
          setPage(page + 1);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    if (employees?.success) {
      setIsCheck(
        employees?.employees?.map((li) => {
          if (!(li._id === userAuth.employee._id)) {
            return li._id;
          }
        }),
      );
    }
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const onImageHandler = (name) => (e) => {
    setPreviewFile(e.target.files[0].name);
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setGetImage({
            ...getImage,
            [name]: reader.result,
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };
  let showUser;
  if (employees?.success) {
    showUser = employees?.employees?.map((e) => {
      if (!(e.email === userAuth?.employee.email)) {
        return (
          <li className="mb-1 pb-2 pt-2 user_list_item" key={e._id}>
            <div className="list_name">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  className="user_img"
                  src={
                    e?.profile_picture[0]?.fileName
                      ? e?.profile_picture[0]?.fileName
                      : dummyProfile
                  }
                  alt="avatar"
                />
                <h6>{e.full_name}</h6>
              </div>
              <input
                id={e._id}
                name={e.full_name}
                type={"checkbox"}
                onClick={handleClick}
                checked={isCheck.includes(e._id)}
              />
            </div>
          </li>
        );
      }
    });
  }

  const FormSubmitHandler = async (e) => {
    e.preventDefault();

    if (getText === "") {
      errorNotify("Please Enter some text!");
    } else if (isCheck.length === 0) {
      errorNotify("Please Select some users!");
    } else {
      let d = new Date();
      const formData = {
        users: isCheck,
        picture: getImage.announcement_img,
        text: getText,
        date: d,
      };

      await dispatch(createAnnouncement(formData));

      e.target.reset();
      setGetText("");
      setIsCheck([]);
      setGetImage({});
      setIsCheckAll(false);
      setPreviewFile("");
    }
  };

  const modal = (
    <Modal
      title="Share With"
      show={showModal}
      onCloseModal={() => setShowModal(false)}
    >
      <div className="announcement_modal">
        {employeeLoading ? (
          <Loader />
        ) : (
          <>
            <ul className="user_list">
              <li className="mb-1 pb-2 user_list_item">
                <div
                  className="list_name"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img className="user_img" src={Group} alt="avatar" />
                    <h6>Select All</h6>
                  </div>
                  <input
                    className="user_select"
                    id={"selectAll"}
                    name={"selectAll"}
                    type={"checkbox"}
                    onClick={handleSelectAll}
                    checked={isCheckAll}
                  />
                </div>
              </li>

              {showUser}
            </ul>
            <div className="mt-3 share_with_btn_container">
              <button onClick={() => setShowModal(!showModal)}>Submit</button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
  return (
    <>
      {userAuth.employee.role === "HR" && (
        <Form onSubmit={FormSubmitHandler}>
          <div>
            <Box
              sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}
            >
              <Avatar
                sx={{
                  width: "9%",
                  height: "auto",
                  mr: 2,
                  objectFit: "contain",
                }}
                src={userAuth?.employee?.profile_picture[0]?.fileName}
              />
              <div style={{ width: "88%" }}>
                <ReactQuill
                  theme="snow"
                  value={getText}
                  onChange={setGetText}
                />
              </div>
              {/* <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Create Announcement..."
                    className={classes.create_input}
                    onChange={(e) => setGetText(e.target.value)}
                  /> */}
            </Box>

            <div className="file_div">
              {/* <Box> */}
              <label>
                <FaImages />
                Upload Image
                <input
                  type="file"
                  onChange={onImageHandler("announcement_img")}
                />
              </label>
              {/* {previewFile ? <Typography sx={{fontSize:"12px",fontWeight:400,color:"rgb(37, 55, 102)",textAlign:"center"}}>{previewFile}</Typography> :""} */}
              {/* </Box> */}

              <label onClick={() => setShowModal(!showModal)}>
                <FaShare />
                Share with
              </label>
            </div>

            <div>
              {previewFile ? (
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "rgb(37, 55, 102)",
                    textAlign: "start",
                    pl: "20px",
                  }}
                >
                  {previewFile}
                </Typography>
              ) : (
                ""
              )}
            </div>

            <hr style={{ color: "#00000054" }} />

            <div className="text-end">
              <Button type="submit" className="announcement_create_btn">
                {annouceLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Create"
                )}
              </Button>
            </div>
          </div>
        </Form>
      )}

      {!announceLoading ? (
        announcementData.length > 0 ? (
          <div id="scrollableDiv">
            <InfiniteScroll
              dataLength={announcementData.length}
              next={fetchData}
              hasMore={hasMore}
              height={height}
              // loader={
              //   <div className="text-center">
              //     <Loader />
              //   </div>
              // }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <GetAnnouncement announceData={announcementData} page={page} />
            </InfiniteScroll>
          </div>
        ) : (
          <p>No Data Found!</p>
        )
      ) : (
        <Loader />
      )}
      {modal}
    </>
  );
};

export default AllAnnouncements;
