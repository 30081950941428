import { Avatar, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Header from "../../../../Components/Header/Header";
import { useStyle } from "./attendance-styles";
import AttendanceIcon from "../../../../Asssets/Images/AttendanceWhite.png";

import "./attendance.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeCurrentMonthAttendance,
  getLastAttendance,
} from "../../../../Redux/Actions/Attendance";
import { tConvert } from "../../../../Utils/helper";
import Loader from "../../../../Utils/Loader";
import CheckInModal from "./CheckInModal";
import CheckOutModal from "./CheckOutModal";
import MetaData from "../../../../Components/MetaData";
import AttendanceForm from "./AttendanceForm";
import moment from "moment";

const Attendance = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [checkOutModalOpen, setCheckOutModalOpen] = useState(false);

  const classes = useStyle();
  const dispatch = useDispatch();
  const { loading, attendance, error } = useSelector(
    (state) => state.currentMonthAttendance
  );
  const {
    loading: lastAttendanceLoading,
    attendance: lastAttendance,
    error: lastAttendanceError,
  } = useSelector((state) => state.lastAttendance);

  useEffect(() => {
    dispatch(getLastAttendance());
    dispatch(getEmployeeCurrentMonthAttendance());
  }, []);

  // console.log(attendanceArray);

  return (
    <div className="page_responsive">
      <Header title="ATTENDANCE" />
      <MetaData title={`Attendance -- HRM`} />

      {lastAttendanceLoading || loading ? (
        <Loader />
      ) : (
        <Box>
          <AttendanceForm
            classes={classes}
            setModalOpen={setModalOpen}
            setCheckOutModalOpen={setCheckOutModalOpen}
            lastAttendance={lastAttendance}
          />

          <Box
            sx={{ margin: "20px 40px" }}
            className={`${classes.attendanceTable} attendanceTable`}
          >
            <Paper
              sx={{
                width: "100%",
                my: 2,
                borderRadius: "20px",
                px: 2,
                py: 1,
                boxShadow:
                  "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
              }}
            >
              <Box className={classes.leave_card_header}>
                <img src={AttendanceIcon} alt="" style={{ width: "30px" }} />
                <Typography
                  variant="h5"
                  className={classes.card_header_heading}
                  sx={{ color: "white", ml: 1, fontSize: "20px" }}
                >
                  Attendances
                </Typography>
              </Box>
              <Box
                sx={{ height: "300px", overflowY: "auto", my: 1 }}
                className="tickets_box"
              >
                {attendance && attendance.attendances.length > 0 ? (
                  <table id="attendance_table" style={{ width: "100%" }}>
                    <tr style={{ textAlign: "left", padding: "20px 0px" }}>
                      <th>
                        {" "}
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Employee
                        </Typography>
                      </th>
                      <th>
                        {" "}
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Date
                        </Typography>
                      </th>
                      <th>
                        {" "}
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Day
                        </Typography>
                      </th>
                      <th>
                        {" "}
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Check In
                        </Typography>
                      </th>
                      <th>
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Check Out
                        </Typography>
                      </th>
                      <th>
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Late Time
                        </Typography>
                      </th>
                      <th>
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Early Off
                        </Typography>
                      </th>
                      <th>
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Over Time
                        </Typography>
                      </th>
                      <th>
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Total Hours
                        </Typography>
                      </th>
                      <th>
                        <Typography
                          variant="h6"
                          className={`${classes.leave_card_heading} fs-14`}
                        >
                          Status
                        </Typography>
                      </th>
                    </tr>

                    {attendance?.attendances &&
                      attendance?.attendances
                        .slice(0)
                        .reverse()
                        .map((a, i) => (
                          <tr key={i}>
                            <td>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  py: 1,
                                }}
                              >
                                <Avatar src={a?.user?.profilePic} />
                                <Typography
                                  sx={{ pl: 1 }}
                                  className="fs-12 align-text"
                                >
                                  {a.user?.name}
                                </Typography>
                              </Box>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {moment(a.date).format("DD-MM-YYYY")}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {new Date(a.date).toLocaleDateString("en-US", {
                                  weekday: "long",
                                })}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {a?.check_in && a?.check_in !== "-"
                                  ? tConvert(
                                      new Date(a?.check_in)
                                        .toString()
                                        .split(" ")[4]
                                    )
                                  : // .toLocaleString()
                                    // .split(" ")[1]
                                    "-"}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {a?.check_out !== "-"
                                  ? //  new Date(
                                    tConvert(
                                      new Date(a?.check_out)
                                        .toString()
                                        .split(" ")[4]
                                    )
                                  : // )
                                    // : .toLocaleString()
                                    // .split(" ")[1]
                                    "-"}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {a.late_time}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {a.early_off}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {a.overtime}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className="fs-12 align-text"
                                sx={{ py: 1 }}
                              >
                                {a.totalHours}
                              </Typography>
                            </td>

                            <td>
                              {new Date(a.date).toLocaleDateString("en-US", {
                                weekday: "long",
                              }) === "Sunday" ||
                              new Date(a.date).toLocaleDateString("en-US", {
                                weekday: "long",
                              }) === "Saturday" ? (
                                <Typography
                                  sx={{ fontSize: "10px", pl: 1 }}
                                  className={`${classes.weekend}`}
                                >
                                  Weekend
                                </Typography>
                              ) : (
                                <Typography
                                  className={`${
                                    a.status === "present"
                                      ? classes.present
                                      : a.status === "Absent"
                                      ? classes.absent
                                      : a.status === "half-day"
                                      ? classes.halfDay
                                      : a.status === "late"
                                      ? classes.late
                                      : a.status === "On Leave"
                                      ? classes.onLeave
                                      : a.status === "On Half Day Leave"
                                      ? classes.halfDayLeave
                                      : classes.holiday
                                  } fs-12`}
                                >
                                  {a?.status}
                                </Typography>
                              )}
                            </td>
                          </tr>
                        ))}
                  </table>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <h4 className="text-center" style={{ fontWeight: "700" }}>
                      No Data Found!
                    </h4>
                  </div>
                )}
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
      <CheckInModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <CheckOutModal
        classes={classes}
        modalOpen={checkOutModalOpen}
        setModalOpen={setCheckOutModalOpen}
      />
    </div>
  );
};

export default Attendance;
