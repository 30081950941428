import { saveAs } from "file-saver";
import CryptoJS from "crypto-js";

export const getUser = () => {
  if (localStorage.getItem("user") !== null) {
    if (localStorage.getItem("user").startsWith("{")) {
      localStorage.clear();
    }
  }
  const encryptedDataFromStorage = localStorage.getItem("user");
  let decryptedData;
  if (encryptedDataFromStorage) {
    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedDataFromStorage,
      "secret_key"
    );
    decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  }
  return decryptedData;
};

export function getToken() {
  let userAuth = getUser() ? getUser() || "" : null;
  return userAuth?.token;
}

export function getRefreshToken() {
  let userAuth = getUser() ? getUser() || "" : null;
  return userAuth?.refreshToken;
}

export function getTokenFormat() {
  const token = getToken();
  if (token) {
    return { headers: { Authorization: `Bearer ${token}` } };
  }
}

export function updateLocalAccessToken(updatedToken) {
  let userAuth = getUser() ? getUser() || "" : null;
  userAuth.token = updatedToken;
  localStorage.setItem("user", JSON.stringify(userAuth));
}

export function CurrentUserInfo() {
  let userAuth = getUser() ? getUser() || "" : null;
  return userAuth;
}
export function minutesConvert(seconds) {
  var date = new Date(null);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 8);
}
export function tConvert(time) {
  // Check correct time format and split into components
  // time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
  //   time,
  // ];

  if (time?.length > 1) {
    // If time format correct
    time = time.split(":"); // Remove full string match value
    var a = parseInt(time[0]) < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time?.join(":") + " " + a; // return adjusted time or original string
}
// 07:12:00
// 7:12:30

// Download Documents

export const downloadFile = (url, name) => {
  saveAs(url, name);
};

export function validateEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(val)) {
    return false;
  } else {
    return true;
  }
}
