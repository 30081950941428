import {
  Avatar,
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../../../../../Components/Header/Header";
import Angelina from "../../../../../Asssets/Images/Angelina.png";
import "./EmployeeDetails.css";
import { useStyle } from "./EmployeeDetails-style";
import DocumentDetailsCard from "./DocumentDetailsCard";
import BankDetailsCard from "./BankDetailsCard";
import JobDetailsCard from "./JobDetailsCard";
import PersonalDetailsCard from "./PersonalDetailsCard";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getEmployeeDetails } from "../../../../../Redux/Actions/HR";
import { useDispatch, useSelector } from "react-redux";
import ProfileImg from "../../../../../Asssets/Images/profile.png";
import moment from "moment";
import Loader from "../../../../../Utils/Loader";
import MetaData from "../../../../../Components/MetaData";
import { MdManageAccounts } from "react-icons/md";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Modal from "../../../../../Components/SiteModal/SiteModal";
import { useForm } from "react-hook-form";
import Input from "../../../../../Components/Input/Input";
import { getCurrentUser } from "../../../../../Redux/Actions/Auth";
import { updateEmployeeLeaves } from "../../../../../Redux/Actions/Employee";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { RiPencilFill } from "react-icons/ri";

const EmployeeDetails = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, employee, error } = useSelector((state) => state.employee);
  const {
    loading: leavesLoading,
    success: leavesSuccess,
    message,
    error: leavesError,
  } = useSelector((state) => state.manageLeaves);
  const [leaveData, setLeaveData] = React.useState([]);
  const {
    error: userError,
    user,
    loading: userLoading,
  } = useSelector((state) => state.currentUser);
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    dispatch(getEmployeeDetails(id));
    // dispatch(getCurrentUser());
  }, [id]);
  // console.log(employee);

  const handleLeaves = (leaveName, leaveValue) => {
    let data = [...leaveData];
    data?.map((d, index) => {
      if (d["leave_type"] === leaveName) {
        d["leave_quota"] = parseFloat(leaveValue);
      }
    });
    // console.log(data)
    setLeaveData(data);
  };

  useEffect(() => {
    if (employee?.success) {
      setLeaveData(employee?.employee?.leaves);
    }
    if (leavesSuccess) {
      successNotify(message);
      dispatch({ type: "UPDATE_EMPLOYEE_LEAVES_RESET" });
      setShow(false);
      dispatch(getEmployeeDetails(id));
    }
    if (leavesError) {
      errorNotify(leavesError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [employee?.success, leavesSuccess, leavesError]);

  const AnnoucementHandler = handleSubmit(async (data) => {});
  const handleUpdateLeaves = (e) => {
    e.preventDefault();
    dispatch(updateEmployeeLeaves(id, leaveData));
  };

  const modal = (
    <Modal
      title="Manage Leaves"
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <>
        <Form onSubmit={AnnoucementHandler}>
          <Container>
            <Row className="justify-content-center align-items-center">
              {employee?.employee?.leaves.map((data, index) => {
                return (
                  <React.Fragment>
                    <Col md={4}>
                      <Form.Label>{data?.leave_type}</Form.Label>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Input>
                        <Form.Control
                          onChange={(e) =>
                            handleLeaves(data?.leave_type, e.target.value)
                          }
                          type="text"
                          defaultValue={data?.leave_quota}
                          placeholder="Enter Value"
                          // {...register("title")}
                        />
                      </Input>
                    </Col>
                  </React.Fragment>
                );
              })}

              <Col md={12}>
                <div className="text-end mt-3">
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={leavesLoading ? true : false}
                    className="generate_modal_btn"
                    onClick={(e) => handleUpdateLeaves(e)}
                  >
                    {leavesLoading ? (
                      <Spinner size="sm" animation="border" />
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </>
    </Modal>
  );

  return (
    <div className="page_responsive">
      <Header title={"PROFILE"} />
      <MetaData
        title={`${
          !loading ? employee?.employee?.full_name : "Employee"
        } -- HRM`}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{ margin: "20px 20px" }}>
            <Paper className={classes.personal_details_card}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid
                  item
                  md={6}
                  sx={{
                    padding: "10px 40px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div className="_detail_avatar_circle">
                        <img
                          src={
                            employee?.employee?.profile_picture[0]?.fileName
                              ? employee?.employee?.profile_picture[0]?.fileName
                              : ProfileImg
                          }
                          alt="Avatar"
                        />
                      </div>
                      <Box sx={{ paddingLeft: "20px" }}>
                        <Typography variant="h6" className={classes.personName}>
                          {employee?.employee?.full_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {employee?.employee?.designation?.designation_name}
                        </Typography>
                        <div className={classes.department_box}>
                          <Typography
                            sx={{ fontWeight: "600" }}
                            className="fs-14"
                          >
                            {employee?.employee?.department?.department_name}
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <div className="manage_leaves_Btn_container">
                        <button
                          style={{ background: "#ee1c25" }}
                          type={"button"}
                          onClick={() => navigate(`/HR/edit-employee/${id}`)}
                        >
                          <RiPencilFill /> Edit Employee
                        </button>
                      </div>
                      <div className="manage_leaves_Btn_container">
                        <button type={"button"} onClick={() => setShow(true)}>
                          <MdManageAccounts /> Manage Leaves
                        </button>
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} className={classes.personal_detail_box}>
                  <Box>
                    <Typography variant="h6">Phone:</Typography>
                    <Typography>{employee?.employee?.phone}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Email:</Typography>
                    <Typography>
                      {employee?.employee?.personal_email}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Gender:</Typography>
                    <Typography>{employee?.employee?.gender}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Birthday:</Typography>
                    <Typography>
                      {moment(employee?.employee.DOB).format("DD-MM-YYYY")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6">Address:</Typography>
                    <Typography sx={{ fontSize: "14px", color: "#A5A9B5" }}>
                      {employee?.employee?.address}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {/* <IconButton
                sx={{ position: "absolute", top: "10px", right: "10px" }}
              >
                <FiEdit style={{ color: "#A5A9B5" }} />
              </IconButton> */}
            </Paper>
          </Box>
          <Box sx={{ margin: "30px 20px" }}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <PersonalDetailsCard
                  classes={classes}
                  employee={employee?.employee}
                />
              </Grid>
              <Grid item md={6}>
                <JobDetailsCard
                  classes={classes}
                  employee={employee?.employee}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ margin: "60px 20px" }}>
            <BankDetailsCard classes={classes} employee={employee?.employee} />
          </Box>
          <Box sx={{ margin: "60px 20px" }}>
            <DocumentDetailsCard
              classes={classes}
              employee={employee?.employee}
            />
          </Box>
        </>
      )}
      {modal}
    </div>
  );
};

export default EmployeeDetails;
