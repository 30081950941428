import api from "../../Utils/interceptors";

// GET All Tickets

export const getAllTickets =
  (page, size, assignTo = "", priority = "", status = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_TICKETS_REQUEST",
      });
      const { data } = await api.get(
        `/tickets/all?page=${page}&size=${size}&assignTo=${assignTo}&priority=${priority}&status=${status}`,
      );
      dispatch({
        type: "GET_ALL_TICKETS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_TICKETS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };

// CREATE New Ticket

export const createTicket = (ticketsData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_TICKET_REQUEST",
    });

    const { data } = await api.post("/ticket/create", ticketsData);

    dispatch({
      type: "CREATE_TICKET_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_TICKET_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// GET Tickets By Indivisual ID

export const ticketsDetail = (ticket_id) => async (dispatch) => {
  try {
    dispatch({
      type: "INDIVISUAL_TICKETS_DETAIL_REQUEST",
    });
    const { data } = await api.get(`/ticket/${ticket_id}`);
    dispatch({
      type: "INDIVISUAL_TICKETS_DETAIL_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "INDIVISUAL_TICKETS_DETAIL_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const getCurrentUserTickets = (page, size) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_MY_TICKETS_REQUEST",
    });
    const { data } = await api.get(`tickets/my?page=${page}&size=${size}`);
    dispatch({
      type: "GET_MY_TICKETS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_MY_TICKETS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const getAssignedTickets = (page, size) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ASSIGNED_TICKETS_REQUEST",
    });
    const { data } = await api.get(
      `tickets/assigned?page=${page}&size=${size}`,
    );
    dispatch({
      type: "GET_ASSIGNED_TICKETS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_ASSIGNED_TICKETS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};

// UPDATE Tickets By Indivisual ID

export const updateTicketById = (ticket_id, ticketData) => async (dispatch) => {
  try {
    dispatch({
      type: "INDIVISUAL_TICKETS_UPDATE_REQUEST",
    });
    const { data } = await api.put(`/ticket/${ticket_id}`, ticketData);
    dispatch({
      type: "INDIVISUAL_TICKETS_UPDATE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "INDIVISUAL_TICKETS_UPDATE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};

// DELETE Tickets By Indivisual ID

export const individualTicketDeleted = (ticket_id) => async (dispatch) => {
  try {
    dispatch({
      type: "INDIVISUAL_TICKET_DELETE_REQUEST",
    });
    const { data } = await api.delete(`/ticket/${ticket_id}`);
    dispatch({
      type: "INDIVISUAL_TICKET_DELETE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "INDIVISUAL_TICKET_DELETE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const sendTicketComment = (ticket_id, comment) => async (dispatch) => {
  try {
    dispatch({
      type: "SEND_TICKET_COMMENT_REQUEST",
    });
    const { data } = await api.put(`/ticket/comment/${ticket_id}`, {
      comment: comment,
    });
    dispatch({
      type: "SEND_TICKET_COMMENT_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "SEND_TICKET_COMMENT_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
