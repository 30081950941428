import api from "../../Utils/interceptors";

export const createHolidayByHR = (holidayData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_HOLIDAY_REQUEST",
    });

    const { data } = await api.post("holiday/create", holidayData);
    dispatch({
      type: "CREATE_HOLIDAY_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_HOLIDAY_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const getHolidayDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_HOLIDAY_DETAILS_REQUEST",
    });
    const { data } = await api.get(`holiday/${id}`);
    dispatch({
      type: "GET_HOLIDAY_DETAILS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_HOLIDAY_DETAILS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const updateHolidayByHR = (holidayData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_HOLIDAY_REQUEST",
    });

    const { data } = await api.put(`holiday/${holidayData._id}`, {
      holiday_title: holidayData.holiday_title,
      holiday_start_date: holidayData.holiday_start_date,
      holiday_end_date: holidayData.holiday_end_date,
      departments: holidayData.departments,
    });
    dispatch({
      type: "UPDATE_HOLIDAY_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "UPDATE_HOLIDAY_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const deleteHolidayByHR = (desId) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_HOLIDAY_REQUEST",
    });

    const { data } = await api.delete(`holiday/${desId}`);
    dispatch({
      type: "DELETE_HOLIDAY_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "DELETE_HOLIDAY_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
