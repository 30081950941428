import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Slide, ToastContainer } from "react-toastify";
import "rc-switch/assets/index.css";
// HR Routes
import { hrRoutes, userRoutes } from "./Containers/HR/Routes/Routes";
import { employeeRoutes } from "./Containers/Employee/Routes/Routes";
import HRLayout from "./Containers/Layouts/HRLayout";
import MainLayout from "./Containers/Layouts/MainLayout";
import EmployeeLayout from "./Containers/Layouts/EmployeeLayout";
import ManagerLayout from "./Containers/Layouts/ManagerLayout";
import { managerRoutes } from "./Containers/Manager/Routes/Routes";
import { useEffect } from "react";
import { connectWithSocketServer } from "./realTimeCommunication/SocketConnection";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "./Redux/Actions/Auth";
import { getAllNotifications } from "./Redux/Actions/Employee";
import { getUser } from "./Utils/helper";
import { getAllFilteredEmployees } from "./Redux/Actions/HR";

function App() {
  const dispatch = useDispatch();
  var audio = new Audio("/iphone_sound.mp3");

  let userAuth = getUser() ? getUser() || "" : null;

  useEffect(() => {
    if (userAuth) {
      dispatch(getCurrentUser());
      dispatch(getAllNotifications());
      connectWithSocketServer(userAuth, audio);
      dispatch(getAllFilteredEmployees());
    }
  }, []);

  useEffect(() => {}, []);

  const userLayout = (
    <Route path={"/"} element={<MainLayout />}>
      {userRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const hrLayout = (
    <Route path={"/HR"} element={<HRLayout />}>
      {hrRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const employeeLayout = (
    <Route path={"/employee"} element={<EmployeeLayout />}>
      {employeeRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  const managerLayout = (
    <Route path={"/manager"} element={<ManagerLayout />}>
      {managerRoutes.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Route>
  );

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Slide}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          {userLayout}
          {hrLayout}
          {employeeLayout}
          {managerLayout}
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
