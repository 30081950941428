import { Button, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "../../../../../Components/Modal/Modal";
import {
  clearErrors,
  updateHolidayByHR,
} from "../../../../../Redux/Actions/Holidays";
import {
  getAllDepartments,
  getAllHolidays,
} from "../../../../../Redux/Actions/HR";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import Select from "react-select";

const UpdateHolidayModal = ({
  classes,
  updateModalOpen,
  setUpdateModalOpen,
  page,
  rowsPerPage,
  updateHolidayDetails,
  setUpdateHolidayDetails,
  loading,
}) => {
  const dispatch = useDispatch();
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.updateHoliday);

  const { loading: departmentsLoading, departments } = useSelector(
    (state) => state.departments
  );
  useEffect(() => {
    if (isUpdated?.success) {
      successNotify(isUpdated?.message);
      dispatch({ type: "UPDATE_HOLIDAY_RESET" });
      setUpdateModalOpen(false);
      dispatch(getAllHolidays(page, rowsPerPage));
      setUpdateHolidayDetails({
        id: "",
        holiday_title: "",
        holiday_start_date: "",
        holiday_end_date: "",
        departments: "",
      });
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch(clearErrors());
    }
  }, [isUpdated, updateError]);
  const handleHolidayUpdate = () => {
    dispatch(
      updateHolidayByHR({
        ...updateHolidayDetails,
        departments: !updateHolidayDetails?.departments
          ? []
          : updateHolidayDetails?.departments?.map((ele) => ele?.value),
      })
    );
  };
  const handleCancel = () => {
    setUpdateModalOpen(false);
    setUpdateHolidayDetails({
      id: "",
      holiday_title: "",
      holiday_start_date: "",
      holiday_end_date: "",
      departments: "",
    });
  };

  useEffect(() => {
    dispatch(getAllDepartments());
  }, []);

  console.log(
    {
      ...updateHolidayDetails,
      departments: !updateHolidayDetails?.departments
        ? []
        : updateHolidayDetails?.departments?.map((ele) => ele?.value),
    },
    "updateHolidayDetailsupdateHolidayDetails"
  );
  return (
    <ReactModal
      open={updateModalOpen}
      setOpen={setUpdateModalOpen}
      title="Update Holiday"
    >
      {loading || departmentsLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="large" animation="border" />
        </Box>
      ) : (
        <>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Holiday Title
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Enter holiday title"
              fullWidth
              className={classes.inputContainer}
              value={updateHolidayDetails?.holiday_title}
              onChange={(e) =>
                setUpdateHolidayDetails({
                  ...updateHolidayDetails,
                  holiday_title: e.target.value,
                })
              }
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Holiday Start Date
            </InputLabel>
            <Box className={classes.datePicker}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={updateHolidayDetails?.holiday_start_date}
                  maxDate={updateHolidayDetails?.holiday_end_date}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setUpdateHolidayDetails({
                      ...updateHolidayDetails,
                      holiday_start_date: newValue,
                    });
                  }}
                  className={classes.inputContainer}
                  renderInput={(params) => (
                    <TextField
                      className={classes.inputContainer}
                      {...params}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Holiday End Date
            </InputLabel>
            <Box className={classes.datePicker}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={updateHolidayDetails?.holiday_start_date}
                  value={updateHolidayDetails?.holiday_end_date}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setUpdateHolidayDetails({
                      ...updateHolidayDetails,
                      holiday_end_date: newValue,
                    });
                  }}
                  className={classes.inputContainer}
                  renderInput={(params) => (
                    <TextField
                      className={classes.inputContainer}
                      {...params}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Departments
            </InputLabel>
            <Select
              // defaultValue={selectedLeaves}
              isMulti
              // isClearable
              name="Departments"
              placeholder="Select Departments"
              options={departments?.map((ele) => ({
                label: ele?.department_name,
                value: ele?._id,
              }))}
              value={updateHolidayDetails?.departments}
              onChange={(newValue) => {
                setUpdateHolidayDetails({
                  ...updateHolidayDetails,
                  departments: newValue,
                });
              }}
              classNamePrefix="select"
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                className={classes.cancelBtn}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.createBtn}
                onClick={() => handleHolidayUpdate()}
                disabled={updateLoading ? true : false}
              >
                {updateLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </ReactModal>
  );
};

export default UpdateHolidayModal;
