import React from "react";
import classes from "./ChangePinCode.module.css";
import { Form, Spinner } from "react-bootstrap";
import { changePinValidation } from "../../Utils/Validation";
import Modal from "../SiteModal/SiteModal";
const ChangePinCode = ({
  show,
  setShow,
  isLoading,
  errors,
  register,
  onSubmitHandler,
}) => {
  return (
    <Modal
      title="Change Pin Code"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <Form onSubmit={onSubmitHandler}>
          <div className="change_password_form_container">
            <div className="form_inner_container">
              <label>Old Pin Code</label>
              <input
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "-" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                }}
                type="number"
                placeholder="Enter an old Pin Code"
                {...register("currentPinCode", changePinValidation?.oldPinCode)}
              />
              {errors?.oldPinCode ? (
                <small className="text-danger">
                  {errors?.oldPinCode && errors?.oldPinCode.message}
                </small>
              ) : null}
            </div>

            <div className="form_inner_container">
              <label>New Pin Code</label>
              <input
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "-" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                }}
                type="number"
                placeholder="Enter a new Pin Code"
                {...register("newPinCode", changePinValidation?.newPinCode)}
              />
              {errors?.newPinCode ? (
                <small className="text-danger">
                  {errors?.newPinCode && errors?.newPinCode?.message}
                </small>
              ) : null}
            </div>

            <div className="form_inner_container">
              <label>Confirm Pin Code</label>
              <input
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "-" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                }}
                type="number"
                placeholder="Enter a confirm Pin Code"
                {...register(
                  "confirmPinCode",
                  changePinValidation?.confirmPinCode,
                )}
              />
              {errors?.confirmPinCode ? (
                <small className="text-danger">
                  {errors?.confirmPinCode && errors?.confirmPinCode?.message}
                </small>
              ) : null}
            </div>
            <div className="password_submit_container">
              <button type="submit">
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </Form>
      </React.Fragment>
    </Modal>
  );
};

export default ChangePinCode;
