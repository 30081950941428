import React from "react";
import { RotatingLines } from "react-loader-spinner";

const Loader = () => {
  return (
    <div
      className={
        "d-flex justify-content-center align-items-center my-5 Loader "
      }
      style={{ top: "35%", position: "absolute", left: "45%" }}
    >
      <RotatingLines
        strokeColor="rgb(0, 151, 217)"
        strokeWidth="5"
        animationDuration="0.75"
        width="60"
        visible={true}
      />
    </div>
  );
};

export default Loader;
