import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  header_main: {
    borderTopLeftRadius: "30px !important",
    borderTopRightRadius: "30px !important",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "none",
    boxShadow: "0px 9px 15px rgba(0,174,250,0.1) !important",
  },
  toolTipData: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  header_profile_pic: {
    "& img": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});
