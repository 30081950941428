import { Button, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "../../../../../Components/Modal/Modal";
import {
  clearErrors,
  updateDesignationByHR,
} from "../../../../../Redux/Actions/Designations";
import { getAllDesignations } from "../../../../../Redux/Actions/HR";
import { errorNotify, successNotify } from "../../../../../Utils/toast";

const UpdateDesignationModal = ({
  classes,
  updateModalOpen,
  setUpdateModalOpen,
  page,
  rowsPerPage,
  updateDesignationDetails,
  setUpdateDesignationDetails,
  loading,
}) => {
  //   console.log(updateDesignationDetails)
  const dispatch = useDispatch();
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.updateDesignation);
  useEffect(() => {
    if (isUpdated?.success) {
      successNotify(isUpdated?.message);
      dispatch({ type: "UPDATE_DESIGNATION_RESET" });
      setUpdateModalOpen(false);
      dispatch(getAllDesignations(page, rowsPerPage));
      setUpdateDesignationDetails({
        _id: "",
        designation_name: "",
      });
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch(clearErrors());
    }
  }, [isUpdated, updateError]);
  const handleUpdateDesignation = () => {
    dispatch(updateDesignationByHR(updateDesignationDetails));
  };
  const handleCancel = () => {
    setUpdateModalOpen(false);
    setUpdateDesignationDetails({
      _id: "",
      designation_name: "",
    });
  };
  return (
    <ReactModal
      open={updateModalOpen}
      setOpen={setUpdateModalOpen}
      title="Update New Designation"
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="large" animation="border" />
        </Box>
      ) : (
        <>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Designation Name
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Enter Designation Name"
              fullWidth
              className={classes.inputContainer}
              value={updateDesignationDetails?.designation_name}
              onChange={(e) =>
                setUpdateDesignationDetails({
                  ...updateDesignationDetails,
                  designation_name: e.target.value,
                })
              }
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                className={classes.cancelBtn}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.createBtn}
                disabled={updateLoading ? true : false}
                onClick={() => handleUpdateDesignation()}
              >
                {updateLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </ReactModal>
  );
};

export default UpdateDesignationModal;
