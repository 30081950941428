import React from "react";
import DashboardIcon from "../../../Asssets/Images/DashboardIcon.png";
import AttendanceIcon from "../../../Asssets/Images/AttendanceIcon.png";
import AnnouncementIcon from "../../../Asssets/Images/AnnouncementIcon.png";
import LeaveManagementIcon from "../../../Asssets/Images/LeaveManagementIcon.png";
import ProfileIcon from "../../../Asssets/Images/ProfileIcon.png";
import TicketIcon from "../../../Asssets/Images/TicketsIcon.png";
import PayrollIcon from "../../../Asssets/Images/payrollIcon.png";

// Manager Routes

import Profile from "../../Employee/Pages/Profile/Profile";
import LeaveDetails from "../../HR/Pages/LeaveManagement/LeaveDetails";
import Tickets from "../../Employee/Pages/Tickets/Tickets";
import TicketDetails from "../../HR/Pages/Ticket/TicketDetails";
import Dashboard from "../../Employee/Pages/Dashboard/Dashboard";
import Attendance from "../../Employee/Pages/Attendance/Attendance";
import Leaves from "../../Employee/Pages/Leaves/Leaves";
import Announcement from "../../Employee/Pages/Announcements/Announcement";
import EmployeePayroll from "../../Employee/Pages/Payroll/Payroll";
import PayrollDetails from "../../HR/Pages/Payroll/payrollDetails";
import Payslip from "../../HR/Pages/Payroll/Payslip/Payslip";
import Requests from "../../Employee/Pages/Requests/Requests";
import RequestDetails from "../../HR/Pages/RequestModule/RequestDetails";

export const managerRoutes = [
  {
    path: "/manager/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/manager/profile",
    component: <Profile />,
  },
  {
    path: "/manager/attendance",
    component: <Attendance />,
  },
  {
    path: "/manager/leaves",
    component: <Leaves />,
  },
  {
    path: "/manager/leaves/:id",
    component: <LeaveDetails />,
  },
  {
    path: "/manager/announcement",
    component: <Announcement />,
  },
  {
    path: "/manager/tickets",
    component: <Tickets />,
  },
  {
    path: "/manager/tickets/:id",
    component: <TicketDetails />,
  },
  {
    path: "/manager/payroll",
    component: <EmployeePayroll />,
  },

  {
    path: "/manager/payroll/:id",
    component: <PayrollDetails />,
  },
  {
    path: "/manager/payroll/payslip/:id",
    component: <Payslip />,
  },
  {
    path: "/manager/requests",
    component: <Requests user="manager" />,
  },
  {
    path: "/manager/request/:id",
    component: <RequestDetails />,
  },
];

export const managerSideBarItems = [
  {
    path: "/manager/dashboard",
    icon: <DashboardIcon />,
    title: "Dashboard",
    isSubNav: false,
  },
  {
    path: "/manager/profile",
    icon: <ProfileIcon />,
    title: "Profile",
    isSubNav: false,
  },

  {
    path: "/manager/attendance",
    icon: <AttendanceIcon />,
    title: "Attendance",
    isSubNav: false,
  },
  {
    path: "/manager/requests",
    icon: <AttendanceIcon />,
    title: "Request Module",
    isSubNav: false,
  },

  {
    path: "/manager/announcement",
    icon: <AnnouncementIcon />,
    title: "Announcement",
    isSubNav: false,
  },

  {
    path: "/manager/leaves",
    icon: <LeaveManagementIcon />,
    title: "Leave Applications",
    isSubNav: false,
  },
  {
    path: "/manager/tickets",
    icon: <TicketIcon />,
    title: "Tickets",
    isSubNav: false,
  },
  {
    path: "/manager/payroll",
    icon: <PayrollIcon />,
    title: "Payroll",
    isSubNav: false,
  },
];
