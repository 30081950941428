export const AllRequestsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_REQUESTS_REQUEST":
      return {
        loading: true,
      };
    case "GET_ALL_REQUESTS_SUCCESS":
      return {
        ...state,
        loading: false,
        requests: action.payload.requests,
        requestCount: action.payload.requestCount,
      };
    case "GET_ALL_REQUESTS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const CurrentUserRequestsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_CURRENT_USER_REQUESTS_REQUEST":
      return {
        loading: true,
      };
    case "GET_CURRENT_USER_REQUESTS_SUCCESS":
      return {
        ...state,
        loading: false,
        requests: action.payload.requests,
        requestCount: action.payload.requestCount,
      };
    case "GET_CURRENT_USER_REQUESTS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const CreateRequestReducer = (state = { request: {} }, action) => {
  switch (action.type) {
    case "CREATE_REQUEST_REQUEST":
      return {
        loading: true,
        error: null,
        success: false,
      };
    case "CREATE_REQUEST_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        request: action.payload,
      };
    case "CREATE_REQUEST_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_REQUEST_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        error: null,
      };
    default:
      return state;
  }
};

export const IndivisualRequestDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "INDIVISUAL_REQUEST_DETAIL_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "INDIVISUAL_REQUEST_DETAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        request: action.payload,
      };
    case "INDIVISUAL_REQUEST_DETAIL_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const RequestDeleteReducer = (state = [], action) => {
  switch (action.type) {
    case "INDIVISUAL_REQUEST_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "INDIVISUAL_REQUEST_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deleted: action.payload,
      };
    case "INDIVISUAL_REQUEST_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "INDIVISUAL_REQUEST_DELETE_RESET":
      return {
        ...state,
        deleted: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
