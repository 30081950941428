import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import { FaEdit } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { RiAddLine } from "react-icons/ri";
import { useStyle } from "./leave-style";
import { useDispatch, useSelector } from "react-redux";
import { getAllLeaves } from "../../../../../Redux/Actions/HR";
import Loader from "../../../../../Utils/Loader";
import CreateLeaveModal from "./CreateLeaveModal";
import {
  clearErrors,
  deleteLeaveByHR,
  getLeaveDetails,
} from "../../../../../Redux/Actions/Leaves";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import UpdateLeaveModal from "./UpdateLeaveModal";

const Leaves = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyle();
  const dispatch = useDispatch();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [leaveData, setLeaveData] = useState({
    leave_type: "",
    leave_quota: "",
  });
  const [updateLeaveDetails, setUpdateLeaveDetails] = useState({
    _id: "",
    leave_type: "",
    leave_quota: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const {
    loading: leavesLoading,
    leaves,
    leavesCount,
  } = useSelector((state) => state.leaves);
  const {
    loading: delLeaveLoading,
    isDeleted,
    error: delLeaveError,
  } = useSelector((state) => state.deleteLeave);

  const {
    loading: leaveLoading,
    leave,
    error: leavenError,
  } = useSelector((state) => state.leaveDetails);
  useEffect(() => {
    dispatch(getAllLeaves(page, rowsPerPage));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (isDeleted?.success) {
      successNotify(isDeleted?.message);
      dispatch(getAllLeaves(page, rowsPerPage));
      dispatch({ type: "DELETE_LEAVE_RESET" });
    }
    if (delLeaveError) {
      errorNotify(delLeaveError);
      dispatch(clearErrors());
    }
  }, [isDeleted?.success, delLeaveError]);
  useEffect(() => {
    if (leave?.success) {
      setUpdateLeaveDetails({
        _id: leave?.leave._id,
        leave_type: leave?.leave?.leave_type,
        leave_quota: leave?.leave?.leave_quota,
      });
    }
  }, [leave]);
  const updateLeave = (desId) => {
    dispatch(getLeaveDetails(desId));
    setUpdateModalOpen(true);
  };

  const deleteLeave = (id) => {
    dispatch(deleteLeaveByHR(id));
  };
  const leaveCols = [
    { name: "sno", label: "S:NO" },
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    { name: "leave_type", label: "LEAVE NAME" },
    { name: "leave_quota", label: "LEAVE QUOTA" },

    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                type="button"
                onClick={() => updateLeave(tableMeta.rowData[1])}
              >
                <FaEdit />
              </button>
              <button
                className="delete_btn"
                type="button"
                onClick={() => deleteLeave(tableMeta.rowData[1])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];

  let newData = [];
  leaves?.map((item, index) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });
  return (
    <>
      {leavesLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="departmentsTable">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                className="addDepartment_btn"
                onClick={() => setModalOpen(true)}
              >
                <span
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                >
                  <RiAddLine />
                </span>
                Add Leave{" "}
              </Button>
            </Box>
            <MuiDataTable
              title="Departments"
              columns={leaveCols}
              data={newData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={leavesCount}
              tableId="hr_leaves_table"
            />
          </Box>
        </>
      )}
      <CreateLeaveModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        leaveData={leaveData}
        setLeaveData={setLeaveData}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <UpdateLeaveModal
        classes={classes}
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        page={page}
        rowsPerPage={rowsPerPage}
        updateLeaveDetails={updateLeaveDetails}
        setUpdateLeaveDetails={setUpdateLeaveDetails}
        loading={leaveLoading}
      />
    </>
  );
};

export default Leaves;
