import { Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { toast } from "react-toastify";
import RebnderModal from "../../../../../Components/RebnderModal";
import api from "../../../../../Utils/interceptors.js";
import { useSelector } from "react-redux";

const JobDetailsCard = ({ classes, employee }) => {
  const [isShow, setIsShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const { user, loading } = useSelector((state) => state.currentUser);

  const handlePinCode = async (otp) => {
    try {
      setLoader(true);
      const { data } = await api.post("auth/user/check-pincode", {
        pinCode: otp,
      });
      if (data?.success) {
        setIsShow(true);
        setModalOpen(false);
        toast.success("Success");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }

    setLoader(false);
  };

  return (
    <Paper className={classes.details_card}>
      <div className="details_head">
        <h5>Job Details</h5>
      </div>
      <Grid container className={classes.personal_details_form}>
        <Grid item md={6}>
          <Typography variant="h6">Role:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.role}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Skype Link:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.skype_link}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Salary:</Typography>
        </Grid>
        <Grid item md={6}>
          {user?.user?.role === "HR" || user?.user?.role === "admin" ? (
            <Typography>{employee?.salary}</Typography>
          ) : (
            <Typography>
              {isShow ? employee?.salary : "﹡﹡﹡﹡﹡"}
              {!isShow ? (
                <IoEye
                  onClick={() => setModalOpen(true)}
                  style={{
                    marginLeft: "10px",
                    marginTop: "-5px",
                    cursor: "pointer",
                  }}
                  size={22}
                />
              ) : (
                <IoEyeOff
                  onClick={() => setIsShow(false)}
                  style={{
                    marginLeft: "10px",
                    marginTop: "-5px",
                    cursor: "pointer",
                  }}
                  size={22}
                />
              )}
            </Typography>
          )}
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Department:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.department?.department_name}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Designation:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.designation?.designation_name}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Line Manager:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.line_manager?.full_name}</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Joining Date:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>
            {moment(employee?.joining_date).format("DD-MM-YYYY")}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Permanent Date:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>
            {employee?.permanent_date
              ? moment(employee?.permanent_date).format("DD-MM-YYYY")
              : "-"}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Webmail ID:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {employee?.email}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Shift Timing:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>
            {/* {
              new Date(employee?.shift?.start_time)
                .toLocaleString()
                .split(" ")[1]
            }{" "}
            <span>to</span>{" "}
            {new Date(employee?.shift?.end_time).toLocaleString().split(" ")[1]} */}
            {moment(employee?.shift?.start_time).format("h:mm a")}{" "}
            <span>to</span>{" "}
            {moment(employee?.shift?.end_time).format("h:mm a")}
          </Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Employee Status:</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.employment_status}</Typography>
        </Grid>
        {/* <Grid item md={6}>
                  <Typography variant="h6">Fuel Allowance:</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography>{employee?.fuel_allowance}</Typography>
                </Grid> */}
        <Grid item md={6}>
          <Typography variant="h6">Leaves:</Typography>
        </Grid>
        <Grid item md={6}>
          {employee?.leaves &&
            employee?.leaves.map((leave, i) => (
              <Grid container key={i}>
                <Grid item md={6}>
                  <Typography>{leave.leave_type}:</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography>{leave.leave_quota}</Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item md={6}>
          <Typography variant="h6">Medical Status</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography>{employee?.medical_status}</Typography>
        </Grid>
      </Grid>
      {modalOpen && (
        <RebnderModal
          loader={loader}
          handlePinCode={handlePinCode}
          setShow={setModalOpen}
          show={modalOpen}
        />
      )}
    </Paper>
  );
};

export default JobDetailsCard;
