import React from "react";
import DashboardIcon from "../../../Asssets/Images/DashboardIcon.png";
import AttendanceIcon from "../../../Asssets/Images/AttendanceIcon.png";
import AnnouncementIcon from "../../../Asssets/Images/AnnouncementIcon.png";
import LeaveManagementIcon from "../../../Asssets/Images/LeaveManagementIcon.png";
import ProfileIcon from "../../../Asssets/Images/ProfileIcon.png";
import TicketIcon from "../../../Asssets/Images/TicketsIcon.png";
import PayrollIcon from "../../../Asssets/Images/payrollIcon.png";
// Employee Routes
import Dashboard from "../Pages/Dashboard/Dashboard";
import Attendance from "../Pages/Attendance/Attendance";
import Leaves from "../Pages/Leaves/Leaves";
import Announcement from "../Pages/Announcements/Announcement";
import LeaveDetails from "../../HR/Pages/LeaveManagement/LeaveDetails";
import Profile from "../Pages/Profile/Profile";
import Tickets from "../Pages/Tickets/Tickets";
import TicketDetails from "../../HR/Pages/Ticket/TicketDetails";
import Payroll from "../../Employee/Pages/Payroll/Payroll";
import PayrollDetails from "../../HR/Pages/Payroll/payrollDetails";
import Payslip from "../../HR/Pages/Payroll/Payslip/Payslip";
import RequestDetails from "../../HR/Pages/RequestModule/RequestDetails";
import Requests from "../Pages/Requests/Requests";

export const employeeRoutes = [
  {
    path: "/employee/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/employee/profile",
    component: <Profile />,
  },
  {
    path: "/employee/attendance",
    component: <Attendance />,
  },
  {
    path: "/employee/leaves",
    component: <Leaves />,
  },
  {
    path: "/employee/leaves/:id",
    component: <LeaveDetails />,
  },
  {
    path: "/employee/announcement",
    component: <Announcement />,
  },
  {
    path: "/employee/tickets",
    component: <Tickets />,
  },
  {
    path: "/employee/tickets/:id",
    component: <TicketDetails />,
  },

  {
    path: "/employee/payroll",
    component: <Payroll />,
  },

  {
    path: "/employee/payroll/:id",
    component: <PayrollDetails />,
  },
  {
    path: "/employee/payroll/payslip/:id",
    component: <Payslip />,
  },
  {
    path: "/employee/requests",
    component: <Requests user="employee" />,
  },
  {
    path: "/employee/request/:id",
    component: <RequestDetails />,
  },
];

export const employeeSideBarItems = [
  {
    path: "/employee/dashboard",
    icon: <DashboardIcon />,
    title: "Dashboard",
    isSubNav: false,
  },
  {
    path: "/employee/profile",
    icon: <ProfileIcon />,
    title: "Profile",
    isSubNav: false,
  },

  {
    path: "/employee/attendance",
    icon: <AttendanceIcon />,
    title: "Attendance",
    isSubNav: false,
  },
  {
    path: "/employee/requests",
    icon: <AttendanceIcon />,
    title: "Request Module",
    isSubNav: false,
  },

  {
    path: "/employee/announcement",
    icon: <AnnouncementIcon />,
    title: "Announcement",
    isSubNav: false,
  },

  {
    path: "/employee/leaves",
    icon: <LeaveManagementIcon />,
    title: "Leave Applications",
    isSubNav: false,
  },
  {
    path: "/employee/tickets",
    icon: <TicketIcon />,
    title: "Tickets",
    isSubNav: false,
  },
  {
    path: "/employee/payroll",
    icon: <PayrollIcon />,
    title: "Payroll",
    isSubNav: false,
  },
];
