import React, { useState } from "react";
import ReactModal from "../../../../Components/Modal/Modal";
import CheckIn from "../../../../Asssets/Images/checkInClock.png";
import { Box } from "@mui/system";
import Clock from "react-live-clock";
import Timer from "../../../../Asssets/Images/Timer.png";
import { Button, InputLabel, TextField } from "@mui/material";
import {
  getEmployeeCurrentMonthAttendance,
  getLastAttendance,
  submitCheckIn,
} from "../../../../Redux/Actions/Attendance";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { clearErrors } from "../../../../Redux/Actions/HR";
import { Spinner } from "react-bootstrap";

const CheckInModal = ({ classes, modalOpen, setModalOpen }) => {
  const [checkInRemarks, setCheckInRemarks] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const { loading, checkIn, success, error } = useSelector(
    (state) => state.checkIn
  );
  useEffect(() => {
    if (success) {
      successNotify(checkIn?.message);
      dispatch({ type: "CHECK_IN_RESET" });
      setModalOpen(false);
      dispatch(getLastAttendance());
      dispatch(getEmployeeCurrentMonthAttendance());
    }
    if (error) {
      errorNotify(error);
      dispatch(clearErrors());
    }
  }, [success, checkIn, error]);

  // get current coordinates---

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  const dispatch = useDispatch();
  const handleCheckIn = () => {
    dispatch(submitCheckIn({ checkInRemarks, ipAddress }));
  };

  return (
    <>
      <ReactModal open={modalOpen} setOpen={setModalOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={CheckIn}
            alt=""
            style={{ width: "90px", height: "90px", marginTop: "-34px" }}
          />
          <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
            <img src={Timer} style={{ width: "32px" }} />
            <Clock
              format={"HH:mm:ss A"}
              ticking={true}
              timezone={"Asia/Karachi"}
            />
          </Box>
          <Box sx={{ my: 1, width: "95%" }}>
            <InputLabel className={classes.department_label}>
              Check In Remarks
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Remarks 200 characters (Max)"
              fullWidth
              className={classes.inputContainer}
              rows={3}
              multiline
              value={checkInRemarks}
              onChange={(e) => setCheckInRemarks(e.target.value)}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 4 }}
              type="submit"
              className={classes.createBtn}
              disabled={loading ? true : false}
              onClick={() => handleCheckIn()}
            >
              {loading ? <Spinner size="sm" animation="border" /> : "Check In"}
            </Button>
          </Box>
        </Box>
      </ReactModal>
    </>
  );
};

export default CheckInModal;
