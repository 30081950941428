import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { connectWithSocketServer } from "../../realTimeCommunication/SocketConnection";
import { clearErrors, getCurrentUser, Login } from "../../Redux/Actions/Auth";
import { getAllNotifications } from "../../Redux/Actions/Employee";
import { errorNotify, successNotify } from "../../Utils/toast";
import { useStyle } from "./login-style";
import { IoEyeOff } from "react-icons/io5";
import { IoEye } from "react-icons/io5";
import "./UserLogin.css";
import { getUser, validateEmail } from "../../Utils/helper";
import { getAllFilteredEmployees } from "../../Redux/Actions/HR";
import { toast } from "react-toastify";
const UserLogin = () => {
  const classes = useStyle();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const audio = new Audio("/iphone_sound.mp3");
  // audio.autoplay = true;
  const { loading, isAuthenticated, user, error } = useSelector(
    (state) => state.Auth
  );

  let userAuth = getUser() ? getUser() || "" : null;
  useEffect(() => {
    if (userAuth) {
      if (userAuth?.employee?.role) {
        navigate(`${userAuth?.employee.role}/dashboard`);
      } else {
        navigate("/");
      }
    }
  }, [userAuth]);
  useEffect(() => {
    if (user?.success) {
      successNotify(user?.message);
      connectWithSocketServer(userAuth, audio);
      dispatch(getCurrentUser());
      dispatch(getAllNotifications());
      dispatch(getAllFilteredEmployees());
      dispatch({ type: "LOGIN_RESET" });
    }
    if (error) {
      errorNotify(error);
      dispatch(clearErrors());
    }
  }, [dispatch, user, error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      errorNotify("Please fill in all fields");
    } else if (password.length < 8) {
      errorNotify("Invalid email or password");
    } else if (!validateEmail(email)) {
      errorNotify("Invalid email or password");
    } else {
      dispatch(Login({ email, password }));
    }
  };
  return (
    <Box className={classes.loginContainer}>
      <Grid
        item
        container
        sx={{ height: "100%", width: "100%" }}
        direction="row"
        justifyContent="flex-end"
        alignItems={"center"}
      >
        <Grid xs={7} item sx={{ mr: 3 }}>
          <Paper className={classes.loginCard}>
            <Box sx={{ width: "80%" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "900", fontSize: "28px", color: "#010101" }}
              >
                <span style={{ color: "#00ACF7" }}>LOGIN</span> ACCOUNT
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box sx={{ my: 2 }}>
                  <InputLabel sx={{ fontWeight: "700" }}>Email</InputLabel>
                  <TextField
                    placeholder="Enter email"
                    fullWidth
                    className={classes.inputContainer}
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <InputLabel sx={{ fontWeight: "700" }}>Password</InputLabel>
                  <div className="password__field__wrapper">
                    <TextField
                      placeholder="password"
                      type={passwordShow ? "" : "password"}
                      fullWidth
                      className={classes.inputContainer}
                      value={password}
                      onChange={(e) => setPassword(e.target.value.trim())}
                    />
                    <span onClick={() => setPasswordShow(!passwordShow)}>
                      {passwordShow ? (
                        <IoEye size={22} />
                      ) : (
                        <IoEyeOff size={22} />
                      )}
                    </span>
                  </div>
                </Box>
                <Divider />
                <button
                  className="login_btn"
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {!loading ? (
                    "Login"
                  ) : (
                    <Spinner size="sm" animation="border" />
                  )}
                </button>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserLogin;
