import { Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../Components/Header/Header";
import Input from "../../../../Components/Input/Input";
import MetaData from "../../../../Components/MetaData";
import Modal from "../../../../Components/SiteModal/SiteModal";
import {
  getPayrollsById,
  updatePayroll,
} from "../../../../Redux/Actions/Payroll";
import Loader from "../../../../Utils/Loader";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useStyle } from "./payroll-styles";
import "./payroll.css";
import { parseInt } from "lodash";
import Switch from "rc-switch";

const PayrollUpdate = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [commissionModal, setCommissionModal] = useState(false);
  const [bonusModal, setBonusModal] = useState(false);
  const [arrearModal, setArrearModal] = useState(false);
  const [retentionModal, setRetentionModal] = useState(false);
  const [otherModal, setOtherModal] = useState(false);
  const [deductionModal, setDeductionModal] = useState(false);
  const [OPDModal, setOPDModal] = useState(false);
  const [medicalModal, setMedicalModal] = useState(false);
  const [loanModal, setLoanModal] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { loading, payroll, success, error } = useSelector(
    (state) => state.payrollById
  );
  const [loanValue, setLoanValue] = useState(0);
  const [advanceSalary, setAdvanceSalary] = useState(0);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
    payroll: updatedData,
  } = useSelector((state) => state.updatePayroll);
  const date = new Date();
  const [attend, setAttend] = useState({
    absents: 0,
    lates: 0,
    halfDays: 0,
  });
  const [totalDays, setTotalDays] = useState();
  const [earnings, setEarnings] = useState();
  const [otherDeductions, setOtherDeductions] = useState();
  const [balanceRecord, setBalanceRecord] = useState();
  const [taxable, setTaxable] = useState(false);
  // console.log(payroll);

  useEffect(() => {
    setLoanValue(payroll?.loanDeduction ? payroll?.loanDeduction : 0);
    setAdvanceSalary(
      payroll?.advanceSalaryDeduction ? payroll?.advanceSalaryDeduction : 0
    );
  }, [payroll]);

  useEffect(() => {
    if (success) {
      setAttend({
        absents: parseInt(payroll?.calculatedAbsents),
        lates: parseInt(payroll?.lates),
        halfDays: parseInt(payroll?.halfDays),
      });
      date.setMonth(parseInt(payroll.date.month));
      date.setFullYear(parseInt(payroll.date.year));
      const lastDay = new Date(date.getFullYear(), date.getMonth(), 0).setHours(
        0,
        0,
        0,
        0
      );
      const lastDate = new Date(lastDay).getDate();
      setTotalDays(lastDate);
      setEarnings({
        commission: {
          amount:
            typeof payroll?.earnings?.commission === "object"
              ? payroll?.earnings?.commission?.amount
                ? payroll?.earnings?.commission?.amount
                : 0
              : payroll?.earnings?.commission
              ? payroll?.earnings?.commission
              : 0,
          taxable: payroll?.earnings?.commission?.taxable
            ? payroll?.earnings?.commission?.taxable
            : true,
        },
        bonus: {
          amount:
            typeof payroll?.earnings?.bonus === "object"
              ? payroll?.earnings?.bonus?.amount
                ? payroll?.earnings?.bonus?.amount
                : 0
              : payroll?.earnings?.bonus
              ? payroll?.earnings?.bonus
              : 0,
          taxable: payroll?.earnings?.bonus?.taxable
            ? payroll?.earnings?.bonus?.taxable
            : true,
        },
        arrear: {
          amount:
            typeof payroll?.earnings?.arrear === "object"
              ? payroll?.earnings?.arrear?.amount
                ? payroll?.earnings?.arrear?.amount
                : 0
              : payroll?.earnings?.arrear
              ? payroll?.earnings?.arrear
              : 0,
          taxable: payroll?.earnings?.arrear?.taxable
            ? payroll?.earnings?.arrear?.taxable
            : false,
        },
        retention: {
          amount: payroll?.earnings?.retention?.amount
            ? payroll?.earnings?.retention?.amount
            : 0,
          taxable: payroll?.earnings?.retention?.taxable,
        },
        other: {
          amount:
            typeof payroll?.earnings?.other === "object"
              ? payroll?.earnings?.other?.amount
                ? payroll?.earnings?.other?.amount
                : 0
              : payroll?.earnings?.other
              ? payroll?.earnings?.other
              : 0,
          taxable: payroll?.earnings?.other?.taxable
            ? payroll?.earnings?.other?.taxable
            : false,
        },
      });
      setBalanceRecord({
        OPDBalance: payroll?.balanceRecord.OPDBalance,
        medicalInsurance: payroll?.balanceRecord.medicalInsurance,
        loanBalance: payroll?.balanceRecord.loanBalance,
      });
    }
    if (updateSuccess) {
      successNotify("Payroll updated successfully");
      dispatch(getPayrollsById(id));
      dispatch({ type: "UPDATE_PAYROLL_RESET" });
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [success, updateSuccess, updateError]);
  useEffect(() => {
    dispatch(getPayrollsById(id));
  }, [id]);
  // console.log(payroll);
  // console.log(totalDays);
  // console.log(attend);
  const manageAttendance = (name, type) => {
    if (type === "inc") {
      const total = attend.lates + attend.halfDays + attend.absents;
      if (total < totalDays) {
        setAttend({ ...attend, [name]: attend[name] + 1 });
      }
    }
    if (type === "dec") {
      if (attend[name] > 0) {
        setAttend({ ...attend, [name]: attend[name] - 1 });
      }
    }
  };
  const allowanceHandler = handleSubmit(async (data) => {
    // console.log(data);
    const allowance = {
      title: data.title,
      amount: parseInt(data.amount),
    };
    if (data.title === "" || parseInt(data.amount) <= 0) {
      errorNotify("Please fill in all fields!");
    } else {
      dispatch(updatePayroll(id, { allowance }));
      setShow(!show);
      reset();
    }
  });
  const SalaryHandler = () => {
    const formData = {};
    dispatch(
      updatePayroll(id, {
        ...attend,
        earnings,
        balanceRecord,
        loanDeduction: parseInt(loanValue),
        advanceSalaryDeduction: parseInt(advanceSalary),
      })
    );
  };
  const handleEarnings = (e, name, taxableBool) => {
    setEarnings((prev) => {
      return {
        ...prev,
        [name]: {
          amount:
            e.target.value !== "" ? parseInt(e.target.value) : e.target.value,
          taxable: taxableBool,
        },
      };
    });
  };
  const handleBalanceRecord = (e, name) => {
    setBalanceRecord((prev) => {
      return {
        ...prev,
        [name]:
          e.target.value !== "" ? parseInt(e.target.value) : e.target.value,
      };
    });
  };
  const deductionHandler = handleSubmit(async (data) => {
    // console.log(data);
    if (otherDeductions.title === "" || parseInt(otherDeductions.amount) <= 0) {
      errorNotify("Please fill in all fields!");
    } else {
      dispatch(updatePayroll(id, { otherDeductions }));
      setDeductionModal(!deductionModal);
      setOtherDeductions();
    }
  });

  const onChange = (value, event) => {
    setTaxable(value);
  };
  return (
    <div className="page_responsive">
      <MetaData
        title={`Payroll - ${
          loading || updateLoading ? "" : payroll?.user?.full_name
        } -- HRM`}
      />
      <Header
        title={`PAYROLL - ${
          loading || updateLoading ? "" : payroll?.user?.full_name
        }`}
      />
      <div className="my-5">
        {loading || updateLoading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="m-0">
              <Col md={6} className="payroll_container">
                <div>
                  <div className="details_head d-flex justify-content-between">
                    <h5>Salary</h5>
                    <h5 style={{ marginRight: "20px" }}>
                      Rs: {Math.round(payroll?.calculatedSalary)}/-
                    </h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={6} className="mt-4">
                        <h6>Basic Salary:</h6>
                      </Col>
                      <Col md={6} className="text-end mt-4">
                        <h6>Rs: {Math.round(payroll?.actualSalary)}/-</h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Tax:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>Rs: {payroll?.tax}/-</h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Net Salary:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>Rs: {Math.round(payroll?.calculatedSalary)}/-</h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col md={6} className="my-1">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Allowances</h5>
                    <IconButton
                      variant="contained"
                      style={{
                        fontSize: "12px",
                        marginRight: "20px",
                        padding: "10px 14px",
                        background:
                          "linear-gradient(to bottom ,#0DC81F, #147D12)",
                        color: "white",
                      }}
                      onClick={() => setShow(true)}
                    >
                      <FaPlus />
                    </IconButton>
                  </div>

                  <div className="details_container">
                    {payroll?.allowance.length > 0 &&
                      payroll?.allowance.map((p) => (
                        <Row className="mt-3">
                          <Col md={6}>
                            <h6>{p?.title}:</h6>
                          </Col>
                          <Col md={6} className="text-end">
                            <h6>Rs: {p?.amount}/-</h6>
                          </Col>
                        </Row>
                      ))}
                  </div>
                </div>
              </Col>

              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Earnings</h5>
                    <h5 style={{ marginRight: "20px" }}>
                      Rs:{" "}
                      {parseInt(earnings?.commission?.amount || 0) +
                        parseInt(earnings?.bonus?.amount || 0) +
                        parseInt(earnings?.arrear?.amount || 0) +
                        parseInt(earnings?.other?.amount || 0) +
                        parseInt(earnings?.retention?.amount || 0)}
                      /-
                    </h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={5}></Col>
                      <Col style={{ padding: "0" }} md={7}>
                        <div className="d-flex justify-content-end gap-1">
                          <p
                            style={{
                              marginBottom: "0",
                              marginRight: "5px",
                              fontWeight: "700",
                            }}
                          >
                            Amount
                          </p>
                          |
                          <p
                            style={{
                              marginBottom: "0",
                              fontWeight: "700",
                              marginRight: "-4px",
                            }}
                          >
                            Taxable
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">Commission:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec d-flex align-items-center gap-2">
                          <span>
                            Rs: {earnings?.commission?.amount || 0} /-
                          </span>
                          <IconButton
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              padding: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                              flexShrink: "0",
                            }}
                            onClick={() => setCommissionModal(true)}
                          >
                            <FaPlus />
                          </IconButton>
                          |
                          <div style={{ marginBottom: "0" }}>
                            <Switch
                              checked={earnings?.commission?.taxable}
                              onChange={(val) => {
                                setTaxable(val);
                                setEarnings((prev) => ({
                                  ...prev,
                                  commission: {
                                    ...prev.commission,
                                    taxable: val,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">Bonus:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec d-flex align-items-center gap-2">
                          <span>Rs: {earnings?.bonus?.amount || 0} /-</span>
                          <IconButton
                            variant="contained"
                            style={{
                              margin: "5px 0px",
                              fontSize: "12px",
                              padding: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                              flexShrink: "0",
                            }}
                            onClick={() => setBonusModal(true)}
                          >
                            <FaPlus />
                          </IconButton>
                          |
                          <div style={{ marginBottom: "0" }}>
                            <Switch
                              checked={earnings?.bonus?.taxable}
                              onChange={(val) => {
                                setTaxable(val);
                                setEarnings((prev) => ({
                                  ...prev,
                                  bonus: {
                                    ...prev.bonus,
                                    taxable: val,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2 mb-3 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">Arrear:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec d-flex align-items-center gap-2">
                          <span>Rs: {earnings?.arrear?.amount || 0} /-</span>
                          <IconButton
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              padding: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                              flexShrink: "0",
                            }}
                            onClick={() => setArrearModal(true)}
                          >
                            <FaPlus />
                          </IconButton>
                          |
                          <div style={{ marginBottom: "0" }}>
                            <Switch
                              checked={earnings?.arrear?.taxable}
                              onChange={(val) => {
                                setTaxable(val);
                                setEarnings((prev) => ({
                                  ...prev,
                                  arrear: {
                                    ...prev.arrear,
                                    taxable: val,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2 mb-3 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">Retention:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec d-flex align-items-center gap-2">
                          <span>Rs: {earnings?.retention?.amount || 0} /-</span>
                          <IconButton
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              padding: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                              flexShrink: "0",
                            }}
                            onClick={() => setRetentionModal(true)}
                          >
                            <FaPlus />
                          </IconButton>
                          |
                          <div style={{ marginBottom: "0" }}>
                            <Switch
                              checked={earnings?.retention?.taxable}
                              onChange={(val) => {
                                setTaxable(val);
                                setEarnings((prev) => ({
                                  ...prev,
                                  retention: {
                                    ...prev.retention,
                                    taxable: val,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">Others:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec d-flex align-items-center gap-2">
                          <span>Rs: {earnings?.other?.amount || 0} /-</span>
                          <IconButton
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              padding: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                              flexShrink: "0",
                            }}
                            onClick={() => setOtherModal(true)}
                          >
                            <FaPlus />
                          </IconButton>
                          |
                          <div style={{ marginBottom: "0" }}>
                            <Switch
                              checked={earnings?.other?.taxable}
                              onChange={(val) => {
                                setTaxable(val);
                                setEarnings((prev) => ({
                                  ...prev,
                                  other: {
                                    ...prev.other,
                                    taxable: val,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Balance Record</h5>
                  </div>

                  <div className="details_container">
                    <Row className="mt-3 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">OPD Balance:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec">
                          <span>Rs: {balanceRecord?.OPDBalance || 0} /-</span>

                          <Button
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              padding: "3px 5px",
                              marginLeft: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                            }}
                            onClick={() => setOPDModal(true)}
                          >
                            Edit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">Medical Insurance:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec">
                          <span>
                            Rs: {balanceRecord?.medicalInsurance || 0} /-
                          </span>

                          <Button
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              padding: "3px 5px",
                              marginLeft: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                            }}
                            onClick={() => setMedicalModal(true)}
                          >
                            Edit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4 mb-3 align-items-center">
                      <Col md={5} className="d-flex align-items-center">
                        <h6 className="mb-0">Loan Amount:</h6>
                      </Col>

                      <Col md={7} className="d-flex justify-content-end">
                        <div className="inc_dec">
                          <span>Rs: {balanceRecord?.loanBalance || 0} /-</span>

                          <Button
                            variant="contained"
                            style={{
                              fontSize: "12px",
                              padding: "3px 5px",
                              marginLeft: "10px",
                              background:
                                "linear-gradient(to bottom ,#0DC81F, #147D12)",
                              color: "white",
                            }}
                            onClick={() => setLoanModal(true)}
                          >
                            Edit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Deductions</h5>
                    <div className="d-flex align-items-center">
                      <h5 style={{ marginRight: "10px" }}>
                        Rs:{" "}
                        {Math.round(payroll?.deduction) +
                          payroll?.otherDeductions.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.amount,
                            0
                          )}
                        /-
                      </h5>
                      <IconButton
                        variant="contained"
                        style={{
                          fontSize: "12px",
                          marginRight: "20px",
                          padding: "10px 12px",
                          background:
                            "linear-gradient(to bottom ,#0DC81F, #147D12)",
                          color: "white",
                        }}
                        onClick={() => setDeductionModal(true)}
                      >
                        <FaPlus />
                      </IconButton>
                    </div>
                  </div>

                  <div className="details_container">
                    <Row className="mt-3">
                      <Col md={6}>
                        <h6>Lates:</h6>
                      </Col>

                      <Col md={6} className="d-flex justify-content-end">
                        <div className="inc_dec">
                          <button
                            className="plus"
                            onClick={() => manageAttendance("lates", "inc")}
                          >
                            +
                          </button>
                          <span>{attend?.lates}</span>

                          <button
                            className="minus"
                            onClick={() => manageAttendance("lates", "dec")}
                          >
                            -
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <h6>Half Days:</h6>
                      </Col>

                      <Col md={6} className="d-flex justify-content-end">
                        <div className="inc_dec">
                          <button
                            className="plus"
                            onClick={() => manageAttendance("halfDays", "inc")}
                          >
                            +
                          </button>
                          <span>{attend?.halfDays}</span>

                          <button
                            className="minus"
                            onClick={() => manageAttendance("halfDays", "dec")}
                          >
                            -
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col md={6}>
                        <h6>Absents:</h6>
                      </Col>

                      <Col md={6} className="d-flex justify-content-end">
                        <div className="inc_dec">
                          <button
                            className="plus"
                            onClick={() => manageAttendance("absents", "inc")}
                          >
                            +
                          </button>
                          <span>{attend?.absents}</span>
                          <button
                            className="minus"
                            onClick={() => manageAttendance("absents", "dec")}
                          >
                            -
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row className="my-1 justify-content-between ">
                      <Col md={6}>
                        <h6>Loan:</h6>
                      </Col>

                      <Col md={4} className="d-flex justify-content-end">
                        <div
                          style={{
                            gap: "10px",
                            alignItems: "center",
                            display: "flex",
                          }}
                          className="inc_dec w-100"
                        >
                          Rs
                          <input
                            onWheel={(e) => e.target.blur()}
                            className="input____text"
                            onKeyDown={(e) =>
                              (e.keyCode === 69 ||
                                e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.keyCode === 109 ||
                                e.keyCode === 189 ||
                                e.keyCode === 187 ||
                                e.keyCode === 107) &&
                              e.preventDefault()
                            }
                            type="number"
                            style={{
                              width: "100%",
                              padding: "0px 5px",
                              margin: "0",
                              outline: "none",
                              border: "1px solid lightgray",
                            }}
                            value={loanValue}
                            onChange={(e) => setLoanValue(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="my-1 justify-content-between ">
                      <Col md={6}>
                        <h6>Advance Salary:</h6>
                      </Col>

                      <Col md={4} className="d-flex justify-content-end">
                        <div
                          style={{
                            gap: "10px",
                            alignItems: "center",
                            display: "flex",
                          }}
                          className="inc_dec w-100 "
                        >
                          Rs
                          <input
                            onWheel={(e) => e.target.blur()}
                            className="input____text"
                            onKeyDown={(e) =>
                              (e.keyCode === 69 ||
                                e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.keyCode === 109 ||
                                e.keyCode === 189 ||
                                e.keyCode === 187 ||
                                e.keyCode === 107) &&
                              e.preventDefault()
                            }
                            type="number"
                            style={{
                              width: "100%",
                              padding: "0px 5px",
                              margin: "0",
                              outline: "none",
                              border: "1px solid lightgray",
                            }}
                            value={advanceSalary}
                            onChange={(e) => setAdvanceSalary(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    {payroll?.otherDeductions.length > 0 &&
                      payroll?.otherDeductions.map((d) => (
                        <Row className="mt-2">
                          <Col md={6}>
                            <h6>{d?.title}:</h6>
                          </Col>
                          <Col md={6} className="text-end">
                            <h6>Rs: {d?.amount}/-</h6>
                          </Col>
                        </Row>
                      ))}
                  </div>
                </div>
              </Col>

              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Leaves and Holidays</h5>
                  </div>

                  <div className="details_container">
                    <Row className="mt-3">
                      <Col md={6}>
                        <h6>Approved Leaves:</h6>
                      </Col>
                      <Col md={6} className="text-center">
                        <h6>{payroll?.approvedLeaves}</h6>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <h6>Approved Half Days:</h6>
                      </Col>
                      <Col md={6} className="text-center">
                        <h6>{payroll?.approvedHalfDays}</h6>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <h6>Holidays:</h6>
                      </Col>
                      <Col md={6} className="text-center">
                        <h6>{payroll?.noOfHolidays}</h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mx-3 mt-4 d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="payroll_update_btn"
                  disabled={updateLoading ? true : false}
                  onClick={SalaryHandler}
                >
                  {updateLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Update"
                  )}
                </Button>
                <Button
                  variant="contained"
                  className="generate_slip_btn"
                  onClick={() => navigate(`/HR/payroll/payslip/${id}`)}
                >
                  Generate Payslip
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <Modal
        title="Create Allowance"
        show={show}
        onCloseModal={() => setShow(!show)}
      >
        <>
          <Form onSubmit={allowanceHandler}>
            <Container>
              <Row>
                <Col md={12} className="mb-2">
                  <Input>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Title"
                      {...register("title")}
                    />
                  </Input>
                </Col>
                <Col md={12}>
                  <Input>
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Amount"
                      {...register("amount")}
                    />
                  </Input>
                </Col>
                <Col md={12}>
                  <div className="text-end mt-3">
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={updateLoading ? true : false}
                      className="generate_modal_btn"
                    >
                      {updateLoading ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        </>
      </Modal>
      <Modal
        title="Add Commission"
        show={commissionModal}
        onCloseModal={() => setCommissionModal(!commissionModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={earnings?.commission?.amount}
              onChange={(e) =>
                handleEarnings(e, "commission", earnings?.commission?.taxable)
              }
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              className="generate_modal_btn"
              onClick={() => setCommissionModal(false)}
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
      <Modal
        title="Add Bonus"
        show={bonusModal}
        onCloseModal={() => setBonusModal(!bonusModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={earnings?.bonus?.amount}
              onChange={(e) =>
                handleEarnings(e, "bonus", earnings?.bonus?.taxable)
              }
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => setBonusModal(false)}
              className="generate_modal_btn"
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
      <Modal
        title="Add Arrear"
        show={arrearModal}
        onCloseModal={() => setArrearModal(!arrearModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={earnings?.arrear?.amount}
              onChange={(e) =>
                handleEarnings(e, "arrear", earnings?.arrear?.taxable)
              }
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => setArrearModal(false)}
              className="generate_modal_btn"
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
      <Modal
        title="Add Retention"
        show={retentionModal}
        onCloseModal={() => setRetentionModal(!retentionModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={earnings?.retention?.amount}
              onChange={(e) =>
                handleEarnings(e, "retention", earnings?.retention?.taxable)
              }
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => setRetentionModal(false)}
              className="generate_modal_btn"
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
      <Modal
        title="Add other amount"
        show={otherModal}
        onCloseModal={() => setOtherModal(!otherModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={earnings?.other?.amount}
              onChange={(e) =>
                handleEarnings(e, "other", earnings?.other?.taxable)
              }
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => setOtherModal(false)}
              className="generate_modal_btn"
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
      <Modal
        title="Other Deductions"
        show={deductionModal}
        onCloseModal={() => setDeductionModal(!deductionModal)}
      >
        <>
          <Form onSubmit={deductionHandler}>
            <Container>
              <Row>
                <Col md={12} className="mb-2">
                  <Input>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Title"
                      onChange={(e) =>
                        setOtherDeductions({ title: e.target.value })
                      }
                    />
                  </Input>
                </Col>
                <Col md={12}>
                  <Input>
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Amount"
                      onChange={(e) =>
                        setOtherDeductions((prev) => {
                          return { ...prev, amount: e.target.value };
                        })
                      }
                    />
                  </Input>
                </Col>
                <Col md={12}>
                  <div className="text-end mt-3">
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={updateLoading ? true : false}
                      className="generate_modal_btn"
                    >
                      {updateLoading ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        </>
      </Modal>
      <Modal
        title="Edit OPD Balance"
        show={OPDModal}
        onCloseModal={() => setOPDModal(!OPDModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={balanceRecord?.OPDBalance}
              onChange={(e) => handleBalanceRecord(e, "OPDBalance")}
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => setOPDModal(false)}
              className="generate_modal_btn"
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
      <Modal
        title="Edit Medical Insurance"
        show={medicalModal}
        onCloseModal={() => setMedicalModal(!medicalModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={balanceRecord?.medicalInsurance}
              onChange={(e) => handleBalanceRecord(e, "medicalInsurance")}
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => setMedicalModal(false)}
              className="generate_modal_btn"
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
      <Modal
        title="Edit Loan Balance"
        show={loanModal}
        onCloseModal={() => setLoanModal(!loanModal)}
      >
        <Container>
          <Input>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount in PKR"
              value={balanceRecord?.loanBalance}
              onChange={(e) => handleBalanceRecord(e, "loanBalance")}
            />
          </Input>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              type="submit"
              onClick={() => setLoanModal(false)}
              className="generate_modal_btn"
            >
              Done
            </Button>
          </div>
        </Container>
      </Modal>
    </div>
  );
};

export default PayrollUpdate;
