import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../Components/Header/Header";
import MetaData from "../../../../Components/MetaData";
import { getPayrollsById } from "../../../../Redux/Actions/Payroll";
import Loader from "../../../../Utils/Loader";
import { useStyle } from "./payroll-styles";
import "./payroll.css";
import { CurrentUserInfo } from "../../../../Utils/helper";

const PayrollDetails = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, payroll, error } = useSelector((state) => state.payrollById);
  useEffect(() => {
    dispatch(getPayrollsById(id));
  }, [id]);

  return (
    <div className="page_responsive">
      <MetaData
        title={`Payroll - ${loading ? "" : payroll?.user?.full_name} -- HRM`}
      />
      <Header title={`PAYROLL - ${loading ? "" : payroll?.user?.full_name}`} />
      <div className="my-5">
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <Row className="m-0">
              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Salary</h5>
                    <h5 style={{ marginRight: "20px" }}>
                      Rs: {Math.round(payroll?.calculatedSalary)} /-
                    </h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={6} className="mt-4">
                        <h6>Basic Salary:</h6>
                      </Col>
                      <Col md={6} className="text-end mt-4">
                        <h6>Rs: {Math.round(payroll?.actualSalary)} /-</h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Tax:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>Rs: {payroll?.tax} /-</h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Net Salary:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>Rs: {Math.round(payroll?.calculatedSalary)} /-</h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Allowances</h5>
                  </div>

                  <div className="details_container">
                    {payroll?.allowance.length > 0 &&
                      payroll?.allowance.map((p) => (
                        <Row className="mt-3">
                          <Col md={6}>
                            <h6>{p?.title}:</h6>
                          </Col>
                          <Col md={6} className="text-end">
                            <h6>Rs: {p?.amount} /-</h6>
                          </Col>
                        </Row>
                      ))}
                  </div>
                </div>
              </Col>

              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Earnings</h5>
                    <h5 style={{ marginRight: "20px" }}>
                      Rs: {payroll?.earnings?.totalEarnings} /-
                    </h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={6} className="mt-4">
                        <h6>Commission:</h6>
                      </Col>
                      <Col md={6} className="text-end mt-4">
                        <h6>
                          Rs:{" "}
                          {typeof payroll?.earnings?.commission === "object"
                            ? payroll?.earnings?.commission?.amount
                            : payroll?.earnings?.commission}{" "}
                          /-
                        </h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Bonus:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>
                          Rs:{" "}
                          {typeof payroll?.earnings?.bonus === "object"
                            ? payroll?.earnings?.bonus?.amount
                            : payroll?.earnings?.bonus}{" "}
                          /-
                        </h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Arrear:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>
                          Rs:{" "}
                          {typeof payroll?.earnings?.arrear === "object"
                            ? payroll?.earnings?.arrear?.amount
                            : payroll?.earnings?.arrear}{" "}
                          /-
                        </h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Retension:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>
                          Rs:{" "}
                          {typeof payroll?.earnings?.retention === "object"
                            ? payroll?.earnings?.retention?.amount
                            : payroll?.earnings?.retention}{" "}
                          /-
                        </h6>
                      </Col>
                      <Col md={6} className="my-1">
                        <h6>Other:</h6>
                      </Col>
                      <Col md={6} className="text-end my-1">
                        <h6>
                          Rs:{" "}
                          {typeof payroll?.earnings?.other === "object"
                            ? payroll?.earnings?.other?.amount
                            : payroll?.earnings?.other}{" "}
                          /-
                        </h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Balance Record</h5>
                  </div>

                  <div className="details_container">
                    <Row>
                      <Col md={6} className="mt-4">
                        <h6>OPD Balance:</h6>
                      </Col>
                      <Col md={6} className="text-end mt-4">
                        <h6>Rs: {payroll?.balanceRecord?.OPDBalance} /-</h6>
                      </Col>
                      <Col md={6} className="mt-4">
                        <h6>Medical Insurance:</h6>
                      </Col>
                      <Col md={6} className="text-end mt-4">
                        <h6>
                          Rs: {payroll?.balanceRecord?.medicalInsurance} /-
                        </h6>
                      </Col>
                      <Col md={6} className="mt-4">
                        <h6>Loan Amount:</h6>
                      </Col>
                      <Col md={6} className="text-end mt-4">
                        <h6>Rs: {payroll?.balanceRecord?.loanBalance} /-</h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Deductions</h5>
                    <h5 style={{ marginRight: "20px" }}>
                      Rs: {Math.round(payroll?.deduction)} /-
                    </h5>
                  </div>

                  <div className="details_container">
                    <Row className="mt-3">
                      <Col md={6}>
                        <h6>Lates:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>{payroll?.lates}</h6>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <h6>Half Days:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>{payroll?.halfDays}</h6>
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col md={6}>
                        <h6>Absents:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>{payroll?.calculatedAbsents}</h6>
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col md={6}>
                        <h6>Loan:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>
                          {payroll?.loanDeduction ? payroll?.loanDeduction : 0}
                        </h6>
                      </Col>
                    </Row>
                    <Row className="my-1">
                      <Col md={6}>
                        <h6>Advance Salary:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>
                          {payroll?.advanceSalaryDeduction
                            ? payroll?.advanceSalaryDeduction
                            : 0}
                        </h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={6} className="bank_main">
                <div className="payroll_container">
                  <div className="details_head d-flex justify-content-between">
                    <h5>Leaves and Holidays</h5>
                  </div>

                  <div className="details_container">
                    <Row className="mt-3">
                      <Col md={6}>
                        <h6>Approved Leaves:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>{payroll?.approvedLeaves}</h6>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <h6>Approved Half Days:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>{payroll?.approvedHalfDays}</h6>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col md={6}>
                        <h6>Holidays:</h6>
                      </Col>
                      <Col md={6} className="text-end">
                        <h6>{payroll?.noOfHolidays}</h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mx-3 mt-4 text-end">
                <Button
                  variant="contained"
                  className="generate_slip_btn"
                  onClick={() =>
                    navigate(
                      `/${
                        CurrentUserInfo()?.employee?.role
                      }/payroll/payslip/${id}`
                    )
                  }
                >
                  Generate Payslip
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </div>
  );
};

export default PayrollDetails;
