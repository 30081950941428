import React from "react";
import Navbar from "../Employee/Routes/Navbar";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../../Utils/helper";

const EmployeeLayout = () => {
  let userAuth = getUser() ? getUser() || "" : null;
  return userAuth?.employee?.role === "employee" ? (
    <React.Fragment>
      <Navbar />
      <Outlet />
    </React.Fragment>
  ) : (
    <Navigate to={`/`} />
  );
};
export default EmployeeLayout;
