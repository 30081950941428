import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  tab_btn: {
    background: "linear-gradient(to bottom, #0097d9, #00b1ff) !important",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    "&:hover": {
      boxShadow: "none !important",
    },
  },
  generate_payroll: {
    background: "linear-gradient(to bottom ,#0DC81F, #147D12) !important",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    paddingTop: "11px !important",
    paddingBottom: "11px !important",
    fontWeight: "700 !Important",
  },
  active_btn: {
    background: "linear-gradient(to bottom, #0097d9, #00b1ff) !important",
    borderRadius: "10px !important",
    boxShadow: "none !important",

    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    "&:hover": {
      boxShadow: "none !important",
    },
  },
  payslip_main: {
    margin: "10px 20px",
    padding: "20px 30px !important",
    borderRadius: "20px !important",
    position: "relative",
  },
  payslip_heading_container: {
    " & img": {
      position: "absolute",
      top: "20px",
      left: "20px",
      width: "150px",
      height: "60px",
    },
    "& h4": {
      textAlign: "center",
      fontWeight: "700",
      color: "#394767 !important",
      paddingBottom: "35px !important",
      fontSize: "26px !important",
    },
  },
  payslip_info_box: {
    backgroundColor: "#F3F8FE",
    borderRadius: "10px",
    border: "1px solid #DCDCDC",
    margin: "10px 0px",
    display: "flex",
    "&  h6:first-child": {
      width: "180px !important",
      borderRight: "1px solid #DCDCDC !important",
      padding: "7px 15px !important",
      fontSize: "14px !important",
      color: "black !important",
      fontWeight: "500 !important",
    },
    "&  h6:last-child": {
      padding: "7px 15px !important",
      fontSize: "14px !important",
      color: "black !important",
      fontWeight: "500 !important",
    },
  },
  monthly_summary: {
    padding: "5px 15px",
    backgroundColor: "#0097D9 !important",
    color: "white !important",
    "&  h6": {
      padding: "5px 10px !important",
      fontSize: "16px !important",
      color: "white !important",
      fontWeight: "700 !important",
    },
  },
  monthly_attendance_box: {
    margin: "20px 0px",
    padding: "0px 20px",
    paddingRight: "40px",
    "& .MuiBox-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "7px 0px",
    },
    "& .MuiBox-root > h6": {
      fontSize: "14px !important",
      fontWeight: "700 !important",
      color: "#253766 !important",
    },
  },
  net_salary_box: {
    margin: "10px 20px",
    backgroundColor: "#F3F8FE",
    border: "1px solid #DCDCDC",
    borderRadius: "20px",
    color: "#253766",
    display: "flex",
    justifyContent: "Space-between",
    alignItems: "center",
    padding: "5px 10px",
    "&  h6": {
      fontSize: "16px !important",
      fontWeight: "600 !important",
    },
  },
  info_text: {
    textAlign: "center",
    marginBottom: "20px !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
  },
});
