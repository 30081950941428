import api from "../../Utils/interceptors";

export const getAllLeaveApplications =
  (
    page,
    size,
    user = "",
    leave_type = "",
    hr_status = "",
    start_date = "",
    end_date = "",
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_LEAVE_APPLICATIONS_REQUEST",
      });
      const { data } = await api.get(
        `leave/applications/all?page=${page}&size=${size}&leave_type=${leave_type}&hr_status=${hr_status}&user=${user}&start_date=${start_date}&end_date=${end_date}`,
      );
      dispatch({
        type: "GET_ALL_LEAVE_APPLICATIONS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_LEAVE_APPLICATIONS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };

export const getCurrentUserLeaveApplications =
  (page = 0, size = 5) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_MY_LEAVE_APPLICATIONS_REQUEST",
      });
      const { data } = await api.get(
        `leave/applications/my?page=${page}&size=${size}`,
      );
      dispatch({
        type: "GET_MY_LEAVE_APPLICATIONS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_MY_LEAVE_APPLICATIONS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };

export const getLineManagerLeaveApplications =
  (page, size) => async (dispatch) => {
    try {
      dispatch({
        type: "GET_LINE_MANAGER_LEAVE_APPLICATIONS_REQUEST",
      });
      const { data } = await api.get(
        `leave/applications/assigned?page=${page}&size=${size}`,
      );
      dispatch({
        type: "GET_LINE_MANAGER_LEAVE_APPLICATIONS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_LINE_MANAGER_LEAVE_APPLICATIONS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };

// Create New Leave

export const createApplicationLeave = (leavesData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LEAVE_REQUEST",
    });

    const { data } = await api.post("/leave/application/create", leavesData);

    dispatch({
      type: "CREATE_LEAVE_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_LEAVE_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

// Leaves Detail By Indivisual Id

export const indivisualLeavesDetail = (leave_id) => async (dispatch) => {
  try {
    dispatch({
      type: "INDIVISUAL_LEAVE_DETAIL_REQUEST",
    });
    const { data } = await api.get(`/leave/application/${leave_id}`);
    dispatch({
      type: "INDIVISUAL_LEAVE_DETAIL_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "INDIVISUAL_LEAVE_DETAIL_FAILED",
      payload: error.response?.data?.message,
    });
  }
};

// Leaves Update By ID

export const indivisualLeavesUpdate =
  (leave_id, leaveData) => async (dispatch) => {
    try {
      dispatch({
        type: "INDIVISUAL_LEAVE_UPDATE_REQUEST",
      });
      const { data } = await api.put(
        `/leave/application/${leave_id}`,
        leaveData,
      );
      dispatch({
        type: "INDIVISUAL_LEAVE_UPDATE_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "INDIVISUAL_LEAVE_UPDATE_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const sendLeaveComment = (leave_id, comment) => async (dispatch) => {
  try {
    dispatch({
      type: "SEND_LEAVE_COMMENT_REQUEST",
    });
    const { data } = await api.put(`/leave/comment/${leave_id}`, {
      comment: comment,
    });
    dispatch({
      type: "SEND_LEAVE_COMMENT_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "SEND_LEAVE_COMMENT_FAILED",
      payload: error.response?.data?.message,
    });
  }
};

// Leaves Delete By ID

export const indivisualLeavesDeleted = (leave_id) => async (dispatch) => {
  try {
    dispatch({
      type: "INDIVISUAL_LEAVE_DELETE_REQUEST",
    });
    const { data } = await api.delete(`/leave/application/${leave_id}`);
    dispatch({
      type: "INDIVISUAL_LEAVE_DELETE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "INDIVISUAL_LEAVE_DELETE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
