import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import { FaEdit } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { RiAddLine } from "react-icons/ri";
import { useStyle } from "./shift-styles";

import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllShifts } from "../../../../../Redux/Actions/HR";
import CreateShiftModal from "./CreateShiftModal";
import Loader from "../../../../../Utils/Loader";
import {
  deleteShiftByHR,
  getShiftDetails,
} from "../../../../../Redux/Actions/Shifts";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import UpdateShiftModal from "./UpdateShiftModal";

const Shifts = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyle();
  const dispatch = useDispatch();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [shiftData, setShiftData] = useState({
    shift_name: "",
    start_time: "",
    end_time: "",
  });
  const [updateShiftDetails, setUpdateShiftDetails] = useState({
    _id: "",
    shift_name: "",
    start_time: "",
    end_time: "",
  });
  const {
    loading: shiftsLoading,
    shifts,
    shiftsCount,
  } = useSelector((state) => state.shifts);
  const {
    loading: delShiftLoading,
    isDeleted,
    error: delDesError,
  } = useSelector((state) => state.deleteShift);

  const {
    loading: shiftLoading,
    shift,
    error: shiftError,
  } = useSelector((state) => state.shiftDetails);
  useEffect(() => {
    dispatch(getAllShifts(page, rowsPerPage));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (isDeleted?.success) {
      successNotify(isDeleted?.message);
      dispatch(getAllShifts(page, rowsPerPage));
      dispatch({ type: "DELETE_DESIGNATION_RESET" });
    }
    if (delDesError) {
      errorNotify(delDesError);
      dispatch(clearErrors());
    }
  }, [isDeleted?.success, delDesError]);
  useEffect(() => {
    if (shift?.success) {
      setUpdateShiftDetails({
        _id: shift?.shift._id,
        shift_name: shift?.shift?.shift_name,
        start_time: shift?.shift?.start_time,
        end_time: shift?.shift?.end_time,
      });
    }
  }, [shift]);

  const updateShift = (shiftId) => {
    dispatch(getShiftDetails(shiftId));
    setUpdateModalOpen(true);
  };
  const deleteShift = (id) => {
    dispatch(deleteShiftByHR(id));
  };

  const shiftCols = [
    { name: "sno", label: "S:NO" },
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    { name: "shift_name", label: "SHIFT NAME" },
    {
      name: "start_time",
      label: "START TIME",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return new Date(value).toLocaleString().split(",")[1].trim();
        },
      },
    },
    {
      name: "end_time",
      label: "END TIME",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return new Date(value).toLocaleString().split(",")[1].trim();
        },
      },
    },
    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                type="button"
                onClick={() => updateShift(tableMeta.rowData[1])}
              >
                <FaEdit />
              </button>
              <button
                className="delete_btn"
                type="button"
                onClick={() => deleteShift(tableMeta.rowData[1])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];

  let newData = [];
  shifts?.map((item, index) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });
  return (
    <>
      {shiftsLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="departmentsTable">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                className="addDepartment_btn"
                onClick={() => setModalOpen(true)}
              >
                <span
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                >
                  <RiAddLine />
                </span>
                Add Shift{" "}
              </Button>
            </Box>
            <MuiDataTable
              title="Departments"
              columns={shiftCols}
              data={newData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={shiftsCount}
              tableId="shifts_table"
            />
          </Box>
        </>
      )}
      <CreateShiftModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        shiftData={shiftData}
        setShiftData={setShiftData}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <UpdateShiftModal
        classes={classes}
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        page={page}
        rowsPerPage={rowsPerPage}
        updateShiftDetails={updateShiftDetails}
        setUpdateShiftDetails={setUpdateShiftDetails}
        loading={shiftLoading}
      />
    </>
  );
};

export default Shifts;
