export const registerReducer = (state = {}, action) => {
  switch (action.type) {
    case "REGISTER_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "REGISTER_SUCCESS":
      return {
        ...state,
        loading: false,
        isRegistered: action.payload,
      };
    case "REGISTER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "REGISTER_RESET":
      return {
        ...state,
        isRegistered: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const HRDashboard = (state = {}, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_DASHBOARD_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
        error: null,
      };
    case "GET_DASHBOARD_DATA_FAILED":
      return {
        ...state,
        loading: false,
        dashboard: null,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const AllEmployeesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_EMPLOYEES_REQUEST":
      return {
        ...state,
        loading: action.moreLoading ? false : true,
        moreLoading: action.moreLoading ? true : false,
        error: null,
        employees: action.moreLoading ? action.prevData : [],
      };
    case "GET_ALL_EMPLOYEES_SUCCESS":
      // console.log(action.payload, "payloadpayload");
      // state?.employees?.employees

      return {
        ...state,
        loading: false,
        moreLoading: false,
        employees:
          state?.employees?.length == 0
            ? action.payload
            : {
                ...action.payload,
                employees: [
                  ...state?.employees?.employees,
                  ...action.payload?.employees,
                ],
              },
      };
    case "GET_ALL_EMPLOYEES_FAILED":
      return {
        ...state,
        loading: false,
        moreLoading: false,
        error: action.payload,
        employees: [],
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const AllFilteredEmployeesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_FILTERED_EMPLOYEES_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        employees: [],
      };
    case "GET_ALL_FILTERED_EMPLOYEES_SUCCESS":
      return {
        ...state,
        loading: false,
        employees: action.payload,
      };
    case "GET_ALL_FILTERED_EMPLOYEES_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        employees: [],
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const AllDepartmentsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_DEPARTMENTS_REQUEST":
      return {
        ...state,
        loading: true,
        success: false,

        error: null,
        departments: [],
      };
    case "GET_ALL_DEPARTMENTS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        success: action.payload.success,
        departments: action.payload.departments,
        departmentCount: action.payload.departmentsCount,
      };
    case "GET_ALL_DEPARTMENTS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
        departments: [],
        departmentCount: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const AllDesignationsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_DESIGNATIONS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        designations: [],
      };
    case "GET_ALL_DESIGNATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        designations: action.payload.designations,
        designationsCount: action.payload.designationsCount,
      };
    case "GET_ALL_DESIGNATIONS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        designations: [],
        designationsCount: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const AllLeavesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_LEAVES_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        leaves: [],
      };
    case "GET_ALL_LEAVES_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        leaves: action.payload.leaves,
        leavesCount: action.payload.leavesCount,
      };
    case "GET_ALL_LEAVES_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        leaves: [],
        leavesCount: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const AllShiftsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_SHIFTS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        shifts: [],
      };
    case "GET_ALL_SHIFTS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        shifts: action.payload.shifts,
        shiftsCount: action.payload.shiftsCount,
      };
    case "GET_ALL_SHIFTS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        shifts: [],
        shiftsCount: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const AllHolidaysReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_HOLIDAYS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        holidays: [],
      };
    case "GET_ALL_HOLIDAYS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        holidays: action.payload.holidays,
        holidaysCount: action.payload.holidaysCount,
      };
    case "GET_ALL_HOLIDAYS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        holidays: [],
        holidaysCount: null,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const getEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_DETAILS_REQUEST":
      return {
        loading: true,
      };
    case "GET_EMPLOYEE_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        employee: action.payload,
      };
    case "GET_EMPLOYEE_DETAILS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getEmployeeDropdownReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_MANAGER_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        employeeDropdown: [],
      };
    case "GET_ALL_MANAGER_SUCCESS":
      return {
        ...state,
        loading: false,
        employeeDropdown: action.payload,
      };
    case "GET_ALL_MANAGER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        employeeDropdown: [],
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
