import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import moment from "moment";

const MyLeaves = ({
  leavesPage,
  setLeavesPage,
  leavesRowsPerPage,
  setLeavesRowsPerPage,
  classes,
  leaveApplications,
  leavesCount,
}) => {
  const navigate = useNavigate();
  const { user, loading } = useSelector((state) => state.currentUser);

  const leaveManagementColumns = [
    {
      name: "user",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
            </div>
          );
        },
        download: false,
        print: false,
        display: false,
      },
    },

    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "user",
      label: "Employee",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={tableMeta.rowData[0].profile_picture[0]?.fileName} />
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "leave_type",
      label: "Leave Type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value}
            </Typography>
          );
        },
      },
    },

    {
      name: "start_date",
      label: "From",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "end_date",
      label: "To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "numOfDays",
      label: "No Of Days",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "leave_subject",
      label: "Subject",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "leave_category",
      label: "Leave Category",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              className={`${
                value === "paid"
                  ? classes.present
                  : value === "unpaid"
                    ? classes.absent
                    : ""
              }`}
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "line_manager_status",
      label: "HOD Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                    ? classes.absent
                    : value === "pending"
                      ? classes.halfDay
                      : classes.late
              }`}
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "hr_status",
      label: "HR Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                    ? classes.absent
                    : value === "pending"
                      ? classes.halfDay
                      : classes.late
              } `}
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(
                    `/${user?.user?.role}/leaves/${tableMeta.rowData[1]}`,
                    {
                      state: { update: false },
                    },
                  )
                }
              >
                <FaEye />
              </button>
            </div>
          );
        },
      },
    },
  ];
  return (
    <Box className="leaves_management_table">
      <MuiDataTable
        title="Departments"
        columns={leaveManagementColumns}
        data={leaveApplications}
        page={leavesPage}
        setPage={setLeavesPage}
        rowsPerPage={leavesRowsPerPage}
        setRowsPerPage={setLeavesRowsPerPage}
        count={leavesCount}
        tableId="my_leaves_table"
      />
    </Box>
  );
};

export default MyLeaves;
