import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../../Utils/helper";

// !userAuth ?
const MainLayout = () => {
  let userAuth = getUser() ? getUser() || "" : null;
  return !userAuth ? (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  ) : (
    <Navigate to={`/${userAuth?.employee?.role}/dashboard`} replace={true} />
  );
};

export default MainLayout;
