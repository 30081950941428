import { Box, Button, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import Header from "../../../../Components/Header/Header";
import "./HRMSetup.css";

import Departments from "./Departments/Departments";
import Designations from "./Designations/Designations";
import Shifts from "./Shifts/Shifts";
import Holidays from "./Holidays/Holidays";
import Leaves from "./Leaves/Leaves";
import MetaData from "../../../../Components/MetaData";

const HRMSetup = () => {
  const [id, setId] = useState(1);

  return (
    <div className="page_responsive">
      <Header title="HRM SETUP" />
      <MetaData title={`Setup -- HRM`} />

      <Box sx={{ margin: "20px 30px" }}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Paper
              sx={{
                width: "100%",
                height: "81.5vh",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                padding: "20px",
                boxShadow: "none",
                border: "none",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                className={`${id === 1 ? "tabs_btn nav_active" : "tabs_btn"}`}
                onClick={() => setId(1)}
              >
                Departments
              </Button>
              <Button
                variant="contained"
                fullWidth
                className={`${id === 2 ? "tabs_btn nav_active" : "tabs_btn"}`}
                onClick={() => setId(2)}
              >
                Designations
              </Button>
              <Button
                variant="contained"
                fullWidth
                className={`${id === 3 ? "tabs_btn nav_active" : "tabs_btn"}`}
                onClick={() => setId(3)}
              >
                Shifts
              </Button>
              <Button
                variant="contained"
                fullWidth
                className={`${id === 4 ? "tabs_btn nav_active" : "tabs_btn"}`}
                onClick={() => setId(4)}
              >
                Holidays
              </Button>
              <Button
                variant="contained"
                fullWidth
                className={`${id === 5 ? "tabs_btn nav_active" : "tabs_btn"}`}
                onClick={() => setId(5)}
              >
                Leaves
              </Button>
            </Paper>
          </Grid>
          <Grid item md={8}>
            {id === 1 ? (
              <Departments />
            ) : id === 2 ? (
              <Designations />
            ) : id === 3 ? (
              <Shifts />
            ) : id === 4 ? (
              <Holidays />
            ) : id === 5 ? (
              <Leaves />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default HRMSetup;
