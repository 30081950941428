import React, { useEffect, useRef, useState } from "react";
import Header from "../../../../Components/Header/Header";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import { FiSearch } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { getAllEmployees, getAllLeaves } from "../../../../Redux/Actions/HR";
import CustomDatePicker from "../../../../Components/DatePicker/DatePicker";
import { Avatar, Typography } from "@mui/material";
import { useStyle } from "../Attendance/attendance-styles";
import "./LeaveManagement.css";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { FaEye } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import MetaData from "../../../../Components/MetaData";
import { getAllLeaveApplications } from "../../../../Redux/Actions/LeaveApplications";
import moment from "moment";
import Loader from "../../../../Utils/Loader";
import ApplyForLeaveModal from "./ApplyForLeaveModal";
import { indivisualLeavesDeleted } from "../../../../Redux/Actions/LeaveApplications";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../Components/SiteModal/SiteModal";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { BiSearch, BiReset } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";

const LeaveManagement = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [hRStatus, setHRStatus] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leaveID, setLeaveID] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [saveUser, setSaveUser] = useState("");
  const [saveLeaveType, setSaveLeaveType] = useState("");
  const [saveHrStatus, setSaveHrStatus] = useState("");
  const [saveStartDate, setSaveStartDate] = useState("");
  const [saveEndDate, setSaveEndDate] = useState("");
  const [isTag, setIstag] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const startDateRef = useRef("");
  const endDateRef = useRef("");
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees
  );
  const { loading, leaveApplications, applicationsCount, error } = useSelector(
    (state) => state.leaveApplications
  );
  const { loading: leavesLoading, leaves } = useSelector(
    (state) => state.leaves
  );
  const {
    loading: deletedLeavesLoading,
    deletedLeaves,
    error: deleteError,
  } = useSelector((state) => state.LeavesApplicationDelete);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const classes = useStyle();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove particular data from localStorage
      window.sessionStorage.removeItem("user");
      window.sessionStorage.removeItem("leave_type");
      window.sessionStorage.removeItem("hr_status");
      window.sessionStorage.removeItem("start_date");
      window.sessionStorage.removeItem("end_date");
      setSaveUser("");
      setSaveLeaveType("getSaveLeave_typ");
      setSaveHrStatus("");
      setSaveStartDate("");
      setSaveEndDate("");
      setIstag(false);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAllLeaves());
  }, []);

  useEffect(() => {
    startDateRef.current = null;
    endDateRef.current = null;
    const getSaveUser = window.sessionStorage.getItem("user");
    const getSaveLeave_type = window.sessionStorage.getItem("leave_type");
    const getSaveHr_status = window.sessionStorage.getItem("hr_status");
    const getSaveStart_date = window.sessionStorage.getItem("start_date");
    const getSaveEnd_date = window.sessionStorage.getItem("end_date");

    dispatch(
      getAllLeaveApplications(
        page,
        size,
        getSaveUser ? getSaveUser : user,
        getSaveLeave_type ? getSaveLeave_type : leaveType,
        getSaveHr_status ? getSaveHr_status : hRStatus,
        getSaveStart_date ? getSaveStart_date : start_date,
        getSaveEnd_date ? getSaveEnd_date : end_date
      )
    );
    const UserName = employees?.employees?.filter(
      (e) => e._id === getSaveUser
    )[0]?.full_name;

    setSaveUser(UserName);
    setSaveLeaveType(getSaveLeave_type);
    setSaveHrStatus(getSaveHr_status);
    setSaveStartDate(
      getSaveStart_date ? new Date(getSaveStart_date).toLocaleDateString() : ""
    );
    setSaveEndDate(
      getSaveEnd_date ? new Date(getSaveEnd_date).toLocaleDateString() : ""
    );

    if (
      getSaveUser ||
      getSaveLeave_type ||
      getSaveHr_status ||
      getSaveStart_date ||
      getSaveEnd_date
    ) {
      setIstag(true);
    } else {
      setIstag(false);
    }
  }, [page, size, isClear]);

  useEffect(() => {
    if (deletedLeaves && deletedLeaves.success) {
      successNotify(deletedLeaves?.message);
      setShow(false);
      dispatch({ type: "INDIVISUAL_LEAVE_DELETE_RESET" });
      dispatch(
        getAllLeaveApplications(
          page,
          size,
          user,
          leaveType,
          hRStatus,
          start_date,
          end_date
        )
      );
    }
    if (deleteError) {
      errorNotify(deleteError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [deletedLeaves, deleteError]);

  const handleStartDate = (dateValue) => {
    setStartDate(dateValue);
  };
  const handleEndDate = (dateValue) => {
    setEndDate(dateValue);
  };

  const handleLeaveID = (id) => {
    setLeaveID(id);
    setShow(!show);
  };

  const modal = (
    <Modal
      title="Delete"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <p className="modal_Text">Are u sure you want to delete this leave?</p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(indivisualLeavesDeleted(leaveID))}
          >
            {deletedLeavesLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Yes"
            )}
          </button>
          <button className="my-1 no_btn" onClick={() => setShow(false)}>
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );

  const leaveManagementColumns = [
    {
      name: "user",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
            </div>
          );
        },
        download: false,
        print: false,
        display: false,
      },
    },

    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "user",
      label: "Employee",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={tableMeta.rowData[0].profile_picture[0]?.fileName} />
              <Typography
                onClick={() => navigate(`/HR/employee/${value?.userId}`)}
                style={{
                  cursor: "pointer",
                }}
                sx={{ pl: 1 }}
                className="fs-12 link__color"
              >
                {value?.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "leave_type",
      label: "Leave Type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value}
            </Typography>
          );
        },
      },
    },

    {
      name: "start_date",
      label: "From",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "end_date",
      label: "To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "numOfDays",
      label: "Days(#)",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "leave_subject",
      label: "Subject",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "leave_category",
      label: "Leave Category",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              className={`${
                value === "paid"
                  ? classes.present
                  : value === "unpaid"
                  ? classes.absent
                  : ""
              }`}
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "line_manager_status",
      label: "HOD Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                  ? classes.absent
                  : value === "pending"
                  ? classes.halfDay
                  : classes.late
              }`}
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "hr_status",
      label: "HR Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                  ? classes.absent
                  : value === "pending"
                  ? classes.halfDay
                  : classes.late
              } `}
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(`/HR/leave-management/${tableMeta.rowData[1]}`, {
                    state: {
                      update: true,
                    },
                  })
                }
              >
                <FaEye />
              </button>
              <button
                className="delete_btn"
                onClick={() => handleLeaveID(tableMeta.rowData[1])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];
  const onSubmitHandler = handleSubmit(async (data) => {
    const { hr_status, leave_type } = data;
    setHRStatus(hr_status);
    setLeaveType(leave_type);
    setPage(0);

    if (user) {
      window.sessionStorage.setItem("user", user);
    }
    if (leave_type) {
      window.sessionStorage.setItem("leave_type", leave_type);
    }
    if (hr_status) {
      window.sessionStorage.setItem("hr_status", hr_status);
    }
    if (start_date) {
      window.sessionStorage.setItem("start_date", start_date);
    }
    if (end_date) {
      window.sessionStorage.setItem("end_date", end_date);
    }

    const getSaveUser = window.sessionStorage.getItem("user");
    const getSaveLeave_type = window.sessionStorage.getItem("leave_type");
    const getSaveHr_status = window.sessionStorage.getItem("hr_status");
    const getSaveStart_date = window.sessionStorage.getItem("start_date");
    const getSaveEnd_date = window.sessionStorage.getItem("end_date");

    dispatch(
      getAllLeaveApplications(
        page,
        size,
        getSaveUser ? getSaveUser : user,
        getSaveLeave_type ? getSaveLeave_type : leave_type,
        getSaveHr_status ? getSaveHr_status : hr_status,
        getSaveStart_date ? getSaveStart_date : start_date,
        getSaveEnd_date ? getSaveEnd_date : end_date
      )
    );

    const UserName = employees?.employees?.filter(
      (e) => e._id === getSaveUser
    )[0]?.full_name;

    setSaveUser(UserName);
    setSaveLeaveType(getSaveLeave_type);
    setSaveHrStatus(getSaveHr_status);
    setSaveStartDate(
      getSaveStart_date ? new Date(getSaveStart_date).toLocaleDateString() : ""
    );
    setSaveEndDate(
      getSaveEnd_date ? new Date(getSaveEnd_date).toLocaleDateString() : ""
    );

    if (
      getSaveUser ||
      getSaveLeave_type ||
      getSaveHr_status ||
      getSaveStart_date ||
      getSaveEnd_date
    ) {
      setIstag(true);
    } else {
      setIstag(false);
    }
  });

  const clearSearch = () => {
    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("leave_type");
    window.sessionStorage.removeItem("hr_status");
    window.sessionStorage.removeItem("start_date");
    window.sessionStorage.removeItem("end_date");
    setSaveUser("");
    setSaveLeaveType("");
    setSaveHrStatus("");
    setSaveStartDate("");
    setSaveEndDate("");
    setUser("");
    setLeaveType("");
    setHRStatus("");
    setStartDate("");
    setEndDate("");
    setIstag(false);
    setIsClear(!isClear);
  };

  const removeItem = (item) => {
    window.sessionStorage.removeItem(item);
    if (item === "user") {
      setSaveUser("");
      setUser("");
    }
    if (item == "leave_type") {
      setSaveLeaveType("");
      setLeaveType("");
    }
    if (item == "hr_status") {
      setSaveHrStatus("");
      setHRStatus("");
    }
    if (item == "start_date") {
      setSaveStartDate("");
      setStartDate("");
    }
    if (item == "end_date") {
      setSaveEndDate("");
      setEndDate("");
    }
    setIsClear(!isClear);
  };

  return (
    <div className="page_responsive">
      <Header title="LEAVE MANAGEMENT" />
      <MetaData title={`Leave Management -- HRM`} />

      <Row className="m-0">
        <Col md={12}>
          <div className="employees_main_selection">
            <Form onSubmit={onSubmitHandler}>
              <Row className="align-items-start">
                <Col md={10}>
                  <Row className="align-content-center gy-3">
                    <Col lg={4}>
                      <Input>
                        <Form.Label>Employee</Form.Label>
                        <AutoCompleteSearch
                          data={employees?.employees}
                          departmentData={user}
                          setDepartmentData={setUser}
                          title={"Search Employee"}
                        />
                      </Input>
                    </Col>
                    <Col lg={4}>
                      <Input>
                        <Form.Label>Leave Type</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          {...register("leave_type")}
                        >
                          <option defaultValue={-1} key={-1} value="">
                            Select Leaves
                          </option>
                          {leaves &&
                            leaves.map((leave, i) => (
                              <option value={leave.leave_type} key={i}>
                                {leave.leave_type}
                              </option>
                            ))}
                        </Form.Select>
                      </Input>
                    </Col>

                    <Col lg={4}>
                      <Input>
                        <Form.Label>Leave Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          {...register("hr_status")}
                        >
                          <option defaultValue={-1} key={-1} value="">
                            Select Status
                          </option>
                          <option value={"pending"}>Pending</option>
                          <option value={"approved"}>Approved</option>
                          <option value={"rejected"}>Rejected</option>
                        </Form.Select>
                      </Input>
                    </Col>
                    <Col lg={4}>
                      <CustomDatePicker
                        ref={startDateRef}
                        maxDate={end_date}
                        label={"START DATE"}
                        value={start_date}
                        onChangeHandler={handleStartDate}
                      />
                    </Col>
                    <Col lg={4}>
                      <CustomDatePicker
                        minDate={start_date}
                        ref={endDateRef}
                        label={"END DATE"}
                        value={end_date}
                        onChangeHandler={handleEndDate}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <div className="d-flex align-items-end mt-2">
                      {isTag && saveUser && (
                        <div
                          className="mx-2 rounded-2 p-2"
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            background: "#efefef",
                            width: "fit-contain",
                          }}
                        >
                          <BiSearch /> <span className="mx-2">{saveUser}</span>
                          <IoIosClose
                            onClick={() => removeItem("user")}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                      {isTag && saveLeaveType && (
                        <div
                          className="mx-2 rounded-2 p-2"
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            background: "#efefef",
                            width: "fit-contain",
                          }}
                        >
                          <BiSearch />{" "}
                          <span className="mx-2">{saveLeaveType}</span>
                          <IoIosClose
                            onClick={() => removeItem("leave_type")}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                      {isTag && saveHrStatus && (
                        <div
                          className="mx-2 rounded-2 p-2"
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            background: "#efefef",
                            width: "fit-contain",
                          }}
                        >
                          <BiSearch />{" "}
                          <span className="mx-2">{saveHrStatus}</span>
                          <IoIosClose
                            onClick={() => removeItem("hr_status")}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                      {isTag && saveStartDate && (
                        <div
                          className="mx-2 rounded-2 p-2"
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            background: "#efefef",
                            width: "fit-contain",
                          }}
                        >
                          <BiSearch />{" "}
                          <span className="mx-2">{saveStartDate}</span>
                          <IoIosClose
                            onClick={() => removeItem("start_date")}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                      {isTag && saveEndDate && (
                        <div
                          className="mx-2 rounded-2 p-2"
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            background: "#efefef",
                            width: "fit-contain",
                          }}
                        >
                          <BiSearch />{" "}
                          <span className="mx-2">{saveEndDate}</span>
                          <IoIosClose
                            onClick={() => removeItem("end_date")}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </div>
                  </Row>
                </Col>

                <Col md={2} className="leaves_btn_container">
                  <button
                    type="button"
                    className="add_leave_btn"
                    onClick={() => setModalOpen(true)}
                  >
                    <AiOutlinePlus /> Add Leave
                  </button>
                  <button className="search_btn" type="submit">
                    <FiSearch />
                    Search
                  </button>
                  <button
                    className="search_btn mt-2"
                    type="button"
                    onClick={clearSearch}
                  >
                    <BiReset />
                    Reset
                  </button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>

        <Col md={12}>
          {employeeLoading || loading || leavesLoading ? (
            <Loader />
          ) : (
            <div className="leaves_management_table">
              <MuiDataTable
                title={"ALL LEAVES"}
                columns={leaveManagementColumns}
                data={leaveApplications}
                page={page}
                setPage={setPage}
                rowsPerPage={size}
                setRowsPerPage={setSize}
                count={applicationsCount}
                tableId="leave_management_table"
              />
            </div>
          )}
        </Col>
      </Row>
      {modal}
      <ApplyForLeaveModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        page={page}
        size={size}
        user={user}
        leave_type={leaveType}
        hRStatus={hRStatus}
        start_date={start_date}
        end_date={end_date}
      />
    </div>
  );
};

export default LeaveManagement;
