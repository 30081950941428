import { Box } from "@mui/material";
import React, { useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./AutoCompleteSearch.css";
import { Form } from "react-bootstrap";
import Input from "../../Components/Input/Input";

const AutoCompleteSearch = ({
  data,
  departmentData,
  setDepartmentData,
  title,
  disabled,
  setSearchContent,
  searchContent,
  isTrue
}) => {
  const [empName, setEmpName] = useState();
  const handleOnSelect = (item) => {
    // the item selected
    setDepartmentData(item._id);
    if (isTrue) {
      setSearchContent({ ...searchContent, employee_code: "", full_name: "", department: "", designation: "", id: item._id })
    }
  };
  const handleOnSearch = (item, result) => {
    // the item selected
    // console.log(result)
    setDepartmentData(result.length > 0 ? result[0]._id : "");
    setEmpName(result.length > 0 ? result[0].full_name : "");
  };
  return (
    <Box className="autoComplete">
      {!disabled ? <ReactSearchAutocomplete
        placeholder={title}
        onClear={() => setDepartmentData()}
        items={data}
        // showClear={true}
        // placeholder={`${departmemntData.head_of_department !== "" ? departmemntData.head_of_department.full_name :"Head of department"}`}
        onSearch={handleOnSearch}
        fuseOptions={{ keys: ["full_name"] }}
        // autoFocus
        onSelect={handleOnSelect}
        resultStringKeyName="full_name"
        // formatResult={formatResult}
        maxResults={10}
      /> : <Form>
        <Input>
          <Form.Control
            type={title}
            placeholder="Enter Employee Name"
            disabled={disabled}
          />
        </Input>
      </Form>}

    </Box>
  );
};

export default AutoCompleteSearch;
