import { Avatar, Typography } from "@mui/material";
import React, { useState } from "react";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { tConvert } from "../../../../Utils/helper";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const MonthlyTimesheet = ({ classes, attendances }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const monthlyTimesheetData = [];
  attendances &&
    attendances.map((att, i) => {
      monthlyTimesheetData.push({
        name: att?.user,
        profilePic: att?.user?.profilePic,
        department: att?.user?.department,
        date: att?.date,
        day: att?.day,
        check_in: att?.check_in
          ? new Date(att?.check_in).toLocaleString().split(",")[1]
          : "",
        check_in_remarks: att?.check_in_remarks,
        check_out: att?.check_out
          ? new Date(att?.check_out).toLocaleString().split(",")[1]
          : "",
        check_out_remarks: att?.check_out_remarks,
        late_time: att?.late_time,
        early_off: att?.early_off,
        overtime: att?.overtime,
        totalHours: att?.totalHours,
        status: att?.status,
      });
    });
  const TimeSheetcolumns = [
    {
      name: "profilePic",
      label: "Picture",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value} />
            </div>
          );
        },
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Employee",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={tableMeta.rowData[0]} />
              <Typography
                onClick={() => navigate(`/HR/employee/${value?.userId}`)}
                style={{
                  cursor: "pointer",
                  textUnderlineOffset: "4px",
                }}
                sx={{ pl: 1 }}
                className="fs-12 link__color"
              >
                {value?.name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {moment(value).format("DD-MM-YYYY")}
            </Typography>
          );
        },
      },
    },
    {
      name: "day",
      label: "Day",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {/* {new Date(value).toLocaleDateString("en-US", {
                weekday: "long",
              })} */}
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_in",
      label: "Check In",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value !== ""
                ? value
                : // .toLocaleString()
                  // .split(" ")[1]
                  "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_in_remarks",
      label: "Check In Remarks",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value !== "" ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_out",
      label: "Check Out",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value !== ""
                ? value
                : // .toLocaleString()
                  // .split(" ")[1]
                  "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_out_remarks",
      label: "Check Out Remarks",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value !== "" ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "late_time",
      label: "Late Time",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value !== "" ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "early_off",
      label: "Early Off",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value !== "" ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "totalHours",
      label: "Total Hours",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value !== "" ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {/* {new Date(tableMeta.rowData[3]).toLocaleDateString("en-US", {
                weekday: "long",
              }) === "Sunday" ||
              new Date(tableMeta.rowData[3]).toLocaleDateString("en-US", {
                weekday: "long",
              }) === "Saturday" ? (
                <Typography
                  sx={{ fontSize: "10px", pl: 1 }}
                  className={`${classes.weekend}`}
                >
                  Weekend
                </Typography>
              ) : (
             
              )} */}

              <Typography
                sx={{ fontSize: "10px", pl: 1 }}
                className={`${
                  value === "present"
                    ? classes.present
                    : value === "Absent"
                    ? classes.absent
                    : value === "half-day"
                    ? classes.halfDay
                    : value === "late"
                    ? classes.late
                    : value === "On Leave"
                    ? classes.onLeave
                    : value === "On Half Day Leave"
                    ? classes.halfDayLeave
                    : value === "Weekend"
                    ? classes.weekend
                    : classes.holiday
                }`}
              >
                {value ? value : "-"}
              </Typography>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <div className="monthly_timesheet_table">
        <MuiDataTable
          isDownload={false}
          columns={TimeSheetcolumns}
          data={monthlyTimesheetData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          // count={departmentCount}
          tableId="hr_timesheet_table"
        />
      </div>
    </>
  );
};

export default MonthlyTimesheet;
