import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./Redux/store";
import "react-quill/dist/quill.snow.css";

axios.defaults.baseURL = "https://dh-hrm.azurewebsites.net/api";
// axios.defaults.baseURL = "https://hrmbackend.azurewebsites.net/api";
// axios.defaults.baseURL = "http://localhost:5000/api";
// axios.defaults.baseURL = "http://DESKTOP-A2HHV4S:5000/api";
// axios.defaults.baseURL = "https://edf4-39-50-175-83.ngrok-free.app/api";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
