import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import { FaEdit } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { RiAddLine } from "react-icons/ri";
import { useStyle } from "./designation-style";
import { useDispatch, useSelector } from "react-redux";
import { getAllDesignations } from "../../../../../Redux/Actions/HR";
import Loader from "../../../../../Utils/Loader";
import DesignationModal from "./CreateDesignationModal";
import {
  clearErrors,
  deleteDesignationByHR,
  getDesignationDetails,
} from "../../../../../Redux/Actions/Designations";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import UpdateDesignationModal from "./UpdateDesignationModal";

const Designations = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyle();
  const dispatch = useDispatch();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [designationName, setDesignationName] = useState("");
  const [updateDesignationDetails, setUpdateDesignationDetails] = useState({
    _id: "",
    designation_name: "",
  });
  const {
    loading: designationsLoading,
    designations,
    designationsCount,
  } = useSelector((state) => state.designations);
  const {
    loading: delDesLoading,
    isDeleted,
    error: delDesError,
  } = useSelector((state) => state.deleteDesignation);

  const {
    loading: designationLoading,
    designation,
    error: designationError,
  } = useSelector((state) => state.designationDetails);

  useEffect(() => {
    dispatch(getAllDesignations(page, rowsPerPage));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (isDeleted?.success) {
      successNotify(isDeleted?.message);
      dispatch(getAllDesignations(page, rowsPerPage));
      dispatch({ type: "DELETE_DESIGNATION_RESET" });
    }
    if (delDesError) {
      errorNotify(delDesError);
      dispatch(clearErrors());
    }
  }, [isDeleted?.success, delDesError]);
  useEffect(() => {
    if (designation?.success) {
      setUpdateDesignationDetails({
        _id: designation?.designation._id,
        designation_name: designation?.designation?.designation_name,
      });
    }
  }, [designation]);

  const updateDesignation = (desId) => {
    dispatch(getDesignationDetails(desId));
    setUpdateModalOpen(true);
  };

  const deleteDesignation = (id) => {
    dispatch(deleteDesignationByHR(id));
  };

  const designationCols = [
    { name: "sno", label: "S:NO" },
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    { name: "designation_name", label: "DESIGNATION NAME" },
    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                type="button"
                onClick={() => updateDesignation(tableMeta.rowData[1])}
              >
                <FaEdit />
              </button>
              <button
                className="delete_btn"
                type="button"
                onClick={() => deleteDesignation(tableMeta.rowData[1])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];
  let newData = [];
  designations?.map((item, index) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });
  return (
    <>
      {designationsLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="departmentsTable">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                className="addDepartment_btn"
                onClick={() => setModalOpen(true)}
              >
                <span
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                >
                  <RiAddLine />
                </span>
                Add Designation{" "}
              </Button>
            </Box>
            <MuiDataTable
              title="Designations"
              columns={designationCols}
              data={newData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={designationsCount}
              tableId="designations_table"
            />
          </Box>
        </>
      )}
      <DesignationModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        designationName={designationName}
        setDesignationName={setDesignationName}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      <UpdateDesignationModal
        classes={classes}
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        page={page}
        rowsPerPage={rowsPerPage}
        updateDesignationDetails={updateDesignationDetails}
        setUpdateDesignationDetails={setUpdateDesignationDetails}
        loading={designationLoading}
      />
    </>
  );
};

export default Designations;
