export const CreateLeaveReducer = (state = { leave: {} }, action) => {
  switch (action.type) {
    case "CREATE_LEAVE_REQUEST":
      return {
        loading: true,
      };
    case "CREATE_LEAVE_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        leave: action.payload,
      };
    case "CREATE_LEAVE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_LEAVE_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const LeaveDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_LEAVE_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_LEAVE_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        leave: action.payload,
      };
    case "GET_LEAVE_DETAILS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const UpdateLeaveReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_LEAVE_REQUEST":
      return {
        loading: true,
      };
    case "UPDATE_LEAVE_SUCCESS":
      return {
        loading: false,
        isUpdated: action.payload,
      };
    case "UPDATE_LEAVE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_LEAVE_RESET":
      return {
        ...state,
        isUpdated: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const DeleteLeaveReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_LEAVE_REQUEST":
      return {
        loading: true,
      };
    case "DELETE_LEAVE_SUCCESS":
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case "DELETE_LEAVE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DELETE_LEAVE_RESET":
      return {
        ...state,
        isDeleted: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
