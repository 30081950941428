import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  attendanceTable: {
    "& .css-hyum1k-MuiToolbar-root": {
      background: "linear-gradient(to bottom, #0097d9, #00b1ff)",
      borderTopLeftRadius: "30px",
      borderTopRightRadius: "30px",
      "& .MuiTypography-root": {
        color: "white !important",
      },
    },
    "& .MuiButtonBase-root": {
      display: "none",
    },
    "& .css-1ygcj2i-MuiTableCell-root": {
      borderBottom: "none !important",
    },
    "& .MuiTableCell-head": {
      backgroundColor: "white !important",
      color: "black !important",
    },
    "& .css-nfmglb-MuiTableCell-root": {
      display: "none !important",
    },
  },
  inputContainerTime: {
    margintop: "4px",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      border: "0px solid rgba(235,235,235,90%) !important",
      borderRadius: "8px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px !important",
      backgroundColor: "#F3F8FE",
      border: "none !important",
      fontSize: "14px !important",
      // border: "1px solid #EBEBEB !important"
    },
  },
  leave_card_header: {
    background: "linear-gradient(to bottom,#0097D9,#00B1FF)",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
  },
  leave_card_heading: {
    textAlign: "left",
    fontWeight: "700 !important",
    color: "#253766",
    marginBottom: "8px",
  },
  present: {
    padding: "5px 10px",
    backgroundColor: "rgb(89, 198, 128)",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  late: {
    padding: "5px 10px",
    // backgroundColor: "#f7941d",
    backgroundColor: "#8959db",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  holiday: {
    padding: "5px 10px",
    backgroundColor: "#01a3a3",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  halfDayLeave: {
    padding: "5px 10px",
    backgroundColor: "#1ab0f1",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  onLeave: {
    padding: "5px 10px",
    backgroundColor: "#005fcf",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  halfDay: {
    padding: "5px 5px",
    backgroundColor: "#F2B225",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  absent: {
    padding: "5px 10px",
    backgroundColor: "#FE022C",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  weekend: {
    padding: "5px 10px",
    backgroundColor: "#000000bf",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  check_in_btn: {
    display: "flex",
    textTransform: "none !important",
    background: "linear-gradient(to bottom ,#0DC81F, #147D12) !important",
    color: "white",
    mr: 2,
    borderRadius: "80px !important",
    padding: "12px 15px !important",
  },
  check_out_btn: {
    display: "flex",
    textTransform: "none !important",
    background: "#FE022C !important",
    color: "white",
    mr: 2,
    borderRadius: "80px !important",
    padding: "12px 15px !important",
  },
  on_hold_btn: {
    background: "#dbac01 !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
    fontSize: "1rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(222,1,38,20%) !important",
    textTransform: "none !important",
  },
  department_box: {
    marginTop: "5px",
    backgroundColor: "#59c680",
    color: "white",
    width: "fit-content",
    padding: "3px 20px",
    borderRadius: "30px",
  },

  inputContainer: {
    margintop: "4px",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      border: "0px solid rgba(235,235,235,90%) !important",
      borderRadius: "8px !important",
      padding: "0px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px !important",
      backgroundColor: "#F3F8FE",
      border: "none !important",
      fontSize: "14px !important",
      // border: "1px solid #EBEBEB !important"
    },
  },
  department_label: {
    fontWeight: "700 !important",
    color: "rgb(37, 55, 102) !important",
    fontSize: "15px !important",
  },
  createBtn: {
    background: "linear-gradient(to bottom ,#0DC81F, #147D12) !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
    fontSize: "1rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(6,151,0,30%) !important",
    textTransform: "none !important",
  },
  checkOutBtn: {
    background: "#FE022C !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
    fontSize: "1rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(222,1,38,20%) !important",
    textTransform: "none !important",
  },
  cancelBtn: {
    background: "red !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
    boxShadow: "none !important",
  },
  createLeaveBtn: {
    background: "linear-gradient(to bottom, #0097d9, #00b1ff) !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
    boxShadow: "none !important",
  },
});
