const initialState = {
  user: {},
};

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return {
        loading: true,
        isAuthenticated: false,
        error: false,
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
        error: false,
      };

    case "LOGIN_RESET":
      return {
        ...state,
        user: {},
      };

    case "LOGIN_FAILED":
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const currentUserReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CURRENT_USER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_CURRENT_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case "GET_CURRENT_USER_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const ChangePassReducer = (state = {}, action) => {
  switch (action.type) {
    case "CHANGE_PASSWORD_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "CHANGE_PASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        isChangedPass: action.payload,
      };
    case "CHANGE_PASSWORD_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CHANGE_PASSWORD_RESET":
      return {
        ...state,
        isChangedPass: {},
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const ChangePinReducer = (state = {}, action) => {
  switch (action.type) {
    case "CHANGE_PIN_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "CHANGE_PIN_SUCCESS":
      return {
        ...state,
        loading: false,
        isChangedPin: action.payload,
      };
    case "CHANGE_PIN_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CHANGE_PIN_RESET":
      return {
        ...state,
        isChangedPin: {},
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
