import { CleaningServices } from "@mui/icons-material";
import { io } from "socket.io-client";
import store from "../Redux/store";
let socket = null;

export const connectWithSocketServer = (userDetails, audio) => {
  // audio.autoplay = true;
  let b = document.querySelector("body");
  b.appendChild(audio);

  const jwtToken = userDetails?.token;
  const userChannel = "notification-" + userDetails.employee._id;
  socket = io(
    "https://dh-hrm.azurewebsites.net" /* "https://hrmbackend.azurewebsites.net" */,
    {
      auth: {
        token: jwtToken,
      },
    }
  );

  socket.on("connect", () => {});

  //Listen for the "new_leave_application" event
  socket.on(userChannel, (data) => {
    document.querySelectorAll("audio")[0].play();
    store.dispatch({
      type: "UPDATE_ALL_NOTIFICATIONS_SUCCESS",
      payload: {
        notification: [
          data.newData,
          ...store.getState().employeeNotifications.notifications,
        ],
        count: store.getState().employeeNotifications.count + 1,
      },
    });
  });
};
export const newLeaveSubmitted = (data) => {
  socket.emit("notification", data);
};
export const logoutUserConnection = (data) => {
  socket.emit("logout", data?.employee?._id);
};
