import api from "../../Utils/interceptors";

export const createLeaveByHR = (leaveData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LEAVE_REQUEST",
    });

    const { data } = await api.post("leave/create", leaveData);
    dispatch({
      type: "CREATE_LEAVE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_LEAVE_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const getLeaveDetails = (leaveId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_LEAVE_DETAILS_REQUEST",
    });
    const { data } = await api.get(`leave/${leaveId}`);
    dispatch({
      type: "GET_LEAVE_DETAILS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_LEAVE_DETAILS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const updateLeaveByHR = (leaveData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LEAVE_REQUEST",
    });

    const { data } = await api.put(`leave/${leaveData._id}`, {
      leave_type: leaveData.leave_type,
      leave_quota: leaveData.leave_quota,
    });
    dispatch({
      type: "UPDATE_LEAVE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "UPDATE_LEAVE_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const deleteLeaveByHR = (leaveId) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_LEAVE_REQUEST",
    });

    const { data } = await api.delete(`leave/${leaveId}`);
    dispatch({
      type: "DELETE_LEAVE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "DELETE_LEAVE_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
