import React from "react";
import ReactModal from "../../../../Components/Modal/Modal";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../Redux/Actions/HR";
import { useStyle } from "../HRMSetup/Departments/department-styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import AttendanceToEdit from "./AttendanceToEdit";
import { errorNotify } from "../../../../Utils/toast";
import { getUserAttendance } from "../../../../Redux/Actions/Attendance";
import { Spinner } from "react-bootstrap";

const EditAttendance = ({
  editModalOpen,
  setEditModalOpen,
  editEmployeeId,
  setEditEmployeeId,
}) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [attendanceDate, setAttendanceDate] = useState("");
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);

  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees
  );
  const { loading, success, attendance, error } = useSelector(
    (state) => state.userAttendance
  );
  const { employees: filtered } = useSelector(
    (state) => state.filteredEmployees
  );
  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);
  useEffect(() => {
    if (success) {
      setEditModalOpen(false);
      setAttendanceModalOpen(true);
      setAttendanceDate("");
    }
  }, [success]);
  const handleGetAttendance = () => {
    if (!editEmployeeId || !attendanceDate) {
      errorNotify("please provide employee and date");
    } else {
      dispatch(getUserAttendance(editEmployeeId, attendanceDate));
    }
  };
  return (
    <>
      <ReactModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        title="Edit Employee Attendance"
      >
        <Box sx={{ my: 2 }}>
          <InputLabel className={classes.department_label} sx={{ mb: 1 }}>
            Employee Name
          </InputLabel>
          <AutoCompleteSearch
            data={filtered?.employees}
            departmentData={editEmployeeId}
            setDepartmentData={setEditEmployeeId}
            title={"Employee Name"}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <InputLabel className={classes.department_label}>
            Attendance Date
          </InputLabel>
          <Box className={classes.datePicker}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={attendanceDate}
                onChange={(newValue) => {
                  setAttendanceDate(newValue);
                }}
                className={classes.inputContainer}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    className={classes.inputContainer}
                    {...params}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              className={classes.cancelBtn}
              onClick={() => setEditModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.createBtn}
              // disabled={createLoading ? true : false}
              onClick={() => handleGetAttendance()}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </ReactModal>
      <AttendanceToEdit
        attendanceModalOpen={attendanceModalOpen}
        setAttendanceModalOpen={setAttendanceModalOpen}
      />
    </>
  );
};

export default EditAttendance;
