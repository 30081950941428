export const EmployeeCurrentMonthAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_CURRENT_MONTH_ATTENDANCE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_EMPLOYEE_CURRENT_MONTH_ATTENDANCE_SUCCESS":
      return {
        ...state,
        loading: false,
        attendance: action.payload,
      };
    case "GET_EMPLOYEE_CURRENT_MONTH_ATTENDANCE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const getLastAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_LAST_ATTENDANCE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_LAST_ATTENDANCE_SUCCESS":
      return {
        ...state,
        loading: false,
        attendance: action.payload,
      };
    case "GET_LAST_ATTENDANCE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const CheckInReducer = (state = { checkIn: {} }, action) => {
  switch (action.type) {
    case "CHECK_IN_REQUEST":
      return {
        loading: true,
      };
    case "CHECK_IN_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        checkIn: action.payload,
      };
    case "CHECK_IN_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CHECK_IN_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const CheckOutReducer = (state = { checkOut: {} }, action) => {
  switch (action.type) {
    case "CHECK_OUT_REQUEST":
      return {
        loading: true,
      };
    case "CHECK_OUT_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        checkOut: action.payload,
      };
    case "CHECK_OUT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CHECK_OUT_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const UserAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_USER_ATTENDANCE_REQUEST":
      return {
        loading: true,
      };
    case "GET_USER_ATTENDANCE_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        attendance: action.payload.attendance,
      };
    case "GET_USER_ATTENDANCE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const updateAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_ATTENDANCE_REQUEST":
      return {
        loading: true,
      };
    case "UPDATE_ATTENDANCE_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        attendance: action.payload,
      };
    case "UPDATE_ATTENDANCE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_ATTENDANCE_RESET":
      return {
        ...state,
        success: false,
        attendance: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
