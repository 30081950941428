import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FaDownload } from "react-icons/fa";
import { downloadFile } from "../../../../../Utils/helper";

import Empty from "../../../../../Asssets/Images/Empty.png";

const DocumentDetailsCard = ({ classes, employee }) => {
  return (
    <Paper className={classes.details_card}>
      <div className="details_head">
        <h5>Document Details</h5>
      </div>
      <Box className="documents">
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.resume[0]?.fileName ? (
              <a
                href={employee?.documents?.resume[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.resume[0]?.fileName?.includes(".pdf") ? (
                  <iframe
                    src={employee?.documents?.resume[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.resume[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.resume[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.resume[0]?.fileName,
                    "Resume"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Resume</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.appointment_letter[0]?.fileName ? (
              <a
                href={employee?.documents?.appointment_letter[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.appointment_letter[0]?.fileName?.includes(
                  ".pdf"
                ) ? (
                  <iframe
                    src={employee?.documents?.appointment_letter[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img
                    src={employee?.documents?.appointment_letter[0]?.fileName}
                  />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.appointment_letter[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.appointment_letter[0]?.fileName,
                    "Appointment-letter"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Appointment Letter</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }} className="cnic_box">
            {employee?.documents?.CNIC_pictures.length > 0 ? (
              employee?.documents?.CNIC_pictures.map((cnic, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <a href={cnic?.fileName} target="_blank">
                    {cnic?.fileName?.includes(".pdf") ? (
                      <iframe
                        src={cnic?.fileName}
                        // width="150px"
                        // height="230px"
                      />
                    ) : (
                      <img src={cnic?.fileName} />
                    )}
                  </a>

                  <div className="cnic_download_btn_container">
                    <button
                      onClick={() =>
                        downloadFile(cnic?.fileName, `Cnic ${index + 1}`)
                      }
                    >
                      <FaDownload />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <img src={Empty} />
            )}
          </Box>
          <Typography variant="h6">CNIC Front & Back</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.photo[0]?.fileName ? (
              <a href={employee?.documents?.photo[0]?.fileName} target="_blank">
                {employee?.documents?.photo[0]?.fileName?.includes(".pdf") ? (
                  <iframe
                    src={employee?.documents?.photo[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.photo[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.photo[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.photo[0]?.fileName,
                    "passport-size-photo"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Passport Size Photo</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.utility_bill[0]?.fileName ? (
              <a
                href={employee?.documents?.utility_bill[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.utility_bill[0]?.fileName?.includes(
                  ".pdf"
                ) ? (
                  <iframe
                    src={employee?.documents?.utility_bill[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.utility_bill[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.utility_bill[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.utility_bill[0]?.fileName,
                    "utitlity-bill"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Utility Bill</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.certificates.length > 0 ? (
              employee?.documents?.certificates.map((certificate, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <a href={certificate?.fileName} target="_blank">
                    {certificate?.fileName?.includes(".pdf") ? (
                      <iframe
                        src={certificate?.fileName}
                        // width="150px"
                        // height="230px"
                      />
                    ) : (
                      <img src={certificate?.fileName} />
                    )}
                  </a>

                  <div className="cnic_download_btn_container">
                    <button
                      onClick={() =>
                        downloadFile(
                          certificate?.fileName,
                          `Certificate ${index + 1}`
                        )
                      }
                    >
                      <FaDownload />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <img src={Empty} />
            )}
          </Box>

          <Typography variant="h6">Certificates</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.SSC[0]?.fileName ? (
              <a href={employee?.documents?.SSC[0]?.fileName} target="_blank">
                {employee?.documents?.SSC[0]?.fileName?.includes(".pdf") ? (
                  <iframe
                    src={employee?.documents?.SSC[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.SSC[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.SSC[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.HSC[0]?.fileName,
                    "SSC Certificate"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">SSC</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.HSC[0]?.fileName ? (
              <a href={employee?.documents?.HSC[0]?.fileName} target="_blank">
                {employee?.documents?.HSC[0]?.fileName?.includes(".pdf") ? (
                  <iframe
                    src={employee?.documents?.HSC[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.HSC[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.HSC[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.HSC[0]?.fileName,
                    "HSC Certificate"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">HSC</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.diploma_degree[0]?.fileName ? (
              <a
                href={employee?.documents?.diploma_degree[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.diploma_degree[0]?.fileName?.includes(
                  ".pdf"
                ) ? (
                  <iframe
                    src={employee?.documents?.diploma_degree[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.diploma_degree[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.diploma_degree[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.bachelors_degree[0]?.fileName,
                    "Diploma Degree"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Diploma Degree</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.bachelors_degree[0]?.fileName ? (
              <a
                href={employee?.documents?.bachelors_degree[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.diploma_degree[0]?.fileName ? (
                  <a
                    href={employee?.documents?.diploma_degree[0]?.fileName}
                    target="_blank"
                  >
                    {employee?.documents?.bachelors_degree[0]?.fileName?.includes(
                      ".pdf"
                    ) ? (
                      <iframe
                        src={employee?.documents?.bachelors_degree[0]?.fileName}
                        // width="150px"
                        // height="230px"
                      />
                    ) : (
                      <img
                        src={employee?.documents?.bachelors_degree[0]?.fileName}
                      />
                    )}
                  </a>
                ) : (
                  <img src={Empty} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.bachelors_degree[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.bachelors_degree[0]?.fileName,
                    "Bechelors Degree"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Bechelors Degree</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.masters_degree[0]?.fileName ? (
              <a
                href={employee?.documents?.masters_degree[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.masters_degree[0]?.fileName?.includes(
                  ".pdf"
                ) ? (
                  <iframe
                    src={employee?.documents?.masters_degree[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.masters_degree[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.masters_degree[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.masters_degree[0]?.fileName,
                    "Masters Degree"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Masters Degree</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.last_payslip[0]?.fileName ? (
              <a
                href={employee?.documents?.last_payslip[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.last_payslip[0]?.fileName?.includes(
                  ".pdf"
                ) ? (
                  <iframe
                    src={employee?.documents?.last_payslip[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img src={employee?.documents?.last_payslip[0]?.fileName} />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.last_payslip[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.last_payslip[0]?.fileName,
                    "Last Payslip"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Last Payslip</Typography>
        </Box>
        <Box className={classes.documents_container}>
          <Box sx={{ display: "flex" }}>
            {employee?.documents?.experience_letter[0]?.fileName ? (
              <a
                href={employee?.documents?.experience_letter[0]?.fileName}
                target="_blank"
              >
                {employee?.documents?.experience_letter[0]?.fileName?.includes(
                  ".pdf"
                ) ? (
                  <iframe
                    src={employee?.documents?.experience_letter[0]?.fileName}
                    // width="150px"
                    // height="230px"
                  />
                ) : (
                  <img
                    src={employee?.documents?.experience_letter[0]?.fileName}
                  />
                )}
              </a>
            ) : (
              <img src={Empty} />
            )}
          </Box>
          {employee?.documents?.experience_letter[0]?.fileName ? (
            <div className="download_btn_container">
              <button
                onClick={() =>
                  downloadFile(
                    employee?.documents?.experience_letter[0]?.fileName,
                    "Experience Letter"
                  )
                }
              >
                <FaDownload />
              </button>
            </div>
          ) : (
            ""
          )}
          <Typography variant="h6">Experience Letter</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default DocumentDetailsCard;
