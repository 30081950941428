export const lastPayrollsReducer = (state = [], action) => {
  switch (action.type) {
    case "LAST_EMPLOYEES_PAYROLLS_REQUEST":
      return {
        loading: true,
      };
    case "LAST_EMPLOYEES_PAYROLLS_SUCCESS":
      return {
        ...state,
        loading: false,
        payrolls: action.payload.payrolls,
        payrollsCount: action.payload.payrollsCount,
      };
    case "LAST_EMPLOYEES_PAYROLLS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const PayrollsByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_PAYROLL_REQUEST":
      return {
        loading: true,
      };
    case "GET_PAYROLL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        payroll: action.payload.payroll,
      };
    case "GET_PAYROLL_FAILED":
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const PayrollsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_PAYROLLS_REQUEST":
      return {
        loading: true,
      };
    case "GET_PAYROLLS_SUCCESS":
      return {
        ...state,
        loading: false,
        payrolls: action.payload.payrolls,
        payrollsCount: action.payload.payrollsCount,
        success: action.payload.success,
      };
    case "GET_PAYROLLS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_PAYROLLS_RESET":
      return {
        ...state,
        loading: false,
        payroll: [],
        success: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const CreatePayrollReducer = (state = { payroll: {} }, action) => {
  switch (action.type) {
    case "CREATE_PAYROLL_REQUEST":
      return {
        loading: true,
      };
    case "CREATE_PAYROLL_SUCCESS":
      return {
        loading: false,
        payroll: action.payload,
      };
    case "CREATE_PAYROLL_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_PAYROLL_RESET":
      return {
        ...state,
        payroll: {},
      };

    case "CLEAR_ERRORS":
      return {
        error: null,
      };
    default:
      return state;
  }
};
export const CreatePayrollByDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_PAYROLL_BY_DEPARTMENT_REQUEST":
      return {
        loading: true,
      };
    case "CREATE_PAYROLL_BY_DEPARTMENT_SUCCESS":
      return {
        loading: false,
        isCreated: action.payload.success,
        message: action.payload.message,
      };
    case "CREATE_PAYROLL_BY_DEPARTMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_PAYROLL_BY_DEPARTMENT_RESET":
      return {
        ...state,
        isCreated: {},
      };

    case "CLEAR_ERRORS":
      return {
        error: null,
      };
    default:
      return state;
  }
};
export const CreatePayrollByDateReducer = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_PAYROLL_BY_DATE_REQUEST":
      return {
        loading: true,
      };
    case "CREATE_PAYROLL_BY_DATE_SUCCESS":
      return {
        loading: false,
        createdByDate: action.payload,
      };
    case "CREATE_PAYROLL_BY_DATE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_PAYROLL_BY_DATE_RESET":
      return {
        ...state,
        createdByDate: {},
      };

    case "CLEAR_ERRORS":
      return {
        error: null,
      };
    default:
      return state;
  }
};
export const updateEmployeePayrollsReducer = (
  state = { payroll: {} },
  action,
) => {
  switch (action.type) {
    case "UPDATE_PAYROLL_REQUEST":
      return {
        loading: true,
      };

    case "UPDATE_PAYROLL_SUCCESS":
      return {
        ...state,
        loading: false,
        payroll: action.payload.payroll,
        success: action.payload.success,
      };

    case "UPDATE_PAYROLL_FAILED":
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case "UPDATE_PAYROLL_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const deleteEmployeePayrollsReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_PAYROLL_REQUEST":
      return {
        loading: true,
      };

    case "DELETE_PAYROLL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
      };

    case "DELETE_PAYROLL_FAILED":
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case "DELETE_PAYROLL_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
