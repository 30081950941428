// GET ALL TICKETS

export const AllTicketsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_TICKETS_REQUEST":
      return {
        loading: true,
      };
    case "GET_ALL_TICKETS_SUCCESS":
      return {
        ...state,
        loading: false,
        allTickets: action.payload.tickets,
        allTicketsCount: action.payload.ticketsCount,
      };
    case "GET_ALL_TICKETS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// CREATE TICKET REDUCER

export const CreateTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_TICKET_REQUEST":
      return {
        loading: true,
        createTicket: null,
        error: null,
        success: false,
      };
    case "CREATE_TICKET_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        createTicket: action.payload,
      };
    case "CREATE_TICKET_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_TICKET_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        error: null,
      };
    default:
      return state;
  }
};

export const CurrentUserTicketsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_MY_TICKETS_REQUEST":
      return {
        loading: true,
      };
    case "GET_MY_TICKETS_SUCCESS":
      return {
        ...state,
        loading: false,
        tickets: action.payload.tickets,
        ticketsCount: action.payload.ticketsCount,
      };
    case "GET_MY_TICKETS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const assignedTicketsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ASSIGNED_TICKETS_REQUEST":
      return {
        loading: true,
      };
    case "GET_ASSIGNED_TICKETS_SUCCESS":
      return {
        ...state,
        loading: false,
        assignedTickets: action.payload.tickets,
        assignedTicketsCount: action.payload.ticketsCount,
      };
    case "GET_ASSIGNED_TICKETS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// GET TICKET BY INDIVISUAL ID

export const IndivisualTicketDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "INDIVISUAL_TICKETS_DETAIL_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        indivisualTicketDetails: {},
      };
    case "INDIVISUAL_TICKETS_DETAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        indivisualTicketDetails: action.payload.ticket,
      };
    case "INDIVISUAL_TICKETS_DETAIL_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE TICKET BY INDIVISUAL ID

export const UpdateTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case "INDIVISUAL_TICKETS_UPDATE_REQUEST":
      return {
        loading: true,
      };
    case "INDIVISUAL_TICKETS_UPDATE_SUCCESS":
      return {
        loading: false,
        ticketUpdated: action.payload,
      };
    case "INDIVISUAL_TICKETS_UPDATE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "INDIVISUAL_TICKETS_UPDATE_RESET":
      return {
        ...state,
        ticketUpdated: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// DELETE LEAVE BY INDIVISUAL ID

export const TicketsDeleteReducer = (state = [], action) => {
  switch (action.type) {
    case "INDIVISUAL_TICKET_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "INDIVISUAL_TICKET_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deletedTicket: action.payload,
      };
    case "INDIVISUAL_TICKET_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "INDIVISUAL_TICKET_DELETE_RESET":
      return {
        ...state,
        deletedTicket: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const SendTicketCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case "SEND_TICKET_COMMENT_REQUEST":
      return {
        loading: true,
      };
    case "SEND_TICKET_COMMENT_SUCCESS":
      return {
        loading: false,
        sendComment: action.payload,
      };
    case "SEND_TICKET_COMMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SEND_TICKET_COMMENT_RESET":
      return {
        ...state,
        sendComment: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
