import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { getAssignedTickets } from "../../../../Redux/Actions/Tickets";
import Loader from "../../../../Utils/Loader";
import moment from "moment";

const AssignedTickets = () => {
  const navigate = useNavigate();
  const [assignedTicketsPage, setAssignedTicketsPage] = useState(0);
  const [assignedTicketsRows, setAssignedTicketsRows] = useState(5);
  const dispatch = useDispatch();
  const {
    loading: assignedLoading,
    assignedTickets,
    assignedTicketsCount,
    error: assignedError,
  } = useSelector((state) => state.assignedTickets);
  useEffect(() => {
    dispatch(getAssignedTickets(assignedTicketsPage, assignedTicketsRows));
  }, [assignedTicketsPage, assignedTicketsRows]);

  const ticketsColumn = [
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "assignBy",
      label: "Assign By",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value?.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "assignTo",
      label: "Assign To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value?.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "subject",
      label: "Subject",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value}
            </Typography>
          );
        },
      },
    },

    {
      name: "last_date",
      label: "Last Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ pl: 1, textAlign: "center" }}
              className={
                value === "medium"
                  ? "medium_status"
                  : value === "low"
                    ? "low_status"
                    : value === "critical"
                      ? "critical_status"
                      : value === "high"
                        ? "high_status"
                        : null
              }
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ pl: 1, textAlign: "center" }}
              className={
                value === "on-hold"
                  ? "medium_status"
                  : value === "open"
                    ? "high_status"
                    : value === "close"
                      ? "low_status"
                      : null
              }
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(`/employee/tickets/${tableMeta.rowData[0]}`, {
                    state: { update: true },
                  })
                }
              >
                <FaEye />
              </button>
            </div>
          );
        },
      },
    },
  ];
  return (
    <Box className="departmentsTable">
      {assignedLoading ? (
        <Loader />
      ) : (
        <MuiDataTable
          title="Departments"
          columns={ticketsColumn}
          data={assignedTickets}
          page={assignedTicketsPage}
          setPage={setAssignedTicketsPage}
          rowsPerPage={assignedTicketsRows}
          setRowsPerPage={setAssignedTicketsRows}
          count={assignedTicketsCount}
          tableId="assigned_tickets_table"
        />
      )}
    </Box>
  );
};

export default AssignedTickets;
