export const CreateHolidayReducer = (state = { holiday: {} }, action) => {
  switch (action.type) {
    case "CREATE_HOLIDAY_REQUEST":
      return {
        loading: true,
      };
    case "CREATE_HOLIDAY_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        holiday: action.payload,
      };
    case "CREATE_HOLIDAY_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_HOLIDAY_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const HolidayDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_HOLIDAY_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_HOLIDAY_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        holiday: action.payload,
      };
    case "GET_HOLIDAY_DETAILS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const UpdateHolidayReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_HOLIDAY_REQUEST":
      return {
        loading: true,
      };
    case "UPDATE_HOLIDAY_SUCCESS":
      return {
        loading: false,
        isUpdated: action.payload,
      };
    case "UPDATE_HOLIDAY_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_HOLIDAY_RESET":
      return {
        ...state,
        isUpdated: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const DeleteHolidayReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_HOLIDAY_REQUEST":
      return {
        loading: true,
      };
    case "DELETE_HOLIDAY_SUCCESS":
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case "DELETE_HOLIDAY_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DELETE_HOLIDAY_RESET":
      return {
        ...state,
        isDeleted: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
