const initialState = {
  announcement: {},
};

export const announcementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ANNOUNCE_REQUEST":
      return {
        loading: true,
        error: false,
        success: false,
      };

    case "ANNOUNCE_SUCCESS":
      return {
        ...state,
        loading: false,
        announce: action.payload,

        error: false,
        success: true,
      };

    case "ANNOUNCE_FAILED":
      return {
        ...state,
        loading: false,
        announce: null,
        error: action.payload,
        success: false,
      };

    case "ANNOUNCE_RESET":
      return {
        ...state,
        announce: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getAllAnnouncementReducer = (
  state = { announceData: [] },
  action,
) => {
  switch (action.type) {
    case "GET_ANNOUNCE_REQUEST":
      return {
        loading: true,
        error: false,
      };

    case "GET_ANNOUNCE_SUCCESS":
      return {
        ...state,
        loading: false,
        announceData: action.payload.announcements,
        announceCount: action.payload.totalPages,
        success: action.payload.success,
        error: false,
      };

    case "GET_ANNOUNCE_FAILED":
      return {
        ...state,
        loading: false,
        announceData: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const DeleteAnnouncementReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_ANNOUNCEMENT_REQUEST":
      return {
        loading: true,
      };
    case "DELETE_ANNOUNCEMENT_SUCCESS":
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case "DELETE_ANNOUNCEMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DELETE_ANNOUNCEMENT_RESET":
      return {
        ...state,
        isDeleted: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
