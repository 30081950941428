import api from "../../Utils/interceptors";

export const getEmployeeCurrentMonthAttendance = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_EMPLOYEE_CURRENT_MONTH_ATTENDANCE_REQUEST",
    });
    const link = `attendance/my/current-month`;
    const { data } = await api.get(link);
    dispatch({
      type: "GET_EMPLOYEE_CURRENT_MONTH_ATTENDANCE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_EMPLOYEE_CURRENT_MONTH_ATTENDANCE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const getLastAttendance = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_LAST_ATTENDANCE_REQUEST",
    });
    const link = `attendance/my/last`;
    const { data } = await api.get(link);
    dispatch({
      type: "GET_LAST_ATTENDANCE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_LAST_ATTENDANCE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const submitCheckIn =
  ({ checkInRemarks, ipAddress }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "CHECK_IN_REQUEST",
      });
      const link = `attendance/check-in`;
      const { data } = await api.post(link, {
        check_in_remarks: checkInRemarks,
        ipAddress,
      });
      dispatch({
        type: "CHECK_IN_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "CHECK_IN_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const submitCheckOut =
  ({ checkOutRemarks, ipAddress }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "CHECK_OUT_REQUEST",
      });
      const link = `attendance/check-out`;
      const { data } = await api.put(link, {
        check_out_remarks: checkOutRemarks,
        ipAddress,
      });
      dispatch({
        type: "CHECK_OUT_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "CHECK_OUT_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const getUserAttendance = (userId, date) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_USER_ATTENDANCE_REQUEST",
    });
    const { data } = await api.get(
      `/attendance/user/date?user=${userId}&date=${date}`
    );
    dispatch({
      type: "GET_USER_ATTENDANCE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_USER_ATTENDANCE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const updateAttendance =
  (id, check_in, check_out) => async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_ATTENDANCE_REQUEST",
      });
      const link = `/attendance/${id}`;
      const { data } = await api.put(link, {
        check_in,
        check_out,
      });
      dispatch({
        type: "UPDATE_ATTENDANCE_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_ATTENDANCE_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
