import { Avatar, Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../../Components/Header/Header";

import { useStyle } from "./employee-dashboard-styles";
import Calendar from "../../../../Components/big-Calender/Calender";
import Leave from "../../../../Asssets/Images/Leave.png";
import AnnouncementIcon from "../../../../Asssets/Images/Announcement.png";
import { useDispatch, useSelector } from "react-redux";
import "./employeeDashboard.css";
import AllAnnouncements from "../../../HR/Pages/Announcement/AllAnnouncements";
import { getLastAttendance } from "../../../../Redux/Actions/Attendance";
import AttendanceForm from "../../../HR/Pages/Attendance/AttendanceForm";
import CheckInModal from "../../../HR/Pages/Attendance/CheckInModal";
import CheckOutModal from "../../../HR/Pages/Attendance/CheckOutModal";
import Loader from "../../../../Utils/Loader";
import moment from "moment";
import { getEmployeeDashboardData } from "../../../../Redux/Actions/Employee";

const Dashboard = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [checkOutModalOpen, setCheckOutModalOpen] = useState(false);

  const {
    loading: lastAttendanceLoading,
    attendance: lastAttendance,
    error: lastAttendanceError,
  } = useSelector((state) => state.lastAttendance);
  const {
    loading: dashboardLoading,
    error: dashboardError,
    dashboard,
  } = useSelector((state) => state.EmployeeDashboardData);
  useEffect(() => {
    dispatch(getLastAttendance());
    dispatch(getEmployeeDashboardData());
  }, []);

  return (
    <div className="page_responsive">
      <Header title={"Dashboard"} />
      {lastAttendanceLoading || dashboardLoading ? (
        <Loader />
      ) : (
        <Box>
          <Box sx={{ margin: "30px -10px" }}>
            <AttendanceForm
              classes={classes}
              setModalOpen={setModalOpen}
              setCheckOutModalOpen={setCheckOutModalOpen}
              lastAttendance={lastAttendance}
            />
          </Box>
          <Box sx={{ margin: "30px 30px" }}>
            <Grid container spacing={2}>
              <Grid item md={5}>
                <Paper
                  className={`${classes.dashboard_card} dashboard_announcements_box`}
                >
                  <Box
                    className={classes.card_header}
                    sx={{
                      borderRight: "4px solid #8C63EE !important",
                    }}
                  >
                    <img src={AnnouncementIcon} alt="" />
                    <Typography
                      variant="h6"
                      className={classes.card_header_heading}
                    >
                      Announcements
                    </Typography>
                  </Box>
                  <AllAnnouncements height={500} />
                </Paper>
              </Grid>
              <Grid item md={7}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "20px",
                    boxShadow:
                      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
                  }}
                  className="react-big-calendar"
                >
                  <Calendar holidays={dashboard?.holidays} />
                </Paper>
              </Grid>
              <Grid item md={12}>
                <Paper className={classes.leave_box}>
                  <Box className={classes.leave_card_header}>
                    <img src={Leave} alt="" />
                    <Typography
                      variant="h5"
                      className={classes.card_header_heading}
                      sx={{ color: "white" }}
                    >
                      Leaves
                    </Typography>
                  </Box>
                  <Box
                    sx={{ height: "230px", overflowY: "auto", my: 1, ml: 1 }}
                    className="tickets_box"
                  >
                    <table
                      id="dashboard_leaves_table"
                      style={{ width: "100%" }}
                    >
                      <tr style={{ textAlign: "left", padding: "20px 0px" }}>
                        <th>
                          {" "}
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            Employee
                          </Typography>
                        </th>
                        <th>
                          {" "}
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            Leave Type
                          </Typography>
                        </th>
                        <th>
                          {" "}
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            From
                          </Typography>
                        </th>
                        <th>
                          {" "}
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            To
                          </Typography>
                        </th>
                        <th>
                          {" "}
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            numOfDays
                          </Typography>
                        </th>
                        <th>
                          {" "}
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            Subject
                          </Typography>
                        </th>
                        <th>
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            HOD Status
                          </Typography>
                        </th>
                        <th>
                          <Typography
                            variant="h6"
                            className={`${classes.leave_card_heading} fs-14`}
                          >
                            HR Status
                          </Typography>
                        </th>
                      </tr>
                      {dashboard?.leaves &&
                        dashboard?.leaves?.map((leave, i) => (
                          <tr key={i}>
                            <td>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  py: 1,
                                }}
                              >
                                <Avatar
                                  src={
                                    leave?.user?.profile_picture[0]?.fileName
                                  }
                                />
                                <Typography sx={{ pl: 1 }} className="fs-12">
                                  {leave?.user?.full_name}
                                </Typography>
                              </Box>
                            </td>
                            <td>
                              <Typography className="fs-12" sx={{ py: 1 }}>
                                {leave?.leave_type}
                              </Typography>
                            </td>
                            <td>
                              <Typography className="fs-12" sx={{ py: 1 }}>
                                {moment(leave?.start_date).format("DD-MM-YYYY")}
                              </Typography>
                            </td>
                            <td>
                              <Typography className="fs-12" sx={{ py: 1 }}>
                                {moment(leave?.end_date).format("DD-MM-YYYY")}
                              </Typography>
                            </td>
                            <td>
                              <Typography className="fs-12" sx={{ py: 1 }}>
                                {leave?.numOfDays}
                              </Typography>
                            </td>
                            <td>
                              <Typography className="fs-12" sx={{ py: 1 }}>
                                {leave?.leave_subject}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className={`${leave?.line_manager_status === "approved" ? classes.approved_status : leave?.line_manager_status === "rejected" ? classes.rejected_status : classes.pending_status} fs-12`}
                              >
                                {leave?.line_manager_status}
                              </Typography>
                            </td>
                            <td>
                              <Typography
                                className={`${leave?.hr_status === "approved" ? classes.approved_status : leave?.hr_status === "rejected" ? classes.rejected_status : classes.pending_status} fs-12`}
                              >
                                {leave?.hr_status}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                    </table>
                    {dashboard?.leaves && !dashboard?.leaves?.length > 0 && (
                      <Box>
                        <Typography
                          sx={{
                            py: 1,
                            textAlign: "center",
                            fontWeight: 700,
                            fontSize: "18px",
                            color: "#253766",
                          }}
                        >
                          No Leave Found!
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      <CheckInModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <CheckOutModal
        classes={classes}
        modalOpen={checkOutModalOpen}
        setModalOpen={setCheckOutModalOpen}
      />
    </div>
  );
};

export default Dashboard;
