import { InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { useStyle } from "./datepicker-styles";

const CustomDatePicker = ({ label, value, onChangeHandler, ref, minDate,maxDate }) => {
  const classes = useStyle();
  return (
    <>
      <InputLabel className={classes.department_label}>{label}</InputLabel>
      <Box className={classes.datePicker}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
          ref={ref}
            value={value}
            maxDate={maxDate}
            minDate={minDate}
            onChange={onChangeHandler}
            className={classes.inputContainer}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField
                className={classes.inputContainer}
                {...params}
                fullWidth
              />
             
            )}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};

export default CustomDatePicker;
