import { ExpandMore, Logout } from "@mui/icons-material";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../../Asssets/Images/profile.png";
import { successNotify } from "../../Utils/toast";
import { useStyles } from "./Header-style";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { getCurrentUser } from "../../Redux/Actions/Auth";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Badge from "@mui/material/Badge";
import "./Header.css";
import Notification from "../Notification/Notification";
import { useState } from "react";
import { logoutUserConnection } from "../../realTimeCommunication/SocketConnection";
import { getUser } from "../../Utils/helper";

const Header = ({ title }) => {
  let userAuth = getUser() ? getUser() || "" : null;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.currentUser);
  const [showNoti, setShowNoti] = useState(false);
  const { count } = useSelector((state) => state.employeeNotifications);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowNoti(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const LogoutHandler = () => {
    logoutUserConnection(userAuth);

    localStorage.clear();
    navigate("/");
    successNotify("Logged Out Successfully!");
  };
  return (
    <div style={{ margin: "0px 15px" }}>
      <Paper className={classes.header_main}>
        <Typography
          variant="h5"
          style={{
            color: "#253766",
            fontWeight: "500",
            fontSize: "18px",
            marginLeft: "8px",
            letterSpacing: "1px",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {loading ? (
            <Spinner
              style={{ color: "rgb(0, 151, 217)" }}
              animation="border"
              size="sm"
            />
          ) : (
            <React.Fragment>
              <div className="notification_icon_container">
                <Badge
                  badgeContent={count}
                  color="secondary"
                  onClick={() => setShowNoti(!showNoti)}
                >
                  <NotificationsActiveIcon className="bell-icon" />
                </Badge>

                {showNoti && <Notification />}
              </div>

              <Avatar
                src={
                  user?.user?.profile_picture[0]?.fileName
                    ? user?.user?.profile_picture[0].fileName
                    : Profile
                }
                className={classes.header_profile_pic}
              />
              <Typography sx={{ ml: 1 }}>{user?.user?.full_name}</Typography>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <ExpandMore sx={{ width: 32, height: 32 }} />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                }}
                className={classes.toolTipData}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={LogoutHandler}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default Header;
