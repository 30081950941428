import { Avatar, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

import React from "react";
const useStyles = makeStyles({
  dashboardCard: {
    minWidth: "90%",
    padding: "12px",
    display: "flex !important",
    alignItems: "center",
    borderRadius: "50px !important",
    border: "1px solid #E4E4E4",
    boxShadow:
      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
  },
  amount: {
    width: "50px !important",
    height: "50px !Important",
    fontWeight: "800",
    fontSize: "24px !important",
  },
});

const DashBoardCard = ({ style, value, sub, heading }) => {
  const classes = useStyles();
  return (
    <Grid item md={3}>
      <Card className={classes.dashboardCard}>
        <Avatar className={classes.amount} style={style}>
          {value}
        </Avatar>
        <Box sx={{ ml: 2 }}>
          <Typography
            className="text-muted"
            style={{ fontSize: "12px", color: "grey" }}
          >
            {sub}
          </Typography>
          <Typography
            variant="h6"
            style={{ fontSize: "17px", fontWeight: "600", color: "#253766" }}
          >
            {heading}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );
};

export default DashBoardCard;
