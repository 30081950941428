import { Avatar, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import Header from "../../../../Components/Header/Header";
import Input from "../../../../Components/Input/Input";
import MetaData from "../../../../Components/MetaData";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { getAllEmployees } from "../../../../Redux/Actions/HR";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useStyle } from "../Attendance/attendance-styles";
import { FaEye } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import "./Ticket.css";
import {
  getAllTickets,
  individualTicketDeleted,
} from "../../../../Redux/Actions/Tickets";
import Loader from "../../../../Utils/Loader";
import { json, useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import TicketCreateModal from "./TicketCreateModal";
import Modal from "../../../../Components/SiteModal/SiteModal";
import { BiSearch, BiReset } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";

const Ticket = () => {
  const classes = useStyle();
  const [employeeId, setEmployeeId] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [priority, setPriority] = useState("");
  const [status, SetStatus] = useState("");
  const [show, setShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [ticketID, setTicketID] = useState("");
  const [isTagOpen, setIsTagOpen] = useState(false);
  const [savePriority, setSavePriority] = useState("");
  const [saveStatus, setStatus] = useState("");
  const [saveName, setName] = useState("");
  const [isClear, setIsClear] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { employees, loading: employeeLoading } = useSelector(
    (state) => state.employees
  );
  const { employees: filtered } = useSelector(
    (state) => state.filteredEmployees
  );
  const { loading, allTickets, allTicketsCount } = useSelector(
    (state) => state.AllTicket
  );
  const {
    createTicket,
    success,
    error: createError,
  } = useSelector((state) => state.CreateTicket);
  const {
    loading: deletedTicketLoading,
    deletedTicket,
    error: deleteError,
  } = useSelector((state) => state.ticketDeleted);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove particular data from localStorage
      window.sessionStorage.removeItem("ticketData-employeeId");
      window.sessionStorage.removeItem("ticketData-priority");
      window.sessionStorage.removeItem("ticketData-status");
      window.sessionStorage.removeItem("ticketData-Name");
      setSavePriority("");
      setStatus("");
      setName("");
      setIsTagOpen(false);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllEmployees());

    const getTicketDataEmployeeId = window.sessionStorage.getItem(
      "ticketData-employeeId"
    );
    const getTicketDataPriority = window.sessionStorage.getItem(
      "ticketData-priority"
    );
    const getTicketDataStatus =
      window.sessionStorage.getItem("ticketData-status");
    const getTicketName = window.sessionStorage.getItem("ticketData-Name");

    dispatch(
      getAllTickets(
        page,
        size,
        getTicketDataEmployeeId ? getTicketDataEmployeeId : employeeId,
        getTicketDataPriority ? getTicketDataPriority : priority,
        getTicketDataStatus ? getTicketDataStatus : status
      )
    );

    if (
      getTicketDataEmployeeId ||
      getTicketDataPriority ||
      getTicketDataStatus ||
      getTicketName
    ) {
      setSavePriority(getTicketDataPriority);
      setStatus(getTicketDataStatus);
      setName(getTicketName);

      if (
        getTicketDataEmployeeId ||
        getTicketDataPriority ||
        getTicketDataStatus ||
        getTicketName
      ) {
        setIsTagOpen(true);
      } else {
        setIsTagOpen(false);
      }
    }
  }, [page, size, isClear]);

  useEffect(() => {
    if (success) {
      successNotify(createTicket.message);
      dispatch({ type: "CREATE_TICKET_RESET" });
      setModalOpen(false);
      dispatch(getAllTickets(page, size, employeeId, priority, status));
    }
    if (createError) {
      errorNotify(createError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [success, createError]);

  useEffect(() => {
    if (deletedTicket && deletedTicket.success) {
      successNotify(deletedTicket?.message);
      setShow(false);
      dispatch({ type: "INDIVISUAL_TICKET_DELETE_RESET" });
      dispatch(getAllTickets(page, size, employeeId, priority, status));
    }
    if (deleteError) {
      errorNotify(deleteError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [deletedTicket, deleteError]);

  const onSubmitHandler = handleSubmit(async (data) => {
    const { priority, status } = data;
    setPriority(priority);
    SetStatus(status);
    setPage(0);

    if (employeeId && employeeId !== undefined) {
      window.sessionStorage.setItem("ticketData-employeeId", employeeId);
    }
    if (priority) {
      window.sessionStorage.setItem("ticketData-priority", priority);
    }
    if (status) {
      window.sessionStorage.setItem("ticketData-status", status);
    }

    const getTicketDataEmployeeId = window.sessionStorage.getItem(
      "ticketData-employeeId"
    );
    const getTicketDataPriority = window.sessionStorage.getItem(
      "ticketData-priority"
    );
    const getTicketDataStatus =
      window.sessionStorage.getItem("ticketData-status");

    const AssignToName = employees?.employees?.filter(
      (e) => e?._id === getTicketDataEmployeeId
    )[0]?.full_name;
    dispatch(
      getAllTickets(
        page,
        size,
        getTicketDataEmployeeId ? getTicketDataEmployeeId : employeeId,
        getTicketDataPriority ? getTicketDataPriority : priority,
        getTicketDataStatus ? getTicketDataStatus : status
      )
    );

    if (employeeId && employeeId !== undefined) {
      window.sessionStorage.setItem("ticketData-Name", AssignToName);
    }

    setSavePriority(getTicketDataPriority);
    setStatus(getTicketDataStatus);

    const getTicketName = window.sessionStorage.getItem("ticketData-Name");
    setName(getTicketName);
    if (
      getTicketDataEmployeeId ||
      getTicketDataPriority ||
      getTicketDataStatus ||
      getTicketName
    ) {
      setIsTagOpen(true);
    } else {
      setIsTagOpen(false);
    }
  });

  const handleTicketID = (id) => {
    setTicketID(id);
    setShow(!show);
  };
  const modal = (
    <Modal
      title="Delete"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <p className="modal_Text">Are u sure you want to delete this Ticket?</p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(individualTicketDeleted(ticketID))}
          >
            {deletedTicketLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Yes"
            )}
          </button>
          <button className="my-1 no_btn" onClick={() => setShow(false)}>
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );

  const ticketsColumns = [
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "assignBy",
      label: "Assign By",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography
                onClick={() => navigate(`/HR/employee/${value?.userId}`)}
                style={{
                  cursor: "pointer",
                }}
                sx={{ pl: 1 }}
                className="fs-12 link__color"
              >
                {value ? value.full_name : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "assignTo",
      label: "Assign To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography
                onClick={() => navigate(`/HR/employee/${value?.userId}`)}
                style={{
                  cursor: "pointer",
                }}
                sx={{ pl: 1 }}
                className="fs-12 link__color"
              >
                {value ? value.full_name : "-"}
              </Typography>
            </div>
          );
        },
      },
    },

    {
      name: "subject",
      label: "Subject",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },

    // {
    //   name: "description",
    //   label: "Description",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Typography sx={{ pl: 1 }} className="fs-12">
    //           {value ? value : "-"}
    //         </Typography>
    //       );
    //     },
    //   },
    // },
    {
      name: "last_date",
      label: "Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ pl: 1, textAlign: "center" }}
              className={
                value === "medium"
                  ? "medium_status"
                  : value === "low"
                  ? "low_status"
                  : value === "critical"
                  ? "critical_status"
                  : value === "high"
                  ? "high_status"
                  : null
              }
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ pl: 1, textAlign: "center" }}
              className={
                value === "on-hold"
                  ? "medium_status"
                  : value === "open"
                  ? "high_status"
                  : value === "close"
                  ? "low_status"
                  : null
              }
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    // onClick={() => navigate(`/HR/leave-management/${tableMeta.rowData[1]}`)}
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(`/HR/tickets/${tableMeta.rowData[0]}`, {
                    state: {
                      update: true,
                    },
                  })
                }
              >
                <FaEye />
              </button>
              {/* onClick={() => handleLeaveID(tableMeta.rowData[1])} */}
              <button
                className="delete_btn"
                onClick={() => handleTicketID(tableMeta.rowData[0])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];

  const clearSearch = () => {
    window.sessionStorage.removeItem("ticketData-employeeId");
    window.sessionStorage.removeItem("ticketData-priority");
    window.sessionStorage.removeItem("ticketData-status");
    window.sessionStorage.removeItem("ticketData-Name");
    setSavePriority("");
    setStatus("");
    setName("");
    setIsTagOpen(false);
    setPriority("");
    setEmployeeId("");
    setIsClear(!isClear);
  };

  const removeItem = (item) => {
    window.sessionStorage.removeItem(item);
    window.sessionStorage.removeItem("ticketData-employeeId");
    if (item === "ticketData-Name") {
      setName("");
      setEmployeeId("");
    }
    if (item === "ticketData-priority") {
      setSavePriority("");
      setPriority("");
    }
    if (item === "ticketData-status") {
      SetStatus("");
    }
    setIsClear(!isClear);
  };

  return (
    <div className="page_responsive">
      <div className="time_sheet_main">
        <MetaData title={`Tickets -- HRM`} />
        <Header title="TICKETS" />

        <Container fluid>
          <Row className="m-0">
            <Col md={12}>
              <div className="employees_main_selection">
                <Form onSubmit={onSubmitHandler}>
                  <Row>
                    <Col md={10}>
                      <Row>
                        <Col md={4} className="auto_complete_employee">
                          <Input>
                            <Form.Label>Assign To</Form.Label>
                            <AutoCompleteSearch
                              data={filtered?.employees}
                              departmentData={employeeId}
                              setDepartmentData={setEmployeeId}
                              title={"Search Employee"}
                            />
                          </Input>
                        </Col>
                        <Col md={4}>
                          <Input>
                            <Form.Label>Priority</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("priority")}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Priority Level
                              </option>
                              <option value="low">Low</option>
                              <option value="medium">Medium</option>
                              <option value="high">High</option>
                              <option value="critical">Critical</option>
                            </Form.Select>
                          </Input>
                        </Col>
                        <Col md={4}>
                          <Input>
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("status")}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Status
                              </option>
                              <option value="open">Open</option>
                              <option value="on-hold">On-Hold</option>
                              <option value="close">Close</option>
                            </Form.Select>
                          </Input>
                        </Col>
                      </Row>
                      <Row>
                        <div className="d-flex align-items-end mt-2">
                          {isTagOpen && saveName && (
                            <div
                              className="mx-2 rounded-2 p-2"
                              style={{
                                fontSize: "12px",
                                fontStyle: "italic",
                                background: "#efefef",
                                width: "fit-contain",
                              }}
                            >
                              <BiSearch />{" "}
                              <span className="mx-2">{saveName}</span>
                              <IoIosClose
                                onClick={() => removeItem("ticketData-Name")}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                          {isTagOpen && savePriority && (
                            <div
                              className="mx-2 rounded-2 p-2"
                              style={{
                                fontSize: "12px",
                                fontStyle: "italic",
                                background: "#efefef",
                                width: "fit-contain",
                              }}
                            >
                              <BiSearch />{" "}
                              <span className="mx-2">{savePriority}</span>
                              <IoIosClose
                                onClick={() =>
                                  removeItem("ticketData-priority")
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                          {isTagOpen && saveStatus && (
                            <div
                              className="mx-2 rounded-2 p-2"
                              style={{
                                fontSize: "12px",
                                fontStyle: "italic",
                                background: "#efefef",
                                width: "fit-contain",
                              }}
                            >
                              <BiSearch />{" "}
                              <span className="mx-2">{saveStatus}</span>
                              <IoIosClose
                                onClick={() => removeItem("ticketData-status")}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                        </div>
                      </Row>
                    </Col>
                    <Col md={2} className="leaves_btn_container">
                      <button
                        type="button"
                        className="add_leave_btn"
                        style={{ marginBottom: "0px" }}
                        onClick={() => setModalOpen(true)}
                      >
                        <AiOutlinePlus /> Create Ticket
                      </button>
                      <button className="search_btn mt-3" type="submit">
                        <FiSearch />
                        Search
                      </button>
                      <button
                        className="search_btn mt-3"
                        type="button"
                        onClick={clearSearch}
                      >
                        <BiReset />
                        Reset
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>

            <Col md={12}>
              {employeeLoading || loading ? (
                <Loader />
              ) : (
                <div className="tickets_management_table">
                  <MuiDataTable
                    title={"ALL TICKETS"}
                    columns={ticketsColumns}
                    data={allTickets}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={size}
                    setRowsPerPage={setSize}
                    count={allTicketsCount}
                    tableId="hr_tickets_table"
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {modal}
      <TicketCreateModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default Ticket;
