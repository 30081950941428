import api from "../../Utils/interceptors";

export const getDashboardData = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_DASHBOARD_DATA_REQUEST",
    });
    const { data } = await api.get("auth/user/dashboard");
    dispatch({
      type: "GET_DASHBOARD_DATA_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_DASHBOARD_DATA_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const getAllFilteredEmployees =
  (
    page,
    size,
    employee_code = "",
    full_name = "",
    department = "",
    designation = "",
    id = ""
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_FILTERED_EMPLOYEES_REQUEST",
      });
      const { data } = await api.get(
        `employees?page=${page}&size=${size}&employee_code=${employee_code}&full_name=${full_name}&department=${department}&designation=${designation}&_id=${id}`
      );
      dispatch({
        type: "GET_ALL_FILTERED_EMPLOYEES_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_FILTERED_EMPLOYEES_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const getAllEmployees =
  (
    page,
    size,
    employee_code = "",
    full_name = "",
    department = "",
    designation = "",
    id = "",
    moreLoading,
    prevData
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_EMPLOYEES_REQUEST",
        moreLoading: moreLoading,
        prevData: prevData,
      });
      const { data } = await api.get(
        `employees?page=${page}&size=${size}&employee_code=${employee_code}&full_name=${full_name}&department=${department}&designation=${designation}&_id=${id}`
      );
      dispatch({
        type: "GET_ALL_EMPLOYEES_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_EMPLOYEES_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const getAllDepartments =
  (page = "", rowsPerPage = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_DEPARTMENTS_REQUEST",
      });
      const link = `departments?page=${page}&size=${rowsPerPage}`;
      const { data } = await api.get(
        page !== "" && rowsPerPage !== "" ? link : `departments`
      );
      dispatch({
        type: "GET_ALL_DEPARTMENTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_DEPARTMENTS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const getAllEmployeesDropdown = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ALL_MANAGER_REQUEST",
    });
    const { data } = await api.get(`employees-dropdown`);
    dispatch({
      type: "GET_ALL_MANAGER_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_ALL_MANAGER_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const getAllDesignations =
  (page = "", rowsPerPage = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_DESIGNATIONS_REQUEST",
      });
      const link = `designations?page=${page}&size=${rowsPerPage}`;
      const { data } = await api.get(
        page !== "" && rowsPerPage !== "" ? link : `designations`
      );
      dispatch({
        type: "GET_ALL_DESIGNATIONS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_DESIGNATIONS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const getAllLeaves = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ALL_LEAVES_REQUEST",
    });
    const { data } = await api.get("leaves");
    dispatch({
      type: "GET_ALL_LEAVES_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_ALL_LEAVES_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const getAllShifts =
  (page = "", rowsPerPage = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_SHIFTS_REQUEST",
      });
      const link = `shifts?page=${page}&size=${rowsPerPage}`;

      const { data } = await api.get(
        page !== "" && rowsPerPage !== "" ? link : `shifts`
      );
      dispatch({
        type: "GET_ALL_SHIFTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_SHIFTS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const getAllHolidays =
  (page = "", rowsPerPage = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_HOLIDAYS_REQUEST",
      });
      const link = `holidays?page=${page}&size=${rowsPerPage}`;
      const { data } = await api.get(
        page !== "" && rowsPerPage !== "" ? link : `holidays`
      );
      dispatch({
        type: "GET_ALL_HOLIDAYS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_HOLIDAYS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };

export const Register = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: "REGISTER_REQUEST",
    });

    const { data } = await api.post("auth/register", inputData);

    dispatch({
      type: "REGISTER_SUCCESS",
      payload: data,
      loading: false,
    });
  } catch (e) {
    dispatch({
      type: "REGISTER_FAILED",
      payload: e.response.data.message,
      success: false,
      loading: false,
    });
  }
};
export const getEmployeeDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_EMPLOYEE_DETAILS_REQUEST",
    });
    const { data } = await api.get(`employee/${id}`);
    dispatch({
      type: "GET_EMPLOYEE_DETAILS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_EMPLOYEE_DETAILS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
