import React from "react";
import ReactModal from "../../../../Components/Modal/Modal";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../Redux/Actions/HR";
import { useStyle } from "../HRMSetup/Departments/department-styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
// import AttendanceToEdit from "./AttendanceToEdit";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { getUserAttendance } from "../../../../Redux/Actions/Attendance";
import { Form, Spinner } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import { useStyle as useStyle2 } from "./payroll-styles";
import {
  PayrollByDate,
  PayrollByDepartment,
  clearErrors,
  createPayroll,
  getLastPayrollsOfEmployees,
} from "../../../../Redux/Actions/Payroll";
import { useNavigate } from "react-router-dom";

const CreatePayrollModal = ({
  show,
  setShow,
  employees,
  departments,
  page,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyle();
  const classes2 = useStyle2();
  const [employeeId, setEmployeeId] = useState("");
  const [date, setDate] = useState("");
  const [tab, setTab] = useState(1);
  const [department, setDepartment] = useState("");
  const [monthDateTab, setMonthDateTab] = useState("month");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const {
    loading: createLoading,
    payroll: createdPayroll,
    error: createError,
  } = useSelector((state) => state.createPayroll);
  const {
    loading: createByDepLoading,
    isCreated,
    message,
    error: createByDepError,
  } = useSelector((state) => state.createPayrollByDepartment);
  const {
    loading: createByDateLoading,
    createdByDate,
    error: createByDateError,
  } = useSelector((state) => state.createPayrollByDate);
  useEffect(() => {
    if (createdPayroll?.success) {
      successNotify(createdPayroll?.message);
      dispatch({ type: "CREATE_PAYROLL_RESET" });
      setShow(false);
      setEmployeeId("");
      setDate("");
      navigate(`/HR/payroll/update/${createdPayroll?.payroll?._id}`);
      dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
    }
    if (isCreated) {
      successNotify(message);
      dispatch({ type: "CREATE_PAYROLL_BY_DEPARTMENT_RESET" });
      setShow(false);
      setDepartment("");
      setDate("");
      dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
    }
    if (createdByDate?.success) {
      successNotify(createdByDate?.message);
      dispatch({ type: "CREATE_PAYROLL_BY_DATE_RESET" });
      setShow(false);
      setEmployeeId("");
      setStartDate("");
      setEndDate("");
      navigate(`/HR/payroll/update/${createdByDate?.payroll?._id}`);
      dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
    }
    if (createError) {
      errorNotify(createError);
      dispatch(clearErrors());
    }
    if (createByDepError) {
      errorNotify(createByDepError);
      dispatch(clearErrors());
    }
    if (createByDateError) {
      errorNotify(createByDateError);
      dispatch(clearErrors());
    }
  }, [
    createdPayroll?.success,
    message,
    createdByDate?.success,
    createError,
    createByDepError,
    createByDateError,
  ]);

  const handleEmpDepTab = (num) => {
    setTab(num);
    setDepartment("");
    setEmployeeId("");
    setDate("");
    setStartDate("");
    setEndDate("");

    if (num === 2) {
      setMonthDateTab("month");
    }
  };
  const handleMonthDateTab = (name) => {
    setMonthDateTab(name);
    setDate("");
    setStartDate("");
    setEndDate("");
  };
  const handleCreatePayroll = () => {
    if (tab === 2) {
      if (department !== "" && date !== "") {
        const payrollData = {
          departmentId: department,
          month: date.split("-")[1],
          year: date.split("-")[0],
        };
        dispatch(PayrollByDepartment(payrollData));
      } else {
        errorNotify("please select department and date to generate payroll!");
      }
    }
    if (tab === 1 && monthDateTab === "month") {
      if (employeeId !== "" && date !== "") {
        const payrollData = {
          userId: employeeId,
          month: date.split("-")[1],
          year: date.split("-")[0],
        };
        dispatch(createPayroll(payrollData));
      } else {
        errorNotify("please select user and month to generate payroll!");
      }
    }
    if (tab === 1 && monthDateTab === "date") {
      if (employeeId !== "" && startDate !== "" && endDate !== "") {
        const payrollData = {
          userId: employeeId,
          start_date: startDate,
          end_date: endDate,
        };
        dispatch(PayrollByDate(payrollData));
      } else {
        errorNotify(
          "please select user, start date  and end date to generate payroll!"
        );
      }
    }
  };
  return (
    <>
      <ReactModal open={show} setOpen={setShow} title="Create Payroll">
        <Box
          sx={{
            my: 2,
            display: "flex",
            gap: 5,
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            className={tab === 1 ? classes2.active_btn : classes2.tab_btn}
            fullWidth
            onClick={() => handleEmpDepTab(1)}
          >
            By Employee
          </Button>
          <Button
            variant="contained"
            className={tab === 2 ? classes2.active_btn : classes2.tab_btn}
            fullWidth
            onClick={() => handleEmpDepTab(2)}
          >
            By Department
          </Button>
        </Box>
        {tab === 1 && (
          <>
            <Box
              sx={{ my: 2, display: "flex", justifyContent: "flex-end" }}
              className={"month_date_tab"}
            >
              <button
                className={monthDateTab === "month" && "active"}
                onClick={() => handleMonthDateTab("month")}
              >
                Month
              </button>
              <button
                className={monthDateTab === "date" && "active"}
                onClick={() => handleMonthDateTab("date")}
              >
                Date
              </button>
            </Box>

            <Box sx={{ my: 2 }}>
              <InputLabel className={classes.department_label} sx={{ mb: 1 }}>
                Employee Name
              </InputLabel>
              <AutoCompleteSearch
                data={employees?.employees}
                departmentData={employeeId}
                setDepartmentData={setEmployeeId}
                title={"Employee Name"}
              />
            </Box>
          </>
        )}
        {tab === 2 && (
          <Box sx={{ my: 2 }}>
            <Input>
              <Form.Label>Select Department</Form.Label>
              <Form.Select
                aria-label="Default select example"
                // {...register("department")}
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option defaultValue={-1} key={-1} value="">
                  Select Department
                </option>
                {departments &&
                  departments?.map((department, i) => (
                    <option value={department._id} key={i}>
                      {department.department_name}
                    </option>
                  ))}
              </Form.Select>
            </Input>
          </Box>
        )}

        {monthDateTab === "month" && (
          <Box sx={{ my: 2 }}>
            <Input>
              <Form.Label>Month/Year</Form.Label>
              <Form.Control
                type="month"
                aria-label="Default select example"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              ></Form.Control>
            </Input>
          </Box>
        )}

        {monthDateTab === "date" && (
          <>
            <Box sx={{ my: 2 }}>
              <InputLabel className={classes.department_label}>
                Start Date
              </InputLabel>
              <Box className={classes.datePicker}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={startDate}
                    maxDate={endDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    className={classes.inputContainer}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        className={classes.inputContainer}
                        {...params}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ my: 2 }}>
              <InputLabel className={classes.department_label}>
                End Date
              </InputLabel>
              <Box className={classes.datePicker}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={endDate}
                    minDate={startDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    className={classes.inputContainer}
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        className={classes.inputContainer}
                        {...params}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              className={classes.cancelBtn}
              onClick={() => setShow(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.createBtn}
              disabled={
                createLoading || createByDepLoading || createByDateLoading
                  ? true
                  : false
              }
              onClick={() => handleCreatePayroll()}
            >
              {createLoading || createByDepLoading || createByDateLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>
      </ReactModal>
    </>
  );
};

export default CreatePayrollModal;
