import React from "react";
import ReactModal from "../../../../Components/Modal/Modal";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAllEmployees } from "../../../../Redux/Actions/HR";
import { useStyle } from "../HRMSetup/Departments/department-styles";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { updateAttendance } from "../../../../Redux/Actions/Attendance";
import { Spinner } from "react-bootstrap";

const AttendanceToEdit = ({ attendanceModalOpen, setAttendanceModalOpen }) => {
  const { attendance } = useSelector((state) => state.userAttendance);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const classes = useStyle();
  const dispatch = useDispatch();
  const {
    loading,
    success,
    attendance: updatedAttendance,
    error,
  } = useSelector((state) => state.updateAttendance);
  useEffect(() => {
    if (attendance) {
      setCheckIn(new Date(attendance?.check_in));
      setCheckOut(new Date(attendance?.check_out));
    }
  }, [attendance]);
  useEffect(() => {
    if (success) {
      successNotify(updatedAttendance?.message);
      dispatch({ type: "UPDATE_ATTENDANCE_RESET" });
      setCheckIn("");
      setCheckOut(null);
      setAttendanceModalOpen(false);
    }
    if (error) {
      errorNotify(error.message);
      dispatch(clearErrors());
    }
  }, [success, error]);
  const handleUpdateAttendance = () => {
    dispatch(updateAttendance(attendance._id, checkIn, checkOut));
  };
  return (
    <ReactModal
      open={attendanceModalOpen}
      setOpen={setAttendanceModalOpen}
      title="Employee Attendance"
    >
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>
          Checked In Time
        </InputLabel>
        <Box className={classes.timePicker}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={checkIn}
              onChange={(newValue) => {
                setCheckIn(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.inputContainer}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>
          Checked Out Time
        </InputLabel>
        <Box className={classes.timePicker}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={checkOut}
              onChange={(newValue) => {
                setCheckOut(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.inputContainer}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            onClick={() => setAttendanceModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.createBtn}
            // disabled={createLoading ? true : false}
            onClick={() => handleUpdateAttendance()}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Update"}
          </Button>
        </Box>
      </Box>
    </ReactModal>
  );
};

export default AttendanceToEdit;
