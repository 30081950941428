import api from "../../Utils/interceptors";

export const getAllRequests =
  (page, size, request_by = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_ALL_REQUESTS_REQUEST",
      });
      const { data } = await api.get(
        `requests/all?page=${page}&size=${size}&request_by=${request_by}`,
      );
      dispatch({
        type: "GET_ALL_REQUESTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALL_REQUESTS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };
export const getCurrentUserRequests =
  (page, size, request_by = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_CURRENT_USER_REQUESTS_REQUEST",
      });
      const { data } = await api.get(
        `requests/my?page=${page}&size=${size}&request_by=${request_by}`,
      );
      dispatch({
        type: "GET_CURRENT_USER_REQUESTS_SUCCESS",
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "GET_CURRENT_USER_REQUESTS_FAILED",
        payload: error.response?.data?.message,
      });
    }
  };

export const createUserRequest = (requestData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_REQUEST_REQUEST",
    });

    const { data } = await api.post("/request/create", requestData);

    dispatch({
      type: "CREATE_REQUEST_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_REQUEST_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};
export const indivisualRequestDetail = (request_id) => async (dispatch) => {
  try {
    dispatch({
      type: "INDIVISUAL_REQUEST_DETAIL_REQUEST",
    });
    const { data } = await api.get(`/request/${request_id}`);
    dispatch({
      type: "INDIVISUAL_REQUEST_DETAIL_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "INDIVISUAL_REQUEST_DETAIL_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const indivisualRequestDeleted = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "INDIVISUAL_REQUEST_DELETE_REQUEST",
    });
    const { data } = await api.delete(`/request/${id}`);
    dispatch({
      type: "INDIVISUAL_REQUEST_DELETE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "INDIVISUAL_REQUEST_DELETE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
