import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../Components/Header/Header";
import MetaData from "../../../../Components/MetaData";
import {
  getAssignedTickets,
  getCurrentUserTickets,
} from "../../../../Redux/Actions/Tickets";
import Loader from "../../../../Utils/Loader";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useStyle } from "../../../HR/Pages/Attendance/attendance-styles";
import TicketCreateModal from "../../../HR/Pages/Ticket/TicketCreateModal";
import { leaveStyle } from "../Leaves/leave-styles";
import AssignedTickets from "./AssignedTickets";
import MyTickets from "./MyTickets";

const Tickets = () => {
  const classes = useStyle();
  const classes2 = leaveStyle();
  const [tab, setTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [assignedTicketsPage, setAssignedTicketsPage] = useState(0);
  const [assignedTicketsRows, setAssignedTicketsRows] = useState(5);
  const dispatch = useDispatch();
  const {
    createTicket,
    success,
    error: createError,
  } = useSelector((state) => state.CreateTicket);
  const { loading, tickets, ticketsCount, error } = useSelector(
    (state) => state.currentUserTickets,
  );
  const {
    loading: assignedLoading,
    assignedTickets,
    assignedTicketsCount,
    error: assignedError,
  } = useSelector((state) => state.assignedTickets);

  useEffect(() => {
    dispatch(getCurrentUserTickets(page, rowsPerPage));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (success) {
      successNotify(createTicket.message);
      dispatch({ type: "CREATE_TICKET_RESET" });
      setModalOpen(false);
      dispatch(getCurrentUserTickets(page, rowsPerPage));
    }
    if (createError) {
      errorNotify(createError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [success, createError]);
  const handleAssignedTickets = () => {
    setTab(2);
    // dispatch(getAssignedTickets(assignedTicketsPage, assignedTicketsRows));
  };

  return (
    <div className="page_responsive">
      <div className="time_sheet_main">
        <MetaData title={`Tickets -- HRM`} />
        <Header title="TICKETS" />
        <Paper className={classes2.button_container}>
          <Box
            sx={{
              py: 3,
              px: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button
                variant="contained"
                className={classes2.tab_btn}
                onClick={() => setTab(1)}
              >
                My Tickets
              </Button>
              <Button
                variant="contained"
                className={classes2.tab_btn}
                onClick={handleAssignedTickets}
              >
                Assigned Tickets
              </Button>
            </Box>
            <Button
              variant="contained"
              className={classes2.add_leave_btn}
              onClick={() => setModalOpen(true)}
            >
              <AiOutlinePlus style={{ marginRight: "5px" }} /> Add Ticket
            </Button>
          </Box>
        </Paper>
        <Box sx={{ px: 2 }}>
          {tab === 1 ? (
            loading ? (
              <Loader />
            ) : (
              <MyTickets
                classes={classes}
                ticketsPage={page}
                setTicketsPage={setPage}
                ticketsRows={rowsPerPage}
                setTicketsRows={setRowsPerPage}
                tickets={tickets}
                ticketsCount={ticketsCount}
              />
            )
          ) : (
            <AssignedTickets />
          )}
        </Box>
      </div>
      <TicketCreateModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default Tickets;
