import { Box, Button, Grid, InputLabel, TextField } from "@mui/material";
import React from "react";
import { Form, Spinner } from "react-bootstrap";
import CustomDatePicker from "../../../../Components/DatePicker/DatePicker";
import Input from "../../../../Components/Input/Input";
import Modal from "../../../../Components/Modal/Modal";
import { useStyle } from "../Attendance/attendance-styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getAllEmployees } from "../../../../Redux/Actions/HR";
import { createUserRequest } from "../../../../Redux/Actions/Requests";
import { successNotify } from "../../../../Utils/toast";
import { BsFillFileImageFill } from "react-icons/bs";

const AddRequestModal = ({ modalOpen, setModalOpen }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { loading, success, request } = useSelector(
    (state) => state.createRequest
  );
  const [requestData, setRequestData] = useState({
    subject: "",
    description: "",
  });
  const [docs, setDocs] = useState([]);
  const [previewDocs, setPreviewDocs] = useState([]);
  const [extDocs, setExtDocs] = useState([]);
  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);
  useEffect(() => {
    if (success) {
      successNotify(request?.message);
      setRequestData({
        subject: "",
        description: "",
      });
      dispatch({ type: "CREATE_REQUEST_RESET" });
    }
  }, [success]);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleChange = (name) => (e) => {
    setRequestData((prev) => {
      return { ...prev, [name]: e.target.value };
    });
  };
  const onSubmitHandler = handleSubmit(async (data) => {
    let tempObj = { ...requestData };
    tempObj["request_docs"] = docs;
    dispatch(createUserRequest(tempObj));
  });

  const uploadDocsChange = (e) => {
    const files = Array.from(e.target.files);
    Promise.all(files.map((file) => URL.createObjectURL(file))).then((urls) => {
      setPreviewDocs(urls);
    });
    Promise.all(files.map((file) => file.name)).then((ext) => {
      setExtDocs(ext);
    });
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setDocs((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Modal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create Request"
      size="700"
    >
      <React.Fragment>
        <Form onSubmit={onSubmitHandler}>
          <Box>
            <Grid container columnSpacing={7}>
              <Grid item md={12}>
                <Box sx={{ my: 2 }}>
                  <InputLabel className={classes.department_label}>
                    Subject
                  </InputLabel>
                  <TextField
                    sx={{ mt: 1 }}
                    placeholder="Enter Request Subject"
                    fullWidth
                    className={classes.inputContainer}
                    value={requestData.subject}
                    onChange={handleChange("subject")}
                  />
                </Box>
              </Grid>

              <Grid item md={12}>
                <Box sx={{ my: 1 }}>
                  <InputLabel className={classes.department_label}>
                    Description
                  </InputLabel>
                  <TextField
                    sx={{ mt: 1 }}
                    placeholder="Enter Request Description"
                    fullWidth
                    className={classes.inputContainer}
                    rows={4}
                    multiline
                    value={requestData.description}
                    onChange={handleChange("description")}
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={12}
                sx={{ display: "flex", gap: "25px", mt: 2, flexWrap: "wrap" }}
              >
                {previewDocs.length !== 0 &&
                  previewDocs.map((d, i) => {
                    return (
                      <div
                        style={{
                          width: "90px",
                          height: "120px",
                          border: "1px solid lightgrey",
                          padding: "5px",
                        }}
                      >
                        {extDocs[i]?.includes(".pdf") && (
                          <iframe src={d} width="100%" height="100px" />
                        )}
                        {/* {(extDocs[i]?.includes(".doc") || extDocs[i]?.includes(".docx")) &&  <img
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src="https://res.cloudinary.com/dfzrlsjiq/image/upload/v1712142938/word-file-default.png"
                      />} */}
                        {(extDocs[i]?.includes(".png") ||
                          extDocs[i]?.includes(".jpg") ||
                          extDocs[i]?.includes(".jpeg")) && (
                          <img
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              height: "100px",
                            }}
                            src={d}
                          />
                        )}
                      </div>
                    );
                  })}
              </Grid>
              <Grid item md={12} sx={{ mt: 3 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Button variant="contained" component="label">
                    Upload Documents
                    <BsFillFileImageFill
                      style={{ fontSize: "26px", marginLeft: "10px" }}
                    />
                    <input
                      hidden
                      accept="image/*, .pdf"
                      multiple
                      type="file"
                      onChange={uploadDocsChange}
                    />
                  </Button>

                  <p style={{ marginBottom: "0px", color: "red" }}>
                    *Supported files: Images and pdf only.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                type="button"
                className={classes.cancelBtn}
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading ? true : false}
                className={classes.createLeaveBtn}
              >
                {loading ? <Spinner size="sm" animation="border" /> : "Create"}
              </Button>
            </Box>
          </Box>
        </Form>
      </React.Fragment>
    </Modal>
  );
};

export default AddRequestModal;
