import { Avatar, Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  clearErrors,
  sendTicketComment,
  ticketsDetail,
  updateTicketById,
} from "../../../../Redux/Actions/Tickets";
import Loader from "../../../../Utils/Loader";
import { useStyle } from "../LeaveManagement/leaveStyles";
import ProfilePic from "../../../../Asssets/Images/profile.png";
import { Button } from "@mui/material";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import Reversal from "../../../../Asssets/Images/reversal.png";
import { SiRocketdotchat } from "react-icons/si";
import { useState } from "react";
import moment from "moment";
import Header from "../../../../Components/Header/Header";

const TicketDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const { loading, indivisualTicketDetails } = useSelector(
    (state) => state.ticketDetail
  );
  const {
    loading: updateLoading,
    ticketUpdated,
    error: updateError,
  } = useSelector((state) => state.ticketUpdated);

  const {
    loading: commentLoading,
    sendComment,
    error: commentError,
  } = useSelector((state) => state.ticketComment);
  useEffect(() => {
    if (ticketUpdated?.success) {
      successNotify(ticketUpdated?.message);
      dispatch({ type: "INDIVISUAL_TICKETS_UPDATE_RESET" });
      dispatch(ticketsDetail(id));
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch(clearErrors());
    }
    if (sendComment?.success) {
      successNotify(sendComment?.message);
      dispatch({ type: "SEND_TICKET_COMMENT_RESET" });
      setComment("");
      dispatch(ticketsDetail(id));
    }
    if (commentError) {
      errorNotify(commentError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [updateError, ticketUpdated?.success, sendComment?.success, commentError]);

  const classes = useStyle();
  const { id } = useParams();
  const { state } = useLocation();
  useEffect(() => {
    dispatch(ticketsDetail(id));
  }, []);
  const updateHandler = (status) => {
    if (status) {
      const updateStatus = {
        status: status,
      };
      dispatch(updateTicketById(id, updateStatus));
    }
  };
  var space = indivisualTicketDetails?.assignBy?.full_name.indexOf(" ");
  const firstname = indivisualTicketDetails?.assignBy?.full_name.substring(
    0,
    space
  );

  const handleSendComment = () => {
    dispatch(sendTicketComment(id, comment));
  };
  return (
    <div className="page_responsive">
      <Header title="Ticket Details" />
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col md={7}>
            <Box className="my-2 ms-4">
              <Paper className={classes.sectionsContainer}>
                <Box className={classes.sectionHeader}>{firstname} Ticket</Box>
                {/* <Box className="mx-3">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="my-3"
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                         <Avatar
                          sx={{ width: "60px", height: "60px" }}
                          src={
                            indivisualTicketDetails?.assignBy?.profile_picture
                              ? indivisualTicketDetails?.assignBy
                                  ?.profile_picture[0]?.fileName
                              : ProfilePic
                          }
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "18px",
                            fontWeight: "700",
                            color: "#253766",
                          }}
                        >
                          {
                            indivisualLeaveDetails?.leaveApplication?.user
                              ?.full_name
                          }
                        </Typography>
                      </div>

                      <div className="created_at_container">
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <MdOutlineWatchLater
                            style={{
                              fontSize: "23px",
                              marginRight: "3px",
                              color: "#0097d9",
                            }}
                          />{" "}
                          <div>
                            <h6
                              className="mb-1"
                              style={{ color: "#0097d9", fontWeight: "500" }}
                            >
                              Created At{" "}
                            </h6>
                            <h6 style={{ fontSize: "14px" }}>
                              {new Date(
                                indivisualLeaveDetails?.leaveApplication?.createdAt
                              ).toLocaleString()}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box> */}
                <Box className={`mx-3 ${classes.leaveDetails}`}>
                  <Row className="mt-4 mb-2">
                    <Col md={4}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Subject
                      </Typography>
                    </Col>
                    <Col md={8}>
                      <Typography>
                        {indivisualTicketDetails?.subject}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col md={4}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Description
                      </Typography>
                    </Col>
                    <Col md={8}>
                      <Typography>
                        {indivisualTicketDetails?.description}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col md={4}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Date
                      </Typography>
                    </Col>
                    <Col md={8}>
                      <Typography>
                        {moment(indivisualTicketDetails?.last_date).format(
                          "DD-MM-YYYY"
                        )}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Status
                      </Typography>
                    </Col>
                    <Col md={8}>
                      <Typography
                        className={
                          indivisualTicketDetails?.status === "on-hold"
                            ? classes.medium
                            : indivisualTicketDetails?.status === "open"
                            ? classes.high
                            : classes.low
                        }
                      >
                        {indivisualTicketDetails?.status}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col md={4}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Priority
                      </Typography>
                    </Col>
                    <Col md={8}>
                      <Typography
                        className={
                          indivisualTicketDetails?.priority === "low"
                            ? classes.low
                            : indivisualTicketDetails?.priority === "medium"
                            ? classes.medium
                            : indivisualTicketDetails?.priority === "high"
                            ? classes.high
                            : classes.critical
                        }
                      >
                        {indivisualTicketDetails?.priority}
                      </Typography>
                    </Col>
                  </Row>

                  <Row className="my-2">
                    {state?.update && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px",
                        }}
                      >
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          {state.update &&
                            indivisualTicketDetails?.status !== "close" && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  marginTop: "20px",
                                }}
                              >
                                <Box sx={{ display: "flex", gap: "10px" }}>
                                  {/* {indivisualLeaveDetails?.leaveApplication.hr_status === "pending" ? */}
                                  <React.Fragment>
                                    {indivisualTicketDetails?.status ===
                                      "open" && (
                                      <Button
                                        onClick={() => updateHandler("on-hold")}
                                        variant="contained"
                                        className={classes.on_hold_btn}
                                      >
                                        {updateLoading ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        ) : (
                                          "On Hold"
                                        )}
                                      </Button>
                                    )}
                                    <Button
                                      onClick={() => updateHandler("close")}
                                      variant="contained"
                                      className={classes.approvedBtn}
                                    >
                                      {updateLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        "Close"
                                      )}
                                    </Button>
                                  </React.Fragment>
                                  {/* : null */}
                                  {/* } */}
                                </Box>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    )}
                  </Row>
                </Box>
              </Paper>
            </Box>
          </Col>
          <Col md={5}>
            <Box className="my-2 me-4">
              <Paper className={classes.sectionsContainer}>
                {/* <Box className={classes.sectionHeader}>Assigned</Box> */}
                <Box sx={{ mb: 2 }}>
                  <div>
                    <div>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, fontWeight: "700" }}
                      >
                        Assign By
                      </Typography>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Avatar
                        sx={{ width: "60px", height: "60px" }}
                        src={
                          indivisualTicketDetails?.assignBy?.profile_picture
                            ? indivisualTicketDetails?.assignBy
                                ?.profile_picture[0]?.fileName
                            : ProfilePic
                        }
                      />
                      <div>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "18px",
                            fontWeight: 600,
                            color: "#394767 !important",
                          }}
                        >
                          {indivisualTicketDetails?.assignBy?.full_name}
                        </Typography>
                        <div className={classes.department_box}>
                          <Typography
                            sx={{ fontWeight: "600" }}
                            className="fs-14"
                          >
                            {
                              indivisualTicketDetails?.assignBy?.department
                                .department_name
                            }
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <img
                    src={Reversal}
                    alt="reversal"
                    style={{ width: "30px" }}
                  />
                  <div
                    style={{
                      border: "1px solid #dedede",
                      width: "100%",
                      height: "1px",
                    }}
                  ></div>
                </Box>
                <Box sx={{ my: 2 }}>
                  <div>
                    <div>
                      <Typography
                        variant="h6"
                        sx={{ mb: 1, fontWeight: "700" }}
                      >
                        Assign To
                      </Typography>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Avatar
                        sx={{ width: "60px", height: "60px" }}
                        src={
                          indivisualTicketDetails?.assignTo?.profile_picture
                            ? indivisualTicketDetails?.assignTo
                                ?.profile_picture[0]?.fileName
                            : ProfilePic
                        }
                      />
                      <div>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "18px",
                            fontWeight: 600,
                            color: "#394767 !important",
                          }}
                        >
                          {indivisualTicketDetails?.assignTo?.full_name}
                        </Typography>
                        <div className={classes.department_box}>
                          <Typography
                            sx={{ fontWeight: "600" }}
                            className="fs-14"
                          >
                            {
                              indivisualTicketDetails?.assignTo?.department
                                .department_name
                            }
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Paper>
            </Box>
          </Col>
          <Col md={12}>
            <Box className="my-2 mx-4">
              <Paper className={classes.sectionsContainer}>
                <Box className={classes.sectionHeader}>
                  <SiRocketdotchat /> Chat
                </Box>
                <Box sx={{ p: 2 }}>
                  {indivisualTicketDetails?.conversation &&
                  indivisualTicketDetails?.conversation.length !== 0 ? (
                    indivisualTicketDetails?.conversation.map((d) => (
                      <Box sx={{ pb: 2, display: "flex", gap: "10px" }}>
                        <Avatar
                          sx={{
                            width: "55px",
                            height: "55px",
                            mr: 1,
                            objectFit: "contain",
                          }}
                          src={d?.user?.profile_picture[0]?.fileName}
                        />
                        <Box sx={{ width: "100%" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mb: 1,
                              width: "100%",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "600",
                                fontSize: "16px",
                                color: "#253766",
                              }}
                            >
                              {d?.user?.full_name}
                            </Typography>

                            <Typography
                              sx={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              {new Date(d?.created).toLocaleString()}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              backgroundColor: "#ebf4f7",
                              padding: "10px 10px",
                            }}
                          >
                            {d?.comment}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography variant="h6" className="mt-3">
                      {" "}
                      No Previous comment found!
                    </Typography>
                  )}
                </Box>
                <Box sx={{ p: 2 }}>
                  <Box sx={{ position: "relative" }}>
                    <input
                      placeholder="Type a message"
                      style={{
                        width: "100%",
                        padding: "20px 110px 40px 20px",
                        border: "1px solid #d6eff7",
                      }}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <button
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "26%",
                      }}
                      className={classes.sendBtn}
                      onClick={handleSendComment}
                      disabled={commentLoading ? true : false}
                    >
                      {commentLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Send"
                      )}
                    </button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Col>
        </Row>
        // <Row className="justify-content-center">
        //   <Col md={11} className="personal_details_container">
        //     <div>
        //       <div className="details_head">
        //         <h5>{firstname} Ticket</h5>
        //       </div>

        //       <div className="details_container">
        //         <Box sx={{ my: 3 }}>
        //           <Box
        //             sx={{
        //               display: "flex",
        //               //   justifyContent: "space-evenly",
        //               alignItems: "center",
        //             }}
        //             className="mb-5"
        //           >
        //             <div style={{ width: "50%" }}>
        //               <div>
        //                 <Typography variant="h6" sx={{ mb: 1 }}>
        //                   Assign By
        //                 </Typography>
        //               </div>
        //               <div
        //                 className="d-flex align-items-center"
        //                 style={{ gap: "10px" }}
        //               >
        //                 <Avatar
        //                   sx={{ width: "60px", height: "60px" }}
        //                   src={
        //                     indivisualTicketDetails?.assignBy?.profile_picture
        //                       ? indivisualTicketDetails?.assignBy
        //                           ?.profile_picture[0]?.fileName
        //                       : ProfilePic
        //                   }
        //                 />
        //                 <div>
        //                   <Typography
        //                     variant="h6"
        //                     sx={{
        //                       fontSize: "18px",
        //                       fontWeight: 600,
        //                       color: "#394767 !important",
        //                     }}
        //                   >
        //                     {indivisualTicketDetails?.assignBy?.full_name}
        //                   </Typography>
        //                   <div className={classes.department_box}>
        //                     <Typography
        //                       sx={{ fontWeight: "600" }}
        //                       className="fs-14"
        //                     >
        //                       {
        //                         indivisualTicketDetails?.assignBy?.department
        //                           .department_name
        //                       }
        //                     </Typography>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>

        //             <div>
        //               <div>
        //                 <Typography variant="h6" sx={{ mb: 1 }}>
        //                   Assign To
        //                 </Typography>
        //               </div>
        //               <div
        //                 className="d-flex align-items-center"
        //                 style={{ gap: "10px" }}
        //               >
        //                 <Avatar
        //                   sx={{ width: "60px", height: "60px" }}
        //                   src={
        //                     indivisualTicketDetails?.assignTo?.profile_picture
        //                       ? indivisualTicketDetails?.assignTo
        //                           ?.profile_picture[0]?.fileName
        //                       : ProfilePic
        //                   }
        //                 />
        //                 <div>
        //                   <Typography
        //                     variant="h6"
        //                     sx={{
        //                       fontSize: "18px",
        //                       fontWeight: 600,
        //                       color: "#394767 !important",
        //                     }}
        //                   >
        //                     {indivisualTicketDetails?.assignTo?.full_name}
        //                   </Typography>
        //                   <div className={classes.department_box}>
        //                     <Typography
        //                       sx={{ fontWeight: "600" }}
        //                       className="fs-14"
        //                     >
        //                       {
        //                         indivisualTicketDetails?.assignTo?.department
        //                           .department_name
        //                       }
        //                     </Typography>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           </Box>

        //           <Divider />
        //           <Row className="my-3">
        //             <Col md={4}>
        //               <Typography
        //                 variant="h6"
        //                 sx={{ fontWeight: "500", fontSize: "16px" }}
        //               >
        //                 Subject
        //               </Typography>
        //             </Col>
        //             <Col md={8}>
        //               <Typography>
        //                 {indivisualTicketDetails?.subject}
        //               </Typography>
        //             </Col>
        //           </Row>
        //           <Divider />

        //           <Row className="my-3">
        //             <Col md={4}>
        //               <Typography
        //                 variant="h6"
        //                 sx={{ fontWeight: "500", fontSize: "16px" }}
        //               >
        //                 Description
        //               </Typography>
        //             </Col>
        //             <Col md={8}>
        //               <Typography>
        //                 {indivisualTicketDetails?.description}
        //               </Typography>
        //             </Col>
        //           </Row>
        //           <Divider />

        //           <Row className="my-3">
        //             <Col md={4}>
        //               <Typography
        //                 variant="h6"
        //                 sx={{ fontWeight: "500", fontSize: "16px" }}
        //               >
        //                 Date
        //               </Typography>
        //             </Col>
        //             <Col md={8}>
        //               <Typography>
        //                 {
        //                   new Date(indivisualTicketDetails?.last_date)
        //                     .toLocaleString()
        //                     .split(",")[0]
        //                 }
        //               </Typography>
        //             </Col>
        //           </Row>
        //           <Divider />

        //           <Row className="my-3">
        //             <Col md={4}>
        //               <Typography
        //                 variant="h6"
        //                 sx={{ fontWeight: "500", fontSize: "16px" }}
        //               >
        //                 Status
        //               </Typography>
        //             </Col>
        //             <Col md={8}>
        //               <Typography
        //                 className={
        //                   indivisualTicketDetails?.status === "on-hold"
        //                     ? "pending_status"
        //                     : indivisualTicketDetails?.status === "open"
        //                     ? "rejected_status"
        //                     : "approved_staus"
        //                 }
        //               >
        //                 {indivisualTicketDetails?.status}
        //               </Typography>
        //             </Col>
        //           </Row>
        //           <Divider />

        //           <Row className="my-3">
        //             <Col md={4}>
        //               <Typography
        //                 variant="h6"
        //                 sx={{ fontWeight: "500", fontSize: "16px" }}
        //               >
        //                 Priority
        //               </Typography>
        //             </Col>
        //             <Col md={8}>
        //               <Typography
        //                 className={
        //                   indivisualTicketDetails?.priority === "low"
        //                     ? "pending_status"
        //                     : indivisualTicketDetails?.priority === "medium"
        //                     ? "medium_status"
        //                     : indivisualTicketDetails?.priority === "high"
        //                     ? "approved_staus"
        //                     : "rejected_status"
        //                 }
        //               >
        //                 {indivisualTicketDetails?.priority}
        //               </Typography>
        //             </Col>
        //           </Row>
        //           <Divider />

        //           {state.update &&
        //             indivisualTicketDetails?.status !== "close" && (
        //               <Box
        //                 sx={{
        //                   display: "flex",
        //                   justifyContent: "flex-end",
        //                   marginTop: "20px",
        //                 }}
        //               >
        //                 <Box sx={{ display: "flex", gap: "10px" }}>
        //                   {/* {indivisualLeaveDetails?.leaveApplication.hr_status === "pending" ? */}
        //                   <React.Fragment>
        //                     {indivisualTicketDetails?.status === "open" && (
        //                       <Button
        //                         onClick={() => updateHandler("on-hold")}
        //                         variant="contained"
        //                         className={classes.on_hold_btn}
        //                       >
        //                         {updateLoading ? (
        //                           <Spinner animation="border" size="sm" />
        //                         ) : (
        //                           "On Hold"
        //                         )}
        //                       </Button>
        //                     )}
        //                     <Button
        //                       onClick={() => updateHandler("close")}
        //                       variant="contained"
        //                       className={classes.createBtn}
        //                     >
        //                       {updateLoading ? (
        //                         <Spinner animation="border" size="sm" />
        //                       ) : (
        //                         "Close"
        //                       )}
        //                     </Button>
        //                   </React.Fragment>
        //                   {/* : null */}
        //                   {/* } */}
        //                 </Box>
        //               </Box>
        //             )}
        //         </Box>
        //       </div>
        //     </div>
        //   </Col>
        // </Row>
      )}
    </div>
  );
};

export default TicketDetails;
