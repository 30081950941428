import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calender.css";
import { FaBirthdayCake } from "react-icons/fa";
import { MdWork } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const CalendarComponent = ({ holidays, birthdays, workAnniversaries }) => {
  const navigate = useNavigate();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const allEvents = [];

    // Process holidays
    holidays?.forEach((holiday) => {
      allEvents.push({
        title: holiday.holiday_title,
        allDay: true,
        start: new Date(holiday.holiday_start_date),
        end: new Date(holiday.holiday_end_date),
        type: "holiday", // Add type property
      });
    });

    // Process birthdays for recurrence
    birthdays?.forEach((birthday) => {
      const currentYear = new Date().getFullYear();
      const birthDate = new Date(birthday.date_of_birth);
      const birthdayThisYear = new Date(currentYear, birthDate.getMonth(), birthDate.getDate());

      allEvents.push({
        title: (
          <div
            title={birthday.employee_name}
            onClick={() => navigate(`/HR/employee/${birthday.employee_id}`)}
          >
            <FaBirthdayCake style={{ color: "white", marginRight: "5px" }} />
            {birthday.employee_name}
          </div>
        ),
        allDay: true,
        start: birthdayThisYear,
        end: birthdayThisYear,
        type: "birthday", // Add type property
      });
    });

    // Process work anniversaries for recurrence
    workAnniversaries?.forEach((anniversary) => {
      const currentYear = new Date().getFullYear();
      const anniversaryDate = new Date(anniversary.joining_date);
      const anniversaryThisYear = new Date(currentYear, anniversaryDate.getMonth(), anniversaryDate.getDate());

      allEvents.push({
        title: (
          <div
            title={anniversary.employee_name}
            onClick={() => navigate(`/HR/employee/${anniversary.employee_id}`)}
          >
            <MdWork style={{ color: "white", marginRight: "5px" }} />
            {anniversary.employee_name}
          </div>
        ),
        allDay: true,
        start: anniversaryThisYear,
        end: anniversaryThisYear,
        type: "workAnniversary", // Add type property
      });
    });

    setEvents(allEvents);
  }, [holidays, birthdays, navigate]);

  const eventPropGetter = (event) => {
    let style = {};
    if (event.type === "birthday") {
      style = {
        backgroundColor: "#fbbd08", // Birthday color
        borderRadius: "5px",
        opacity: 0.8,
        color: "black",
        border: "none",
        display: "block",
      };
    } else if (event.type === "holiday") {
      style = {
        // backgroundColor: "#fbbd08", // Holiday color
        backgroundColor: "#8c63ee", // Holiday color
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "none",
        display: "block",
      };
    } else if (event.type === "workAnniversary") {
      style = {
        // backgroundColor: "#fbbd08", // Holiday color
        backgroundColor: "#17a2b8", // Holiday color
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "none",
        display: "block",
      };
    }
    return {
      style: style,
    };
  };

  return (
    <div style={{ height: "500pt" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={["month", "week", "day"]}
        eventPropGetter={eventPropGetter}
      />
    </div>
  );
};

export default CalendarComponent;
