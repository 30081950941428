import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  btnStyle: {
    background: "black !important",
    color: "white !important",
    width: "25px !important",
    height: "25px !important",
    position: "absolute !important",
    right: "15px !important",
    top: "25px !important",
    "&:hover": {
      background: "black !important",
      color: "white !important",
    },
  },
});
