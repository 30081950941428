import React from "react";
import HRNavbar from "../HR/Routes/Navbar";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../../Utils/helper";

const HRLayout = () => {
  let userAuth = getUser() ? getUser() || "" : null;
  return userAuth?.employee?.role === "HR" ? (
    <React.Fragment>
      <HRNavbar />
      <Outlet />
    </React.Fragment>
  ) : (
    <Navigate to={`/`} />
  );
};
export default HRLayout;
