import api from "../../Utils/interceptors";
import axios from "axios";
import CryptoJS from "crypto-js";

export const Login = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGIN_REQUEST",
    });

    const { data } = await axios.post("auth/login", inputData);
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: data,
    });
    // test----
    const jsonString = JSON.stringify(data);

    const encryptedData = CryptoJS.AES.encrypt(
      jsonString,
      "secret_key"
    ).toString();

    localStorage.setItem("user", encryptedData);

    // test----
  } catch (e) {
    dispatch({
      type: "LOGIN_FAILED",
      payload:
        e.response.data.message === "User not found!"
          ? "Invalid email or password"
          : e.response.data.message,
    });
  }
};

export const Register = (registerData) => async (dispatch) => {
  try {
    dispatch({
      type: "REGISTER_REQUEST",
    });

    const { data } = await axios.post("register", registerData);

    dispatch({
      type: "REGISTER_SUCCESS",
      payload: data,
      success: true,
    });
  } catch (e) {
    dispatch({
      type: "REGISTER_FAILED",
      payload: e.response.data.message,
      success: false,
    });
  }
};

export const changePassword = (id, inputData) => async (dispatch) => {
  try {
    dispatch({
      type: "CHANGE_PASSWORD_REQUEST",
    });

    const { data } = await api.put(
      `/auth/user/update-password/${id}`,
      inputData
    );

    dispatch({
      type: "CHANGE_PASSWORD_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CHANGE_PASSWORD_FAILED",
      payload: e.response.data.message,
    });
  }
};

export const changePinCode = (id, inputData) => async (dispatch) => {
  try {
    dispatch({
      type: "CHANGE_PIN_REQUEST",
    });

    const { data } = await api.put(`/auth/user/update-pincode`, inputData);

    dispatch({
      type: "CHANGE_PIN_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CHANGE_PIN_FAILED",
      payload: e.response.data.message,
    });
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CURRENT_USER_REQUEST",
    });
    const { data } = await api.get(`auth/user`);
    dispatch({
      type: "GET_CURRENT_USER_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_CURRENT_USER_FAILED",
      payload: error.response?.data?.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
