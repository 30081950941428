import { Box, Button, InputLabel, TextField } from "@mui/material";
import React, { useEffect } from "react";
import ReactModal from "../../../../../Components/Modal/Modal";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  clearErrors,
  createShiftByHR,
  updateShiftByHR,
} from "../../../../../Redux/Actions/Shifts";
import { useDispatch, useSelector } from "react-redux";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { getAllShifts } from "../../../../../Redux/Actions/HR";
import { Spinner } from "react-bootstrap";

const UpdateShiftModal = ({
  classes,
  updateModalOpen,
  setUpdateModalOpen,
  page,
  rowsPerPage,
  updateShiftDetails,
  setUpdateShiftDetails,
  loading,
}) => {
  const dispatch = useDispatch();
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.updateShift);
  useEffect(() => {
    if (isUpdated?.success) {
      successNotify(isUpdated?.message);
      dispatch({ type: "UPDATE_Shift_RESET" });
      setUpdateModalOpen(false);
      dispatch(getAllShifts(page, rowsPerPage));
      setUpdateShiftDetails({
        _id: "",
        shift_name: "",
        start_time: "",
        end_time: "",
      });
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch(clearErrors());
    }
  }, [isUpdated, updateError]);
  const handleUpdateShift = () => {
    dispatch(
      updateShiftByHR({
        _id: updateShiftDetails._id,
        shift_name: updateShiftDetails.shift_name,
        start_time: new Date(updateShiftDetails.start_time).toISOString(),

        end_time: new Date(updateShiftDetails.end_time).toISOString(),
      }),
    );
  };
  const handleCancel = () => {
    setUpdateModalOpen(false);
    setUpdateShiftDetails({
      _id: "",
      shift_name: "",
      start_time: "",
      end_time: "",
    });
  };
  return (
    <ReactModal
      open={updateModalOpen}
      setOpen={setUpdateModalOpen}
      title="Update Shift"
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="large" animation="border" />
        </Box>
      ) : (
        <>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Shift Name
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Enter Shift Name"
              fullWidth
              className={classes.inputContainer}
              value={updateShiftDetails?.shift_name}
              onChange={(e) =>
                setUpdateShiftDetails({
                  ...updateShiftDetails,
                  shift_name: e.target.value,
                })
              }
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Shift Start Time
            </InputLabel>
            <Box className={classes.timePicker}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={updateShiftDetails?.start_time}
                  onChange={(newValue) => {
                    setUpdateShiftDetails({
                      ...updateShiftDetails,
                      start_time: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputContainer}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Shift End Time
            </InputLabel>
            <Box className={classes.timePicker}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={updateShiftDetails?.end_time}
                  onChange={(newValue) => {
                    setUpdateShiftDetails({
                      ...updateShiftDetails,
                      end_time: newValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputContainer}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                className={classes.cancelBtn}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.createBtn}
                onClick={() => handleUpdateShift()}
                disabled={updateLoading ? true : false}
              >
                {updateLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </ReactModal>
  );
};

export default UpdateShiftModal;
