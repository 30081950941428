import { Button, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import Header from "../../../../Components/Header/Header";
import Input from "../../../../Components/Input/Input";
import MetaData from "../../../../Components/MetaData";
import {
  clearErrors,
  getAllDepartments,
  getAllEmployees,
} from "../../../../Redux/Actions/HR";
import {
  createPayroll,
  deletePayrollById,
  getLastPayrollsOfEmployees,
  getPayrolls,
  PayrollByDepartment,
} from "../../../../Redux/Actions/Payroll";
import Loader from "../../../../Utils/Loader";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { useStyle } from "./payroll-styles";
import PreviousPayrolls from "./PreviousPayrolls";
import { BiSearchAlt, BiReset, BiSearch } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import SearchedPayrolls from "./SearchedPayrolls";
import Modal from "../../../../Components/SiteModal/SiteModal";
import CreatePayrollModal from "./CreatePayrollModal";
import { BaseURL } from "../../../../Utils/interceptors";
import axios from "axios";
import { getToken } from "../../../../Utils/helper";
import { CSVLink } from "react-csv";
import { IoMdDownload } from "react-icons/io";
import { UnfoldLess } from "@mui/icons-material";
import headers from "./csvData";
import { toast } from "react-toastify";

const Payroll = () => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [show, setShow] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [date, setDate] = useState("");
  const [department, setDepartment] = useState("");
  const [search, setSearch] = useState(false);
  const [tab, setTab] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [payrollsPage, setPayrollsPage] = useState(0);
  const [payrollsRowsPerPage, setPayrollsRowsPerPage] = useState(5);
  const [downloadDate, setDownloadDate] = useState("");
  const [downloadDepartment, setDownloadDepartment] = useState("");
  const [allPayrollsData, setAllPayrollsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSearch, setPageSearch] = useState(false);
  const [isSearched, setIsSearch] = useState(false);
  const [searchName, setSearchName] = useState(false);
  const [searchFilterName, setSearchFilterName] = useState("");
  const [searchDate, setSearchDate] = useState(false);
  const [searchFilterDate, setSearchFilterDate] = useState("");
  const [datas, setDatas] = useState([]);

  const dateRef = useRef();
  const navigate = useNavigate();
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  // } = useForm();
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees
  );
  const { loading: departmentLoading, departments } = useSelector(
    (state) => state.departments
  );
  const { loading, payrolls, payrollsCount, error } = useSelector(
    (state) => state.lastPayrolls
  );

  // const {
  //   loading: createLoading,
  //   payroll: createdPayroll,
  //   error: createError,
  // } = useSelector((state) => state.createPayroll);
  // const {
  //   loading: createByDepLoading,
  //   isCreated,
  //   message,
  //   error: createByDepError,
  // } = useSelector((state) => state.createPayrollByDepartment);

  const {
    loading: searchedLoading,
    payrolls: searchedPayrolls,
    payrollsCount: searchedCount,
    success,
    error: searchedError,
  } = useSelector((state) => state.payrolls);

  useEffect(() => {
    if (searchedPayrolls !== undefined) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    const getSearchName = window.sessionStorage.getItem("filterSearchName");
    setSearchFilterName(getSearchName);
    const getFulterDate = window.sessionStorage.getItem("filterDate");
    setSearchFilterDate(getFulterDate);
  }, []);

  const { success: deletePayrollSuccess } = useSelector(
    (state) => state.deletePayroll
  );

  useEffect(() => {
    // if (createdPayroll?.success) {
    //   successNotify(createdPayroll?.message);
    //   dispatch({ type: "CREATE_PAYROLL_RESET" });
    //   setShow(false);
    //   navigate(`/HR/payroll/update/${createdPayroll?.payroll?._id}`);
    //   dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
    // }
    // if (isCreated) {
    //   successNotify(message);
    //   dispatch({ type: "CREATE_PAYROLL_BY_DEPARTMENT_RESET" });
    //   dispatch({ type: "GET_PAYROLLS_RESET" });

    //   setShow(false);
    //   dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
    // }
    // if (createError) {
    //   errorNotify(createError);
    //   dispatch(clearErrors());
    // }
    // if (createByDepError) {
    //   errorNotify(createByDepError);
    //   dispatch(clearErrors());
    // }
    if (deletePayrollSuccess) {
      setDepartment("");
      setEmployeeId("");
    }
  }, [
    // createdPayroll?.success,
    // createError,
    // message,
    // isCreated,
    // createByDepError,
    deletePayrollSuccess,
  ]);
  useEffect(() => {
    dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
  }, [page, rowsPerPage]);

  // useEffect(() => {
  //   dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
  //   dispatch(getAllEmployees());
  //   dispatch(getAllDepartments());
  //   return () => {
  //     dispatch({ type: "GET_PAYROLLS_RESET" });
  //     setSearch(false);
  //   };
  // }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove particular data from localStorage
      window.sessionStorage.removeItem("filterSearchName");
      window.sessionStorage.removeItem("filterDate");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (search) {
      if (date) {
        dispatch(
          getPayrolls(
            payrollsPage,
            payrollsRowsPerPage,
            employeeId,
            department,
            date.split("-")[1],
            date.split("-")[0]
          )
        );
      } else {
        dispatch(
          getPayrolls(payrollsPage, payrollsRowsPerPage, employeeId, department)
        );
      }
    }
  }, [search, payrollsPage, payrollsRowsPerPage]);

  // const onSubmitHandler = handleSubmit(async (data) => {
  //   if (tab === 1 && (!employeeId || !date)) {
  //     errorNotify("please select user and date to generate payroll!");
  //     setShow(false);
  //   } else if (tab === 2 && (!department || !date)) {
  //     errorNotify("please select department and date to generate payroll!");
  //     setShow(false);
  //   } else if (tab === 1 && employeeId && date) {
  //     const payrollData = {
  //       userId: employeeId,
  //       month: date.split("-")[1],
  //       year: date.split("-")[0],
  //     };
  //     dispatch(createPayroll(payrollData));
  //   } else if (tab === 2 && department && date) {
  //     const payrollData = {
  //       departmentId: department,
  //       month: date.split("-")[1],
  //       year: date.split("-")[0],
  //     };
  //     dispatch(PayrollByDepartment(payrollData));
  //   }
  // });
  const handleEmpTab = () => {
    setTab(1);
    setDepartment("");
  };
  const handleDepTab = () => {
    setTab(2);
    setEmployeeId("");
  };

  // search
  const filterSearchName = employees?.employees?.filter(
    (e) => e._id === employeeId
  )[0]?.full_name;
  const handleSearch = (e) => {
    e.stopPropagation();
    setSearch(true);
    setIsSearch(true);
    setPayrollsPage(0);
    if (filterSearchName) {
      window.sessionStorage.setItem("filterSearchName", filterSearchName);
    }
    if (department) {
      const filterDepartment = departments?.filter(
        (e) => e._id === department
      )[0]?.department_name;
      window.sessionStorage.setItem("filterSearchName", filterDepartment);
    }
    const getFilterName = sessionStorage.getItem("filterSearchName");
    setSearchFilterName(getFilterName);
    if (date) {
      window.sessionStorage.setItem("filterDate", date);
    }
    const getFilterDate = window.sessionStorage.getItem("filterDate");
    setSearchFilterDate(getFilterDate);

    if (getFilterDate) {
      setSearchDate(true);
    }
    if (searchFilterName) {
      setSearchName(true);
    }
    setPayrollsRowsPerPage(5);
    if (!employeeId && !date && !department) {
      errorNotify("please fill at least one field to search payroll!");
    }
    if (date) {
      dispatch(
        getPayrolls(
          payrollsPage,
          payrollsRowsPerPage,
          getFilterName ? employeeId : "",
          department,
          date.split("-")[1],
          date.split("-")[0]
        )
      );
    } else {
      dispatch(
        getPayrolls(payrollsPage, payrollsRowsPerPage, employeeId, department)
      );
    }
  };

  const clearSearch = () => {
    setPageSearch(!pageSearch);
    setIsSearch(false);
    setDate((dateRef.current.value = ""));
    window.sessionStorage.removeItem("filterSearchName");
    setSearchFilterName("");
    window.sessionStorage.removeItem("filterDate");
    setSearchFilterDate("");
    setSearchDate(false);
  };

  useEffect(() => {
    dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
    dispatch(getAllEmployees());
    dispatch(getAllDepartments());
    return () => {
      dispatch({ type: "GET_PAYROLLS_RESET" });
      setSearch(false);
    };
  }, [pageSearch]);

  const DownloadModal = (
    <Modal
      title="Download CSV"
      size={"md"}
      show={downloadModalOpen}
      onCloseModal={() => setDownloadModalOpen(!downloadModalOpen)}
    >
      <React.Fragment>
        <div className="downLoad__modal__wrapper">
          <Row className="gy-4">
            <Col md={12}>
              <Input>
                <Form.Label>Month/Year</Form.Label>
                <Form.Control
                  type="month"
                  aria-label="Default select example"
                  value={downloadDate}
                  onChange={(e) => setDownloadDate(e.target.value)}
                ></Form.Control>
              </Input>
            </Col>
            <Col md={12}>
              <Input>
                <Form.Label>Select Department</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  // {...register("department")}
                  value={downloadDepartment}
                  onChange={(e) => setDownloadDepartment(e.target.value)}
                >
                  <option defaultValue={-1} key={-1} value="">
                    Select Department
                  </option>
                  {departments &&
                    departments?.map((department, i) => (
                      <option value={department._id} key={i}>
                        {department.department_name}
                      </option>
                    ))}
                </Form.Select>
              </Input>
            </Col>
            <Col style={isLoading ? { pointerEvents: "none" } : {}} md={12}>
              <CSVLink
                onClick={() => {
                  if (datas?.length === 0) {
                    toast.warn("No data to download");
                    return false;
                  }
                }}
                className={"download__btn__main"}
                data={datas}
                headers={headers}
                filename={`payroll_mastersheet_${
                  downloadDate && downloadDate?.split("-")?.reverse()?.join(",")
                }_${
                  departments &&
                  !departments?.filter((e) => e._id === downloadDepartment)[0]
                    ?.department_name
                    ? ""
                    : departments?.filter(
                        (e) => e._id === downloadDepartment
                      )[0]?.department_name
                }`}
              >
                {isLoading ? "Please wait..." : "Download CSV"}
              </CSVLink>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </Modal>
  );

  const handleGeneratePayroll = () => {
    setShow(!show);
  };

  const getAllPayrolls = async () => {
    const url = `${BaseURL}/payrolls?date=${
      downloadDate && downloadDate?.split("-")?.reverse()?.join(",")
    }&department=${downloadDepartment && downloadDepartment}`;
    const token = getToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };
    try {
      setIsLoading(true);
      const res = await axios.get(url, options);
      if (res !== undefined) {
        setAllPayrollsData(res?.data?.payrolls);

        let data = [];
        const csvMap = await res?.data?.payrolls?.map((val) => {
          const {
            date,
            actualSalary,
            workingDays,
            approvedPaidLeaves,
            approvedPaidHalfDays,
            approvedUnpaidLeaves,
            approvedUnpaidHalfDays,
            tax,
            loanDeduction,
            deduction,
            totalDeductions,
            calculatedSalary,
            offWorkingDays,
            actualWorkingDays,
          } = val;
          const { full_name, CNIC, designation, department, joining_date } =
            val?.user;
          const {
            medicalInsurancePerMonth,
            OPDPerMonth,
            retentionIncentive,
            totalEmployeePackageperMonth,
          } = val?.employeePackage;
          const { commission, bonus, other, arrear, retention, totalEarnings } =
            val?.earnings;
          const {
            totalCLSL,
            usedCLSL,
            remainingCLSL,
            totalAL,
            usedAL,
            remainingAL,
          } = val?.leavesRecord;
          const { loanBalance, medicalInsurance, OPDBalance } =
            val?.balanceRecord;

          const csvObj = {
            name: full_name,
            cnic: CNIC,
            current_designation: designation?.designation_name,
            department: department?.department_name,
            DOJ: new Date(joining_date).toLocaleDateString('en-GB').split('/').join('-'),
            month: date.month + ", " + date.year,
            total_salary: actualSalary,
            medical_insurance: medicalInsurancePerMonth,
            OPD: OPDPerMonth,
            retention_Incentive: retention.amount,
            total_employee_package: totalEmployeePackageperMonth,
            working_days: workingDays,
            monthly_basic_salary: actualSalary,
            commission: commission?.amount,
            bonus: bonus?.amount,
            arrears: arrear?.amount,
            retention: retention?.amount,
            others: other?.amount,
            total_earnings: totalEarnings,
            paid_leaves_and_halfdays:
              approvedPaidLeaves + " Leaves & " + approvedPaidHalfDays + " Halfdays",
            unpaid_leaves_and_halfdays:
              approvedUnpaidLeaves + " Leaves & " + approvedUnpaidHalfDays + " Halfdays",
            tax_deduction: tax,
            loan_deduction: loanDeduction,
            deduction: deduction,
            total_deduction: totalDeductions,
            net_payable_salary: calculatedSalary,
            total_working_days: workingDays,
            off_working_days: offWorkingDays,
            actual_working_days: actualWorkingDays,
            total_casual_and_sick_leaves: totalCLSL,
            used_casual_and_sick_leaves: usedCLSL,
            remaining_casual_and_sick_leaves: remainingCLSL,
            total_annual_leaves: totalAL,
            used_annual_leaves: usedAL,
            remaining_annual_leaves: remainingAL,
            remaining_loan_balance: loanBalance,
            medical_insurance_limit: medicalInsurance,
            OPD_balance: OPDBalance,
          };
          data.push(csvObj);
        });
        setDatas(data);
      }
      setIsLoading(false);
    } catch (error) {
      // if (error?.message == "Network Error") {
      //   toast.error("Please check your internet connection", {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      // } else {
      //   toast.error(error?.response?.data?.error, {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      // }
    }
  };

  useEffect(() => {
    getAllPayrolls();
  }, [downloadDate, downloadDepartment]);

  return (
    <div className="page_responsive">
      <MetaData title={`Payroll -- HRM`} />
      <Header title="PAYROLL" />

      <Container fluid>
        <Row className="mx-2 mt-4 mb-1">
          <Col md={3}>
            <Button
              variant="contained"
              className={tab === 1 ? classes.active_btn : classes.tab_btn}
              fullWidth
              onClick={() => handleEmpTab()}
            >
              By Employee
            </Button>
          </Col>
          <Col md={3}>
            <Button
              variant="contained"
              className={tab === 2 ? classes.active_btn : classes.tab_btn}
              fullWidth
              onClick={() => handleDepTab()}
            >
              By Department
            </Button>
          </Col>
        </Row>
        <Row className="m-0">
          <Col md={12}>
            <div className="employees_main_selection">
              <Form onSubmit={handleGeneratePayroll}>
                <Row className="p-0 m-0">
                  {tab === 1 && (
                    <Col md={4}>
                      <Input>
                        <Form.Label>Employee</Form.Label>
                        <AutoCompleteSearch
                          data={employees?.employees}
                          departmentData={employeeId}
                          setDepartmentData={setEmployeeId}
                          title={"Search Employee"}
                        />
                      </Input>
                    </Col>
                  )}
                  {tab === 2 && (
                    <Col md={4}>
                      <Input>
                        <Form.Label>Select Department</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          // {...register("department")}
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}
                        >
                          <option defaultValue={-1} key={-1} value="">
                            Select Department
                          </option>
                          {departments &&
                            departments?.map((department, i) => (
                              <option value={department._id} key={i}>
                                {department.department_name}
                              </option>
                            ))}
                        </Form.Select>
                      </Input>
                    </Col>
                  )}

                  <Col md={3}>
                    <Input>
                      <Form.Label>Month/Year</Form.Label>
                      <Form.Control
                        type="month"
                        aria-label="Default select example"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        ref={dateRef}
                      ></Form.Control>
                    </Input>
                  </Col>
                  <Col md={2} className="d-flex align-items-end ">
                    <div className="align-items-center d-flex">
                      <div className="payroll__search_btn_container">
                        <IconButton
                          // className={classes.search_payroll}
                          onClick={(e) => handleSearch(e)}
                        >
                          <BiSearchAlt />
                        </IconButton>
                      </div>
                      {/* clear search */}
                      <div className="payroll__search_btn_container">
                        <IconButton
                          // className={classes.search_payroll}
                          onClick={clearSearch}
                        >
                          <BiReset />
                        </IconButton>
                      </div>
                    </div>
                  </Col>
                  <Col md={3} className="d-flex">
                    <div className="payroll_btn_container">
                      <Button
                        variant="contained"
                        type="button"
                        className={classes.generate_payroll}
                        // disabled={createLoading ? true : false}
                        onClick={() => handleGeneratePayroll()}
                      >
                        Generate Payroll
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              <div className="d-flex align-items-end mt-2">
                {window.sessionStorage.getItem("filterSearchName") && (
                  <div
                    className="mx-2 rounded-2 p-2"
                    style={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      background: "#efefef",
                      width: "fit-contain",
                    }}
                  >
                    <BiSearch />{" "}
                    <span className="mx-2">{searchFilterName}</span>
                  </div>
                )}
                {window.sessionStorage.getItem("filterDate") && (
                  <div
                    className="mx-2 rounded-2 p-2"
                    style={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      background: "#efefef",
                      width: "fit-contain",
                    }}
                  >
                    <BiSearch />{" "}
                    <span className="mx-2">{searchFilterDate}</span>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="download__wrapper">
            <Button onClick={() => setDownloadModalOpen(true)}>
              <IoMdDownload size={22} /> Download CSV
            </Button>
          </div>
          <Col md={12}>
            {loading || searchedLoading ? (
              <Loader />
            ) : !success && isSearched === false ? (
              <PreviousPayrolls
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                payrolls={payrolls}
                payrollsCount={payrollsCount}
                isDownload={false}
              />
            ) : (
              <SearchedPayrolls
                isDownload={false}
                success={true}
                page={page}
                department={department}
                date={date}
                employeeId={employeeId}
                setPage={setPage}
                payrollsPage={payrollsPage}
                setPayrollsPage={setPayrollsPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                payrollsRowsPerPage={payrollsRowsPerPage}
                setPayrollsRowsPerPage={setPayrollsRowsPerPage}
                payrolls={searchedPayrolls}
                payrollsCount={searchedCount}
              />
            )}
          </Col>
        </Row>
      </Container>

      {/* {modal} */}
      <CreatePayrollModal
        show={show}
        setShow={setShow}
        employees={employees}
        departments={departments}
        page={page}
        rowsPerPage={rowsPerPage}
      />
      {downloadModalOpen && DownloadModal}
    </div>
  );
};

export default Payroll;
