import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  personal_details_card: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottomLeftRadius: "30px !important",
    borderBottomRightRadius: "30px !important",
    padding: "20px 0px",
    boxShadow:
      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 10%) 0px 30px 23px -30px !important",
    position: "relative",
  },
  personName: {
    color: "#253766",
    fontWeight: "700 !important",
    fontSize: "23px !important",
    lineHeight: "1 !important",
  },
  department_box: {
    marginTop: "5px",
    backgroundColor: "#59c680",
    color: "white",
    width: "fit-content",
    padding: "3px 20px",
    borderRadius: "30px",
  },
  details_card: {
    borderRadius: "40px !important",
    height: "100%",
    padding: "20px",
    boxShadow:
      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
  },
  personal_detail_box: {
    borderLeft: "1px solid #dbdada !important",
    padding: "10px 60px",
    "& .MuiBox-root": {
      display: "flex",
      borderBottom: "1px solid #ECECEC",
      alignItems: "center",
      margin: "2px 0px",
      "& h6": {
        fontSize: "16px !important",
        fontWeight: "600 !important",
        color: "#253766 !important",
        width: "140px",
      },
      "& p": {
        color: "#000000 !important",
        fontSize: "14px !important",
      },
    },
  },
  personal_details_form: {
    padding: "20px 10px",
    alignItems: "center",
    "& h6": {
      fontWeight: "600 !important",
      // fontStyle: "italic !important",
      color: "rgb(37, 55, 102) !important",
      margin: "5px 0px",
      fontSize: "15px !important",
    },
    "& p": {
      fontWeight: "400",
      color: "#000000 !important",
      fontSize: "14px !important",
      margin: "5px 0px",
    },
  },
  documents_container: {
    margin: "0 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
    border: "1px solid #DEDEDE",
    padding: "10px",
    borderTopLeftRadius: "50px",
    borderBottomRightRadius: "50px",
    height: "auto",
    "& h6": {
      fontWeight: "500",
      // fontStyle: "italic",
      color: "rgb(37, 55, 102)",
      margin: "5px 0px",
      fontSize: "13px",
      textAlign: "center",
    },
    "& img": {
      margin: "0 3px",
      width: "150px",
      height: "230px",
      objectFit: "contain",
    },
  },
});
