import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  create_input: {
    backgroundColor: "#f3f8fe",
    borderRadius: "30px",
    color: "grey",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
});
