import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import Top from "../../Asssets/Images/Line.png";
import Logo from "../../Asssets/Images/Logo.png";
import { successNotify } from "../../Utils/toast";
import { useEffect } from "react";
import io from "socket.io-client";
import { logoutUserConnection } from "../../realTimeCommunication/SocketConnection";
import { getCurrentUser } from "../../Redux/Actions/Auth";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { getUser } from "../../Utils/helper";
var socket;

let userAuth = getUser() ? getUser() || "" : null;
const DashboardSidebar = (props) => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  // useEffect(() => {
  // dispatch(getCurrentUser());
  // }, []);
  // useEffect(() => {
  //   // if (userAuth?.success) {
  //   socket = io("https://dh-hrm.azurewebsites.net", {
  //     auth: {
  //       token: userAuth?.token,
  //     },
  //   });
  //   // }
  // }, []);
  // console.log(socket);

  const [sideBar, setSideBar] = useState(true);

  const classes = (path) => {
    if (path === location.pathname) {
      return "nav_active";
    }
    return "";
  };

  const LogoutHandler = () => {
    logoutUserConnection(userAuth);
    localStorage.clear();
    navigation("/");
    successNotify("Logged Out Successfully!");
    setLogoutSuccess(true);
  };

  return (
    <div className={sideBar ? "sidebar active" : "sidebar"}>
      <div style={{ width: "100vw", position: "absolute", top: "-8px" }}>
        <img src={Top} alt="" style={{ width: "100%" }} />
      </div>
      <div className={"logo_content"}>
        <div className={"profile"}>
          <p className={"mb-0"}>
            {" "}
            <img
              alt="logo"
              src={Logo}
              style={{ position: "sticky", zIndex: 100 }}
            />{" "}
          </p>
        </div>
        {sideBar ? (
          <IoMdCloseCircle
            className={"fa-bars"}
            onClick={() => setSideBar(false)}
          />
        ) : (
          <FaIcons.FaBars
            className={"fa-bars"}
            onClick={() => setSideBar(true)}
          />
        )}
      </div>
      <ul className="nav_list">
        {props?.sideBarItems?.map((item, index) => {
          if (item.path) {
            return (
              <li key={index} className={`${classes(item.path)}`}>
                <Link to={item.path}>
                  <img src={item.icon.type} alt="icon" />
                  <span>{item.title}</span>
                </Link>
              </li>
            );
          }
        })}
        <li className="logout_btn" onClick={LogoutHandler}>
          <Link to={"#"}>
            <FiIcons.FiLogOut />
            <span>Logout</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default DashboardSidebar;
