import React from "react";
import { Modal } from "react-bootstrap";
import "./SiteModal.css";
import { IoMdCloseCircleOutline } from "react-icons/io";

const SiteModal = ({ title, show, size, children, onCloseModal }) => {
  return (
    <Modal show={show} size={size} backdrop="static" className={"site_modal"}>
      <Modal.Header>
        <div className={"review_header"}>
          <h4 className="site_modal_title">{title}</h4>
          <IoMdCloseCircleOutline onClick={onCloseModal} />
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
export default SiteModal;
