import { Box, Button, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "../../../../../Components/Modal/Modal";
import { getAllLeaves } from "../../../../../Redux/Actions/HR";
import {
  clearErrors,
  createLeaveByHR,
} from "../../../../../Redux/Actions/Leaves";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import AutoCompleteSearch from "../../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import Input from "../../../../../Components/Input/Input";

const CreateLeaveModal = ({
  classes,
  modalOpen,
  setModalOpen,
  handleCreate,
  isCreating,
}) => {
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees
  );
  const [employee, setEmployee] = useState("");

  return (
    <ReactModal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Add New Employee"
    >
      <Box sx={{ my: 2 }}>
        <Input>
          <Form.Label>Employee</Form.Label>
          <AutoCompleteSearch
            data={employees?.employees}
            departmentData={employee}
            setDepartmentData={setEmployee}
            title={"Search Employee"}
          />
        </Input>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            onClick={() => setModalOpen(false)}
            disabled={isCreating ? true : false}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.createBtn}
            onClick={() => handleCreate({ employeeId: employee })}
          >
            {isCreating ? <Spinner size="sm" animation="border" /> : "ADD"}
          </Button>
        </Box>
      </Box>
    </ReactModal>
  );
};

export default CreateLeaveModal;
