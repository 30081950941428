export const EmployeeDashboard = (state = {}, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_DASHBOARD_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_EMPLOYEE_DASHBOARD_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        dashboard: action.payload,
        error: null,
      };
    case "GET_EMPLOYEE_DASHBOARD_DATA_FAILED":
      return {
        ...state,
        loading: false,
        dashboard: null,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const updateEmployeeReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_EMPLOYEE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "UPDATE_EMPLOYEE_SUCCESS":
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case "UPDATE_EMPLOYEE_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "UPDATE_EMPLOYEE_RESET":
      return {
        ...state,
        isUpdated: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// DELETE LEAVE BY INDIVISUAL ID

export const deleteEmployeeReducer = (state = [], action) => {
  switch (action.type) {
    case "DELETE_EMPLOYEE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DELETE_EMPLOYEE_SUCCESS":
      return {
        ...state,
        loading: false,
        deletedEmployee: action.payload,
      };
    case "DELETE_EMPLOYEE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "DELETE_EMPLOYEE_RESET":
      return {
        ...state,
        deletedEmployee: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const banEmployeeReducer = (state = [], action) => {
  switch (action.type) {
    case "BAN_EMPLOYEE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "BAN_EMPLOYEE_SUCCESS":
      return {
        ...state,
        loading: false,
        banEmployee: action.payload,
      };
    case "BAN_EMPLOYEE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "BAN_EMPLOYEE_RESET":
      return {
        ...state,
        banEmployee: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const unBanEmployeeReducer = (state = [], action) => {
  switch (action.type) {
    case "UN_BAN_EMPLOYEE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "UN_BAN_EMPLOYEE_SUCCESS":
      return {
        ...state,
        loading: false,
        unbanEmployee: action.payload,
      };
    case "UN_BAN_EMPLOYEE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "UN_BAN_EMPLOYEE_RESET":
      return {
        ...state,
        unbanEmployee: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const activeEmployeeReducer = (state = [], action) => {
  switch (action.type) {
    case "ACTIVE_EMPLOYEE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "ACTIVE_EMPLOYEE_SUCCESS":
      return {
        ...state,
        loading: false,
        active: action.payload,
      };
    case "ACTIVE_EMPLOYEE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "ACTIVE_EMPLOYEE_RESET":
      return {
        ...state,
        active: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const inActiveEmployeeReducer = (state = [], action) => {
  switch (action.type) {
    case "IN_ACTIVE_EMPLOYEE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "IN_ACTIVE_EMPLOYEE_SUCCESS":
      return {
        ...state,
        loading: false,
        inactive: action.payload,
      };
    case "IN_ACTIVE_EMPLOYEE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "IN_ACTIVE_EMPLOYEE_RESET":
      return {
        ...state,
        inactive: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// UPDATE PROFILE PIC

export const updateProfilePictureReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_PROFILE_PICTURE_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "UPDATE_PROFILE_PICTURE_SUCCESS":
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case "UPDATE_PROFILE_PICTURE_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_PROFILE_PICTURE_RESET":
      return {
        ...state,
        isUpdated: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const manageLeavesReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_EMPLOYEE_LEAVES_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "UPDATE_EMPLOYEE_LEAVES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
      };
    case "UPDATE_EMPLOYEE_LEAVES_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_EMPLOYEE_LEAVES_RESET":
      return {
        ...state,
        success: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Employee Payroll Reducer

export const EmployeePayrollsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_PAYROLLS_REQUEST":
      return {
        loading: true,
      };
    case "GET_EMPLOYEE_PAYROLLS_SUCCESS":
      return {
        ...state,
        loading: false,
        payrolls: action.payload.payrolls,
        payrollsCount: action.payload.payrollsCount,
        success: action.payload.success,
      };
    case "GET_EMPLOYEE_PAYROLLS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_EMPLOYEE_PAYROLLS_RESET":
      return {
        ...state,
        loading: false,
        payrolls: [],
        success: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL NOTIFICATIONS

export const employeeNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_ALL_NOTIFICATIONS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ALL_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        notifications: action.payload.notification,
        count: action.payload.count,
        error: null,
      };
    case "UPDATE_ALL_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        notifications: action.payload.notification,
        count: action.payload.count,
        error: null,
      };
    case "GET_ALL_NOTIFICATIONS_FAILED":
      return {
        ...state,
        loading: false,
        notifications: null,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const markNotificationAsRead = (state = {}, action) => {
  switch (action.type) {
    case "READ_NOTIFICATION_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "READ_NOTIFICATION_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
      };
    case "READ_NOTIFICATION_RESET":
      return {
        ...state,
        loading: false,
        success: false,
      };
    case "READ_NOTIFICATION_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
