import { Box, Button, InputLabel, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "../../../../../Components/Modal/Modal";
import { getAllLeaves } from "../../../../../Redux/Actions/HR";
import {
  clearErrors,
  createLeaveByHR,
} from "../../../../../Redux/Actions/Leaves";
import { errorNotify, successNotify } from "../../../../../Utils/toast";

const CreateLeaveModal = ({
  classes,
  modalOpen,
  setModalOpen,
  page,
  rowsPerPage,
  leaveData,
  setLeaveData,
}) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    leave,
    error: createError,
    success,
  } = useSelector((state) => state.createLeave);
  useEffect(() => {
    if (success) {
      successNotify(leave?.message);
      dispatch({ type: "CREATE_LEAVE_RESET" });
      setModalOpen(false);
      dispatch(getAllLeaves(page, rowsPerPage));
    }
    if (createError) {
      errorNotify(createError);
      dispatch(clearErrors());
    }
  }, [success, createError, leave]);
  const handleCreateLeave = () => {
    dispatch(createLeaveByHR(leaveData));
  };
  return (
    <ReactModal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create New Leave Type"
    >
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>Leave Type</InputLabel>
        <TextField
          sx={{ mt: 1 }}
          placeholder="Enter leave type"
          fullWidth
          className={classes.inputContainer}
          value={leaveData.leave_type}
          onChange={(e) =>
            setLeaveData({ ...leaveData, leave_type: e.target.value })
          }
        />
      </Box>
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>
          Leave Quota
        </InputLabel>
        <TextField
          sx={{ mt: 1 }}
          type="number"
          placeholder="Enter leave quota"
          fullWidth
          className={classes.inputContainer}
          value={leaveData.leave_quota}
          onChange={(e) =>
            setLeaveData({ ...leaveData, leave_quota: e.target.value })
          }
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            onClick={() => setModalOpen(false)}
            disabled={createLoading ? true : false}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.createBtn}
            onClick={() => handleCreateLeave()}
          >
            {createLoading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Box>
    </ReactModal>
  );
};

export default CreateLeaveModal;
