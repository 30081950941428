import api from "../../Utils/interceptors";

export const createDepartmentByHR =
  (departmentName, HODId) => async (dispatch) => {
    try {
      dispatch({
        type: "CREATE_DEPARTMENT_REQUEST",
      });

      const { data } = await api.post("department/create", {
        department_name: departmentName,
        head_of_department: HODId,
      });
      dispatch({
        type: "CREATE_DEPARTMENT_SUCCESS",
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: "CREATE_DEPARTMENT_FAILED",
        payload: e.response.data.message,
      });
    }
  };
export const getDepartmentDetails = (depId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_DEPARTMENTS_DETAILS_REQUEST",
    });
    const { data } = await api.get(`department/${depId}`);
    dispatch({
      type: "GET_DEPARTMENTS_DETAILS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_DEPARTMENTS_DETAILS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const updateDepartmentByHR =
  (departmentData, head_of_department) => async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_DEPARTMENT_REQUEST",
      });

      const { data } = await api.put(`department/${departmentData._id}`, {
        department_name: departmentData.department_name,
        head_of_department: head_of_department,
      });
      dispatch({
        type: "UPDATE_DEPARTMENT_SUCCESS",
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: "UPDATE_DEPARTMENT_FAILED",
        payload: e.response.data.message,
      });
    }
  };
export const deleteDepartmentByHR = (depId) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_DEPARTMENT_REQUEST",
    });

    const { data } = await api.delete(`department/${depId}`);
    dispatch({
      type: "DELETE_DEPARTMENT_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "DELETE_DEPARTMENT_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
