import { Button, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "../../../../../Components/Modal/Modal";
import { getAllLeaves } from "../../../../../Redux/Actions/HR";
import {
  clearErrors,
  updateLeaveByHR,
} from "../../../../../Redux/Actions/Leaves";
import { errorNotify, successNotify } from "../../../../../Utils/toast";

const UpdateLeaveModal = ({
  classes,
  updateModalOpen,
  setUpdateModalOpen,
  page,
  rowsPerPage,
  updateLeaveDetails,
  setUpdateLeaveDetails,
  loading,
}) => {
  const dispatch = useDispatch();
  const {
    loading: updateLoading,
    isUpdated,
    error: updateError,
  } = useSelector((state) => state.updateLeave);
  useEffect(() => {
    if (isUpdated?.success) {
      successNotify(isUpdated?.message);
      dispatch({ type: "UPDATE_LEAVE_RESET" });
      setUpdateModalOpen(false);
      dispatch(getAllLeaves(page, rowsPerPage));
      setUpdateLeaveDetails({
        _id: "",
        leave_type: "",
        leave_quota: "",
      });
    }
    if (updateError) {
      errorNotify(updateError);
      dispatch(clearErrors());
    }
  }, [isUpdated, updateError]);
  const handleUpdateLeave = () => {
    dispatch(updateLeaveByHR(updateLeaveDetails));
  };
  const handleCancel = () => {
    setUpdateModalOpen(false);
    setUpdateLeaveDetails({
      _id: "",
      leave_type: "",
      leave_quota: "",
    });
  };
  return (
    <ReactModal
      open={updateModalOpen}
      setOpen={setUpdateModalOpen}
      title="Create New Leave Type"
    >
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="large" animation="border" />
        </Box>
      ) : (
        <>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Leave Type
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Enter leave type"
              fullWidth
              className={classes.inputContainer}
              value={updateLeaveDetails.leave_type}
              onChange={(e) =>
                setUpdateLeaveDetails({
                  ...updateLeaveDetails,
                  leave_type: e.target.value,
                })
              }
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <InputLabel className={classes.department_label}>
              Leave Quota
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              type="number"
              placeholder="Enter leave quota"
              fullWidth
              className={classes.inputContainer}
              value={updateLeaveDetails.leave_quota}
              onChange={(e) =>
                setUpdateLeaveDetails({
                  ...updateLeaveDetails,
                  leave_quota: e.target.value,
                })
              }
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                className={classes.cancelBtn}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.createBtn}
                onClick={() => handleUpdateLeave()}
                disabled={updateLoading ? true : false}
              >
                {updateLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  "Update"
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </ReactModal>
  );
};

export default UpdateLeaveModal;
