import { Avatar, Typography } from "@mui/material";
import React, { useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import Modal from "../../../../Components/SiteModal/SiteModal";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import {
  deletePayrollById,
  getLastPayrollsOfEmployees,
} from "../../../../Redux/Actions/Payroll";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clearErrors } from "../../../../Redux/Actions/HR";

const PreviousPayrolls = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  payrolls,
  payrollsCount,
  isDownload,
}) => {
  const [payrollId, setPayrollId] = useState("");
  const [show, setShow] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, message, success } = useSelector(
    (state) => state.deletePayroll
  );
  let setPayrollData = [];
  payrolls &&
    payrolls.map((att, i) => {
      setPayrollData.push({
        _id: att?._id,
        name: att?.user,
        profilePic: att?.user?.profile_picture[0]?.fileName,
        date: `${att?.date?.month}/${att?.date?.year}`,
        lates: att?.lates,
        halfDays: att?.halfDays,
        absents: att?.calculatedAbsents,
        loanDeduction: att?.calculatedAbsents,
        noOfHolidays: att?.noOfHolidays,
        approvedLeaves: att?.approvedLeaves,
        salary: att?.actualSalary,
        netSalary: att.calculatedSalary,
        tax: att?.tax,
        deduction: att?.deduction,
      });
    });
  const handlePayrollID = (id) => {
    setPayrollId(id);
    setShow(!show);
  };
  useEffect(() => {
    if (success) {
      successNotify(message);
      dispatch({ type: "DELETE_PAYROLL_RESET" });
      setShow(false);
      dispatch(getLastPayrollsOfEmployees(page, rowsPerPage));
    }
    if (error) {
      errorNotify(error);
      dispatch(clearErrors());
    }
  }, [success, error]);

  const deleteModal = (
    <Modal
      title="Delete"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <p className="modal_Text">
          Are u sure you want to delete this Payroll?
        </p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(deletePayrollById(payrollId))}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button className="my-1 no_btn" onClick={() => setShow(false)}>
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );
  const payrollColumns = [
    {
      name: "_id",
      options: {
        display: false,
        print: false,
        download: false,
      },
    },
    {
      name: "profilePic",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value} />
            </div>
          );
        },
        download: false,
        print: false,
        display: false,
      },
    },

    {
      name: "name",
      label: "Employee",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={tableMeta.rowData[1]} />
              <Typography
                onClick={() => navigate(`/HR/employee/${value?.userId}`)}
                style={{
                  cursor: "pointer",
                }}
                sx={{ pl: 1 }}
                className="fs-12 link__color"
              >
                {value?.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "date",
      label: "Month/Year",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "absents",
      label: "Absents",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    // {
    //   name: "loanDeduction",
    //   label: "Loan Deduction",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "flex-start",
    //             alignItems: "center",
    //           }}
    //         >
    //           <Typography sx={{ pl: 1 }} className="fs-12">
    //             {value ? value : "-"}
    //           </Typography>
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "lates",
      label: "Lates",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "halfDays",
      label: "HalfDays",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "approvedLeaves",
      label: "Leaves",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "noOfHolidays",
      label: "Holidays(#)",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "tax",
      label: "Tax",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "salary",
      label: "Salary",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {Math.round(value)}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "netSalary",
      label: "NetSalary",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pl: 1 }} className="fs-12">
                {Math.round(value)}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(`/HR/payroll/${tableMeta.rowData[0]}`, {})
                }
              >
                <FaEye />
              </button>
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(`/HR/payroll/update/${tableMeta.rowData[0]}`)
                }
              >
                <FaEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() => handlePayrollID(tableMeta.rowData[0])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];
  return (
    <div className="leaves_management_table payroll_table mx-3">
      <MuiDataTable
        isDownload={isDownload}
        title={""}
        columns={payrollColumns}
        data={setPayrollData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={payrollsCount}
        tableId="previous_table"
      />
      {deleteModal}
    </div>
  );
};

export default PreviousPayrolls;
