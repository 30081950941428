import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";
import "./CreateEmployee.css";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { useForm } from "react-hook-form";
import { createUserForm } from "../../../../../Utils/Validation";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Header from "../../../../../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getAllDepartments,
  getAllEmployeesDropdown,
  getAllDesignations,
  getAllLeaves,
  getAllShifts,
} from "../../../../../Redux/Actions/HR";
import { Register } from "../../../../../Redux/Actions/HR";
import Loader from "../../../../../Utils/Loader";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import MetaData from "../../../../../Components/MetaData";
import InputMask from "react-input-mask";

const CreateEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDocuments, setUserDocuments] = useState({
    HSC: [],
    SSC: [],
    appointment_letter: [],
    bachelors_degree: [],
    office_policy: [],
    utility_bill: [],
    masters_degree: [],
    diploma_degree: [],
    experience_letter: [],
    last_payslip: [],
    resume: [],
    photo: [],
  });

  const [CNIC_pictures, setCNIC_pictures] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    dispatch(getAllDepartments());
    dispatch(getAllEmployeesDropdown());
    dispatch(getAllDesignations());
    dispatch(getAllLeaves());
    dispatch(getAllShifts());
  }, []);
  const { loading: departmentLoading, departments } = useSelector(
    (state) => state.departments
  );
  const { loading: employeeDropdownLoading, employeeDropdown } = useSelector(
    (state) => state.employeeDropdown
  );
  const { loading: designationLoading, designations } = useSelector(
    (state) => state.designations
  );
  const { loading: leavesLoading, leaves } = useSelector(
    (state) => state.leaves
  );
  const { loading: shiftsLoading, shifts } = useSelector(
    (state) => state.shifts
  );

  const { loading, isRegistered, error } = useSelector(
    (state) => state.register
  );
  useEffect(() => {
    if (isRegistered) {
      successNotify("Employee Created Successfully!");
      dispatch({ type: "REGISTER_RESET" });
      navigate("/HR/employees");
      // navigation(`/${userAuth?.employee.role}/employee`)
    } else if (error) {
      errorNotify(error);
      dispatch(clearErrors());
    }
  }, [isRegistered, error]);

  const onFileChangeHandler = (name) => (e) => {
    const files = Array.from(e.target.files);
    if (name === "CNIC_pictures") {
      setCNIC_pictures([]);
    }
    if (name === "certificates") {
      setCertificates([]);
    }
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          if (name === "CNIC_pictures") {
            setCNIC_pictures((old) => [...old, reader.result]);
          }
          if (name === "certificates") {
            setCertificates((old) => [...old, reader.result]);
          }
          setUserDocuments({
            ...userDocuments,
            [name]: reader.result,
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const getDocument = function document() {
    for (let checkDocument in userDocuments) {
      if (userDocuments[checkDocument].length === 0) return true;
    }
    return false;
  };

  const getCnic = function document() {
    if (CNIC_pictures.length === 0 || CNIC_pictures.length === 1) return true;

    return false;
  };

  const certificate = function document() {
    if (certificates.length === 0) return true;
    return false;
  };

  const onSubmitHandler = handleSubmit(async (data) => {
    let selectedLeaves = [];
    selectedOption &&
      selectedOption.forEach((leave) => {
        selectedLeaves.push(leave.value);
      });
    const userData = {
      ...data,
      leaves: selectedLeaves,
      documents: {
        CNIC_pictures: CNIC_pictures,
        HSC: userDocuments.HSC,
        SSC: userDocuments.SSC,
        appointment_letter: userDocuments.appointment_letter,
        bachelors_degree: userDocuments.bachelors_degree,
        office_policy: userDocuments.office_policy,
        utility_bill: userDocuments.utility_bill,
        masters_degree: userDocuments.masters_degree,
        diploma_degree: userDocuments.diploma_degree,
        certificates: certificates,
        experience_letter: userDocuments.experience_letter,
        last_payslip: userDocuments.last_payslip,
        resume: userDocuments.resume,
        photo: userDocuments.photo,
      },
      isDocumentVerified:
        (getDocument() || getCnic() || certificate()) === false ? true : false,
    };
    dispatch(Register(userData));
  });

  const options = [];
  leaves &&
    leaves.forEach((leave) => {
      let { __v, _id, ...rest } = leave;
      options.push({ value: rest, label: rest.leave_type });
    });

  const handleOffChange = (value) => {
    setSelectedOption(value);
  };

  return (
    <div className="page_responsive">
      <Header title="CREATE EMPLOYEE" />
      <MetaData title={`Create Employee -- HRM`} />

      <div className="create_emp_bg_background">
        <Container>
          {loading ? (
            <div>
              <Loader />
              <h4
                className="text-center"
                style={{
                  position: "absolute",
                  top: "55%",
                  left: "42%",
                  fontWeight: "600",
                  color: "rgb(37, 55, 102)",
                }}
              >
                Please Wait
              </h4>
            </div>
          ) : (
            <Form onSubmit={onSubmitHandler}>
              <Row className="m-0">
                <Col md={6} className="personal_details_container">
                  <div>
                    <div className="details_head">
                      <h5>Personal Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "full_name",
                                createUserForm.full_name
                              )}
                              placeholder="Enter Your Full Name"
                            />
                          </Input>
                          {errors.full_name ? (
                            <small className="text-danger">
                              {errors.full_name && errors.full_name.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Father/Husband Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "father_husband_name",
                                createUserForm.father_husband_name
                              )}
                              placeholder="Enter Your Full Name"
                            />
                          </Input>
                          {errors.father_husband_name ? (
                            <small className="text-danger">
                              {errors.father_husband_name &&
                                errors.father_husband_name.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              type="date"
                              {...register("DOB", createUserForm.DOB)}
                            />
                          </Input>
                          {errors.DOB ? (
                            <small className="text-danger">
                              {errors.DOB && errors.DOB.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Form.Label>Gender</Form.Label>
                          <div key={`inline-radio-1`} className="mb-3">
                            <Form.Check
                              inline
                              label="Male"
                              value={"male"}
                              type={"radio"}
                              id={`inline-radio-1`}
                              {...register("gender", createUserForm.gender)}
                            />
                            <Form.Check
                              inline
                              label="Female"
                              value={"female"}
                              type={"radio"}
                              id={`inline-radio-2`}
                              {...register("gender", createUserForm.gender)}
                            />
                            <br />
                            {errors.gender ? (
                              <small className="text-danger">
                                {errors.gender && errors.gender.message}
                              </small>
                            ) : null}
                          </div>
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              {...register(
                                "personal_email",
                                createUserForm.personal_email
                              )}
                              placeholder="Enter Your Email Address"
                            />
                          </Input>
                          {errors.personal_email ? (
                            <small className="text-danger">
                              {errors.personal_email &&
                                errors.personal_email.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Marital Status</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "marital_status",
                                createUserForm.marital_status
                              )}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Please Select Marital Status
                              </option>
                              <option value="Single">Single</option>
                              <option value="Married">Married</option>
                              <option value="Divorced">Divorced</option>
                            </Form.Select>
                          </Input>
                          {errors.marital_status ? (
                            <small className="text-danger">
                              {errors.marital_status &&
                                errors.marital_status.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Form.Label>CNIC</Form.Label>
                          <div className="nic__input_mask">
                            <InputMask
                              type={"text"}
                              placeholder="Enter Your CNIC"
                              {...register("CNIC", createUserForm.CNIC)}
                              mask="99999-9999999-9"
                              maskChar=" "
                            />
                          </div>
                          {errors.CNIC ? (
                            <small className="text-danger">
                              {errors.CNIC && errors.CNIC.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("phone", createUserForm.phone)}
                              placeholder="Enter Your Phone"
                            />
                          </Input>
                          {errors.phone ? (
                            <small className="text-danger">
                              {errors.phone && errors.phone.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={12} className="my-1">
                          <Input>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("address", createUserForm.address)}
                              placeholder="Enter Your Address"
                            />
                          </Input>
                          {errors.address ? (
                            <small className="text-danger">
                              {errors.address && errors.address.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Emergency Contact Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("ECE_name", createUserForm.ECE_name)}
                              placeholder="Enter Name"
                            />
                          </Input>
                          {errors.ECE_name ? (
                            <small className="text-danger">
                              {errors.ECE_name && errors.ECE_name.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Emergency Contact Number</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "ECE_number",
                                createUserForm.ECE_number
                              )}
                              placeholder="Enter Number"
                            />
                          </Input>
                          {errors.ECE_number ? (
                            <small className="text-danger">
                              {errors.ECE_number && errors.ECE_number.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={12} className="my-1">
                          <Input>
                            <Form.Label>
                              Relation With Emergency Contact
                            </Form.Label>
                            <Form.Control
                              type="text"
                              {...register("ECE_relation")}
                              placeholder="Enter relation name"
                            />
                          </Input>
                          {/* {errors.address ? (
                            <small className="text-danger">
                              {errors.address && errors.address.message}
                            </small>
                          ) : null} */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="my-1">
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Job Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("role", createUserForm.role)}
                            >
                              <option defaultValue={-1} key={-1} value={""}>
                                Please Select Role
                              </option>
                              <option value="HR">HR</option>
                              <option value="employee">Employee</option>
                              <option value="manager">Manager</option>
                            </Form.Select>
                          </Input>

                          {errors.role ? (
                            <small className="text-danger">
                              {errors.role && errors.role.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Skype Link</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "skype_link",
                                createUserForm.skype_link
                              )}
                              placeholder="Enter Your Skype Link"
                            />
                          </Input>
                          {errors.skype_link ? (
                            <small className="text-danger">
                              {errors.skype_link && errors.skype_link.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Salary</Form.Label>
                            <Form.Control
                              type="number"
                              {...register("salary", createUserForm.salary)}
                              placeholder="Enter Salary"
                            />
                          </Input>
                          {errors.salary ? (
                            <small className="text-danger">
                              {errors.salary && errors.salary.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Department</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "department",
                                createUserForm.department
                              )}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Department
                              </option>
                              {departments &&
                                departments?.map((department, i) => (
                                  <option value={department._id} key={i}>
                                    {department.department_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>

                          {errors.department ? (
                            <small className="text-danger">
                              {errors.department && errors.department.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Line Manager</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("manager", createUserForm.manager)}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Line Manager
                              </option>
                              {employeeDropdown &&
                                employeeDropdown?.employees?.map(
                                  (manager, i) => (
                                    <option value={manager._id} key={i}>
                                      {manager.full_name}
                                    </option>
                                  )
                                )}
                            </Form.Select>
                          </Input>

                          {errors.manager ? (
                            <small className="text-danger">
                              {errors.manager && errors.manager.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Designation</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "designation",
                                createUserForm.designation
                              )}
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Designation
                              </option>
                              {designations &&
                                designations?.map((designation, i) => (
                                  <option value={designation._id} key={i}>
                                    {designation.designation_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>
                          {errors.designation ? (
                            <small className="text-danger">
                              {errors.designation && errors.designation.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Date of Joining</Form.Label>
                            <Form.Control
                              type="date"
                              {...register(
                                "joining_date",
                                createUserForm.joining_date
                              )}
                            />
                          </Input>
                          {errors.joining_date ? (
                            <small className="text-danger">
                              {errors.joining_date &&
                                errors.joining_date.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Permanent Date</Form.Label>
                            <Form.Control
                              // pattern="\d{4}-\d{2}-\d{2}"
                              placeholder="DD-MM-YYYY"
                              type="date"
                              {...register(
                                "permanent_date",
                                createUserForm.permanent_date
                              )}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Webmail ID</Form.Label>
                            <Form.Control
                              type="email"
                              {...register("email", createUserForm.email)}
                              placeholder="Enter Webmail ID"
                            />
                          </Input>
                          {errors.email ? (
                            <small className="text-danger">
                              {errors.email && errors.email.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("password", createUserForm.password)}
                              placeholder="Enter password"
                            />
                          </Input>
                          {errors.password ? (
                            <small className="text-danger">
                              {errors.password && errors.password.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Shift Timing</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("shift", createUserForm.shift)}
                            >
                              <option defaultValue={-1} key={-1} value={""}>
                                Please Select Timing
                              </option>
                              {shifts &&
                                shifts.map((shift) => (
                                  <option value={shift._id}>
                                    {shift.shift_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>
                          {errors.shift ? (
                            <small className="text-danger">
                              {errors.shift && errors.shift.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Employment Status</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register(
                                "employment_status",
                                createUserForm.employment_status
                              )}
                            >
                              <option defaultValue={-1} key={-1} value={""}>
                                Please Select Employement
                              </option>
                              <option value="Internship">Internship</option>
                              <option value="Probation">Probation</option>
                              <option value="Contractual">Contractual</option>
                              <option value="Permanent">Permanent</option>
                            </Form.Select>
                          </Input>

                          {errors.employment_status ? (
                            <small className="text-danger">
                              {errors.employment_status &&
                                errors.employment_status.message}
                            </small>
                          ) : null}
                        </Col>

                        {/* <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Fuel Allowance</Form.Label>
                            <Form.Control
                              {...register(
                                "fuel_allowance",
                                createUserForm.fuel_allowance
                              )}
                              type="number"
                              placeholder="Enter Fuel Allowance"
                            />
                          </Input>
                          {errors.fuel_allowance ? (
                            <small className="text-danger">
                              {errors.fuel_allowance &&
                                errors.fuel_allowance.message}
                            </small>
                          ) : null}
                        </Col> */}
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Medical Status</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("medical_status")}
                              placeholder="Enter Medical Status"
                            />
                          </Input>
                          {/* {errors.line_manager ? (
                            <small className="text-danger">
                              {errors.line_manager &&
                                errors.line_manager.message}
                            </small>
                          ) : null} */}
                        </Col>

                        <Col md={6} className="my-1">
                          <Form.Label>Leaves</Form.Label>
                          <Select
                            value={selectedOption}
                            isMulti
                            onChange={handleOffChange}
                            options={options}
                          />
                          {errors.leaves_status ? (
                            <small className="text-danger">
                              {errors.leaves_status &&
                                errors.leaves_status.message}
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="bank_main">
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Bank Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Account Title Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "account_title",
                                createUserForm.account_title
                              )}
                              placeholder="Enter Account Title Name"
                            />
                          </Input>
                          {errors.account_title ? (
                            <small className="text-danger">
                              {errors.account_title &&
                                errors.account_title.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "account_number",
                                createUserForm.account_number
                              )}
                              placeholder="Enter Account Number"
                            />
                          </Input>
                          {errors.account_number ? (
                            <small className="text-danger">
                              {errors.account_title &&
                                errors.account_number.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Bank Name</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "bank_name",
                                createUserForm.bank_name
                              )}
                              placeholder="Enter Bank Name"
                            />
                          </Input>
                          {errors.account_number ? (
                            <small className="text-danger">
                              {errors.bank_name && errors.bank_name.message}
                            </small>
                          ) : null}
                        </Col>
                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Branch Name</Form.Label>
                            <Form.Control
                              {...register("branch", createUserForm.branch)}
                              type="text"
                              placeholder="Enter Bank Name"
                            />
                          </Input>
                          {errors.branch ? (
                            <small className="text-danger">
                              {errors.branch && errors.branch.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Bank Branch Address</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "bank_branch_address",
                                createUserForm.bank_branch_address
                              )}
                              placeholder="Enter Bank Branch Address"
                            />
                          </Input>
                          {errors.branch ? (
                            <small className="text-danger">
                              {errors.branch && errors.branch.message}
                            </small>
                          ) : null}
                        </Col>

                        <Col md={6} className="my-1">
                          <Input>
                            <Form.Label>Bank Branch Code</Form.Label>
                            <Form.Control
                              type="text"
                              {...register(
                                "bank_branch_code",
                                createUserForm.bank_branch_code
                              )}
                              placeholder="Enter Bank Branch Code"
                            />
                          </Input>
                          {errors.bank_branch_code ? (
                            <small className="text-danger">
                              {errors.bank_branch_code &&
                                errors.bank_branch_code.message}
                            </small>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="documents_main">
                  <div className="personal_details_container">
                    <div className="details_head">
                      <h5>Document Details</h5>
                    </div>

                    <div className="details_container">
                      <Row>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Resume</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("resume")}
                              placeholder="Enter Your Full Name"
                            />
                          </Input>
                        </Col>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Appointment Letter</Form.Label>
                            <Form.Control
                              onChange={onFileChangeHandler(
                                "appointment_letter"
                              )}
                              type="file"
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Office Policy</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("office_policy")}
                            />
                          </Input>
                        </Col>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Utility Bills</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("utility_bill")}
                            />
                          </Input>
                        </Col>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>CNIC Both Front/Back</Form.Label>
                            <Form.Control
                              multiple
                              type="file"
                              onChange={onFileChangeHandler("CNIC_pictures")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Passport Size Photo</Form.Label>
                            <Form.Control
                              multiple
                              type="file"
                              onChange={onFileChangeHandler("photo")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>HSC</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("HSC")}
                            />
                          </Input>
                        </Col>
                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>SSC</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("SSC")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Bachelor Degree</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("bachelors_degree")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Masters Degree</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("masters_degree")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Experience Letter</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler(
                                "experience_letter"
                              )}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Last PaySlip</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("last_payslip")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Certificates</Form.Label>
                            <Form.Control
                              type="file"
                              multiple
                              onChange={onFileChangeHandler("certificates")}
                            />
                          </Input>
                        </Col>

                        <Col md={6} className="my-2">
                          <Input>
                            <Form.Label>Diploma Degree</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={onFileChangeHandler("diploma_degree")}
                            />
                          </Input>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              <Col md={12}>
                <div className="d-flex justify-content-end py-3 update_employee_btn_container">
                  <Button type="submit">Create</Button>
                </div>
              </Col>
            </Form>
          )}
        </Container>
      </div>
    </div>
  );
};

export default CreateEmployee;
