import { Box, Button, Grid, InputLabel, TextField } from "@mui/material";
import React from "react";
import { Form, Spinner } from "react-bootstrap";
import CustomDatePicker from "../../../../Components/DatePicker/DatePicker";
import Input from "../../../../Components/Input/Input";
import Modal from "../../../../Components/Modal/Modal";
import { useStyle } from "../Attendance/attendance-styles";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { useEffect } from "react";
import { getAllEmployees } from "../../../../Redux/Actions/HR";
import { ticketsValidation } from "../../../../Utils/Validation";
import { errorNotify } from "../../../../Utils/toast";
import { createTicket } from "../../../../Redux/Actions/Tickets";

const TicketCreateModal = ({ modalOpen, setModalOpen }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [last_date, setEndDate] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const { employees, loading: employeeLoading } = useSelector(
    (state) => state.employees
  );
  const { loading, success } = useSelector((state) => state.CreateTicket);

  const { employees: filtered } = useSelector(
    (state) => state.filteredEmployees
  );

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);
  useEffect(() => {
    if (success) {
      reset();
      setEmployeeId("");
      setEndDate("");
    }
  }, [success]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmitHandler = handleSubmit(async (data) => {
    if (!employeeId) {
      errorNotify("Please select employee to assign ticket");
    } else if (!last_date) {
      errorNotify("Please enter valid last date");
    } else {
      const ticketData = {
        ...data,
        last_date: last_date,
        assignTo: employeeId,
      };
      dispatch(createTicket(ticketData));
    }
  });
  const handleEndDate = (dateValue) => {
    setEndDate(dateValue);
  };

  return (
    <Modal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create New Ticket"
      size="1000"
    >
      <React.Fragment>
        <Form onSubmit={onSubmitHandler}>
          <Box>
            <Grid container columnSpacing={7}>
              <Grid item md={6}>
                <InputLabel className={classes.department_label} sx={{ mb: 1 }}>
                  Assign To
                </InputLabel>
                <AutoCompleteSearch
                  data={filtered?.employees}
                  departmentData={employeeId}
                  setDepartmentData={setEmployeeId}
                  title={"Search Employee..."}
                />
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Input>
                    <Form.Label>Priority</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      {...register("priority", ticketsValidation.priority)}
                    >
                      <option defaultValue={-1} key={-1} value="">
                        Select Priority Level
                      </option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                      <option value="critical">Critical</option>
                    </Form.Select>
                  </Input>
                  {errors.priority ? (
                    <small className="text-danger">
                      {errors.priority && errors.priority.message}
                    </small>
                  ) : null}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ my: 2 }}>
                  <InputLabel className={classes.department_label}>
                    Ticket Subject
                  </InputLabel>
                  <TextField
                    sx={{ mt: 1 }}
                    placeholder="Enter Ticket Subject"
                    fullWidth
                    className={classes.inputContainer}
                    {...register("subject", ticketsValidation.subject)}
                  />

                  {errors.subject ? (
                    <small className="text-danger">
                      {errors.subject && errors.subject.message}
                    </small>
                  ) : null}
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ my: 1 }}>
                  <CustomDatePicker
                    label={"Date"}
                    value={last_date}
                    onChangeHandler={handleEndDate}
                  />
                </Box>
              </Grid>
              <Grid item md={12}>
                <Box sx={{ my: 1 }}>
                  <InputLabel className={classes.department_label}>
                    Ticket Description
                  </InputLabel>
                  <TextField
                    sx={{ mt: 1 }}
                    placeholder="Enter Ticket Description"
                    fullWidth
                    className={classes.inputContainer}
                    rows={2}
                    multiline
                    {...register("description", ticketsValidation.description)}
                  />
                  {errors.description ? (
                    <small className="text-danger">
                      {errors.description && errors.description.message}
                    </small>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Button
                variant="contained"
                type="button"
                className={classes.cancelBtn}
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading ? true : false}
                className={classes.createLeaveBtn}
              >
                {loading ? <Spinner size="sm" animation="border" /> : "Create"}
              </Button>
            </Box>
          </Box>
        </Form>
      </React.Fragment>
    </Modal>
  );
};

export default TicketCreateModal;
