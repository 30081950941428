import api from "../../Utils/interceptors";

export const createShiftByHR = (shiftData) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_SHIFT_REQUEST",
    });

    const { data } = await api.post("shift/create", shiftData);
    dispatch({
      type: "CREATE_SHIFT_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "CREATE_SHIFT_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const getShiftDetails = (shiftId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SHIFT_DETAILS_REQUEST",
    });
    const { data } = await api.get(`shift/${shiftId}`);
    dispatch({
      type: "GET_SHIFT_DETAILS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_SHIFT_DETAILS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const updateShiftByHR = (shiftData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_SHIFT_REQUEST",
    });

    const { data } = await api.put(`shift/${shiftData._id}`, {
      shift_name: shiftData.shift_name,
      start_time: shiftData.start_time,
      end_time: shiftData.end_time,
    });
    dispatch({
      type: "UPDATE_SHIFT_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "UPDATE_SHIFT_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const deleteShiftByHR = (shiftId) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_SHIFT_REQUEST",
    });

    const { data } = await api.delete(`shift/${shiftId}`);
    dispatch({
      type: "DELETE_SHIFT_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "DELETE_SHIFT_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: "CLEAR_ERRORS" });
};
