import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  sectionsContainer: {
    width: "100%",
    borderRadius: "35px !important",
    padding: "15px",
    boxShadow:
      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
    marginBottom: "30px",
  },
  sectionHeader: {
    background: "linear-gradient(to bottom, #0097d9, #00b1ff)",
    padding: "10px 20px",
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    color: "white",
    fontWeight: "700",
    fontSize: "20px",
  },
  leaveDetails: {
    "& h6": {
      color: "#253766",
      fontWeight: "600",
      fontSize: "15px",
    },
    "& p": {
      fontWeight: "400",
      fontSize: "14px",
    },
  },
  pending: {
    backgroundColor: "orange",
    color: "white",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "30px",
    width: "fit-content",
    padding: "3px 10px",
  },
  approved: {
    backgroundColor: "rgb(89, 198, 128)",
    color: "white",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "30px",
    width: "fit-content",
    padding: "3px 10px",
  },
  rejected: {
    backgroundColor: "#FE022C",
    color: "white",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "30px",
    width: "fit-content",
    padding: "3px 10px",
  },
  high: {
    backgroundColor: "#FE022C",
    color: "white",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "30px",
    width: "fit-content",
    padding: "3px 10px",
  },
  critical: {
    backgroundColor: "#FE022C",
    color: "white",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "30px",
    width: "fit-content",
    padding: "3px 10px",
  },
  low: {
    backgroundColor: "rgb(89, 198, 128)",
    color: "white",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "30px",
    width: "fit-content",
    padding: "3px 10px",
  },
  medium: {
    backgroundColor: "#f7941d",
    color: "white",
    fontSize: "12px",
    fontWeight: "700",
    borderRadius: "30px",
    width: "fit-content",
    padding: "3px 10px",
  },
  sendBtn: {
    background: "linear-gradient(to bottom, #0097d9, #00b1ff)",
    color: "white !important",
    padding: "10px 20px !important",
    // borderRadius: "25px !important",
    fontSize: "0.8rem !important",
    fontWeight: "600 !important",
    // boxShadow: "0px 15px 15px rgba(6,151,0,30%) !important",
    textTransform: "none !important",
    border: "none",
  },
  approvedBtn: {
    background: "linear-gradient(to bottom ,#0DC81F, #147D12) !important",
    color: "white !important",
    padding: "5px 15px !important",
    borderRadius: "25px !important",
    fontSize: "0.8rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(6,151,0,30%) !important",
    textTransform: "none !important",
  },
  rejectedBtn: {
    background: "#FE022C !important",
    color: "white !important",
    padding: "5px 15px !important",
    borderRadius: "25px !important",
    fontSize: "0.8rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(222,1,38,20%) !important",
    textTransform: "none !important",
  },
  on_hold_btn: {
    background: "#dbac01 !important",
    color: "white !important",
    padding: "5px 15px !important",
    borderRadius: "25px !important",
    fontSize: "0.8rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(222,1,38,20%) !important",
    textTransform: "none !important",
  },
  documents_container: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
    border: "1px solid #DEDEDE",
    padding: "10px",
    // borderTopLeftRadius: "50px",
    // borderBottomRightRadius: "50px",
    height: "auto",
    "& h6": {
      fontWeight: "500",
      // fontStyle: "italic",
      color: "rgb(37, 55, 102)",
      margin: "5px 0px",
      fontSize: "13px",
      textAlign: "center",
    },
    "& img": {
      margin: "0 3px",
      width: "97%",
      height: "200px",
      objectFit: "contain",
    },
  },
  department_box: {
    marginTop: "5px",
    backgroundColor: "#59c680",
    color: "white",
    width: "fit-content",
    padding: "3px 20px",
    borderRadius: "30px",
  },
});
