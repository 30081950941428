import React, { useEffect } from "react";
import { Container, Col, Row, Form, Spinner } from "react-bootstrap";
import Header from "../../../../../Components/Header/Header";
import "./Employees.css";
import { useForm } from "react-hook-form";
import Input from "../../../../../Components/Input/Input";
import Button from "../../../../../Components/Button/Button";
import { FiSearch } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ProfileImg from "../../../../../Asssets/Images/profile.png";
import { useState } from "react";
import {
  getAllDepartments,
  getAllDesignations,
  getAllEmployees,
  getAllFilteredEmployees,
} from "../../../../../Redux/Actions/HR";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../../../Utils/Loader";
import { MoreVert } from "@mui/icons-material";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import MetaData from "../../../../../Components/MetaData";
import { BsDot, BsTrashFill } from "react-icons/bs";
import {
  banEmployeeByID,
  deleteEmployeeByID,
  unBanEmployeeByID,
} from "../../../../../Redux/Actions/Employee";
import Modal from "../../../../../Components/SiteModal/SiteModal";
import { successNotify } from "../../../../../Utils/toast";
import AutoCompleteSearch from "../../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { BiSearch, BiReset } from "react-icons/bi";

const Employees = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [isSearchData, setIsSearchData] = useState(false);

  const [showBanModal, setShowBanModal] = useState(false);
  const [showUnBanModal, setShowUnBanModal] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [tagContent, setTagContent] = useState({
    employee_code: "",
    department: "",
    designation: "",
    id: "",
  });
  const {
    loading: employeeLoading,
    employees,
    moreLoading,
  } = useSelector((state) => state.employees);

  const { employees: filtered } = useSelector(
    (state) => state.filteredEmployees
  );

  const { loading: departmentLoading, departments } = useSelector(
    (state) => state.departments
  );
  const { loading: designationLoading, designations } = useSelector(
    (state) => state.designations
  );
  const { loading: deletedLoading, deletedEmployee } = useSelector(
    (state) => state.employeeDelete
  );
  const { loading: banLoading, banEmployee } = useSelector(
    (state) => state.banEmployee
  );
  const { loading: unBanLoading, unbanEmployee } = useSelector(
    (state) => state.unBanEmployee
  );
  const [employeeID, setEmployeeID] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(48);
  const [searchContent, setSearchContent] = useState({
    employee_code: "",
    full_name: "",
    department: "",
    designation: "",
    id: "",
  });
  const isAnyFieldFilled =
    searchContent.employee_code ||
    searchContent.full_name ||
    searchContent.department ||
    searchContent.designation ||
    searchContent.id;

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove particular data from localStorage
      window.sessionStorage.removeItem("searchContent");

      setSearchContent({
        employee_code: "",
        full_name: "",
        department: "",
        designation: "",
        id: "",
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getAllFilteredEmployees());
    dispatch(getAllDepartments());
    dispatch(getAllDesignations());
    const getstorageData = JSON.parse(
      window.sessionStorage.getItem("searchContent")
    );
    const { employee_code, full_name, department, designation } = searchContent;
    dispatch(
      getAllEmployees(
        page,
        size,
        getstorageData?.employee_code
          ? getstorageData?.employee_code
          : employee_code,
        getstorageData?.full_name ? getstorageData?.full_name : full_name,
        getstorageData?.department ? getstorageData?.department : department,
        getstorageData?.designation ? getstorageData?.designation : designation,
        getstorageData?.id ? getstorageData?.id : id
      )
    );

    if (getstorageData) {
      const fullName = filtered?.employees?.filter(
        (e) => e?._id === getstorageData?.id
      )[0]?.full_name;
      const departmentss = departments?.filter(
        (e) => e?._id === getstorageData?.department
      )[0]?.department_name;
      const designationss = designations?.filter(
        (e) => e?._id === getstorageData?.designation
      )[0]?.designation_name;

      setTagContent({
        employee_code,
        department: departmentss,
        designation: designationss,
        id: fullName,
      });
      if (
        tagContent?.employee_code !== "" ||
        tagContent?.department !== undefined ||
        tagContent?.designation !== undefined ||
        tagContent?.id !== undefined
      ) {
        setIsSearchData(true);
      } else {
        setIsSearchData(false);
      }
    }
  }, []);

  const addEmployeeBtn = (e) => {
    e.stopPropagation();
    navigate("/HR/employees/create");
  };

  const onSubmitHandler = handleSubmit(async (data) => {
    const { employee_code, full_name, department, designation } = data;
    setSearchContent({ employee_code, full_name, department, designation, id });
    setPage(0);
    if (
      searchContent.employee_code !== " " ||
      searchContent.full_name !== "" ||
      searchContent.department !== "" ||
      searchContent.designation !== "" ||
      searchContent.id !== ""
    ) {
      setIsSearchData(true);
      window.sessionStorage.setItem(
        "searchContent",
        JSON.stringify(searchContent)
      );
      const getstorageData = JSON.parse(
        window.sessionStorage.getItem("searchContent")
      );

      const fullName = filtered?.employees?.filter(
        (e) => e._id === getstorageData.id
      )[0]?.full_name;
      const department = departments?.filter(
        (e) => e?._id === getstorageData.department
      )[0]?.department_name;
      const designation = designations?.filter(
        (e) => e._id === getstorageData?.designation
      )[0]?.designation_name;

      setTagContent({
        employee_code,
        department: department,
        designation: designation,
        id: fullName,
      });

      dispatch(
        getAllEmployees(
          page,
          size,
          getstorageData.employee_code,
          getstorageData.full_name,
          getstorageData.department,
          getstorageData.designation,
          getstorageData.id
        )
      );
    }
  });
  const loadMoreSubmit = () => {
    const { employee_code, full_name, department, designation } = searchContent;
    setPage(page + 1);
    dispatch(
      getAllEmployees(
        page + 1,
        size,
        employee_code,
        full_name,
        department,
        designation,
        id,
        true,
        employees
      )
    );
  };

  useEffect(() => {
    const { employee_code, full_name, department, designation } = searchContent;
    if (deletedEmployee && deletedEmployee.success) {
      successNotify(deletedEmployee?.message);
      setShow(false);
      dispatch({ type: "DELETE_EMPLOYEE_RESET" });
      dispatch(
        getAllEmployees(
          page,
          size,
          employee_code,
          full_name,
          department,
          designation,
          id
        )
      );
    }
    if (banEmployee && banEmployee.success) {
      successNotify(banEmployee?.message);
      setShowBanModal(false);
      dispatch({ type: "BAN_EMPLOYEE_RESET" });
      dispatch(
        getAllEmployees(
          page,
          size,
          employee_code,
          full_name,
          department,
          designation,
          id
        )
      );
    }
    if (unbanEmployee && unbanEmployee.success) {
      successNotify(unbanEmployee?.message);
      setShowUnBanModal(false);
      dispatch({ type: "UN_BAN_EMPLOYEE_RESET" });
      dispatch(
        getAllEmployees(
          page,
          size,
          employee_code,
          full_name,
          department,
          designation,
          id
        )
      );
    }
  }, [deletedEmployee, banEmployee, unbanEmployee]);

  const handleEmployeeID = (id) => {
    setEmployeeID(id);
    setShow(!show);
  };
  const handleEmployeeBan = (id) => {
    setEmployeeID(id);
    setShowBanModal(!showBanModal);
  };
  const handleEmployeeUnBan = (id) => {
    setEmployeeID(id);
    setShowUnBanModal(!showUnBanModal);
  };

  const modal = (
    <Modal
      title="Delete"
      size={"lg"}
      show={show}
      onCloseModal={() => setShow(!show)}
    >
      <React.Fragment>
        <p className="modal_Text">
          Are you sure you want to delete this employee?
        </p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(deleteEmployeeByID(employeeID))}
          >
            {deletedLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button className="my-1 no_btn" onClick={() => setShow(false)}>
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );
  const BanModal = (
    <Modal
      title="Ban Employee"
      size={"lg"}
      show={showBanModal}
      onCloseModal={() => setShowBanModal(!showBanModal)}
    >
      <React.Fragment>
        <p className="modal_Text">
          Are you sure you want to Ban this employee?
        </p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(banEmployeeByID(employeeID))}
          >
            {banLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button className="my-1 no_btn" onClick={() => setShow(false)}>
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );
  const UnBanModal = (
    <Modal
      title="Un Ban Employee"
      size={"lg"}
      show={showUnBanModal}
      onCloseModal={() => setShowUnBanModal(!showUnBanModal)}
    >
      <React.Fragment>
        <p className="modal_Text">
          Are you sure you want to Ban this employee?
        </p>
        <div className="modal_decision">
          <button
            className="my-1 bg_red"
            onClick={() => dispatch(unBanEmployeeByID(employeeID))}
          >
            {unBanLoading ? <Spinner animation="border" size="sm" /> : "Yes"}
          </button>
          <button className="my-1 no_btn" onClick={() => setShow(false)}>
            No
          </button>
        </div>
      </React.Fragment>
    </Modal>
  );

  const clearSearch = () => {
    window.sessionStorage.removeItem("searchContent");
    setSearchContent({
      employee_code: "",
      full_name: "",
      department: "",
      designation: "",
      id: "",
    });

    setTagContent({
      employee_code: "",
      department: "",
      designation: "",
      id: "",
    });

    setIsFilter(!isFilter);
    setId("");
    setIsSearchData(false);

    const { employee_code, full_name, department, designation, id } =
      searchContent;
    dispatch(
      getAllEmployees(
        page,
        size,
        employee_code,
        full_name,
        department,
        designation,
        id
      )
    );

    dispatch(getAllFilteredEmployees());
    dispatch(getAllDepartments());
    dispatch(getAllDesignations());
  };

  return (
    <div className="page_responsive">
      <Header title="EMPLOYEE PROFILES" />
      <MetaData title={`Staff Management -- HRM`} />

      {employeeLoading || departmentLoading || designationLoading ? (
        <Loader />
      ) : (
        <Container fluid>
          <Row className="m-0">
            <Col md={12}>
              <div className="employees_main_selection">
                <Form onSubmit={onSubmitHandler}>
                  <Row className="align-items-start">
                    <Col xxl={10} xl={9}>
                      <Row className="gy-3">
                        <Col xxl={6} xl={6}>
                          <Input>
                            <Form.Label>Employee Code</Form.Label>
                            <Form.Control
                              type="text"
                              {...register("employee_code")}
                              onChange={(e) =>
                                setSearchContent({
                                  ...searchContent,
                                  employee_code: e.target.value,
                                  full_name: "",
                                  department: "",
                                  designation: "",
                                  id: "",
                                })
                              }
                              disabled={
                                !!isAnyFieldFilled &&
                                !searchContent.employee_code
                              }
                              placeholder="Enter Employee Code"
                            />
                          </Input>
                        </Col>
                        <Col xxl={6} xl={6}>
                          <Input>
                            <Form.Label>Employee Name</Form.Label>
                            <AutoCompleteSearch
                              data={filtered?.employees}
                              departmentData={id}
                              setDepartmentData={setId}
                              title={"Search Employee"}
                              disabled={!!isAnyFieldFilled && !searchContent.id}
                              setSearchContent={setSearchContent}
                              searchContent={searchContent}
                              isTrue
                            />
                          </Input>
                        </Col>
                        <Col xxl={6} xl={6}>
                          <Input>
                            <Form.Label>Select Department</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("department")}
                              onChange={(e) =>
                                setSearchContent({
                                  ...searchContent,
                                  employee_code: "",
                                  full_name: "",
                                  department: e.target.value,
                                  designation: "",
                                  id: "",
                                })
                              }
                              disabled={
                                !!isAnyFieldFilled && !searchContent.department
                              }
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Department
                              </option>
                              {departments &&
                                departments?.map((department, i) => (
                                  <option value={department._id} key={i}>
                                    {department.department_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>
                        </Col>
                        <Col xxl={6} xl={6}>
                          <Input>
                            <Form.Label>Select Designation</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("designation")}
                              onChange={(e) =>
                                setSearchContent({
                                  ...searchContent,
                                  employee_code: "",
                                  full_name: "",
                                  department: "",
                                  designation: e.target.value,
                                  id: "",
                                })
                              }
                              disabled={
                                !!isAnyFieldFilled && !searchContent.designation
                              }
                            >
                              <option defaultValue={-1} key={-1} value="">
                                Select Designation
                              </option>
                              {designations &&
                                designations?.map((designation, i) => (
                                  <option value={designation._id} key={i}>
                                    {designation.designation_name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Input>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <div className="d-flex align-items-end">
                          {isSearchData && (
                            <div
                              className="mx-2 rounded-2 p-2"
                              style={{
                                fontSize: "12px",
                                fontStyle: "italic",
                                background: "#efefef",
                                width: "fit-contain",
                              }}
                            >
                              <BiSearch />{" "}
                              <span className="mx-2">
                                {tagContent.id}
                                {tagContent.designation} {tagContent.department}
                                {tagContent.employee_code}
                              </span>
                            </div>
                          )}
                        </div>
                      </Row>
                    </Col>

                    {/* buttons */}
                    <Col xxl={2} xl={3}>
                      <Col className="d-flex justify-content-end mb-2">
                        <div className="add_employees_btn_container">
                          <button onClick={(e) => addEmployeeBtn(e)}>
                            {" "}
                            <AiOutlinePlus /> Add Employee
                          </button>
                        </div>
                      </Col>
                      <Col className="d-flex mb-2">
                        <div className="add_employees_btn_container">
                          <button onClick={() => navigate("/HR/wfh")}>
                            <AiOutlinePlus /> Work from home
                          </button>
                        </div>
                      </Col>
                      <Col className="d-flex">
                        <div className="search_btn_container">
                          <button>
                            <FiSearch />
                            Search
                          </button>
                        </div>
                      </Col>

                      <Col className="d-flex">
                        <div className="search_btn_container">
                          <button onClick={clearSearch}>
                            <BiReset />
                            Reset
                          </button>
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>

          <Row className="m-3 gy-5">
            {employees &&
              employees?.employees?.map((employee, index) => (
                <Col key={index} md={4}>
                  <div className="employee_card">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center">
                          {!employee?.ban && employee?.isActive ? (
                            <p
                              className="mb-0"
                              style={{
                                backgroundColor: "#1ab51a",
                                color: "#fff",
                                padding: "2px 10px 2px 10px",
                                marginLeft: "8px",
                                borderRadius: "20px",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              {/* <BsDot style={{fontSize:"20px"}}/> */}
                              Active
                            </p>
                          ) : !employee?.ban && !employee?.isActive ? (
                            <p
                              className="mb-0"
                              style={{
                                backgroundColor: "orange",
                                color: "#fff",
                                padding: "2px 10px 2px 10px",
                                marginLeft: "8px",
                                borderRadius: "20px",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              {/* <BsDot style={{fontSize:"20px"}}/> */}
                              Inactive
                            </p>
                          ) : (
                            <p
                              className="mb-0"
                              style={{
                                backgroundColor: "red",
                                color: "#fff",
                                padding: "2px 10px 2px 10px",
                                marginLeft: "8px",
                                borderRadius: "20px",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              {/* <BsDot style={{fontSize:"20px"}}/> */}
                              Ban
                            </p>
                          )}
                        </div>
                      </div>
                      <DropdownButton
                        className="edit_employee_dropdown"
                        id="dropdown-basic-button"
                        title={<MoreVert />}
                      >
                        <Dropdown.Item
                          eventKey={index}
                          onClick={() =>
                            navigate(`/HR/edit-employee/${employee?.userId}`)
                          }
                        >
                          <DriveFileRenameOutlineIcon className="btn_edit_Icon" />{" "}
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey={index}
                          onClick={() => handleEmployeeID(employee?.userId)}
                        >
                          <BsTrashFill className="btn_ban_Icon" /> Delete{" "}
                        </Dropdown.Item>
                        {!employee.ban ? (
                          <Dropdown.Item
                            eventKey={index}
                            onClick={() => handleEmployeeBan(employee?.userId)}
                          >
                            <NotInterestedIcon className="btn_ban_Icon" /> Ban{" "}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            eventKey={index}
                            onClick={() =>
                              handleEmployeeUnBan(employee?.userId)
                            }
                          >
                            <NotInterestedIcon className="btn_un_ban_Icon" />{" "}
                            Unban{" "}
                          </Dropdown.Item>
                        )}
                      </DropdownButton>
                    </div>
                    <div className="avatar_main">
                      <div className="avatar_circle">
                        <img
                          src={
                            employee?.profile_picture[0]?.fileName
                              ? employee?.profile_picture[0]?.fileName
                              : ProfileImg
                          }
                          alt="Avatar"
                        />
                      </div>

                      <div className="user_Info_container">
                        <h5>{employee.full_name}</h5>
                        <p>{employee.designation?.designation_name}</p>
                        <div className="text-center">
                          <button>
                            {employee.department?.department_name}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="user_details_container">
                      <div>
                        <h5>EMPLOYEE ID</h5>
                      </div>
                      <div>
                        <h6>{employee.employee_code}</h6>
                      </div>
                    </div>

                    <div className="user_details_container m-0">
                      <div>
                        <h5>DATE OF JOIN</h5>
                      </div>
                      <div>
                        <h6>
                          {moment(employee.joining_date).format("Do MMM YYYY")}
                        </h6>
                      </div>
                    </div>
                    <div className="employee_card_bottom">
                      <hr />
                      <div className="view_profile_btn">
                        <button
                          onClick={() =>
                            navigate(`/HR/employee/${employee.userId}`)
                          }
                        >
                          View Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}

            <Col md={12} className="mb-4">
              {employees?.totalEmployees !== employees?.employees?.length ? (
                <div className="text-center mt-2 py-2">
                  {moreLoading ? (
                    <Spinner animation="border" variant="info" />
                  ) : (
                    <Button
                      disabled={moreLoading}
                      onClick={() => loadMoreSubmit()}
                    >
                      {" "}
                      Load More
                    </Button>
                  )}
                </div>
              ) : employees?.totalEmployees === 0 ? (
                <h5 className="text-center">No records found!</h5>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Container>
      )}
      {modal}
      {BanModal}
      {UnBanModal}
    </div>
  );
};

export default Employees;
