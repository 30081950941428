import React, { useEffect, useState } from "react";
import Header from "../../../../Components/Header/Header";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import { FiSearch } from "react-icons/fi";
import { useForm } from "react-hook-form";
import "./TimeSheet.css";
import {
  getMonthlyTimesheetOfSelectedEmployee,
  getTodaysTimesheetOfAllEMployees,
} from "../../../../Redux/Actions/Timesheet";
import { useDispatch, useSelector } from "react-redux";
import { useStyle } from "../Attendance/attendance-styles";
import { getAllEmployees } from "../../../../Redux/Actions/HR";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { errorNotify } from "../../../../Utils/toast";
import TodayTimesheet from "./TodayTimesheet";
import Loader from "../../../../Utils/Loader";
import MonthlyTimesheet from "./MonthlyTimesheet";
import MetaData from "../../../../Components/MetaData";
import EditAttendance from "./EditAttendance";
import { useLocation } from "react-router-dom";
import { BiSearch, BiReset } from "react-icons/bi";
import Modal from "../../../../Components/SiteModal/SiteModal";
import CustomDatePicker from "../../../../Components/DatePicker/DatePicker";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import axios from "axios";
import { BaseURL } from "../../../../Utils/interceptors";
import { getToken } from "../../../../Utils/helper";
import { toast } from "react-toastify";
import { AiOutlinePlus } from "react-icons/ai";
import styleclass from "./TimeSheet.module.css";
import { IoMdDownload } from "react-icons/io";
import { CSVLink } from "react-csv";
import { RiPencilFill } from "react-icons/ri";
import moment from "moment";
const TimeSheet = () => {
  const dispatch = useDispatch();
  const [employeeId, setEmployeeId] = useState("");
  const [modifiedTimeSheet, setModifiedTimeSheet] = useState([]);
  const [editEmployeeId, setEditEmployeeId] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [saveDate, setSaveDate] = useState("");
  const [saveName, setSaveName] = useState("");
  const [pageSearch, setPageSearch] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [last_date, setEndDate] = useState("");
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    window.sessionStorage.removeItem("searchDate");
    window.sessionStorage.removeItem("searchName");

    setSaveDate("");
    setSaveName("");
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Remove particular data from localStorage
      window.sessionStorage.removeItem("searchDate");
      window.sessionStorage.removeItem("searchName");

      setSaveDate("");
      setSaveName("");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getTodaysTimesheetOfAllEMployees());
    return () => {
      dispatch({ type: "GET_MONTHLY_EMPLOYEE_TIMESHEET_RESET" });
    };
  }, [pageSearch]);

  let { loading, success, error, timesheet } = useSelector(
    (state) => state.todaysTimesheet
  );
  let {
    loading: monthlyTimesheetLoading,
    success: monthlyTimesheetSucess,
    error: monthlyTimesheetError,
    attendances,
  } = useSelector((state) => state.monthlyTimesheet);

  const onSubmitHandler = handleSubmit(async (data) => {
    let monthYear = data.month_year.split("-");

    if (pathname === "/HR/timesheet" && employeeId && data.month_year) {
      const employeeName = employees?.employees?.filter(
        (e) => e._id === employeeId
      )[0]?.full_name;
      window.sessionStorage.setItem("searchName", employeeName);
      window.sessionStorage.setItem("searchDate", data.month_year);

      const getDate = window.sessionStorage.getItem("searchDate");
      const getName = window.sessionStorage.getItem("searchName");

      setSaveDate(getDate);
      setSaveName(getName);
    }

    if (!data.month_year || !employeeId) {
      errorNotify("please select employee and month!");
    }
    const formData = {
      // employee: data?.employee,
      // department: data?.department,
      month: monthYear[1],
      year: monthYear[0],
    };
    dispatch(
      getMonthlyTimesheetOfSelectedEmployee({
        id: employeeId,
        month: formData.month,
        year: formData.year,
      })
    );
  });
  const { loading: employeeLoading, employees } = useSelector(
    (state) => state.employees
  );

  const classes = useStyle();

  const clearFilter = () => {
    window.sessionStorage.removeItem("searchDate");
    window.sessionStorage.removeItem("searchName");

    setSaveDate("");
    setSaveName("");
    setPageSearch(!pageSearch);
  };

  const { employees: filtered } = useSelector(
    (state) => state.filteredEmployees
  );
  const handleEndDate = (dateValue) => {
    setEndDate(dateValue);
  };

  const handleAttendanceSubmit = async () => {
    const url = `${BaseURL}/attendance/create/${editEmployeeId}`;
    const token = getToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    // Adjust the date
    const adjustedDate = new Date(last_date.getTime() + 5 * 60 * 60 * 1000); // Add 5 hours to the date
    const isoDateString = adjustedDate.toISOString();

    // Extract date part from isoDateString
    const isoDatePart = isoDateString.split("T")[0];

    // Combine date part with time part of checkIn and checkOut
    const checkInTime = new Date(checkIn).toISOString().split("T")[1];
    const checkOutTime = new Date(checkOut).toISOString().split("T")[1];

    // Construct check-in and check-out dates with adjusted date part
    const checkInDate = `${isoDatePart}T${checkInTime}`;
    let checkOutDate = `${isoDatePart}T${checkOutTime}`;

    // If check_out is before check_in, increment the date by 1
    if (new Date(checkOut) < new Date(checkIn)) {
      const nextDay = new Date(adjustedDate);
      nextDay.setDate(nextDay.getDate() + 1);
      const nextDayIsoDateString = nextDay.toISOString();
      const nextDayIsoDatePart = nextDayIsoDateString.split("T")[0];
      checkOutDate = `${nextDayIsoDatePart}T${checkOutTime}`;
    }

    const params = {
      date: isoDateString, // Unchanged
      check_in: checkInDate,
      check_out: checkOutDate,
    };
    for (let key in params) {
      if (params[key] === "") {
        return toast.error("Please fill all the fields");
      }
    }
    if (!editEmployeeId) {
      return toast.error("Please select an employee");
    }
    try {
      setIsLoading(true);
      const res = await axios.post(url, params, options);
      if (res !== undefined) {
        toast.success("Attendance created successfully");
        setModalOpen(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { position: "top-center" });
    }
    setIsLoading(false);
  };
  const CreateAttendanceModal = (
    <Modal
      title="Create Attendance"
      size={"md"}
      show={modalOpen}
      onCloseModal={() => setModalOpen(!modalOpen)}
    >
      <React.Fragment>
        <div className="downLoad__modal__wrapper">
          <Row className="gy-4">
            <Col md={12}>
              <label className="employee__name__label___">Employee Name</label>
              <AutoCompleteSearch
                data={filtered?.employees}
                departmentData={editEmployeeId}
                setDepartmentData={setEditEmployeeId}
                title={"Employee Name"}
              />
            </Col>

            <Col md={12}>
              <CustomDatePicker
                label={"Date"}
                value={last_date}
                onChangeHandler={handleEndDate}
              />
            </Col>

            <Col md={12}>
              <label className="employee__name__label___">Checkin</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={checkIn}
                  onChange={(newValue) => {
                    setCheckIn(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputContainerTime}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md={12}>
              <label className="employee__name__label___">Checkout</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={checkOut}
                  onChange={(newValue) => {
                    setCheckOut(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputContainerTime}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Col>
            <Col md={12}>
              <div className="createAtt__btn">
                <button
                  disabled={isLoading}
                  onClick={handleAttendanceSubmit}
                  type="button"
                >
                  {isLoading ? "Please wait..." : "Submit"}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </Modal>
  );

  const csvHeader = [
    {
      label: "Employee Name",
      key: !monthlyTimesheetSucess && timesheet ? "employeeName" : "name",
    },
    ...(!monthlyTimesheetSucess && timesheet
      ? [
          {
            label: "Department",
            key: "department",
          },
        ]
      : [
          {
            label: "Day",
            key: "day",
          },
          {
            label: "Date",
            key: "date",
          },
        ]),
    {
      label: "Check In",
      key: "check_in",
    },
    {
      label: "Check In Remarks",
      key: "check_in_remarks",
    },
    {
      label: "Check Out",
      key: "check_out",
    },
    {
      label: "Check Out Remarks",
      key: "check_out_remarks",
    },
    {
      label: "Late Time",
      key: "late_time",
    },
    {
      label: "Early Off",
      key: "early_off",
    },
    {
      label: "Total Hours",
      key: "totalHours",
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  return (
    <>
      <div className="page_responsive">
        <div className="time_sheet_main">
          <Header title="EMPLOYEES TIMESHEET" />
          <MetaData title={`Timesheet -- HRM`} />

          <Container fluid>
            <Row className="m-0">
              <Col md={12}>
                <div className="employees_main_selection">
                  <Form onSubmit={onSubmitHandler}>
                    <Row>
                      <Col md={4}>
                        <Input>
                          <Form.Label>Employee</Form.Label>
                          <AutoCompleteSearch
                            data={filtered?.employees}
                            departmentData={employeeId}
                            setDepartmentData={setEmployeeId}
                            title={"Search Employee"}
                          />
                        </Input>
                      </Col>

                      <Col md={3}>
                        <Input>
                          <Form.Label>Month/Year</Form.Label>
                          <Form.Control
                            type="month"
                            aria-label="Default select example"
                            {...register("month_year")}
                          ></Form.Control>
                        </Input>
                      </Col>
                      <Col
                        md={5}
                        style={{ justifyContent: "end" }}
                        className="d-flex gap-2"
                      >
                        <div>
                          <div className="search_btn_container">
                            <button type="submit">
                              <FiSearch />
                              Search
                            </button>
                          </div>
                          <div className="search_btn_container">
                            <button type="button" onClick={clearFilter}>
                              <BiReset />
                              Reset Search
                            </button>
                          </div>
                        </div>
                        <div>
                          <div className="search_btn_container">
                            <button
                              className="create__attendence__btn__color"
                              onClick={() => setModalOpen(true)}
                              type="button"
                            >
                              <AiOutlinePlus style={{ marginRight: "0px" }} />
                              Create Attendance
                            </button>
                          </div>
                          <div className="search_btn_container">
                            <button
                              type="button"
                              onClick={() => setEditModalOpen(true)}
                            >
                              <RiPencilFill />
                              Edit Attendance
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div className="d-flex align-items-end mt-2">
                    {saveName && (
                      <div
                        className="mx-2 rounded-2 p-2"
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          background: "#efefef",
                          width: "fit-contain",
                        }}
                      >
                        <BiSearch /> <span className="mx-2">{saveName}</span>
                      </div>
                    )}
                    {saveDate && (
                      <div
                        className="mx-2 rounded-2 p-2"
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          background: "#efefef",
                          width: "fit-contain",
                        }}
                      >
                        <BiSearch /> <span className="mx-2">{saveDate}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Col>

              {/* {allPayrollsData?.length > 0 && ( */}
              <div
                style={
                  loading || monthlyTimesheetLoading
                    ? { pointerEvents: "none" }
                    : {}
                }
                className="download__wrapper"
              >
                <CSVLink
                  className={"download__btn__main__timesheet"}
                  data={
                    !monthlyTimesheetSucess && timesheet
                      ? timesheet?.map((item, index) => ({
                          employeeName: item?.user?.full_name,
                          department:
                            item?.user.department?.department_name ||
                            item?.user?.department,
                          check_in: item?.check_in
                            ? new Date(item?.check_in)
                                .toLocaleString()
                                .split(",")[1]
                            : "",
                          check_in_remarks: item?.check_in_remarks,
                          check_out: item?.check_out
                            ? new Date(item?.check_out)
                                .toLocaleString()
                                .split(",")[1]
                            : "",
                          check_out_remarks: item?.check_out_remarks,
                          late_time: item?.late_time,
                          early_off: item?.early_off,
                          overtime: item?.overtime,
                          totalHours: item?.totalHours,
                          status: item?.status,
                        }))
                      : attendances?.map((att, i) => ({
                          name: att?.user?.name,
                          department: att?.user?.department,
                          date: att?.date
                            ? new Date(att?.date).toLocaleDateString("en-GB")
                            : null,
                          day: att?.day,
                          check_in: att?.check_in
                            ? new Date(att?.check_in)
                                .toLocaleString()
                                .split(",")[1]
                            : "",
                          check_in_remarks: att?.check_in_remarks,
                          check_out: att?.check_out
                            ? new Date(att?.check_out)
                                .toLocaleString()
                                .split(",")[1]
                            : "",
                          check_out_remarks: att?.check_out_remarks,
                          late_time: att?.late_time,
                          early_off: att?.early_off,
                          overtime: att?.overtime,
                          totalHours: att?.totalHours,
                          status: att?.status,
                        })) || []
                  }
                  headers={csvHeader}
                  filename={
                    !saveName
                      ? `Employees_Timesheet_${moment(new Date()).format(
                          "DD-MM-YYYY"
                        )}`
                      : `Timesheet_${saveName}_${saveDate
                          ?.split("-")
                          ?.reverse()
                          .join("-")}`
                  }
                >
                  <IoMdDownload size={22} /> Download CSV
                </CSVLink>
              </div>
              {/* )} */}

              <Col md={12}>
                {loading || monthlyTimesheetLoading ? (
                  <Loader />
                ) : (
                  <div className="time_sheet_table">
                    {!monthlyTimesheetSucess && timesheet ? (
                      <TodayTimesheet classes={classes} timesheet={timesheet} />
                    ) : (
                      <MonthlyTimesheet
                        classes={classes}
                        attendances={attendances}
                      />
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <EditAttendance
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        editEmployeeId={editEmployeeId}
        setEditEmployeeId={setEditEmployeeId}
      />
      {modalOpen && CreateAttendanceModal}
    </>
  );
};

export default TimeSheet;
