import React, { useEffect, useState } from "react";
import ReactModal from "../../../../Components/Modal/Modal";
import CheckOut from "../../../../Asssets/Images/CheckOutClock.png";
import { Box } from "@mui/system";
import Clock from "react-live-clock";
import Timer from "../../../../Asssets/Images/Timer.png";
import { Button, InputLabel, TextField } from "@mui/material";
import {
  getEmployeeCurrentMonthAttendance,
  getLastAttendance,
  submitCheckOut,
} from "../../../../Redux/Actions/Attendance";
import { useDispatch, useSelector } from "react-redux";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { clearErrors } from "../../../../Redux/Actions/HR";
import { Spinner } from "react-bootstrap";

const CheckOutModal = ({ classes, modalOpen, setModalOpen }) => {
  const [checkOutRemarks, setCheckOutRemarks] = useState("");
  const [ipAddress, setIPAddress] = useState("");
  const dispatch = useDispatch();
  const { loading, checkOut, error, success } = useSelector(
    (state) => state.checkOut
  );
  useEffect(() => {
    if (success) {
      dispatch({ type: "CHECK_OUT_RESET" });
      successNotify(checkOut?.message);
      setModalOpen(false);
      dispatch(getLastAttendance());
      dispatch(getEmployeeCurrentMonthAttendance());
    }
    if (error) {
      errorNotify(error);
      dispatch(clearErrors());
    }
  }, [success, checkOut, error]);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  const checkOutHandle = () => {
    dispatch(submitCheckOut({ checkOutRemarks, ipAddress }));
  };
  return (
    <>
      <ReactModal open={modalOpen} setOpen={setModalOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={CheckOut}
            alt=""
            style={{ width: "90px", height: "90px", marginTop: "-34px" }}
          />
          <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
            <img src={Timer} style={{ width: "32px" }} />
            <Clock
              format={"HH:mm:ss A"}
              ticking={true}
              timezone={"Asia/Karachi"}
            />
          </Box>
          <Box sx={{ my: 1, width: "95%" }}>
            <InputLabel className={classes.department_label}>
              Check Out Remarks
            </InputLabel>
            <TextField
              sx={{ mt: 1 }}
              placeholder="Remarks 200 characters (Max)"
              fullWidth
              className={classes.inputContainer}
              rows={3}
              multiline
              disabled={loading ? true : false}
              value={checkOutRemarks}
              onChange={(e) => setCheckOutRemarks(e.target.value)}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 4 }}
              className={classes.checkOutBtn}
              onClick={() => checkOutHandle()}
            >
              {loading ? <Spinner size="sm" animation="border" /> : "Check Out"}
            </Button>
          </Box>
        </Box>
      </ReactModal>
    </>
  );
};

export default CheckOutModal;
