import { makeStyles } from "@mui/styles";
import LoginBG from "../../../src/Asssets/Images/LoginBG.png";
export const useStyle = makeStyles({
  loginContainer: {
    width: "100vw",
    maxWidth: "100%",
    height: "100vh",
    backgroundImage: `url(${LoginBG})`,
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  inputContainer: {
    margintop: "4px",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      border: "0.5px solid rgba(235,235,235,90%) !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px !important",
      backgroundColor: "#F3F8FE",
      border: "none !important",
      // border: "1px solid #EBEBEB !important"
    },
  },
  loginCard: {
    width: "100%",
    maxWidth: "700px",
    height: "70vh",
    maxHeight: "600px",
    borderRadius: "30px !important",
    boxShadow: "0px 9px 15px rgba(0,174,250,20%) !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
