import axios from "axios";
import { getTokenFormat } from "../../Utils/helper";

import api from "../../Utils/interceptors";

export const getEmployeeDashboardData = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_EMPLOYEE_DASHBOARD_DATA_REQUEST",
    });
    const { data } = await api.get("auth/user/employee-dashboard");
    dispatch({
      type: "GET_EMPLOYEE_DASHBOARD_DATA_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_EMPLOYEE_DASHBOARD_DATA_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
// Update Employee Reducer

export const updateEmployeeById = (id, updatedData) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_EMPLOYEE_REQUEST",
    });

    const { data } = await axios.put(
      `/employee/${id}`,
      updatedData,
      getTokenFormat(),
    );

    dispatch({
      type: "UPDATE_EMPLOYEE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "UPDATE_EMPLOYEE_FAIL",
      payload: e.response.data.message,
    });
  }
};

//  Delete Employee By ID

export const deleteEmployeeByID = (employee_id) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_EMPLOYEE_REQUEST",
    });
    const { data } = await api.delete(`/employee/${employee_id}`);
    dispatch({
      type: "DELETE_EMPLOYEE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "DELETE_EMPLOYEE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const banEmployeeByID = (employee_id) => async (dispatch) => {
  try {
    dispatch({
      type: "BAN_EMPLOYEE_REQUEST",
    });
    const { data } = await api.put(`/employee/ban/${employee_id}`, {
      ban: true,
    });
    dispatch({
      type: "BAN_EMPLOYEE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "BAN_EMPLOYEE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const unBanEmployeeByID = (employee_id) => async (dispatch) => {
  try {
    dispatch({
      type: "UN_BAN_EMPLOYEE_REQUEST",
    });
    const { data } = await api.put(`/employee/ban/${employee_id}`, {
      ban: false,
    });
    dispatch({
      type: "UN_BAN_EMPLOYEE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "UN_BAN_EMPLOYEE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const activeEmployeeByID = (employee_id) => async (dispatch) => {
  try {
    dispatch({
      type: "ACTIVE_EMPLOYEE_REQUEST",
    });
    const { data } = await api.put(`/employee/active/${employee_id}`, {
      isActive: true,
    });
    dispatch({
      type: "ACTIVE_EMPLOYEE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "ACTIVE_EMPLOYEE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const inActiveEmployeeByID = (employee_id) => async (dispatch) => {
  try {
    dispatch({
      type: "IN_ACTIVE_EMPLOYEE_REQUEST",
    });
    const { data } = await api.put(`/employee/active/${employee_id}`, {
      isActive: false,
    });
    dispatch({
      type: "IN_ACTIVE_EMPLOYEE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "IN_ACTIVE_EMPLOYEE_FAILED",
      payload: error.response?.data?.message,
    });
  }
};

// UPDATE PROFILE PIC BY ID

export const updateProfilePictureById =
  (id, profile_picture) => async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_PROFILE_PICTURE_REQUEST",
      });

      const { data } = await axios.put(
        `/employee/profilePic/${id}`,
        { profile_picture },
        getTokenFormat(),
      );

      dispatch({
        type: "UPDATE_PROFILE_PICTURE_SUCCESS",
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: "UPDATE_PROFILE_PICTURE_FAIL",
        payload: e.response.data.message,
      });
    }
  };

export const updateEmployeeLeaves = (id, leaves) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_EMPLOYEE_LEAVES_REQUEST",
    });

    const { data } = await api.put(`/employee/leaves/${id}`, { leaves });

    dispatch({
      type: "UPDATE_EMPLOYEE_LEAVES_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "UPDATE_EMPLOYEE_LEAVES_FAIL",
      payload: e.response.data.message,
    });
  }
};

// Create PayRoll

export const getEmployeePayrolls =
  (user_id = "", page, size, month = "", year = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_EMPLOYEE_PAYROLLS_REQUEST",
      });

      let link = `payrolls/employee/${user_id}?page=${page}&size=${size}`;
      if (month === "" || year === "") {
        link = `payrolls/employee/${user_id}?page=${page}&size=${size}`;
      } else {
        link = `payrolls/employee/${user_id}?page=${page}&size=${size}&date=${`${month},${year}`}`;
      }

      const { data } = await api.get(link);
      dispatch({
        type: "GET_EMPLOYEE_PAYROLLS_SUCCESS",
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: "GET_EMPLOYEE_PAYROLLS_FAILED",
        payload: e.response.data.message,
      });
    }
  };

// MY NOTIFICATIONS

export const getAllNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ALL_NOTIFICATIONS_REQUEST",
    });
    const { data } = await api.get("notifications/my");
    dispatch({
      type: "GET_ALL_NOTIFICATIONS_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "GET_ALL_NOTIFICATIONS_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
export const readNotifications = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "READ_NOTIFICATION_REQUEST",
    });
    const { data } = await api.put(`notification/read/${id}`, {});
    dispatch({
      type: "READ_NOTIFICATION_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "READ_NOTIFICATION_FAILED",
      payload: error.response?.data?.message,
    });
  }
};
