import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Input from "../../../../Components/Input/Input";
import { FiSearch } from "react-icons/fi";
import { AiOutlinePlus } from "react-icons/ai";
import AutoCompleteSearch from "../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { getAllFilteredEmployees } from "../../../../Redux/Actions/HR";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../Components/Header/Header";
import MetaData from "../../../../Components/MetaData";
import {
  getAllRequests,
  getCurrentUserRequests,
  indivisualRequestDeleted,
} from "../../../../Redux/Actions/Requests";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import Loader from "../../../../Utils/Loader";
import { Avatar, Typography } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import AddRequestModal from "../../../HR/Pages/RequestModule/AddRequestModal";
import { useStyle } from "../../../HR/Pages/Attendance/attendance-styles";
import style from "./Requests.module.css";
import { BaseURL } from "../../../../Utils/interceptors";
import { getToken } from "../../../../Utils/helper";
import axios from "axios";
import { toast } from "react-toastify";

const Requests = ({ user }) => {
  const [addModal, setAddModal] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [employeeRequest, setEmployeeRequest] = useState([]);
  const [tabsText, setTabsText] = useState("my");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyle();
  const { loading, requests, error, requestCount } = useSelector(
    (state) => state.currentUserRequest
  );
  const {
    err: userError,
    user: employee,
    loading: userLoading,
  } = useSelector((state) => state.currentUser);
  const { success } = useSelector((state) => state.createRequest);
  const {
    deleted,
    loading: deletedLoading,
    error: deleteError,
  } = useSelector((state) => state.deleteRequest);

  useEffect(() => {
    dispatch(getCurrentUserRequests(page, size));
  }, [page, size]);
  useEffect(() => {
    if (success) {
      setAddModal(false);
      dispatch(getCurrentUserRequests(page, size));
    }
    if (deleted?.success) {
      successNotify(deleted?.message);
      dispatch({ type: "INDIVISUAL_REQUEST_DELETE_RESET" });
      dispatch(getCurrentUserRequests(page, size));
    }
    if (deleteError) {
      errorNotify(deleteError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [success, deleted?.success, deleteError]);
  const handleRequestDelete = (e, id) => {
    e.preventDefault();
    dispatch(indivisualRequestDeleted(id));
  };
  // console.log(requests)
  const ticketsColumns = [
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "request_by",
      label: "Request By",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value ? value.full_name : "-"}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "subject",
      label: "subject",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1, margin: "0 auto" }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },

    {
      name: "request_date",
      label: "Request Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "line_manager_status",
      label: "HOD Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                  ? classes.absent
                  : value === "pending"
                  ? classes.halfDay
                  : classes.late
              }`}
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              // className="fs-12"
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "hr_status",
      label: "HR Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              className={`${
                value === "approved"
                  ? classes.present
                  : value === "rejected"
                  ? classes.absent
                  : value === "pending"
                  ? classes.halfDay
                  : classes.late
              }`}
              sx={{ fontSize: "10px", pl: 1, fontWeight: "600" }}
              // className="fs-12"
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    // onClick={() => navigate(`/HR/leave-management/${tableMeta.rowData[1]}`)}
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(`/${user}/request/${tableMeta.rowData[0]}`, {
                    state: {
                      update: true,
                    },
                  })
                }
              >
                <FaEye />
              </button>
              {/* onClick={() => handleLeaveID(tableMeta.rowData[1])} */}
              <button
                className="delete_btn"
                onClick={(e) => handleRequestDelete(e, tableMeta.rowData[0])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];

  const getEmployeesReuqest = async () => {
    const url = `${BaseURL}/requests/assigned?page=${page}&size=${size}`;
    const token = getToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };
    try {
      setIsLoading(true);
      const res = await axios.get(url, options);
      if (res !== undefined) {
        setEmployeeRequest(res?.data?.requests);
        setTotalCount(res?.data?.requestsCount);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getEmployeesReuqest();
  }, []);

  return (
    <>
      <div className="page_responsive">
        <Header title="Requests" />
        <MetaData title={`Request Module -- HRM`} />
        <Container fluid>
          <Row className="m-0">
            <Col md={12}>
              <div className="employees_main_selection">
                <Form>
                  <Row className="align-items-end justify-content-end">
                    {/* <Col md={2}>
                      <Input>
                        <Form.Label>Employee Id</Form.Label>
                        <Form.Control
                          type="text"
                        //   {...register("employee_code")}
                          placeholder="Enter Employee Id"
                        />
                      </Input>
                    </Col> */}

                    <Col md={6}>
                      <div className={style.btn__wrapper}>
                        {employee?.user?.isLineManager && (
                          <>
                            <Button
                              style={
                                tabsText == "my"
                                  ? {
                                      background:
                                        "linear-gradient(to bottom, #0097d9, #00b1ff)",
                                      color: "#fff",
                                    }
                                  : {}
                              }
                              onClick={() => setTabsText("my")}
                            >
                              My Requests
                            </Button>
                            <Button
                              style={
                                tabsText == "employee"
                                  ? {
                                      background:
                                        "linear-gradient(to bottom, #0097d9, #00b1ff)",
                                      color: "#fff",
                                    }
                                  : {}
                              }
                              onClick={() => setTabsText("employee")}
                            >
                              Employees Requests
                            </Button>
                          </>
                        )}
                      </div>
                    </Col>

                    <Col md={6} className="d-flex gap-3 justify-content-end">
                      <div
                        className="add_employees_btn_container"
                        style={{ width: "fit-content" }}
                      >
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setAddModal(true);
                          }}
                        >
                          {" "}
                          <AiOutlinePlus /> Add Request
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col md={12}>
              {loading || isLoading ? (
                <Loader />
              ) : (
                <div className="tickets_management_table">
                  <MuiDataTable
                    title={"ALL TICKETS"}
                    columns={ticketsColumns}
                    data={tabsText === "my" ? requests : employeeRequest}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={size}
                    setRowsPerPage={setSize}
                    count={tabsText === "my" ? requestCount : totalCount}
                    tableId="requests_table"
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <AddRequestModal modalOpen={addModal} setModalOpen={setAddModal} />
    </>
  );
};

export default Requests;
