import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Header from "../../../../Components/Header/Header";
import Input from "../../../../Components/Input/Input";
import MetaData from "../../../../Components/MetaData";
import { BiSearchAlt } from "react-icons/bi";
import SearchedPayrolls from "./SearchedPayroll";
import "./Payroll.css";
import { getEmployeePayrolls } from "../../../../Redux/Actions/Employee";
import { useDispatch, useSelector } from "react-redux";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { toast } from "react-toastify";
import api from "../../../../Utils/interceptors.js";
import RebnderModal from "../../../../Components/RebnderModal/index.js";
import Loader from "../../../../Utils/Loader";

const EmployeePayroll = () => {
  const dispatch = useDispatch();
  const [employeeId, setEmployeeId] = useState("");
  const [date, setDate] = useState("");
  const { user, loading } = useSelector((state) => state.currentUser);
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const {
    loading: payrollLoading,
    error,
    payrolls,
    payrollsCount,
  } = useSelector((state) => state.EmployeePayrolls);
  const [payrollsPage, setPayrollsPage] = useState(0);
  const [payrollsRowsPerPage, setPayrollsRowsPerPage] = useState(5);

  // GET Current User

  //   useEffect(() => {
  //     dispatch(getCurrentUser());
  //   }, []);

  useEffect(() => {
    if (user?.success && isShow) {
      dispatch(
        getEmployeePayrolls(user?.user?._id, payrollsPage, payrollsRowsPerPage),
      );
    }
  }, [user?.success, payrollsPage, payrollsRowsPerPage, isShow]);

  // Employee Payroll Creation Success

  useEffect(() => {
    if (payrolls?.success && isShow) {
      successNotify(payrolls?.message);
      dispatch({ type: "GET_EMPLOYEE_PAYROLLS_RESET" });
    }
    if (error && isShow) {
      errorNotify(error?.message);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [payrolls?.success, error, isShow]);

  const handleCreatePayroll = (e) => {
    e.preventDefault();
    if (!employeeId && !date) {
      errorNotify("please fill the field to search payroll!");
    } else if (date) {
      dispatch(
        getEmployeePayrolls(
          user?.user?._id,
          payrollsPage,
          payrollsRowsPerPage,
          date.split("-")[1],
          date.split("-")[0],
        ),
      );
    } else {
      dispatch(
        getEmployeePayrolls(user?.user?._id, payrollsPage, payrollsRowsPerPage),
      );
    }
  };

  const handlePinCode = async (otp) => {
    try {
      setLoader(true);
      const { data } = await api.post("auth/user/check-pincode", {
        pinCode: otp,
      });
      if (data?.success) {
        setIsShow(true);
        setModalOpen(false);
        toast.success("Success");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }

    setLoader(false);
  };
  return (
    <div className="page_responsive">
      <MetaData title={`Payroll -- HRM`} />
      <Header title="PAYROLL" />
      {isShow && <Container fluid>
        <Row className="m-0">
          <Col md={12}>
            <div className="employees_main_selection">
              <Form onSubmit={handleCreatePayroll}>
                <Row>
                  <Col md={12}>
                    <div className="monthly_year_container">
                      <Input>
                        <Form.Label>Month/Year</Form.Label>
                        <Form.Control
                          type="month"
                          aria-label="Default select example"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        ></Form.Control>
                      </Input>
                      <div className="payroll__search_btn">
                        <button
                          type="submit"
                          disabled={payrollLoading ? true : false}
                        >
                          <BiSearchAlt /> Search
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {payrollLoading || loading ? (
              <Loader animation={"border"} size={"sm"} />
            ) : (
              <SearchedPayrolls
                payrollsPage={payrollsPage}
                setPayrollsPage={setPayrollsPage}
                payrollsRowsPerPage={payrollsRowsPerPage}
                setPayrollsRowsPerPage={setPayrollsRowsPerPage}
                payrolls={payrolls}
                payrollsCount={payrollsCount}
              />
            )}
          </Col>
        </Row>
      </Container>}
      {modalOpen && (
        <RebnderModal
          loader={loader}
          handlePinCode={handlePinCode}
          setShow={setModalOpen}
          show={modalOpen}
        />
      )}
    </div>
  );
};

export default EmployeePayroll;
