export const CreateDepartmentReducer = (state = { department: {} }, action) => {
  switch (action.type) {
    case "CREATE_DEPARTMENT_REQUEST":
      return {
        loading: true,
        department: null,
        error: null,
      };
    case "CREATE_DEPARTMENT_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        department: action.payload,
      };
    case "CREATE_DEPARTMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_DEPARTMENT_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        error: null,
      };
    default:
      return state;
  }
};
export const DepartmentsDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_DEPARTMENTS_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        department: {},
      };
    case "GET_DEPARTMENTS_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        department: action.payload,
      };
    case "GET_DEPARTMENTS_DETAILS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const UpdateDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_DEPARTMENT_REQUEST":
      return {
        loading: true,
      };
    case "UPDATE_DEPARTMENT_SUCCESS":
      return {
        loading: false,
        isUpdated: action.payload,
      };
    case "UPDATE_DEPARTMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_DEPARTMENT_RESET":
      return {
        ...state,
        isUpdated: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const DeleteDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_DEPARTMENT_REQUEST":
      return {
        loading: true,
      };
    case "DELETE_DEPARTMENT_SUCCESS":
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case "DELETE_DEPARTMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DELETE_DEPARTMENT_RESET":
      return {
        ...state,
        isDeleted: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
