import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import MuiDataTable from "../../../../../Components/MUIDataTable/MUIDataTable";
import { FaEdit } from "react-icons/fa";
import { BsTrashFill } from "react-icons/bs";
import { RiAddLine } from "react-icons/ri";
import { useStyle } from "./holiday-style";

import { getAllHolidays } from "../../../../../Redux/Actions/HR";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../../../Utils/Loader";
import CreateHolidayModal from "./CreateHolidayModal";
import {
  clearErrors,
  deleteHolidayByHR,
  getHolidayDetails,
} from "../../../../../Redux/Actions/Holidays";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import UpdateHolidayModal from "./UpdateHolidayModal";

const Holidays = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [updateHolidayModalOpen, setUpdateHolidayModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [holidayData, setHolidayData] = useState({
    holiday_title: "",
    holiday_start_date: "",
    holiday_end_date: "",
    departments: "",
  });
  const [updateHolidayDetails, setUpdateHolidayDetails] = useState({
    _id: "",
    holiday_title: "",
    holiday_start_date: "",
    holiday_end_date: "",
    departments: "",
  });
  const {
    loading: holidaysLoading,
    holidays,
    holidaysCount,
  } = useSelector((state) => state.holidays);
  const {
    loading: delHolidayLoading,
    isDeleted,
    error: delHolidayError,
  } = useSelector((state) => state.deleteHoliday);
  const {
    loading: holidayLoading,
    holiday,
    error: holidayError,
  } = useSelector((state) => state.holidayDetails);
  useEffect(() => {
    dispatch(getAllHolidays(page, rowsPerPage));
  }, [page, rowsPerPage]);
  useEffect(() => {
    if (isDeleted?.success) {
      successNotify(isDeleted?.message);
      dispatch(getAllHolidays(page, rowsPerPage));
      dispatch({ type: "DELETE_HOLIDAY_RESET" });
    }
    if (delHolidayError) {
      errorNotify(delHolidayError);
      dispatch(clearErrors());
    }
  }, [isDeleted?.success, delHolidayError]);
  useEffect(() => {
    if (holiday?.success) {
      setUpdateHolidayDetails({
        _id: holiday?.holiday._id,
        holiday_title: holiday?.holiday?.holiday_title,
        holiday_start_date: holiday?.holiday?.holiday_start_date,
        holiday_end_date: holiday?.holiday?.holiday_end_date,
        departments: holiday?.holiday?.departments?.map((ele) => ({
          label: ele?.department_name,
          value: ele?._id,
        })),
      });
    }
  }, [holiday]);
  const deleteHoliday = (id) => {
    dispatch(deleteHolidayByHR(id));
  };
  const updateHoliday = (id) => {
    dispatch(getHolidayDetails(id));
    setUpdateHolidayModalOpen(true);
  };
  const holidayCols = [
    { name: "sno", label: "S:NO" },
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    { name: "holiday_title", label: "HOLIDAY TITLE" },
    {
      name: "holiday_start_date",
      label: "START DATE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "holiday_end_date",
      label: "END DATE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                type="button"
                onClick={() => updateHoliday(tableMeta.rowData[1])}
              >
                <FaEdit />
              </button>
              <button
                className="delete_btn"
                type="button"
                onClick={() => deleteHoliday(tableMeta.rowData[1])}
              >
                <BsTrashFill />
              </button>
            </div>
          );
        },
      },
    },
  ];

  let newData = [];
  holidays?.map((item, index) => {
    newData.push({ sno: rowsPerPage * page + index + 1, ...item });
  });
  return (
    <>
      {holidaysLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="departmentsTable">
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                className="addDepartment_btn"
                onClick={() => setModalOpen(true)}
              >
                <span
                  style={{
                    fontSize: "20px",
                    marginRight: "10px",
                    marginTop: "-3px",
                  }}
                >
                  <RiAddLine />
                </span>
                Add Holiday{" "}
              </Button>
            </Box>
            <MuiDataTable
              title="Holidays"
              columns={holidayCols}
              data={newData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              count={holidaysCount}
              tableId="holidays_table"
            />
          </Box>
        </>
      )}
      <CreateHolidayModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        holidayData={holidayData}
        setHolidayData={setHolidayData}
        page={page}
        rowsPerPage={rowsPerPage}
      />

      <UpdateHolidayModal
        classes={classes}
        updateModalOpen={updateHolidayModalOpen}
        setUpdateModalOpen={setUpdateHolidayModalOpen}
        page={page}
        rowsPerPage={rowsPerPage}
        updateHolidayDetails={updateHolidayDetails}
        setUpdateHolidayDetails={setUpdateHolidayDetails}
        loading={holidayLoading}
      />
    </>
  );
};

export default Holidays;
