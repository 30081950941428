import api from "../../Utils/interceptors";

// Create Announcement

export const createAnnouncement = (announceData) => async (dispatch) => {
  try {
    dispatch({
      type: "ANNOUNCE_REQUEST",
    });

    const { data } = await api.post("/announcement", announceData);

    dispatch({
      type: "ANNOUNCE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "ANNOUNCE_FAILED",
      payload: e.response.data.message,
    });
  }
};

// GET announcement Data

export const getAnnouncementData = (page) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ANNOUNCE_REQUEST",
    });

    const { data } = await api.get(`/announcements?page=${page}`);

    dispatch({
      type: "GET_ANNOUNCE_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "GET_ANNOUNCE_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const deleteAnnouncementById = (id) => async (dispatch) => {
  // console.log(page)
  try {
    dispatch({
      type: "DELETE_ANNOUNCEMENT_REQUEST",
    });

    const { data } = await api.delete(`/announcement/${id}`);

    dispatch({
      type: "DELETE_ANNOUNCEMENT_SUCCESS",
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: "DELETE_ANNOUNCEMENT_FAILED",
      payload: e.response.data.message,
    });
  }
};
export const getAllAnnouncements = (page) => {
  return api.get(`/announcements?page=${page}`);
};
