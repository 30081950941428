import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { getUser, tConvert } from "../../../../Utils/helper";
import CheckIn from "../../../../Asssets/Images/checkIn.png";
import CheckOut from "../../../../Asssets/Images/checkOut2.png";
import In from "../../../../Asssets/Images/In.png";
import Out from "../../../../Asssets/Images/Out.png";

const AttendanceForm = ({
  lastAttendance,
  setModalOpen,
  setCheckOutModalOpen,
  classes,
}) => {
  const date = new Date().setHours(0, 0, 0, 0);
  let userAuth = getUser() ? getUser() || "" : null;
  const startTime = new Date(userAuth?.employee?.shift?.start_time)
    ?.toString()
    ?.split(" ")[4]
    ?.split(":")[0];
  const setStartTime = new Date(lastAttendance?.attendance?.check_in).setHours(
    parseInt(startTime),
    0,
    0,
    0,
  );
  const endTime = new Date(userAuth?.employee?.shift?.end_time)
    ?.toString()
    ?.split(" ")[4]
    ?.split(":")[0];
  // const setEndTime = new Date().setHours(endTime, 0, 0, 0);
  let lastCheckOutTime = new Date(
    lastAttendance?.attendance?.check_in,
  ).setHours(new Date(setStartTime).getHours() + 13, 0, 0, 0);
  return (
    <>
      <Box sx={{ margin: "30px 40px" }}>
        {
          <Paper
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 30px",
              boxShadow: "none",
              borderRadius: "20px",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", my: 1, alignItems: "center" }}>
                <img
                  src={CheckIn}
                  alt="checkin"
                  style={{ width: "50px", height: "50px" }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#253766",
                    margin: "0 20px",
                    width: "150px",
                  }}
                >
                  Last Check-In
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {lastAttendance?.attendance &&
                  Object.keys(lastAttendance?.attendance).length !== 0 &&
                  lastAttendance?.attendance?.check_in
                    ? tConvert(
                        new Date(lastAttendance?.attendance?.check_in)
                          .toString()
                          .split(" ")[4],
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", my: 1, alignItems: "center" }}>
                <img
                  src={CheckOut}
                  alt="checkout"
                  style={{ width: "50px", height: "50px" }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#253766",
                    margin: "0 20px",
                    width: "150px",
                  }}
                >
                  Last Check-Out
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  {lastAttendance?.attendance &&
                  Object.keys(lastAttendance?.attendance).length !== 0 &&
                  lastAttendance?.attendance?.check_out
                    ? tConvert(
                        new Date(lastAttendance?.attendance?.check_out)
                          .toString()
                          .split(" ")[4],
                      )
                    : "-"}
                </Typography>
              </Box>
            </Box>
            <Box>
              {/* {lastAttendance?.attendance.status !== "On Leave" ? : } */}
              {lastAttendance?.attendance === null ||
              // lastAttendance.attendance.status === "on-leave" ||
              (lastAttendance?.attendance?.check_in &&
                lastAttendance?.attendance?.check_out) ||
              new Date().getTime() > new Date(lastCheckOutTime).getTime() ? (
                <Button
                  variant="contained"
                  className={classes.check_in_btn}
                  onClick={() => setModalOpen(true)}
                >
                  <img src={In} alt="in" style={{ width: "32px" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "700 !important",
                      fontSize: "16px",
                      mx: 2,
                    }}
                  >
                    Check In
                  </Typography>
                </Button>
              ) : lastAttendance?.attendance?.check_in &&
                !lastAttendance?.attendance?.check_out &&
                new Date().getTime() < new Date(lastCheckOutTime).getTime() ? (
                <Button
                  variant="contained"
                  className={classes.check_out_btn}
                  onClick={() => setCheckOutModalOpen(true)}
                >
                  <img src={Out} alt="in" style={{ width: "32px" }} />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "700 !important",
                      fontSize: "16px",
                      mx: 2,
                    }}
                  >
                    Check Out
                  </Typography>
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Paper>
        }
      </Box>
    </>
  );
};

export default AttendanceForm;
