export const CreateDesignationReducer = (
  state = { designation: {} },
  action,
) => {
  switch (action.type) {
    case "CREATE_DESIGNATION_REQUEST":
      return {
        loading: true,
        designation: null,
        error: null,
      };
    case "CREATE_DESIGNATION_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        designation: action.payload,
      };
    case "CREATE_DESIGNATION_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_DESIGNATION_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const DesignationDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_DESIGNATION_DETAILS_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        designation: {},
      };
    case "GET_DESIGNATION_DETAILS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        designation: action.payload,
      };
    case "GET_DESIGNATION_DETAILS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const UpdateDesignationReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_DESIGNATION_REQUEST":
      return {
        loading: true,
      };
    case "UPDATE_DESIGNATION_SUCCESS":
      return {
        loading: false,
        isUpdated: action.payload,
      };
    case "UPDATE_DESIGNATION_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UPDATE_DESIGNATION_RESET":
      return {
        ...state,
        isUpdated: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const DeleteDesignationReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_DESIGNATION_REQUEST":
      return {
        loading: true,
      };
    case "DELETE_DESIGNATION_SUCCESS":
      return {
        loading: false,
        isDeleted: action.payload,
      };
    case "DELETE_DESIGNATION_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DELETE_DESIGNATION_RESET":
      return {
        ...state,
        isDeleted: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
