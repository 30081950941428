import { Button, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Modal from "../../../../../Components/Modal/Modal";
import AutoCompleteSearch from "../../../../../Components/AutoCompleteSearch/AutoCompleteSearch";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartments } from "../../../../../Redux/Actions/HR";
import { useState } from "react";
import {
  clearErrors,
  createDepartmentByHR,
} from "../../../../../Redux/Actions/Departments";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const DepartmentModal = ({
  update = false,
  classes,
  modalOpen,
  setModalOpen,
  page,
  rowsPerPage,
  departmentData,
  setDepartmentData,
  HODId,
  setHODId,
  employees,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    loading: createLoading,
    department,
    error: createError,
    success,
  } = useSelector((state) => state.createDepartment);

  useEffect(() => {
    if (success) {
      successNotify(department?.message);
      dispatch({ type: "CREATE_DEPARTMENT_RESET" });
      setModalOpen(false);
      dispatch(getAllDepartments(page, rowsPerPage));
    }
    if (createError) {
      errorNotify(createError);
      dispatch(clearErrors());
    }
  }, [success, createError, department]);

  const handleCreateDepartment = () => {
    dispatch(createDepartmentByHR(departmentData, HODId));
  };

  return (
    <Modal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create New Department"
    >
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>
          Department Name
        </InputLabel>
        <TextField
          sx={{ mt: 1 }}
          placeholder="Enter Department Name"
          fullWidth
          className={classes.inputContainer}
          value={departmentData}
          onChange={(e) => setDepartmentData(e.target.value)}
        />
      </Box>
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label} sx={{ mb: 1 }}>
          Head Of Department
        </InputLabel>
        <AutoCompleteSearch
          data={employees?.employees}
          departmentData={HODId}
          setDepartmentData={setHODId}
          title={"Head Of Department"}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.createBtn}
            disabled={createLoading ? true : false}
            onClick={() => handleCreateDepartment()}
          >
            {createLoading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DepartmentModal;
