import React from "react";
import Navbar from "../Manager/Routes/Navbar";
import { Navigate, Outlet } from "react-router-dom";
import { getUser } from "../../Utils/helper";

const ManagerLayout = () => {
  let userAuth = getUser() ? getUser() || "" : null;
  return userAuth?.employee?.role === "manager" ? (
    <React.Fragment>
      <Navbar />
      <Outlet />
    </React.Fragment>
  ) : (
    <Navigate to={`/`} />
  );
};
export default ManagerLayout;
