import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  inputContainer: {
    margintop: "4px",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      border: "0px solid rgba(235,235,235,90%) !important",
      borderRadius: "8px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px !important",
      backgroundColor: "#F3F8FE",
      border: "none !important",
      fontSize: "14px !important",
      // border: "1px solid #EBEBEB !important"
    },
  },
  department_label: {
    fontWeight: "700 !important",
    color: "#394767!important",
    fontSize: "15px !important",
  },
  createBtn: {
    background: "linear-gradient(to bottom, #0097d9, #00b1ff) !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
  },
  cancelBtn: {
    background: "red !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
  },
});
