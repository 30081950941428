import { combineReducers } from "redux";
import {
  CheckInReducer,
  CheckOutReducer,
  EmployeeCurrentMonthAttendanceReducer,
  UserAttendanceReducer,
  getLastAttendanceReducer,
  updateAttendanceReducer,
} from "./Attendance";
import {
  AuthReducer,
  ChangePassReducer,
  ChangePinReducer,
  currentUserReducer,
} from "./Auth";
import {
  CreateDepartmentReducer,
  DeleteDepartmentReducer,
  DepartmentsDetailsReducer,
  UpdateDepartmentReducer,
} from "./Departments";
import {
  CreateDesignationReducer,
  DeleteDesignationReducer,
  DesignationDetailsReducer,
  UpdateDesignationReducer,
} from "./Designations";
import {
  CreateHolidayReducer,
  DeleteHolidayReducer,
  HolidayDetailsReducer,
  UpdateHolidayReducer,
} from "./Holidays";
import {
  AllDepartmentsReducer,
  AllDesignationsReducer,
  AllEmployeesReducer,
  getEmployeeDropdownReducer,
  AllFilteredEmployeesReducer,
  AllHolidaysReducer,
  AllLeavesReducer,
  AllShiftsReducer,
  getEmployeeReducer,
  HRDashboard,
  registerReducer,
} from "./HR";
import {
  CreateLeaveReducer,
  DeleteLeaveReducer,
  LeaveDetailsReducer,
  UpdateLeaveReducer,
} from "./Leaves";
import {
  CreateShiftReducer,
  DeleteShiftReducer,
  ShiftDetailsReducer,
  UpdateShiftReducer,
} from "./Shifts";
import {
  EmployeeDashboard,
  updateEmployeeReducer,
  deleteEmployeeReducer,
  updateProfilePictureReducer,
  manageLeavesReducer,
  EmployeePayrollsReducer,
  employeeNotificationsReducer,
  markNotificationAsRead,
  banEmployeeReducer,
  unBanEmployeeReducer,
  activeEmployeeReducer,
  inActiveEmployeeReducer,
} from "./Employee";
import { monthlyAttendanceReducer, todaysAttendanceReducer } from "./TimeSheet";
import {
  DeleteAnnouncementReducer,
  announcementReducer,
  getAllAnnouncementReducer,
} from "./Announcement";
import {
  AllLeaveApplicationsReducer,
  CreateLeavesReducer,
  IndivisualLeaveDetailsReducer,
  UpdateLeaveApplicationReducer,
  LeavesDeleteReducer,
  CurrentUserLeaveApplicationsReducer,
  LineManagerLeaveApplicationsReducer,
  SendLeaveApplicationCommentReducer,
} from "./LeaveApplications";
import {
  AllTicketsReducer,
  CreateTicketReducer,
  IndivisualTicketDetailsReducer,
  UpdateTicketReducer,
  TicketsDeleteReducer,
  CurrentUserTicketsReducer,
  assignedTicketsReducer,
  SendTicketCommentReducer,
} from "./Tickets";
import {
  CreatePayrollByDateReducer,
  CreatePayrollByDepartmentReducer,
  CreatePayrollReducer,
  deleteEmployeePayrollsReducer,
  lastPayrollsReducer,
  PayrollsByIdReducer,
  PayrollsReducer,
  updateEmployeePayrollsReducer,
} from "./payrolls";
import {
  AllRequestsReducer,
  CreateRequestReducer,
  CurrentUserRequestsReducer,
  IndivisualRequestDetailsReducer,
  RequestDeleteReducer,
} from "./Requests";

const rootReducer = combineReducers({
  //*********************** Auth ****************************
  Auth: AuthReducer,
  passChanged: ChangePassReducer,
  pinChanged: ChangePinReducer,
  //HR
  //********************dashboard****************************
  dashboardData: HRDashboard,
  departments: AllDepartmentsReducer,
  designations: AllDesignationsReducer,
  leaves: AllLeavesReducer,
  shifts: AllShiftsReducer,
  holidays: AllHolidaysReducer,
  employees: AllEmployeesReducer,
  employeeDropdown: getEmployeeDropdownReducer,
  //register employee
  register: registerReducer,
  employeeUpdate: updateEmployeeReducer,
  employeeDelete: deleteEmployeeReducer,
  updateProfilePic: updateProfilePictureReducer,

  // Create Announcement

  createAnnouncement: announcementReducer,
  getAnnouncement: getAllAnnouncementReducer,
  deleteAnnouncement: DeleteAnnouncementReducer,

  //************************hr setup***********************

  //department
  createDepartment: CreateDepartmentReducer,
  departmentDetails: DepartmentsDetailsReducer,
  updateDepartment: UpdateDepartmentReducer,
  deleteDepartment: DeleteDepartmentReducer,
  //designation
  createDesignation: CreateDesignationReducer,
  designationDetails: DesignationDetailsReducer,
  updateDesignation: UpdateDesignationReducer,
  deleteDesignation: DeleteDesignationReducer,
  //shift
  createShift: CreateShiftReducer,
  shiftDetails: ShiftDetailsReducer,
  updateShift: UpdateShiftReducer,
  deleteShift: DeleteShiftReducer,
  //holiday
  createHoliday: CreateHolidayReducer,
  holidayDetails: HolidayDetailsReducer,
  updateHoliday: UpdateHolidayReducer,
  deleteHoliday: DeleteHolidayReducer,
  //leave

  createLeave: CreateLeaveReducer,
  leaveDetails: LeaveDetailsReducer,
  updateLeave: UpdateLeaveReducer,
  deleteLeave: DeleteLeaveReducer,

  // Leave Application
  createLeaveApplication: CreateLeavesReducer,
  leaveApplicationDetails: IndivisualLeaveDetailsReducer,
  UpdateLeaveApplication: UpdateLeaveApplicationReducer,
  LeavesApplicationDelete: LeavesDeleteReducer,
  currentUserLeaveApplications: CurrentUserLeaveApplicationsReducer,
  leaveApplications: AllLeaveApplicationsReducer,
  lineManagerLeaveApplications: LineManagerLeaveApplicationsReducer,
  leaveComment: SendLeaveApplicationCommentReducer,

  // Tickets
  AllTicket: AllTicketsReducer,
  CreateTicket: CreateTicketReducer,
  ticketDetail: IndivisualTicketDetailsReducer,
  ticketUpdated: UpdateTicketReducer,
  ticketDeleted: TicketsDeleteReducer,
  currentUserTickets: CurrentUserTicketsReducer,
  assignedTickets: assignedTicketsReducer,
  ticketComment: SendTicketCommentReducer,

  //************************employee***********************
  employee: getEmployeeReducer,
  employeeNotifications: employeeNotificationsReducer,
  markAsRead: markNotificationAsRead,
  filteredEmployees: AllFilteredEmployeesReducer,

  //***********************Attendance************************
  currentMonthAttendance: EmployeeCurrentMonthAttendanceReducer,
  lastAttendance: getLastAttendanceReducer,
  checkIn: CheckInReducer,
  checkOut: CheckOutReducer,
  userAttendance: UserAttendanceReducer,
  updateAttendance: updateAttendanceReducer,

  //***********************TimeSheet************************
  monthlyTimesheet: monthlyAttendanceReducer,
  todaysTimesheet: todaysAttendanceReducer,

  //***********************Employee************************
  EmployeeDashboardData: EmployeeDashboard,
  currentUser: currentUserReducer,
  manageLeaves: manageLeavesReducer,
  EmployeePayrolls: EmployeePayrollsReducer,
  banEmployee: banEmployeeReducer,
  unBanEmployee: unBanEmployeeReducer,
  activeEmployee: activeEmployeeReducer,
  inActiveEmployee: inActiveEmployeeReducer,

  //***********************payrolls************************
  lastPayrolls: lastPayrollsReducer,
  payrollById: PayrollsByIdReducer,
  payrolls: PayrollsReducer,
  createPayroll: CreatePayrollReducer,
  createPayrollByDepartment: CreatePayrollByDepartmentReducer,
  createPayrollByDate: CreatePayrollByDateReducer,
  updatePayroll: updateEmployeePayrollsReducer,
  deletePayroll: deleteEmployeePayrollsReducer,

  //***********************requests************************
  createRequest: CreateRequestReducer,
  getRequests: AllRequestsReducer,
  currentUserRequest: CurrentUserRequestsReducer,
  requestDetails: IndivisualRequestDetailsReducer,
  deleteRequest: RequestDeleteReducer,
});

export default rootReducer;
