import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPayrollsById } from "../../../../../Redux/Actions/Payroll";
import Logo2 from "../../../../../Asssets/Images/designlogo2.png";
import { Box } from "@mui/system";
import { useStyle } from "../payroll-styles";
import Download from "../../../../../Asssets/Images/download-icon.jpg";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import Loader from "../../../../../Utils/Loader";

const Payslip = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyle();
  const componentRef = useRef();
  const { loading, payroll, success, error } = useSelector(
    (state) => state.payrollById
  );
  const [cl, setCl] = useState("0");
  const [sl, setSl] = useState("0");
  const [al, setAl] = useState("0");
  const [totalAllowance, setTotalAllowance] = useState(0);
  useEffect(() => {
    dispatch(getPayrollsById(id));
  }, [id]);
  useEffect(() => {
    if (success) {
      setTotalAllowance(0);
      payroll?.user?.leaves.map((leave) => {
        if (leave.leave_type == "Casual leaves") setCl(leave.leave_quota);
        if (leave.leave_type == "sick leaves") setSl(leave.leave_quota);
        if (leave.leave_type == "Annual Leave") setAl(leave.leave_quota);
      });
      payroll?.allowance?.map((all) => {
        setTotalAllowance((prev) => prev + all?.amount);
      });
    }
  }, [success]);

  return (
    <div className="page_responsive">
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              right: "50px",
              top: "20px",
              zIndex: "2",
            }}
          >
            <ReactToPrint
              trigger={() => (
                <img
                  src={Download}
                  style={{ cursor: "pointer" }}
                  alt="download"
                />
              )}
              content={() => componentRef.current}
            />
          </Box>
          <Paper className={classes.payslip_main} ref={componentRef}>
            <Box className={classes.payslip_heading_container}>
              <img src={Logo2} />
              <Typography variant="h4">PAY SLIP</Typography>
            </Box>
            <Divider />
            <Grid container spacing={3} sx={{ marginTop: "5px" }}>
              <Grid item md={6}>
                <Box className={classes.payslip_info_box}>
                  <Typography variant="h6">Employee Name</Typography>
                  <Typography variant="h6">
                    {payroll?.user.full_name}
                  </Typography>
                </Box>
                <Box className={classes.payslip_info_box}>
                  <Typography variant="h6">Designation</Typography>
                  <Typography variant="h6">
                    {payroll?.user?.designation?.designation_name}
                  </Typography>
                </Box>
                <Box className={classes.payslip_info_box}>
                  <Typography variant="h6">Department</Typography>
                  <Typography variant="h6">
                    {payroll?.user?.department?.department_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box className={classes.payslip_info_box}>
                  <Typography variant="h6">CNIC Number</Typography>
                  <Typography variant="h6">{payroll?.user?.CNIC}</Typography>
                </Box>
                <Box className={classes.payslip_info_box}>
                  <Typography variant="h6">Shift Time</Typography>
                  <Typography variant="h6">
                    {
                      new Date(payroll?.user?.shift?.start_time)
                        .toLocaleString()
                        .split(" ")[1]
                    }{" "}
                    <span>to</span>{" "}
                    {
                      new Date(payroll?.user?.shift?.end_time)
                        .toLocaleString()
                        .split(" ")[1]
                    }
                  </Typography>
                </Box>
                <Box className={classes.payslip_info_box}>
                  <Typography variant="h6">Payroll Month</Typography>
                  <Typography variant="h6">
                    {payroll?.date?.month < 10
                      ? "0" + payroll?.date?.month
                      : payroll?.date?.month}
                    -{payroll?.date?.year}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Paper
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 7px 7px rgba(0,0,0,0.14)",
                marginBottom: "20px",
              }}
            >
              <Grid container>
                <Grid item md={6}>
                  <Box
                    className={classes.monthly_summary}
                    sx={{ borderTopLeftRadius: "10px" }}
                  >
                    <Typography variant="h6">
                      Monthly Attendance Summary
                    </Typography>
                  </Box>
                  <Box
                    className={classes.monthly_attendance_box}
                    sx={{ borderRight: "1px dashed #DCDCDC" }}
                  >
                    <Box>
                      <Typography variant="h6">Lates</Typography>
                      <Typography>{payroll?.lates}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Half Days</Typography>
                      <Typography>{payroll?.halfDays}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Paid Leaves</Typography>
                      <Typography>{payroll?.approvedLeaves}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Paid HalfDays</Typography>
                      <Typography>{payroll?.approvedHalfDays}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Unpaid Leaves</Typography>
                      <Typography>{payroll?.calculatedAbsents}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box
                    className={classes.monthly_summary}
                    sx={{ borderTopRightRadius: "10px" }}
                  >
                    <Typography variant="h6">Balance Record</Typography>
                  </Box>
                  <Box className={classes.monthly_attendance_box}>
                    <Box>
                      <Typography variant="h6">Leaves(CL - SL - AL)</Typography>
                      <Typography>{`${cl} - ${sl} - ${al}`}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Loan</Typography>
                      <Typography>
                        {payroll?.balanceRecord?.loanBalance}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">OPD Balance</Typography>
                      <Typography>
                        {payroll?.balanceRecord?.OPDBalance}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Medical Insurance limit
                      </Typography>
                      <Typography>
                        {payroll?.balanceRecord?.medicalInsurance}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box className={classes.monthly_summary}>
                    <Typography variant="h6">Employee Package</Typography>
                  </Box>
                  <Box className={classes.monthly_attendance_box}>
                    <Box>
                      <Typography variant="h6">
                        Basic Monthly Package
                      </Typography>
                      <Typography>{payroll?.user?.salary}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">Medical Insurance</Typography>
                      <Typography>
                        {payroll?.balanceRecord?.medicalInsurance ? "25000" : 0}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">OPD</Typography>
                      <Typography>
                        {payroll?.balanceRecord?.OPDBalance ? "4000" : 0}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderTop: "1px dashed #DCDCDC",
                        paddingTop: "10px",
                      }}
                    >
                      <Typography variant="h6">
                        Total Employee Package
                      </Typography>
                      <Typography>
                        PKR
                        <span style={{ marginLeft: "15px" }}>
                          {Math.round(
                            parseInt(payroll?.user?.salary) +
                              (payroll?.balanceRecord?.medicalInsurance
                                ? 25000
                                : 0) +
                              (payroll?.balanceRecord?.OPDBalance ? 4000 : 0)
                          )}{" "}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box className={classes.monthly_summary}>
                    <Typography variant="h6">Allowances</Typography>
                  </Box>
                  <Box className={classes.monthly_attendance_box}>
                    {payroll?.allowance.length > 0 &&
                      payroll?.allowance.map((all) => (
                        <Box>
                          <Typography variant="h6">{all?.title}</Typography>
                          <Typography>{all?.amount}</Typography>
                        </Box>
                      ))}
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box
                    className={classes.monthly_attendance_box}
                    sx={{
                      borderTop: "1px dashed #DCDCDC",
                      margin: " 0px 20px 20px 20px",
                      padding: "0px 20px 0px 0px",
                    }}
                  >
                    <Box>
                      <Typography variant="h6">Total Allowance</Typography>
                      <Typography>{totalAllowance}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Grid container>
                    <Grid item md={6}>
                      <Box className={classes.monthly_summary}>
                        <Typography variant="h6">Earnings</Typography>
                      </Box>
                      <Box className={classes.monthly_attendance_box}>
                        <Box>
                          <Typography variant="h6">Basic Salary</Typography>
                          <Typography>
                            {Math.round(payroll?.actualSalary)}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Commission</Typography>
                          <Typography>
                            {typeof payroll?.earnings?.commission === "object"
                              ? payroll?.earnings?.commission?.amount
                              : payroll?.earnings?.commission}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Bonus</Typography>
                          <Typography>
                            {" "}
                            {typeof payroll?.earnings?.bonus === "object"
                              ? payroll?.earnings?.bonus?.amount
                              : payroll?.earnings?.bonus}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Arrear</Typography>
                          <Typography>
                            {" "}
                            {typeof payroll?.earnings?.arrear === "object"
                              ? payroll?.earnings?.arrear?.amount
                              : payroll?.earnings?.arrear}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Retention</Typography>
                          <Typography>
                            {typeof payroll?.earnings?.retention === "object"
                              ? payroll?.earnings?.retention?.amount
                              : payroll?.earnings?.retention}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Others</Typography>
                          <Typography>
                            {" "}
                            {typeof payroll?.earnings?.other === "object"
                              ? payroll?.earnings?.other?.amount
                              : payroll?.earnings?.other}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box className={classes.monthly_summary}>
                        <Typography variant="h6">Deduction</Typography>
                      </Box>
                      <Box className={classes.monthly_attendance_box}>
                        <Box>
                          <Typography variant="h6">Tax</Typography>
                          <Typography>{payroll?.tax}</Typography>
                        </Box>
                        {/* <Box>
                          <Typography variant="h6">Deduction</Typography>
                          <Typography>
                            {Math.round(payroll?.deduction)}
                          </Typography>
                        </Box> */}
                        <Box>
                          <Typography variant="h6">Lates</Typography>
                          <Typography>
                            {payroll?.lates ? payroll?.lates : 0}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Half Days</Typography>
                          <Typography>
                            {payroll?.halfDays ? payroll?.halfDays : 0}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Absents</Typography>
                          <Typography>
                            {payroll?.calculatedAbsents
                              ? payroll?.calculatedAbsents
                              : 0}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Loan</Typography>
                          <Typography>
                            {payroll?.loanDeduction
                              ? payroll?.loanDeduction
                              : 0}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="h6">Advance Salary</Typography>
                          <Typography>
                            {payroll?.advanceSalaryDeduction
                              ? payroll?.advanceSalaryDeduction
                              : 0}
                          </Typography>
                        </Box>
                        {payroll?.otherDeductions &&
                          payroll.otherDeductions.map((o, i) => (
                            <Box>
                              <Typography variant="h6">{o.title}</Typography>
                              <Typography>{o?.amount}</Typography>
                            </Box>
                          ))}
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        className={classes.monthly_attendance_box}
                        sx={{
                          borderTop: "1px dashed #DCDCDC",
                          margin: " 0px 20px 20px 20px",
                          padding: "0px 20px 0px 0px",
                        }}
                      >
                        <Box>
                          <Typography variant="h6">Total Earning</Typography>
                          <Typography>
                            {Math.round(parseFloat(payroll?.actualSalary)) +
                              parseInt(
                                typeof payroll?.earnings?.commission ===
                                  "object"
                                  ? payroll?.earnings?.commission?.amount
                                  : payroll?.earnings?.commission
                              ) +
                              parseInt(
                                typeof payroll?.earnings?.bonus === "object"
                                  ? payroll?.earnings?.bonus?.amount
                                  : payroll?.earnings?.bonus
                              ) +
                              parseInt(
                                typeof payroll?.earnings?.arrear === "object"
                                  ? payroll?.earnings?.arrear?.amount
                                  : payroll?.earnings?.arrear
                              ) +
                              parseInt(
                                typeof payroll?.earnings?.other === "object"
                                  ? payroll?.earnings?.other?.amount
                                  : payroll?.earnings?.other
                              )}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        className={classes.monthly_attendance_box}
                        sx={{
                          borderTop: "1px dashed #DCDCDC",
                          margin: " 0px 20px 20px 20px",
                          padding: "0px 20px 0px 0px",
                        }}
                      >
                        <Box>
                          <Typography variant="h6">Total Deduction</Typography>
                          <Typography>
                            {Math.round(payroll?.deduction) +
                              payroll?.otherDeductions.reduce(
                                (prev, curr) => prev + curr.amount,
                                0
                              ) +
                              Math.round(payroll?.tax)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Box className={classes.net_salary_box}>
                    <Typography variant="h6">Net Salary</Typography>
                    <Typography>
                      PKR
                      <span style={{ marginLeft: "15px" }}>
                        {Math.round(payroll?.calculatedSalary)}{" "}
                      </span>
                    </Typography>
                  </Box>
                  <Typography className={classes.info_text}>
                    *This pay slip is system generated and does not required and
                    signature or stamp
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default Payslip;
