import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  dashboard_card: {
    height: "300px",
    padding: "16px",
    borderRadius: "20px !important",
    boxShadow:
      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
  },
  attendance_list_item: {
    borderRadius: "20px",
    backgroundColor: "#F3F8FE",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 10px",
  },
  card_header: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    backgroundColor: "#F3F8FE",
    width: "calc(100% - 0px) !important",
    display: "flex",
    borderRight: "4px solid #14D4E2",
    padding: "8px 0px",
    paddingLeft: "8px",
    marginBottom: "8px",
  },
  card_header_heading: {
    fontWeight: "700 !important",
    color: "#253766",
    paddingLeft: "8px",
    fontSize: "19px !important",
  },
  ticket_card_heading: {
    textAlign: "left",
    fontWeight: "700 !important",
    color: "#253766",
  },
  leave_card_header: {
    background: "linear-gradient(to bottom,#0097D9,#00B1FF)",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
  },
  leave_card_heading: {
    textAlign: "left",
    fontWeight: "600 !important",
    color: "#253766",
    marginBottom: "8px",
    fontSize: "13px !important",
  },
  pending_status: {
    padding: "5px 10px",
    backgroundColor: "#f7941d",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  approved_status: {
    padding: "5px 10px",
    backgroundColor: "rgb(89, 198, 128)",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  rejected_status: {
    padding: "5px 10px",
    backgroundColor: "#FE022C",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
});
