import React, { useEffect, useCallback } from "react";
import MUIDataTable, { FilterType } from "mui-datatables";
import { debounce } from "lodash";
import "./MUIDataTable.css";

const MuiDataTable = ({
  data,
  columns,
  title,
  setPage,
  page,
  rowsPerPage = 5,
  setRowsPerPage,
  count,
  setText,
  text,
  isDownload,
  tableId, // Unique identifier for each table instance
}) => {
  const getStorageKey = (key) => `${tableId}_${key}`;

  const option = {
    filter: false,
    download: false,
    filterType: "checkbox",
    search: true,
    viewColumns: false,
    responsive: "standard",
    selectableRows: "none",
    rowsPerPageOptions: [5, 10, 20],
    rowsPerPage,
    page,
    count,
    serverSide: true,
    sort: false,
    jumpToPage: false,
    searchText: text,
    exportOptions: {
      columns: [2, 3, 4, 5],
    },

    onChangePage(currentPage) {
      setPage(currentPage);
      window.sessionStorage.setItem(getStorageKey("page"), currentPage);
    },
    onChangeRowsPerPage(numberOfRows) {
      setRowsPerPage(numberOfRows);
      window.sessionStorage.setItem(getStorageKey("rows_per_page"), numberOfRows);
    },

    onSearchChange: (searchText) => {
      if (searchText === null) {
        setText("");
        window.sessionStorage.removeItem(getStorageKey("search_text"));
      } else {
        debouncedSave(searchText);
      }
    },
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setText(nextValue);
      window.sessionStorage.setItem(getStorageKey("search_text"), nextValue);
    }, 1000),
    [tableId]
  );

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    const savedPage = parseInt(window.sessionStorage.getItem(getStorageKey("page")), 10);
    const savedRowsPerPage = parseInt(window.sessionStorage.getItem(getStorageKey("rows_per_page")), 10);
    const savedSearchText = window.sessionStorage.getItem(getStorageKey("search_text"));

    if (!isNaN(savedPage)) {
      setPage(savedPage);
    }
    if (!isNaN(savedRowsPerPage)) {
      setRowsPerPage(savedRowsPerPage);
    }
    if (savedSearchText !== null) {
      setText(savedSearchText);
    }
  }, [setPage, setRowsPerPage, setText, tableId]);

  return (
    <div className="mui_datatables">
      <style>
        {`
        .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular{
          min-height:10px !important;
        }
        `}
      </style>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={option}
      />
    </div>
  );
};

export default MuiDataTable;
