import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

const BankDetailsCard = ({ classes, employee }) => {
  return (
    <Paper className={classes.details_card}>
      <div className="details_head">
        <h5>Bank Details</h5>
      </div>
      <Grid container className={classes.personal_details_form} spacing={3}>
        <Grid item md={6}>
          <Grid container>
            <Grid item md={6}>
              <Typography variant="h6">Account Title Name</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{employee?.account_title}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6">Branch Name</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{employee?.branch}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6">Bank Name</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{employee?.bank_name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Grid container>
            <Grid item md={6}>
              <Typography variant="h6">Account Number</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{employee?.account_number}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6">Bank Branch Address</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{employee?.bank_branch_address}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography variant="h6">Bank Branch Code</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography>{employee?.bank_branch_code}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BankDetailsCard;
