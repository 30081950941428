export const AllLeaveApplicationsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_ALL_LEAVE_APPLICATIONS_REQUEST":
      return {
        loading: true,
      };
    case "GET_ALL_LEAVE_APPLICATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        leaveApplications: action.payload.leaveApplications,
        applicationsCount: action.payload.applicationsCount,
      };
    case "GET_ALL_LEAVE_APPLICATIONS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const CurrentUserLeaveApplicationsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_MY_LEAVE_APPLICATIONS_REQUEST":
      return {
        loading: true,
      };
    case "GET_MY_LEAVE_APPLICATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        leaveApplications: action.payload.leaves,
        leavesCount: action.payload.leavesCount,
      };
    case "GET_MY_LEAVE_APPLICATIONS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// CREATE LEAVE REDUCER

export const LineManagerLeaveApplicationsReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_LINE_MANAGER_LEAVE_APPLICATIONS_REQUEST":
      return {
        loading: true,
      };
    case "GET_LINE_MANAGER_LEAVE_APPLICATIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        assignedLeaves: action.payload.leaveApplications,
        assignedLeavesCount: action.payload.leavesCount,
      };
    case "GET_LINE_MANAGER_LEAVE_APPLICATIONS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// CREATE LEAVE REDUCER

export const CreateLeavesReducer = (state = { createLeave: {} }, action) => {
  switch (action.type) {
    case "CREATE_LEAVE_REQUEST":
      return {
        loading: true,
        createLeave: null,
        error: null,
        success: false,
      };
    case "CREATE_LEAVE_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        createLeave: action.payload,
      };
    case "CREATE_LEAVE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CREATE_LEAVE_RESET":
      return {
        ...state,
        success: false,
      };

    case "CLEAR_ERRORS":
      return {
        error: null,
      };
    default:
      return state;
  }
};

// GET LEAVE BY INDIVIDUAL ID

export const IndivisualLeaveDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case "INDIVISUAL_LEAVE_DETAIL_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        indivisualLeaveDetails: {},
      };
    case "INDIVISUAL_LEAVE_DETAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        success: action.payload.success,
        indivisualLeaveDetails: action.payload,
      };
    case "INDIVISUAL_LEAVE_DETAIL_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// UPDATE LEAVE BY INDIVISUAL ID

export const UpdateLeaveApplicationReducer = (state = {}, action) => {
  switch (action.type) {
    case "INDIVISUAL_LEAVE_UPDATE_REQUEST":
      return {
        loading: true,
      };
    case "INDIVISUAL_LEAVE_UPDATE_SUCCESS":
      return {
        loading: false,
        leaveUpdated: action.payload,
      };
    case "INDIVISUAL_LEAVE_UPDATE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "INDIVISUAL_LEAVE_UPDATE_RESET":
      return {
        ...state,
        leaveUpdated: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// DELETE LEAVE BY INDIVISUAL ID

export const LeavesDeleteReducer = (state = [], action) => {
  switch (action.type) {
    case "INDIVISUAL_LEAVE_DELETE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "INDIVISUAL_LEAVE_DELETE_SUCCESS":
      return {
        ...state,
        loading: false,
        deletedLeaves: action.payload,
      };
    case "INDIVISUAL_LEAVE_DELETE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "INDIVISUAL_LEAVE_DELETE_RESET":
      return {
        ...state,
        deletedLeaves: {},
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const SendLeaveApplicationCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case "SEND_LEAVE_COMMENT_REQUEST":
      return {
        loading: true,
      };
    case "SEND_LEAVE_COMMENT_SUCCESS":
      return {
        loading: false,
        sendComment: action.payload,
      };
    case "SEND_LEAVE_COMMENT_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SEND_LEAVE_COMMENT_RESET":
      return {
        ...state,
        sendComment: null,
      };

    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
