import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import { RiAddLine } from "react-icons/ri";
import { toast } from "react-toastify";
import Loader from "../../../../Utils/Loader";
import { getToken } from "../../../../Utils/helper";
import { BaseURL } from "../../../../Utils/interceptors";
import Header from "../../../../Components/Header/Header";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import MetaData from "../../../../Components/MetaData";
import CreateLeaveModal from "../HRMSetup/FromFromHome/CreateLeaveModal";
import { useStyle } from "../HRMSetup/FromFromHome/leave-style";
import style from "./WorkFromHome.module.css";

const WorkFromHome = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyle();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const getEmployees = async () => {
    const url = `${BaseURL}/wfh-all?page=${page}&size=${limit}`;
    const token = getToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };
    try {
      setIsLoading(true);
      const res = await axios.get(url, options);
      if (res !== undefined) {
        setEmployeeData(res?.data?.employees);
        setTotalCount(res?.data?.employeesCount);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const handleCreate = async (params) => {
    const url = `${BaseURL}/wfh/create`;
    const token = getToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    try {
      setIsCreating(true);
      const res = await axios.post(url, params, options);
      if (res !== undefined) {
        toast.success("Added successfully");
        setModalOpen(false);
        getEmployees();
      }
    } catch (err) {
      console.log(err);
    }
    setIsCreating(false);
  };

  const leaveCols = [
    { name: "sno", label: "S:NO" },
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "userId",
      label: "EMPLOYEE NAME",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Typography sx={{ fontSize: "0.875rem", fontWeight: "600" }}>
                {value?.full_name}
              </Typography>
            </div>
          );
        },
      },
    },

    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              {/* <button
                className="edit_btn"
                type="button"
                // onClick={() => updateLeave(tableMeta.rowData[1])}
              >
                <FaEdit />
              </button> */}
              <button
                disabled={isDeleting}
                className="delete_btn"
                type="button"
                onClick={() => {
                  handleDelete(tableMeta?.rowData[1]);
                }}
              >
                {<BsTrashFill />}
              </button>
            </div>
          );
        },
      },
    },
  ];

  const handleDelete = async (id) => {
    const url = `${BaseURL}/wfh/${id}`;
    const token = getToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };

    try {
      setIsDeleting(true);
      const res = await axios.delete(url, options);
      if (res !== undefined) {
        toast.success("Deleted successfully");
        setModalOpen(false);
        getEmployees();
      }
    } catch (err) {
      console.log(err);
    }
    setIsDeleting(false);
  };

  let newData = [];
  employeeData?.map((item, index) => {
    newData.push({ sno: limit * page + index + 1, ...item });
  });

  return (
    <div className="page_responsive">
      <Header title="WORK FROM HOME" />
      <MetaData title={`Work From Home -- HRM`} />
      <div className={style.pageMain}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box className="departmentsTable">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  className="addDepartment_btn"
                  onClick={() => setModalOpen(true)}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      marginRight: "10px",
                      marginTop: "-3px",
                    }}
                  >
                    <RiAddLine />
                  </span>
                  Add Employee{" "}
                </Button>
              </Box>
              <MuiDataTable
                title="Employee"
                columns={leaveCols}
                data={newData}
                page={page}
                setPage={setPage}
                rowsPerPage={limit}
                setRowsPerPage={setLimit}
                count={totalCount}
                tableId="wfh_table"
              />
            </Box>
          </>
        )}
      </div>
      <CreateLeaveModal
        classes={classes}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleCreate={handleCreate}
        isCreating={isCreating}
      />
      {/* <UpdateLeaveModal
        classes={classes}
        updateModalOpen={updateModalOpen}
        setUpdateModalOpen={setUpdateModalOpen}
        page={page}
        rowsPerPage={rowsPerPage}
        updateLeaveDetails={updateLeaveDetails}
        setUpdateLeaveDetails={setUpdateLeaveDetails}
        loading={leaveLoading}
      /> */}
    </div>
  );
};

export default WorkFromHome;
