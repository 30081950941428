import { Button, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "../../../../Components/Header/Header";
import MetaData from "../../../../Components/MetaData";
import { getAllDepartments } from "../../../../Redux/Actions/HR";
import {
  getCurrentUserLeaveApplications,
  getLineManagerLeaveApplications,
} from "../../../../Redux/Actions/LeaveApplications";
import Loader from "../../../../Utils/Loader";
import { useStyle } from "../../../HR/Pages/Attendance/attendance-styles";
import MyLeaves from "./MyLeaves/MyLeaves";
import "../../../HR/Pages/LeaveManagement/LeaveManagement.css";
import AssignedLeaves from "./AssignedLeaves/AssignedLeaves";
import ApplyForLeaveModal from "./ApplyForLeave/ApplyForLeaveModal";
import { errorNotify, successNotify } from "../../../../Utils/toast";
import { getCurrentUser } from "../../../../Redux/Actions/Auth";
import { leaveStyle } from "./leave-styles";
import { getUser } from "../../../../Utils/helper";

const Leaves = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [leavesPage, setLeavesPage] = useState(0);
  const [leavesRowsPerPage, setLeavesRowsPerPage] = useState(5);

  const [tab, setTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isHOD, setIsHOD] = useState(false);
  const classes = useStyle();
  const classes2 = leaveStyle();
  let userAuth = getUser() ? getUser() || "" : null;

  const dispatch = useDispatch();
  const {
    loading: departmentsLoading,
    departments,
    success,
  } = useSelector((state) => state.departments);
  const { loading, leaveApplications, leavesCount, error } = useSelector(
    (state) => state.currentUserLeaveApplications,
  );

  const {
    error: createError,
    loading: createLoading,
    createLeave,
    success: createLeaveSuccess,
  } = useSelector((state) => state.createLeaveApplication);
  useEffect(() => {
    dispatch(getAllDepartments(page, rowsPerPage));
    dispatch(getCurrentUserLeaveApplications(leavesPage, leavesRowsPerPage));
  }, [leavesPage, leavesRowsPerPage]);
  useEffect(() => {
    if (success) {
      departments.map((dep) => {
        if (
          dep?.head_of_department?._id === userAuth?.employee?._id ||
          userAuth?.employee?.isLineManager
        ) {
          return setIsHOD(true);
        }
      });
    }
  }, [success]);

  useEffect(() => {
    if (createLeaveSuccess) {
      successNotify(createLeave?.message);
      dispatch(getCurrentUserLeaveApplications(leavesPage, leavesRowsPerPage));
      setModalOpen(false);
      dispatch({ type: "CREATE_LEAVE_RESET" });
      dispatch(getCurrentUser());
    }
    if (createError) {
      errorNotify(createError);
      dispatch({ type: "CLEAR_ERRORS" });
    }
  }, [createLeaveSuccess, createError]);
  const handleAssignedLeaves = () => {
    setTab(2);
    // dispatch(
    //   getLineManagerLeaveApplications(assignedPage, assignedRowsPerPage)
    // );
  };

  return (
    <div className="page_responsive">
      <Header title="LEAVE MANAGEMENT" />
      <MetaData title={`Leave Management -- HRM`} />
      {departmentsLoading ? (
        <Loader />
      ) : (
        <>
          {isHOD ? (
            <Paper className={classes2.button_container}>
              <Box
                sx={{
                  py: 3,
                  px: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Button
                    variant="contained"
                    className={classes2.tab_btn}
                    onClick={() => setTab(1)}
                  >
                    My Leaves
                  </Button>
                  <Button
                    variant="contained"
                    className={classes2.tab_btn}
                    onClick={handleAssignedLeaves}
                  >
                    Requested Leaves
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  className={classes2.add_leave_btn}
                  onClick={() => setModalOpen(true)}
                >
                  <AiOutlinePlus style={{ marginRight: "5px" }} /> Add Leave
                </Button>
              </Box>
            </Paper>
          ) : (
            <Paper className={classes2.button_container}>
              <Box
                sx={{
                  py: 3,
                  px: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  className={classes2.add_leave_btn}
                  onClick={() => setModalOpen(true)}
                >
                  <AiOutlinePlus style={{ marginRight: "5px" }} /> Add Leave
                </Button>
              </Box>
            </Paper>
          )}
          <Box sx={{ px: 2 }}>
            {tab === 1 ? (
              loading ? (
                <Loader />
              ) : (
                <MyLeaves
                  classes={classes}
                  leavesPage={leavesPage}
                  setLeavesPage={setLeavesPage}
                  leavesRowsPerPage={leavesRowsPerPage}
                  setLeavesRowsPerPage={setLeavesRowsPerPage}
                  leaveApplications={leaveApplications}
                  leavesCount={leavesCount}
                />
              )
            ) : (
              <AssignedLeaves />
            )}
          </Box>
        </>
      )}
      <ApplyForLeaveModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default Leaves;
