import { Avatar, Box, Paper, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../Components/Header/Header";
import Input from "../../../../Components/Input/Input";
import MetaData from "../../../../Components/MetaData";
import { getEmployeeCurrentMonthAttendance } from "../../../../Redux/Actions/Attendance";
import { useStyle } from "../../../HR/Pages/Attendance/attendance-styles";
import AttendanceIcon from "../../../../Asssets/Images/AttendanceWhite.png";
import { getUser, tConvert } from "../../../../Utils/helper";
import { useForm } from "react-hook-form";
import { errorNotify } from "../../../../Utils/toast";
import { getMonthlyTimesheetOfSelectedEmployee } from "../../../../Redux/Actions/Timesheet";
import Loader from "../../../../Utils/Loader";
import MonthlyTimesheet from "../../../HR/Pages/TimeSheet/MonthlyTimesheet";
import moment from "moment";

const Attendance = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  let userAuth = getUser() ? getUser() || "" : null;
  let total = [];
  const { loading, attendance, error } = useSelector(
    (state) => state.currentMonthAttendance
  );
  let {
    loading: monthlyTimesheetLoading,
    success: monthlyTimesheetSucess,
    error: monthlyTimesheetError,
    attendances,
  } = useSelector((state) => state.monthlyTimesheet);
  // console.log(attendance)
  attendance?.attendances &&
    attendance?.attendances.map((att) => {
      if (att.status === "present") {
        total["presents"] += 1;
      } else if (att.status === "Absent") {
        total["absents"] += 1;
      } else if (att.status === "half-day") {
        total["HalfDays"] += 1;
      } else if (att.status === "late") {
        total["Lates"] += 1;
      }
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmitHandler = handleSubmit(async (data) => {
    let monthYear = data.month_year.split("-");
    if (!data.month_year) {
      errorNotify("please select employee and month!");
    }
    const formData = {
      // employee: data?.employee,
      // department: data?.department,
      month: monthYear[1],
      year: monthYear[0],
    };
    dispatch(
      getMonthlyTimesheetOfSelectedEmployee({
        id: userAuth?.employee?._id,
        month: formData.month,
        year: formData.year,
      })
    );
  });

  useEffect(() => {
    dispatch(getEmployeeCurrentMonthAttendance());
    return () => {
      dispatch({ type: "GET_MONTHLY_EMPLOYEE_TIMESHEET_RESET" });
    };
  }, []);
  return (
    <div className="page_responsive">
      <div className="time_sheet_main">
        <Header title="EMPLOYEES ATTENDANCE" />
        <MetaData title={`Attendance -- HRM`} />
        <Container fluid>
          <Row className="m-0">
            <Col md={12}>
              <div className="employees_main_selection">
                <Form onSubmit={onSubmitHandler}>
                  <Row>
                    <Col md={3}>
                      <Input>
                        <Form.Label>Month/Year</Form.Label>
                        <Form.Control
                          type="month"
                          aria-label="Default select example"
                          {...register("month_year")}
                        ></Form.Control>
                      </Input>
                    </Col>
                    <Col md={9} className="d-flex">
                      <div className="search_btn_container">
                        <button>
                          <FiSearch />
                          Search
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
          {loading || monthlyTimesheetLoading ? (
            <Loader />
          ) : (
            <Box>
              {!monthlyTimesheetSucess && attendance ? (
                <Box
                  sx={{ margin: "40px 20px" }}
                  className={`${classes.attendanceTable} attendanceTable`}
                >
                  <Paper
                    sx={{
                      width: "100%",
                      my: 2,
                      borderRadius: "20px",
                      px: 2,
                      py: 1,
                      boxShadow:
                        "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
                    }}
                  >
                    <Box className={classes.leave_card_header}>
                      <img
                        src={AttendanceIcon}
                        alt=""
                        style={{ width: "30px" }}
                      />
                      <Typography
                        variant="h5"
                        className={classes.card_header_heading}
                        sx={{ color: "white", ml: 1, fontSize: "20px" }}
                      >
                        Attendances
                      </Typography>
                    </Box>
                    <Box
                      sx={{ height: "300px", overflowY: "auto", my: 1 }}
                      className="tickets_box"
                    >
                      {attendance && attendance.attendances.length > 0 ? (
                        <table id="attendance_table" style={{ width: "100%" }}>
                          <tr
                            style={{ textAlign: "left", padding: "20px 0px" }}
                          >
                            <th>
                              {" "}
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Employee
                              </Typography>
                            </th>
                            <th>
                              {" "}
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Date
                              </Typography>
                            </th>
                            <th>
                              {" "}
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Day
                              </Typography>
                            </th>
                            <th>
                              {" "}
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Check In
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Check Out
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Late Time
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Early Off
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Over Time
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Total Hours
                              </Typography>
                            </th>
                            <th>
                              <Typography
                                variant="h6"
                                className={`${classes.leave_card_heading} fs-14`}
                              >
                                Status
                              </Typography>
                            </th>
                          </tr>

                          {attendance?.attendances &&
                            attendance?.attendances
                              .slice(0)
                              .reverse()
                              .map((a, i) => (
                                <tr key={i}>
                                  <td>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        py: 1,
                                      }}
                                    >
                                      <Avatar src={a?.user?.profilePic} />
                                      <Typography
                                        sx={{ pl: 1 }}
                                        className="fs-12 align-text"
                                      >
                                        {a.user?.name}
                                      </Typography>
                                    </Box>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {moment(a.date).format("DD-MM-YYYY")}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {new Date(a.date).toLocaleDateString(
                                        "en-US",
                                        {
                                          weekday: "long",
                                        }
                                      )}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {a?.check_in && a?.check_in !== "-"
                                        ? tConvert(
                                            new Date(a?.check_in)
                                              .toString()
                                              .split(" ")[4]
                                          )
                                        : // .toLocaleString()
                                          // .split(" ")[1]
                                          "-"}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {a?.check_out !== "-"
                                        ? //  new Date(
                                          tConvert(
                                            new Date(a?.check_out)
                                              .toString()
                                              .split(" ")[4]
                                          )
                                        : // )
                                          // : .toLocaleString()
                                          // .split(" ")[1]
                                          "-"}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {a.late_time}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {a.early_off}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {a.overtime}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      className="fs-12 align-text"
                                      sx={{ py: 1 }}
                                    >
                                      {a.totalHours}
                                    </Typography>
                                  </td>

                                  <td>
                                    {new Date(a.date).toLocaleDateString(
                                      "en-US",
                                      {
                                        weekday: "long",
                                      }
                                    ) === "Sunday" ||
                                    new Date(a.date).toLocaleDateString(
                                      "en-US",
                                      {
                                        weekday: "long",
                                      }
                                    ) === "Saturday" ? (
                                      <Typography
                                        sx={{ fontSize: "10px", pl: 1 }}
                                        className={`${classes.weekend}`}
                                      >
                                        Weekend
                                      </Typography>
                                    ) : (
                                      <Typography
                                        className={`${
                                          a.status === "present"
                                            ? classes.present
                                            : a.status === "Absent"
                                            ? classes.absent
                                            : a.status === "half-day"
                                            ? classes.halfDay
                                            : a.status === "late"
                                            ? classes.late
                                            : a.status === "On Leave"
                                            ? classes.onLeave
                                            : a.status === "On Half Day Leave"
                                            ? classes.halfDayLeave
                                            : classes.holiday
                                        } fs-12`}
                                      >
                                        {a?.status}
                                      </Typography>
                                    )}
                                  </td>
                                </tr>
                              ))}
                        </table>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <h4
                            className="text-center"
                            style={{ fontWeight: "700" }}
                          >
                            No Data Found!
                          </h4>
                        </div>
                      )}
                    </Box>
                  </Paper>
                </Box>
              ) : (
                <Box sx={{ margin: "40px 20px" }}>
                  <div className="time_sheet_table">
                    <MonthlyTimesheet
                      classes={classes}
                      attendances={attendances}
                    />
                  </div>
                </Box>
              )}
            </Box>
          )}
        </Container>
      </div>
    </div>
  );
};

export default Attendance;
