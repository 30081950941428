import { makeStyles } from "@mui/styles";

export const leaveStyle = makeStyles({
  button_container: {
    margin: "16px !important",
    boxShadow: "none !important",
    borderBottomLeftRadius: "20px !important",
    borderBottomRightRadius: "20px !important",
    border: "none !important",
  },
  tab_btn: {
    marginRight: "24px !important",
    background: "linear-gradient(to bottom, #0097d9, #00b1ff) !important",
    boxShadow: "none !important",
    borderRadius: "10px !important",
  },
  add_leave_btn: {
    background: "#ee1c25 !important",
    boxShadow: "none !important",
    borderRadius: "10px !important",
  },
});
