import axios from "axios";
import { getRefreshToken, getToken, updateLocalAccessToken } from "./helper";

const instance = axios.create({
  baseURL: "https://dh-hrm.azurewebsites.net/api",
  // baseURL: "https://hrmbackend.azurewebsites.net/api",
  // baseURL: "http://localhost:5000/api",
  // baseURL: "http://DESKTOP-A2HHV4S:5000/api",
  // baseURL: "https://edf4-39-50-175-83.ngrok-free.app/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export const BaseURL = "https://dh-hrm.azurewebsites.net/api";
// export const BaseURL = "https://hrmbackend.azurewebsites.net/api";
// export const BaseURL = "http://localhost:5000/api";
// export const BaseURL = "http://DESKTOP-A2HHV4S:5000/api";
// export const BaseURL = "https://edf4-39-50-175-83.ngrok-free.app/api";

export const BaseUrl = instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/refreshtoken", {
            refreshToken: getRefreshToken(),
          });

          const { accessToken } = rs.data?.data;
          updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          localStorage.clear();
          window.location.pathname = "/";
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
