import React from "react";
import UserLogin from "../../Auth/UserLogin";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Employees from "../Pages/StaffManagement/AllEmployees/Employees";
import CreateEmployee from "../Pages/StaffManagement/CreateEmployee/CreateEmployee";
import DashboardIcon from "../../../Asssets/Images/DashboardIcon.png";
import ProfileIcon from "../../../Asssets/Images/ProfileIcon.png";
import TimeSheetIcon from "../../../Asssets/Images/timesheetIcon.png";
import StaffIcon from "../../../Asssets/Images/Staff.png";
import AttendanceIcon from "../../../Asssets/Images/AttendanceIcon.png";
import AnnouncementIcon from "../../../Asssets/Images/AnnouncementIcon.png";
import PayrollIcon from "../../../Asssets/Images/payrollIcon.png";
import LeaveManagementIcon from "../../../Asssets/Images/LeaveManagementIcon.png";
import TicketsIcon from "../../../Asssets/Images/TicketsIcon.png";
import PolicyIcon from "../../../Asssets/Images/Policy.png";
import SetupIcon from "../../../Asssets/Images/Setup.png";
import Attendance from "../Pages/Attendance/Attendance";
import Announcement from "../Pages/Announcement/Announcement";
import Payroll from "../Pages/Payroll/Payroll";
import LeaveManagement from "../Pages/LeaveManagement/LeaveManagement";
import Ticket from "../Pages/Ticket/Ticket";
import CompanyPolicies from "../Pages/CompanyPolicies/CompanyPolicies";
import HRMSetup from "../Pages/HRMSetup/HRMSetup";
import EmployeeDetails from "../Pages/StaffManagement/EmployeeDetails/EmployeeDetails";
import TimeSheet from "../Pages/TimeSheet/TimeSheet";
import EditEmployee from "../Pages/StaffManagement/EditEmployee/EditEmployee";
import LeaveDetails from "../Pages/LeaveManagement/LeaveDetails";
import TicketDetails from "../Pages/Ticket/TicketDetails";
import Profile from "../../Employee/Pages/Profile/Profile";
import PayrollUpdate from "../Pages/Payroll/PayrollUpdate";
import PayrollDetails from "../Pages/Payroll/payrollDetails";
import Payslip from "../Pages/Payroll/Payslip/Payslip";
import Requests from "../Pages/RequestModule/Requests";
import RequestDetails from "../Pages/RequestModule/RequestDetails";
import WorkFromHome from "../Pages/WorkFromHome";

// HR Routes

export const userRoutes = [
  {
    path: "/",
    component: <UserLogin />,
  },
];

export const hrRoutes = [
  {
    path: "/HR/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/HR/profile",
    component: <Profile />,
  },
  {
    path: "/HR/employees",
    component: <Employees />,
  },
  {
    path: "/HR/employees/create",
    component: <CreateEmployee />,
  },
  {
    path: "/HR/employee/:id",
    component: <EmployeeDetails />,
  },
  {
    path: "/HR/edit-employee/:id",
    component: <EditEmployee />,
  },
  {
    path: "/HR/attendance",
    component: <Attendance />,
  },
  {
    path: "/HR/announcement",
    component: <Announcement />,
  },
  {
    path: "/HR/payroll",
    component: <Payroll />,
  },
  {
    path: "/HR/payroll/:id",
    component: <PayrollDetails />,
  },
  {
    path: "/HR/payroll/payslip/:id",
    component: <Payslip />,
  },
  {
    path: "/HR/payroll/update/:id",
    component: <PayrollUpdate />,
  },
  {
    path: "/HR/leave-management",
    component: <LeaveManagement />,
  },
  {
    path: "/HR/leave-management/:id",
    component: <LeaveDetails />,
  },
  {
    path: "/HR/tickets",
    component: <Ticket />,
  },
  {
    path: "/HR/tickets/:id",
    component: <TicketDetails />,
  },
  // {
  //   path: "/HR/company-policies",
  //   component: <CompanyPolicies />,
  // },
  {
    path: "/HR/hrm-setup",
    component: <HRMSetup />,
  },
  {
    path: "/HR/timesheet",
    component: <TimeSheet />,
  },
  {
    path: "/HR/requests",
    component: <Requests />,
  },
  {
    path: "/HR/request/:id",
    component: <RequestDetails />,
  },
  {
    path: "/HR/wfh",
    component: <WorkFromHome />,
  },
];

export const HrSideBarItems = [
  {
    path: "/HR/dashboard",
    icon: <DashboardIcon />,
    title: "Dashboard",
    isSubNav: false,
  },
  {
    path: "/HR/profile",
    icon: <ProfileIcon />,
    title: "Profile",
    isSubNav: false,
  },
  {
    path: `${"/HR/employees/create" && "/HR/employees"}`,
    icon: <StaffIcon />,
    title: "Staff Management",
    isSubNav: false,
  },
  {
    path: "/HR/attendance",
    icon: <AttendanceIcon />,
    title: "Attendance",
    isSubNav: false,
  },
  {
    path: "/HR/requests",
    icon: <AttendanceIcon />,
    title: "Request Module",
    isSubNav: false,
  },
  {
    path: "/HR/timesheet",
    icon: <TimeSheetIcon />,
    title: "Time Sheet",
    isSubNav: false,
  },
  {
    path: "/HR/announcement",
    icon: <AnnouncementIcon />,
    title: "Announcement",
    isSubNav: false,
  },
  {
    path: "/HR/payroll",
    icon: <PayrollIcon />,
    title: "Payroll",
    isSubNav: false,
  },
  {
    path: "/HR/leave-management",
    icon: <LeaveManagementIcon />,
    title: "Leave Management",
    isSubNav: false,
  },
  {
    path: "/HR/tickets",
    icon: <TicketsIcon />,
    title: "Tickets",
    isSubNav: false,
  },
  // {
  //   path: "/HR/company-policies",
  //   icon: <PolicyIcon />,
  //   title: "Company Policies",
  //   isSubNav: false,
  // },
  {
    path: "/HR/hrm-setup",
    icon: <SetupIcon />,
    title: "HRM Setup",
    isSubNav: false,
  },
];
