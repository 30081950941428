import { Box, Button, InputLabel, TextField } from "@mui/material";
import React, { useEffect } from "react";
import ReactModal from "../../../../../Components/Modal/Modal";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  clearErrors,
  createShiftByHR,
} from "../../../../../Redux/Actions/Shifts";
import { useDispatch, useSelector } from "react-redux";
import { errorNotify, successNotify } from "../../../../../Utils/toast";
import { getAllShifts } from "../../../../../Redux/Actions/HR";
import { Spinner } from "react-bootstrap";

const CreateShiftModal = ({
  classes,
  modalOpen,
  setModalOpen,
  shiftData,
  setShiftData,
  page,
  rowsPerPage,
}) => {
  const dispatch = useDispatch();
  const {
    loading: createLoading,
    shift,
    error: createError,
    success,
  } = useSelector((state) => state.createShift);
  useEffect(() => {
    if (success) {
      successNotify(shift?.message);
      dispatch({ type: "CREATE_SHIFT_RESET" });
      setModalOpen(false);
      dispatch(getAllShifts(page, rowsPerPage));
    }
    if (createError) {
      errorNotify(createError);
      dispatch(clearErrors());
    }
  }, [success, createError, shift]);
  const handleCreateShift = () => {
    dispatch(
      createShiftByHR({
        shift_name: shiftData.shift_name,
        start_time: new Date(shiftData.start_time).toISOString(),
        // .split(" ")[4]
        // .slice(0, 5),
        end_time: new Date(shiftData.end_time).toISOString(),
        // .split(" ")[4]
        // .slice(0, 5),
      }),
    );
  };
  return (
    <ReactModal
      open={modalOpen}
      setOpen={setModalOpen}
      title="Create New Shift"
    >
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>Shift Name</InputLabel>
        <TextField
          sx={{ mt: 1 }}
          placeholder="Enter Shift Name"
          fullWidth
          className={classes.inputContainer}
          value={shiftData.shift_name}
          onChange={(e) =>
            setShiftData({ ...shiftData, shift_name: e.target.value })
          }
        />
      </Box>
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>
          Shift Start Time
        </InputLabel>
        <Box className={classes.timePicker}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={shiftData.start_time}
              onChange={(newValue) => {
                setShiftData({ ...shiftData, start_time: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.inputContainer}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <InputLabel className={classes.department_label}>
          Shift End Time
        </InputLabel>
        <Box className={classes.timePicker}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={shiftData.end_time}
              onChange={(newValue) => {
                setShiftData({ ...shiftData, end_time: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.inputContainer}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.createBtn}
            onClick={() => handleCreateShift()}
            disabled={createLoading ? true : false}
          >
            {createLoading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Box>
    </ReactModal>
  );
};

export default CreateShiftModal;
