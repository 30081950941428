import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import moment from "moment";
import { useSelector } from "react-redux";

const MyTickets = ({
  ticketsPage,
  setTicketsPage,
  ticketsRows,
  setTicketsRows,
  tickets,
  ticketsCount,
}) => {
  const navigate = useNavigate();
  const {
    err: userError,
    user: employee,
    loading: userLoading,
  } = useSelector((state) => state.currentUser);
  const ticketsColumn = [
    {
      name: "_id",
      options: {
        display: false,
      },
    },
    {
      name: "assignBy",
      label: "Assign By",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value?.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "assignTo",
      label: "Assign To",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value?.profile_picture[0]?.fileName} />
              <Typography sx={{ pl: 1 }} className="fs-12">
                {value?.full_name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "subject",
      label: "Subject",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value}
            </Typography>
          );
        },
      },
    },

    {
      name: "last_date",
      label: "Last Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? moment(value).format("DD-MM-YYYY") : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ pl: 1, textAlign: "center" }}
              className={
                value === "medium"
                  ? "medium_status"
                  : value === "low"
                  ? "low_status"
                  : value === "critical"
                  ? "critical_status"
                  : value === "high"
                  ? "high_status"
                  : null
              }
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ pl: 1, textAlign: "center" }}
              className={
                value === "on-hold"
                  ? "medium_status"
                  : value === "open"
                  ? "high_status"
                  : value === "close"
                  ? "low_status"
                  : null
              }
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="leaves_management_btn_container">
              <button
                className="edit_btn"
                onClick={() =>
                  navigate(
                    `/${
                      employee?.user?.role === "employee"
                        ? "employee"
                        : "manager"
                    }/tickets/${tableMeta.rowData[0]}`,
                    {
                      state: { update: false },
                    }
                  )
                }
              >
                <FaEye />
              </button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <Box className="departmentsTable">
      <MuiDataTable
        title="Departments"
        columns={ticketsColumn}
        data={tickets}
        page={ticketsPage}
        setPage={setTicketsPage}
        rowsPerPage={ticketsRows}
        setRowsPerPage={setTicketsRows}
        count={ticketsCount}
        tableId="my_tickets_table"
      />
    </Box>
  );
};

export default MyTickets;
