const headers = [
  { label: "Name", key: "name" },
  { label: "CNIC", key: "cnic" },
  { label: "Current Designation", key: "current_designation" },
  { label: "Department", key: "department" },
  { label: "DOJ", key: "DOJ" },
  { label: "Month", key: "month" },
  { label: "Total Salary", key: "total_salary" },
  { label: "Medical Insurance", key: "medical_insurance" },
  { label: "OPD", key: "OPD" },
  { label: "Retention Incentive", key: "retention_Incentive" },
  { label: "Total Empolyee Package", key: "total_employee_package" },
  { label: "Working Days", key: "working_days" },
  { label: "Monthly/Basic Salary", key: "monthly_basic_salary" },
  { label: "Commission", key: "commission" },
  { label: "Bonus", key: "bonus" },
  { label: "Arrears", key: "arrears" },
  { label: "Retention", key: "retention" },
  { label: "Others", key: "others" },
  { label: "Total Earnings", key: "total_earnings" },
  { label: "Paid Leaves and Halfdays", key: "paid_leaves_and_halfdays" },
  { label: "Unpaid Leaves and Halfdays", key: "unpaid_leaves_and_halfdays" },
  { label: "Tax Deduction", key: "tax_deduction" },
  { label: "Loan Deduction", key: "loan_deduction" },
  { label: "Deduction", key: "deduction" },
  { label: "Total Deduction", key: "total_deduction" },
  { label: "Net Payable Salary", key: "net_payable_salary" },
  { label: "Total Working Days", key: "total_working_days" },
  { label: "Off Working Days", key: "off_working_days" },
  { label: "Actual Working Days", key: "actual_working_days" },
  {
    label: "Total Casual and Sick Leaves",
    key: "total_casual_and_sick_leaves",
  },
  { label: "Used Casual and Sick Leaves", key: "used_casual_and_sick_leaves" },
  {
    label: "Remaining Casual and Sick Leaves",
    key: "remaining_casual_and_sick_leaves",
  },
  { label: "Total Annual Leaves", key: "total_annual_leaves" },
  { label: "Used Annual Leaves", key: "used_annual_leaves" },
  { label: "Remaining Annual Leaves", key: "remaining_annual_leaves" },
  { label: "Remaining Loan Balance", key: "remaining_loan_balance" },
  { label: "Medical Insurance Limit", key: "medical_insurance_limit" },
  { label: "OPD Balance", key: "OPD_balance" },
];

export default headers;
