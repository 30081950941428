import { Avatar, Typography } from "@mui/material";
import React, { useState } from "react";
import MuiDataTable from "../../../../Components/MUIDataTable/MUIDataTable";
import { minutesConvert, tConvert } from "../../../../Utils/helper";
import { useNavigate } from "react-router-dom";

const TodayTimesheet = ({ classes, timesheet }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const timeSheetData = [];
  timesheet &&
    timesheet.map((item, index) => {
      timeSheetData.push({
        employeeName: item?.user,
        employeePic: item?.user?.profile_picture[0]?.fileName,
        department:
          item?.user.department?.department_name || item?.user?.department,
        check_in: item?.check_in
          ? new Date(item?.check_in).toLocaleString().split(",")[1]
          : "",
        check_in_remarks: item?.check_in_remarks,
        check_out: item?.check_out
          ? new Date(item?.check_out).toLocaleString().split(",")[1]
          : "",
        check_out_remarks: item?.check_out_remarks,
        late_time: item?.late_time,
        early_off: item?.early_off,
        overtime: item?.overtime,
        totalHours: item?.totalHours,
        status: item?.status,
      });
    });
  const TimeSheetcolumns = [
    {
      name: "employeePic",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={value} />
              {/* <Typography sx={{ pl: 1 }} className="fs-12">
                {value?.full_name}
              </Typography> */}
            </div>
          );
        },
        download: false,
        print: false,
        display: false,
      },
    },
    {
      name: "employeeName",
      label: "Employee",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar src={tableMeta.rowData[0]} />
              <Typography
                onClick={() => navigate(`/HR/employee/${value?.userId}`)}
                style={{
                  cursor: "pointer",
                  textUnderlineOffset: "4px",
                }}
                sx={{ pl: 1 }}
                className="fs-12 link__color"
              >
                {value?.full_name}
              </Typography>
            </div>
          );
        },
        // download:false,
        // print:false,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1, textAlign: "center" }} className="fs-12">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_in",
      label: "Check In",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_in_remarks",
      label: "Check In Remarks",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_out",
      label: "Check Out",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "check_out_remarks",
      label: "Check Out Remarks",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "late_time",
      label: "Late Time",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "early_off",
      label: "Early Off",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "totalHours",
      label: "Total Hours",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography sx={{ pl: 1 }} className="fs-12">
              {value ? minutesConvert(value) : "-"}
            </Typography>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              sx={{ fontSize: "10px", pl: 1 }}
              className={`${
                value === "present"
                  ? classes.present
                  : value === "Absent"
                  ? classes.absent
                  : value === "half-day"
                  ? classes.halfDay
                  : value === "late"
                  ? classes.late
                  : value === "On Leave"
                  ? classes.onLeave
                  : value === "On Half Day Leave"
                  ? classes.halfDayLeave
                  : classes.holiday
              }`}
            >
              {value ? value : "-"}
            </Typography>
          );
        },
      },
    },
  ];
  return (
    <>
      <div className="monthly_timesheet_table">
        <MuiDataTable
          isDownload={false}
          columns={TimeSheetcolumns}
          data={timeSheetData}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          // count={departmentCount}
          tableId="today_timesheet_table"
        />
      </div>
    </>
  );
};

export default TodayTimesheet;
