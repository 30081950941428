import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  inputContainer: {
    margintop: "4px",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      border: "0px solid rgba(235,235,235,90%) !important",
      borderRadius: "8px !important",
      backgroundColor: "#F3F8FE !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px !important",
      backgroundColor: "#F3F8FE",
      border: "none !important",
      fontSize: "14px !important",
      // border: "1px solid #EBEBEB !important"
    },
  },
  department_label: {
    fontWeight: "600 !important",
    color: "rgb(37, 55, 102) !important",
    fontSize: "15px !important",
    lineHeight: "2.75 !important",
    height: "30px !important",
    display: "flex !important",
    alignItems: "flex-end !important"

  },
  datePicker: {
    "& .MuiFormLabel-root": {
      top: "-7px !important",
    },
  },
});
