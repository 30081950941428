export const todaysAttendanceReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_TODAY'S_TIMESHEETS_REQUEST":
      return {
        loading: true,
      };
    case "GET_TODAY'S_TIMESHEETS_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        timesheet: action.payload.timesheet,
        // absents:action.payload.absents
      };
    case "GET_TODAY'S_TIMESHEETS_FAILED":
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const monthlyAttendanceReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_MONTHLY_EMPLOYEE_TIMESHEET_REQUEST":
      return {
        loading: true,
        attendances: null,
      };
    case "GET_MONTHLY_EMPLOYEE_TIMESHEET_SUCCESS":
      return {
        loading: false,
        success: action.payload.success,
        attendances: action.payload.attendances,
      };
    case "GET_MONTHLY_EMPLOYEE_TIMESHEET_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "GET_MONTHLY_EMPLOYEE_TIMESHEET_RESET":
      return {
        ...state,
        attendances: null,
        success: false,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
