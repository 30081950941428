import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  attendance_box: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 30px !important",
    boxShadow: "none !important",
    borderRadius: "20px !important",
  },
  check_in_btn: {
    display: "flex",
    textTransform: "none !important",
    background: "linear-gradient(to bottom ,#0DC81F, #147D12) !important",
    color: "white",
    mr: 2,
    borderRadius: "80px !important ",
    padding: "12px 15px !important",
  },
  check_out_btn: {
    display: "flex",
    textTransform: "none !important",
    background: "#FE022C !important",
    color: "white",
    mr: 2,
    borderRadius: "80px !important",
    padding: "12px 15px !important",
  },
  dashboard_card: {
    height: "100%",
    padding: "16px",
    borderRadius: "20px !important",
    boxShadow:
      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
  },
  card_header: {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    backgroundColor: "#F3F8FE",
    width: "calc(100% - 0px) !important",
    display: "flex",
    borderRight: "4px solid #14D4E2",
    padding: "8px 0px",
    paddingLeft: "8px",
    marginBottom: "8px",
  },
  leave_box: {
    width: "100%",
    my: 2,
    borderRadius: "20px !important",
    px: 2,
    py: 1,
    boxShadow:
      "rgb(50 50 93 / 10%) 0px 0px 100px -20px, rgb(0 0 0 / 20%) 0px 30px 23px -30px !important",
  },
  card_header_heading: {
    fontWeight: "700 !important",
    color: "#253766",
    paddingLeft: "8px",
    fontSize: "19px !important",
  },
  leave_card_header: {
    background: "linear-gradient(to bottom,#0097D9,#00B1FF)",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
  },
  leave_card_heading: {
    textAlign: "left",
    fontWeight: "700 !important",
    color: "#253766",
    marginBottom: "8px",
  },
  pending_status: {
    padding: "5px 10px",
    backgroundColor: "#f7941d",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  approved_status: {
    padding: "5px 10px",
    backgroundColor: "rgb(89, 198, 128)",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  rejected_status: {
    padding: "5px 10px",
    backgroundColor: "#FE022C",
    borderRadius: "20px",
    color: "white",
    width: "fit-content",
    // py: 1,
  },
  department_label: {
    fontWeight: "700 !important",
    color: "rgb(37, 55, 102) !important",
    fontSize: "15px !important",
  },
  createBtn: {
    background: "linear-gradient(to bottom ,#0DC81F, #147D12) !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
    fontSize: "1rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(6,151,0,30%) !important",
    textTransform: "none !important",
  },
  checkOutBtn: {
    background: "#FE022C !important",
    color: "white !important",
    padding: "8px 20px !important",
    borderRadius: "25px !important",
    fontSize: "1rem !important",
    fontWeight: "600 !important",
    boxShadow: "0px 15px 15px rgba(222,1,38,20%) !important",
    textTransform: "none !important",
  },
  inputContainer: {
    margintop: "4px",
    border: "none !important",
    "& .MuiOutlinedInput-root": {
      border: "0px solid rgba(235,235,235,90%) !important",
      borderRadius: "8px !important",
      padding: "0px !important",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px !important",
      backgroundColor: "#F3F8FE",
      border: "none !important",
      fontSize: "14px !important",
      // border: "1px solid #EBEBEB !important"
    },
  },
});
